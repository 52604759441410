
const defaultRssSetting = {
    play_video_full_length: false,
    display_video_content: false,
    image_fullscreen: false,
    original_layout: false,
    posts_per_page: false,
    image_blending: false,
    stretch_image: false,
    show_image: false,
    mute_video: false,
    design: "original",
    bg_color: '#dddddd',
    text_color: '#000000ff',
    position: "FULL_SCREEN",
    speed: 1,
    direction: "left",
    separator: "",
    newSeparator: null,
    duration: 20,
    language: "en",
    font_family: "Roboto",
    font_size: 20,
    font_size_device: "Small",
}


const designOptions = [
    { label: "Original", value: "original" },
    { label: "Default", value: "default" },
];

const blendingOptions = [
    { label: "full", value: "full" },
    { label: "Medium", value: "medium" },
    { label: "None", value: "none" },
];

const perPageOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
];

const directionOptions = [
    { icon: <i className="fas fa-arrow-left" ></i>, value: 'left' },
    { icon: <i className="fas fa-arrow-right" ></i>, value: 'right' },
    // { icon: <i className="fas fa-arrow-up" ></i>, value: 'up' },
    // { icon: <i className="fas fa-arrow-down" ></i>, value: 'down' }
];

const speedOptions = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
];

const rotationOptions = [
    { label: "0ْ ", value: "0" },
    { label: "90ْ ", value: "90" },
    { label: "180ْ ", value: "180" },
    { label: "270 ", value: "270" },
];

// ReactPrime file input options 
const chooseOptions = {
    className: 'upload_separator',
    label: 'click to upload image...',
    style: {
        background: 'transparent',
        color: 'gray',
        width: "100%",
        border: "2px dashed #D9DEE3",
        fontWeight: "normal",
        fontSize: "14px",

    }
};

// Campaign statuses option
const campaignStatusOptions = [
    { name: 'Draft', code: "DRAFT" },
    { name: 'Published', code: 'PUBLISHED' },
];

const emptyCampaign = {
    name: "",
    type: "",
    start_time: "",
    end_time: "",
    date: ""
};

export {
    defaultRssSetting,

    // dropdown options
    campaignStatusOptions,
    directionOptions,
    blendingOptions,
    rotationOptions,
    perPageOptions,
    designOptions,
    speedOptions,
    chooseOptions,

    emptyCampaign,
}