import React, { useEffect, useState } from 'react'

import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import TemplatesLayouts from './Templates/TemplatesLayouts';
import CustomLayouts from './Custom/CustomLayouts';
import { Paginator } from 'primereact/paginator';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useFetchTemplates } from '../../../../queries/layouts';
import { Spinner } from 'react-bootstrap';


function CreateLayoutPopUp() {

  const dispatch = useDispatch()

  const { openDialog, } = useSelector((state) => state.global);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const rowsPerPage = 19;

  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading:templatesLoading, refetch, isFetching } = useFetchTemplates(currentPage);

  useEffect(() => {
    // dispatch(toggleLoader("createLoading"))
    refetch();
  }, [currentPage]);

  const items = [
    {
      label: 'Templates Layouts',
      command: () => setActiveIndex(0),
    },
    {
      label: 'Custom Layouts',
      command: () => setActiveIndex(1),
    },
  ];

  useEffect(() => {
    if (data?.totalRecords !== undefined) {
      setTotalRecords(data.totalRecords);
    }
  }, [data]);

  const onPageChange = (event) => {
    setCurrentPage(event.page);
  };

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <TemplatesLayouts templatesLoading={isFetching}/>;
      case 1:
        return <CustomLayouts templatesLoading={isFetching}/>;
      default:
        return null;
    }
  };

  return (
    <Dialog visible={openDialog.CreateLayoutPopUp}
      breakpoints={{ '960px': '95vw' }}
      header="Choose a Template"
      modal
      style={{ overflow: 'hidden' }}
      blockScroll={true}
      className="p-fluid w-[75%] h-[85%]"
      onHide={() => dispatch(toggleDialog("CreateLayoutPopUp"))}
    >
      <div className="overflow-hidden h-full">
        <TabMenu className='pe-5 pl-5' model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        {
          templatesLoading ?
            <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
              <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
            </div>
            : <>
              <div className="flex flex-col mt-3 overflow-y-auto h-[calc(100%-3rem)]" >
                {renderContent()}
              </div>
              {
                activeIndex === 1 ?
                  <div className="sticky bottom-0 bg-white p-2">
                    <Paginator
                      first={currentPage * rowsPerPage}
                      rows={rowsPerPage}
                      totalRecords={totalRecords}
                      onPageChange={onPageChange}
                    />
                  </div>
                  : ""}
            </>
            }
      </div>
    </Dialog>
  )
}

export default CreateLayoutPopUp