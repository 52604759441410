import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useTitle, formatDuration, } from "../../../../config/global";
import "../../css/setup.css";

import AddContentPopup from '../../../SharedComponents/AddContentPopup/AddContentPopup';
import AssignPopup from '../AssignToDevicePopup';
import DragDrop from '../../../SharedComponents/DragDrop/DragDrop';
import Layout from '../../../Layout/Layout'

import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { useSelector, useDispatch } from 'react-redux';
import { setHideTabs, setCampaignDetails, setLayer, setType, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useGetCampaign, useGetCampaignLayerContent, usePublishCampaignMutation, useResetCampaignMutation } from '../../../../queries';
import { Tooltip } from 'primereact/tooltip';

function CampaignSetup() {
  useTitle("Campaign Setup")

  const dispatch = useDispatch();
  const { isPrayerCampaign, campaignDetails, openDialog, layerOptions, layer, assign } = useSelector((state) => state.global);
  const { id, type } = useParams();
  const { isLoading: campaignLoading, data: campaignInfo } = useGetCampaign({ enabled: true, id });
  const { isLoading: layerLoading, data, refetch } = useGetCampaignLayerContent(id, layer);
  const publishCampaign = usePublishCampaignMutation()
  const resetCampaign = useResetCampaignMutation()


  const isDefaultPrayer = !JSON.parse(sessionStorage.getItem('is_default_prayer'));
  const loading = layerLoading

  //  Responsive dropdown actions menu
  const options = [
    {
      label: 'Add Content',
      icon: 'pi pi-plus',
      command: () => {
        dispatch(toggleDialog("addContent"))
      }
    }, {
      label: 'Assign',
      icon: 'bx bx-up-arrow-alt',
      command: () => {
        dispatch(toggleDialog("assignDeviceGroup"))
      }
    },
    {
      label: 'Publish',
      icon: '',
      command: () => {
        publish()
      }
    },
    {
      label: 'Reset',
      visible: campaignDetails.status === "DRAFT",
      icon: '',
      command: () => {
        reset()
      }
    }
  ];

  // Get Campaign Details 
  useEffect(() => {
    if (!isEmpty(campaignInfo)) {
      dispatch(setCampaignDetails(campaignInfo));
      dispatch(setHideTabs(false))
    }
  }, [campaignInfo])

  // Get layer media on layer change
  useEffect(() => {
    if (layer == "BANNER")
      dispatch(setType("rss"))
    else
      dispatch(setType("media"))
  }, [layer]);

  // Publish all campaign content from draft 
  const publish = async () => {
    await publishCampaign.mutateAsync({ id, name: campaignInfo?.name })
  }

  const reset = async () => {
    await resetCampaign.mutateAsync({ id, name: campaignInfo?.name })
  }

  const subNavbarProps = useMemo(() => {
    if (!isDefaultPrayer) return null;
    return {
      title: campaignLoading ? "Loading..." : campaignDetails?.name || " ",
      date: campaignLoading ? "" : campaignDetails?.created_at || ""
    };
  }, [campaignDetails]);

  const subNavbarContent = useMemo(() => {
    if (!isDefaultPrayer) return null;
    return (
      <div className={`flex ${campaignDetails.status === "DRAFT" ? "justify-between" : "justify-end"} w-100`}>
        <Button label={"Discard"} loading={resetCampaign.isLoading} className={"p-button-sm  me-2 p-button-outlined p-button-danger"}
          visible={campaignDetails.status === "DRAFT"}
          onClick={reset} />
        <div className="">
          <Button label=" "
            id="assign-btn"
            data-pr-tooltip="Assign to device"
            data-pr-position="bottom"
            icon={<span><i className="bx bx-up-arrow-alt"></i> <i className="bx bx-desktop"></i></span>}
            className={`p-button-sm  me-2 p-button-outlined ${isPrayerCampaign ? "d-none" : ""}`}
            onClick={() => dispatch(toggleDialog("assignDeviceGroup"))} disabled={loading} />
          <Tooltip target="#assign-btn" />
          <Button label={"Publish"} loading={publishCampaign.isLoading || assign} className={"p-button-sm px-5 me-2 "}
            disabled={loading || campaignDetails.status === "PUBLISHED"}
            onClick={publish} />
        </div>
      </div>

    );
  }, [campaignDetails.status, loading, publishCampaign.isLoading, assign, resetCampaign.isLoading]);

  return (
    <>
      <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
        <div className="  mr-1  d-flex justify-content-between mb-3">
          <div className="col-8 col-md-6 d-flex">
            <Dropdown value={layer} options={layerOptions} optionLabel="name" optionValue="code" className='w-50 '
              onChange={(e) => dispatch(setLayer(e.value))} />

            {
              // total duration for main layer
              layer == "MAIN" && !loading &&
              <div className='mx-3 d-none d-sm-block'>
                <p className="mb-0 ">Total Duration</p>
                <div className='fw-bold'> {formatDuration(campaignDetails.campaign_duration)}</div>
              </div>
            }

          </div>
          {
            !JSON.parse(sessionStorage.getItem('is_default_prayer')) &&
            <>
              <div className='d-none d-md-flex'>
                <Button label="Add Content" icon="pi pi-plus-circle" className=' mr-1 py-2 p-button-outlined p-button-secondary' onClick={() => dispatch(toggleDialog("addContent"))} disabled={loading} />
              </div>
              <SplitButton label="Option" model={options} className="p-button-sm mr-2 mb-2 d-flex d-md-none" />
            </>
          }
        </div>
        {
          data?.length == 0 && !loading ?
            <h5 className='w-100 card d-flex align-items-center text-center p-5 mt-5'>
              There is no media assigned yet !
            </h5>
            :
            ""
        }
        {
          loading ?
            <div className='w-100 h-100 d-flex align-items-center' >
              <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
            </div>
            :
            data?.length != 0 ? <DragDrop data={data} /> : ""
        }

        {openDialog.addContent ? <AddContentPopup loading={loading} /> : <></>}
        {openDialog.assignDeviceGroup ? <AssignPopup campaignId={id} /> : <></>}


      </Layout>
    </>
  )
}

export default CampaignSetup