import { useMutation, useQuery, useQueryClient } from "react-query";

import axiosInstance from "../config/Axios";
import { useCustomToast } from "../pages/Contexts/ToastContext";

import { useDispatch } from "react-redux";

import {
  setDefaultTemplate,
  setIsPublished,
  setLayoutAspectRatio,
  setScreenDetails,
  setScreens_setting,
  setSelectedLayout,
  setUserTemplate,
  updateCampaignMuted,
} from "../redux/Slices/layoutSlice";
import {
  setDisableBtn,
  toggleDeleteDialog,
  toggleDialog,
  toggleLoader,
} from "../redux/Slices/Global/globalSlice";
import { handleSwitchUnit } from "../config/layoutHelper";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { handleResErrors } from "./utils";

// -------------------------delete Layout-------------------------//

const deleteLayout = async (id) => {
  await axiosInstance.delete(`/layout/${id}`);
};

export const useDeleteLayout = () => {
  const { showToast } = useCustomToast();
  const dispatch = useDispatch();

  return useMutation(deleteLayout, {
    onMutate: () => {
      dispatch(setDisableBtn(true));
    },
    onSuccess: async () => {
      showToast("success", "Delete Layout", "Layout Deleted Successfully");
      dispatch(toggleDeleteDialog("layout"));
      dispatch(setDisableBtn(false));
    },
    onError: (error) => {
      showToast("error", "Layout Deletion", error.message);
      dispatch(setDisableBtn(false));
    },
  });
};

// -------------------------fetch all Templates-------------------------//

const fetchTemplates = async (page) => {
  const response = await axiosInstance.get(`/layout/all?page=${page + 1}`);
  const data = response.data.data;

  const defaultTemplate = data.default_layout.map((item) => ({
    id: item.id,
    title: item.title,
    screens_setting: item.screens_setting,
    aspect_ratio_id: item.aspect_ratio_id,
    aspect_ratio: item.aspect_ratio,
  }));

  const userTemplate = data.custom_layout.data.map((item) => ({
    id: item.id,
    title: item.title,
    screens_setting: item.screens_setting,
    aspect_ratio_id: item.aspect_ratio_id,
    aspect_ratio: item.aspect_ratio,
  }));

  return {
    defaultTemplate,
    userTemplate,
    totalRecords: data.custom_layout.pagination.total,
  };
};

export const useFetchTemplates = (currentPage) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();

  return useQuery({
    queryKey: ["templates", currentPage],
    queryFn: () => fetchTemplates(currentPage),
    onSuccess: (data) => {
      // dispatch(toggleLoader("createLoading"));
      dispatch(setDefaultTemplate(data.defaultTemplate));
      dispatch(setUserTemplate(data.userTemplate));
    },
    onError: (error) => {
      showToast("error", "Failed", "An error occurred.");
      // dispatch(toggleLoader("createLoading"));
    },
  });
};

// -------------------------select Template and create new Layout-------------------------//

const selectingTemplate = async (templateId) => {
  const response = await axiosInstance.post(`/layout/${templateId}/select`);
  return response.data.data[0].pivot.layout_id;
};

export const useSelectTemplate = (navigate, showToast) => {
  const dispatch = useDispatch();

  return useMutation(
    async (templateId) => {
      // dispatch(toggleLoader("createLoading"));
      dispatch(setDisableBtn(true));

      return selectingTemplate(templateId);
    },
    {
      onSuccess: (layoutId) => {
        dispatch(toggleDialog("CreateLayoutPopUp"));
        dispatch(setDisableBtn(false));

        navigate(`/apps/layouts/${layoutId}/show`);
        showToast("success", "Layout Creation", "Layout created successfully!");
      },
      onError: (error) => {
        // dispatch(toggleLoader("createLoading"));
        dispatch(setDisableBtn(false));
        showToast("error", "Layout Creation", error.message);
      },
    }
  );
};

// -------------------------delete Template-------------------------//

const deleteTemplate = async (templateId) => {
  await axiosInstance.delete(`/layout/${templateId}`);
};

export const useDeleteTemplate = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(deleteTemplate, {
    onMutate: () => {
      dispatch(setDisableBtn(true));
    },
    onSuccess: async () => {
      showToast(
        "success",
        "Delete Template Layout",
        "Layout Template Deleted Successfully"
      );
      // dispatch(toggleLoader("createLoading"));
      queryClient.invalidateQueries(fetchTemplates);
      dispatch(setDisableBtn(false));
    },
    onError: (error) => {
      showToast("error", "Template Deletion", error.message);
      dispatch(setDisableBtn(false));
    },
  });
};

// -------------------------create Template-------------------------//
const createTemplate = async ({ layoutName, areas, currentAspectRatio }) => {
  if (!layoutName.name.trim()) {
    throw new Error("Title is required and cannot be empty");
  }

  const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
  const sanitizedAreas = areasConverted.map(({ unit, ...rest }) => rest);

  const response = await axiosInstance.post(`/layout/create`, {
    title: layoutName.name,
    screens_setting: sanitizedAreas,
    type: "CUSTOM",
    aspect_ratio_id: currentAspectRatio.id,
  });
  return response.data;
};

export const useCreateTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showToast } = useCustomToast();

  return useMutation(createTemplate, {
    onMutate: () => {
      dispatch(setDisableBtn(true));
    },
    onSuccess: () => {
      dispatch(toggleDialog("CustomLayoutPopUp"));
      showToast(
        "success",
        "Layout Creation",
        "Layout Template created successfully!"
      );
      // dispatch(toggleLoader("createLoading"));
      queryClient.invalidateQueries(fetchTemplates);
      dispatch(setDisableBtn(false));
    },
    onError: (error) => {
      dispatch(setDisableBtn(false));
      showToast("error", "Layout Creation", error.message);
    },
  });
};

export const useCustomizeTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showToast } = useCustomToast();

  return useMutation(createTemplate, {
    onMutate: () => {
      dispatch(setDisableBtn(true));
    },
    onSuccess: () => {
      dispatch(toggleDialog("TemplateCustomizationPopUp"));
      showToast(
        "success",
        "Layout Creation",
        "Layout Template created successfully!"
      );
      // dispatch(toggleLoader("createLoading"));
      queryClient.invalidateQueries(fetchTemplates);
      dispatch(setDisableBtn(false));
    },
    onError: (error) => {
      dispatch(setDisableBtn(false));
      showToast("error", "Layout Creation", error.message);
    },
  });
};

// -------------------------Layout setup -------------------------//
const fetchLayoutData = async (id) => {
  const response = await axiosInstance.get(`/layout/${id}`);
  return response.data.data;
};

export const useFetchLayoutData = (id) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ["layoutData", id],
    queryFn: () => fetchLayoutData(id),
    onMutate: () => {
      dispatch(toggleLoader("Loading"));
    },
    onSuccess: (data) => {
      dispatch(setSelectedLayout(data));
      dispatch(toggleLoader("Loading"));
    },
    onError: (error) => {
      console.error("Error in fetchLayoutData:", error);
      dispatch(toggleLoader("Loading"));
    },
  });
};

const getScreen = async (id) => {
  const response = await axiosInstance.get(`/campaign/${id}`);
  return response.data;
};

export const useGetScreen = (id) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();

  return useQuery({
    queryKey: ["screenData", id],
    queryFn: () => getScreen(id),
    enabled: false,
    onSuccess: (data) => {
      dispatch(setScreenDetails(data));
      dispatch(updateCampaignMuted(data.is_muted));
    },
    onError: (error) => {
      showToast("error", "Fetching Data", error.response?.data?.message);
    },
  });
};

// -------------------------fetch Screens Data-------------------------//
const fetchScreensData = async (id) => {
  const response = await axiosInstance.get(`/layout/${id}/campaigns`);
  return response.data.data;
};

export const useFetchScreensData = (id) => {
  const dispatch = useDispatch();
  const { screenDetails } = useSelector((state) => state.layout);
  return useQuery({
    queryKey: ["screensData", id],
    queryFn: () => fetchScreensData(id),
    onSuccess: (data) => {
      const screensData = data.map((screen) => ({ ...screen }));
      if (screensData.length > 0) {
        if (isEmpty(screenDetails)) {
          dispatch(setScreenDetails(screensData[0]));
          // const selectedScreen = screensData.find(
          // (item) => item.id === screensData[0].id
          // );
          // dispatch(updateCampaignDuration(selectedScreen.campaign_duration));
          // dispatch(updateCampaignMuted(selectedScreen.is_muted));

        } else {
          const selectedScreen = screensData.find(
            (item) => item.id === screenDetails.id
          );
          dispatch(setScreenDetails(selectedScreen));

          //   if (screenDetails.id != screensData[0].id) {
          //     // dispatch(setScreenDetails(screensData[0]));
          //     const selectedScreen = screensData.find(
          //       (item) => item.id === screensData[0].id
          //     );
          //     // dispatch(updateCampaignDuration(selectedScreen.campaign_duration));
          //     // dispatch(updateCampaignMuted(selectedScreen.is_muted));
          //   } else {
          //     const selectedScreen = screensData.find((item) =>
          //       item.id === screenDetails?.id
          //         ? screenDetails.id
          //         : screensData[0].id
          //     );
          //     // dispatch(updateCampaignDuration(selectedScreen.campaign_duration));
          //     // dispatch(updateCampaignMuted(selectedScreen.is_muted))
          //   }
        }
        dispatch(setScreens_setting(screensData));

        const allPublished = data.every(
          (screen) => screen.status === "PUBLISHED"
        );

        if (allPublished) {
          dispatch(setIsPublished(true));
        } else {
          dispatch(setIsPublished(false));
        }
      }
      dispatch(toggleLoader("Loading"));
    },
    onError: (error) => {
      console.error("Error in fetchScreensData:", error);
      dispatch(toggleLoader("Loading"));
    },
  });
};

// ------------------------- Publish Screens ---------------------------//
const publishScreens = async (id) => {
  const { data } = await axiosInstance.post(`/campaign/${id}/publish/screen`, {
    id,
  });
  return data.data;
};

export const usePublishScreensMutation = () => {
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation(publishScreens, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(fetchScreensData);

      showToast(
        "success",
        "Publish Layout",
        "All Layout's screens have been published successfully."
      );
    },
    onError: (error) => handleResErrors(error, showToast),
  });
};

// -------------------------stretch Screens media-------------------------//
const stretchScreensMedia = async (id) => {
  const response = await axiosInstance.get(`/layout/stretch/screen/${id}`);
  return response;
};

export const useStretchScreensMedia = (id) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => stretchScreensMedia(id),
    onSuccess: (data, id) => {
      showToast(
        "success",
        "Screen Media",
        "Screen's Media Stretched Successfully!"
      );

      queryClient.invalidateQueries(fetchScreensData);
      dispatch(setIsPublished(false));
    },
    onError: (error) => { },
  });
};

// -------------------------unstretch Screens media-------------------------//
const unStretchScreensMedia = async (id) => {
  const response = await axiosInstance.get(`/layout/unstretch/screen/${id}`);
  return response;
};

export const useUnStretchScreensMedia = (id) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => unStretchScreensMedia(id),
    onSuccess: (data, id) => {
      showToast(
        "success",
        "Screen Media",
        "Screen's Media Un Stretched Successfully!"
      );

      queryClient.invalidateQueries(fetchScreensData);
      dispatch(setIsPublished(false));
    },
    onError: (error) => { },
  });
};

// -------------------------fetch layout aspect ratio-------------------------//
const fetchLayoutAspectRatio = async (id) => {
  const response = await axiosInstance.get(`/aspect-ratio/${id}/show`);
  return response;
};

export const useFetchLayoutAspectRatio = (id) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();

  return useQuery({
    queryKey: ["aspectRatio", id],
    queryFn: () => fetchLayoutAspectRatio(id),
    enabled: !!id,
    onSuccess: (response) => {
      dispatch(setLayoutAspectRatio(response.data.data));
    },
    onError: (error) => { },
  });
};

// -------------------------update layout  -------------------------//
const updateLayout = async ({ layoutId, data }) => {
  const response = await axiosInstance.post(`/layout/${layoutId}/update`, data);
  return response.data;
};

export const useUpdateLayout = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showToast } = useCustomToast();

  return useMutation({
    mutationFn: ({ layoutId, data }) => updateLayout({ layoutId, data }),
    onMutate: () => {
      dispatch(setDisableBtn(true));
    },
    onSuccess: () => {
      dispatch(setDisableBtn(false));
      showToast("success", "Update Layout", "Layout Updated Successfully!");
      queryClient.invalidateQueries(["layoutData"]);
      queryClient.invalidateQueries(["screensData"]);
      dispatch(setIsPublished(false));
    },
    onError: (error) => {
      dispatch(setDisableBtn(false));
      if (error?.response?.data?.errors) {
        Object.values(error?.response?.data?.errors).forEach((err) => {
          showToast("error", "Update Layout", err[0]);
        });
      }
    },
  });
};

// -------------------------create  aspect ratio  -------------------------//
const createAspectRatio = async (newAspect) => {
  const response = await axiosInstance.post(`/aspect-ratio/create`, {
    name: newAspect.name,
    width: newAspect.width,
    height: newAspect.height,
  });
  return response.data.data;
};

export const useCreateAspectRatio = () => {
  const queryClient = useQueryClient();
  const { showToast } = useCustomToast();

  return useMutation({
    mutationFn: createAspectRatio,
    onSuccess: (newAspectRatio) => {
      queryClient.setQueryData(["aspectRatios"], (prev) => [
        ...(prev || []),
        newAspectRatio,
      ]);
      showToast("success", "Aspect Ratio Creation", "Aspect Ratio created successfully!");
    },
  });
};

// -------------------------fetch all aspect ratio-------------------------//
const fetchAllAspectRatio = async () => {
  const response = await axiosInstance.get(`/aspect-ratio`);
  return response;
};

export const useFetchAllAspectRatio = () => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();

  return useQuery({
    queryKey: ["aspectRatio"],
    queryFn: () => fetchAllAspectRatio(),
    onSuccess: (response) => {
      // dispatch(setLayoutAspectRatio(response.data.data));
    },
    onError: (error) => { },
  });
};

// -------------------------fetch screen media mute status -------------------------//
const fetchMuteStatus = async (id) => {
  const response = await axiosInstance.get(`/layout/media/status/${id}`);
  return response.data;
};

export const useFetchMuteStatus = (id, setAllStretched, setEnabledStretch) => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["muteStatus", id],
    queryFn: () => fetchMuteStatus(id),
    enabled: !!id,
    onSuccess: (data) => {
      if (data && typeof data.all_stretched !== "undefined") {
        setAllStretched(data.all_stretched);
        setEnabledStretch(data.enable_stretched);
      }
    },
    onError: (error) => {
      showToast({
        title: "Error",
        description: error.message || "Failed to fetch media status",
        status: "error",
      });
    },
  });
};
