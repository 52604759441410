import LazyImage from '../../ShowMedia/LazyImage';

function MediaThumb({ item }) {

    return (

        <div className="h-[58px] flex justify-center items-center overflow-hidden">
            <LazyImage
                media={item}
                popupType="media_preview"
                index={item.id}
                styleClass="me-2 image_container max-w-full max-h-full w-auto h-auto object-contain"
            />
        </div>

    )
}

export default MediaThumb