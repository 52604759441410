import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useUpdateCampaignContentSettings, useUpdateMedia, useUpdatePlaylistContentSettings } from '../../../queries';
import { handleRssSetting, trimFileNameExc } from '../../../config/global';
import { TYPES_ALLOWED_STRETCH } from '../../../Constants/media';
import { setRssSettings } from '../../../redux/Slices/Apps/appsSlice';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import RssSettings from '../RSS/RssSettings';
import { Media } from '../ShowMedia/Media';

const rssTypes = ["RSS", "RSS_CUSTOM"]

function MediaSettingPopup({ item, removeMedia, setSelectedRow }) {
  const { formState: { errors }, control, reset } = useForm();
  const { layer, openDialog } = useSelector((state) => state.global);
  const { rssSettings } = useSelector(state => state.apps);
  const dispatch = useDispatch();

  const updateCampaignItemSetting = useUpdateCampaignContentSettings();
  const updatePlaylistItemSetting = useUpdatePlaylistContentSettings();
  const updateMediaName = useUpdateMedia();
  const loading = useMemo(() => updateCampaignItemSetting?.isLoading || updatePlaylistItemSetting.isLoading,
    [updateCampaignItemSetting?.isLoading, updatePlaylistItemSetting.isLoading])

  const [editName, setEditName] = useState(false)
  const [name, setName] = useState(trimFileNameExc(item.file_name))
  const [setting, setSetting] = useState({
    "show_video_screen": item.show_video_screen,
    "is_muted": item.is_muted,
    "maintain_aspect_ratio": item.maintain_aspect_ratio,
    "is_showed": item.is_showed,
    "downloadable": item.downloadable,
    "show_layer_two": item.show_layer_two,
    "show_layer_one": item.show_layer_one,
    "show_layer_three": item.show_layer_three,
  });

  useEffect(() => {
    let _settings = handleRssSetting(item?.rss_settings, item?.title);
    dispatch(setRssSettings(_settings))
    reset(_settings)
  }, [])

  const updateSetting = async () => {
    const formData = new FormData();

    for (var key in setting) {
      formData.append(key, Number(setting[key]));
    }

    if (rssTypes?.includes(item.type))
      updateRssSettings(formData)

    if (window.location.pathname.includes("playlist")) {
      formData.append("playlist_media_id", item.playlist_media_id);
      await updatePlaylistItemSetting.mutateAsync(formData)
    } else {
      await updateCampaignItemSetting.mutateAsync({ data: formData, id: item.campaign_playlist_media_id })
    }
  }

  const updateRssSettings = (formData) => {
    let rss_settings = {
      ...rssSettings,
      direction: rssSettings?.direction === "left" ? "ltr" : "rtl",
      font_size: Number(rssSettings?.font_size),
    };

    for (var key in rss_settings) {
      if (key !== "newSeparator" || !isEmpty(rssSettings?.newSeparator)) {
        formData.append(`rss_settings[${key}]`, rss_settings[key]);
      }
    }

  }

  const updateName = async () => {
    if (item.file_name !== name) {
      await updateMediaName.mutateAsync({
        id: item.media_id,
        data: { "name": `${name}.xml` }
      }, {
        onSuccess: (data) => {
          setEditName(false)
          item.file_name = trimFileNameExc(data.file_name)
        }
      })
    } else {
      setEditName(false)
    }
  }

  //Dialog Header 
  const header = () => {
    return editName ?
      <div className='d-flex align-items-center ' >
        <InputText className='w-50' value={name} onChange={(e) => setName(e.target.value)} />
        <div className="flex  ">
          <Button label="" icon="fa-solid fa-check"
            loading={updateMediaName.isLoading}
            className="text-xl py-0 mx-2 bg-transparent text-black border-transparent "
            type="submit"
            onClick={updateName}
          />
        </div>
      </div>
      :
      <h4 className=' m-0 fw-bold d-flex align-items-center '>
        <span>{name}</span>
        <Button label="" icon="fas fa-pencil" className="text-lg py-0 mx-2 bg-transparent text-black border-transparent " type="button"
          onClick={() => setEditName(true)} />
      </h4 >
  }

  const onclose = () => {
    dispatch(toggleDialog("mediaSettingPopup"))
    setSelectedRow({})
  }

  return (
    <>
      <Dialog visible={openDialog.mediaSettingPopup}
        onHide={onclose} draggable={false}
        breakpoints={{ '960px': '95vw' }}
        style={{ width: rssTypes?.includes(item.type) ? "60vw" : '50vw', minHeight: '300px' }}
        header={header} >
        <div className="d-flex">
          <div className="col-6 col-lg-7 border-end d-flex flex-column justify-content-center align-content-center flex-wrap p-3 hight">
            <div className={`col-12 d-flex flex-column ${item?.rss_settings?.position == "BOTTOM" ? "justify-content-end border-2 border-dark" : ""}`} style={{ minHeight: "300px" }}>
              <Media data={item} />
            </div>
          </div>
          <div className="col-6 col-lg-5  px-0 d-flex flex-column justify-content-between">

            {
              rssTypes?.includes(item.type) ?
                <div className="px-2 mt-3 ">
                  <h6 className='text-center text-black fw-bold mb-2'>RSS Settings</h6>
                  <RssSettings errors={errors} control={control} reset={reset} />
                </div>
                :
                <div className="px-2 mt-3">
                  <h6 className='text-center text-black fw-bold'>Media Settings</h6>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'> Show video screen</span>
                    <InputSwitch checked={setting.show_video_screen} onChange={(e) => setSetting({ ...setting, "show_video_screen": e.value })} />
                  </div>
                  {
                    item.section != "LINKS" &&
                    <div className="d-flex justify-content-between mb-2">
                      <span className='fs-8'>Downloadable</span>
                      <InputSwitch checked={setting.downloadable} onChange={(e) => setSetting({ ...setting, "downloadable": e.value })} />
                    </div>
                  }
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Showed</span>
                    <InputSwitch checked={setting.is_showed} onChange={(e) => setSetting({ ...setting, "is_showed": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Mute</span>
                    <InputSwitch disabled={item.type == "HDMI"} checked={setting.is_muted} onChange={(e) => setSetting({ ...setting, "is_muted": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Maintain  media aspect ratio</span>
                    <InputSwitch disabled={!(TYPES_ALLOWED_STRETCH.includes(item.type))} checked={(setting.maintain_aspect_ratio)} onChange={(e) => setSetting({ ...setting, "maintain_aspect_ratio": (e.value) })} />
                  </div>
                </div>
            }

            {
              layer == "MAIN" ? window.location.pathname.includes("layouts") ? "" :
                <div className="px-2 border-top py-3" >
                  <h6 className='text-center text-black fw-bold'>Play Settings</h6>

                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show playground layer </span>
                    <InputSwitch checked={setting.show_layer_one} onChange={(e) => setSetting({ ...setting, "show_layer_one": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show corner layer</span>
                    <InputSwitch checked={setting.show_layer_two} onChange={(e) => setSetting({ ...setting, "show_layer_two": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show banner layer</span>
                    <InputSwitch inputId=' `${ item.id }-layer-three` }' checked={setting.show_layer_three} onChange={(e) => setSetting({ ...setting, "show_layer_three": e.value })} />
                  </div>
                </div>
                :
                ""
            }
            <div className='border-top px-2 py-3 d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-center align-items-center'>
                {
                  item.downloadable == 1 ?
                    <a href={item.path} >
                      <i className="fas fa-download me-2 cursor-pointer text-light fs-5" ></i>
                    </a>
                    :
                    ""
                }
                <i className="bx bx-trash text-light cursor-pointer fs-5" onClick={removeMedia} ></i>
              </div>
              <Button label={["RSS", "RSS_CUSTOM"].includes(item.type) ? "Publish" : "Update"}
                loading={loading}
                className='btn btn-primary fs-8 py-1 px-2'
                onClick={updateSetting} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default MediaSettingPopup