import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { getFormErrorMessage, validateNotEqual } from '../../../config/global';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useCreatePlaylistMutation } from '../../../queries';

const Create = () => {
    const { control, formState: { errors }, handleSubmit } = useForm({});
    const { openDialog } = useSelector((state) => state.global);
    const createPlaylist = useCreatePlaylistMutation();
    const dispatch = useDispatch();

    const hideDialog = () => {
        dispatch(toggleDialog("createPlaylist"));
    }

    const onSubmit = async (data) => {
        await createPlaylist.mutateAsync(data)
    };

    return (
        <Dialog visible={openDialog.createPlaylist} style={{ width: '450px' }}
            header="New PlayList" modal className="p-fluid"
            onHide={hideDialog}
            breakpoints={{ '960px': '95vw' }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="my-5 px-4">
                    <div className="field">
                        <label className="form-label"> Name </label>
                        <span className="p-float-label">
                            <Controller name="name" control={control}
                                rules={{
                                    required: 'Name is required.', validate: validateNotEqual
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field} autoFocus
                                        className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                        </span>
                        {getFormErrorMessage('name', errors)}
                    </div>
                </div>
                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createPlaylist.isLoading} onClick={hideDialog} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Create" loading={createPlaylist.isLoading} />
                </div>
            </form>
        </Dialog>
    );
};
export default Create;
