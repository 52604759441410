import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";

import { useConfirmDeviceMutation } from '../../../queries/devices';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { Button } from 'primereact/button';

function Confirm() {
    const dispatch = useDispatch();
    const openDialog = useSelector((state) => state.global.openDialog);

    const { register, formState: { errors }, handleSubmit } = useForm();

    const confirmDevice = useConfirmDeviceMutation();

    const onSubmit = async (data) => {
        await confirmDevice.mutateAsync({
            "code": data.code.replace(/\s/g, ''),
            "name": data.name
        })
    };

    const handleFocus = (e) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    return (
        <>
            <Dialog visible={openDialog.confirmDialog}
                style={{ width: '60%', height: "auto" }}
                header="New Device"
                breakpoints={{ '960px': '95vw' }}
                modal
                className="p-fluid"
                onHide={() => dispatch(toggleDialog("confirmDialog"))}
            >
                <form onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleSubmit(onSubmit)();
                        }
                    }}
                    className="d-flex flex-column align-items-center justify-content-between h-100">
                    <div className="col-12 my-4 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="d-flex flex-column mb-4 align-items-start w-50">
                            <label htmlFor="basic" className='mb-2'>Device Name</label>

                            <InputText
                                {...register("name", { required: "Device name is required" })}
                                className={`w-100   ${errors.name ? 'form-control is-invalid' : 'form-control'}`}
                                aria-invalid={errors.name ? "true" : "false"}
                            />
                            {errors.name && <p className='fv-plugins-message-container invalid-feedback text-start'>{errors.name?.message}</p>}
                        </div>

                        <div className="d-flex flex-column align-items-start w-50">
                            <label htmlFor="basic" className='mb-2'>Enter the 6-character pairing code</label>
                            <InputMask
                                dir='ltr'
                                {...register("code", { required: "Device code is required" })}
                                aria-invalid={errors.code ? "true" : "false"}
                                className={`w-100 text-center  ${errors.code ? 'form-control is-invalid' : 'form-control'}`}
                                id='basic'
                                mask="9 9 9 9 9 9"
                                placeholder="9 9 9 9 9 9"
                                style={{ height: "100px", fontSize: "50px", letterSpacing: "2px" }}
                                onFocus={handleFocus}
                            ></InputMask>

                            {errors.code && <p className='fv-plugins-message-container invalid-feedback text-start'>{errors.code?.message}</p>}
                        </div>

                    </div>

                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray" >
                        <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={confirmDevice.isLoading} onClick={() => dispatch(toggleDialog("confirmDialog"))} />
                        <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Confirm" loading={confirmDevice.isLoading} />
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default Confirm