import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../assets/vendor/css/pages/page-auth.css';
import './css/app.css';
import 'react-phone-input-2/lib/style.css'

import { useTitle } from '../../config/global';
import Layout from '../Layout/Layout';
import Account from './components/Account';
import Billing from './components/Billing';
import { ProfileProvider } from '../Contexts/ProfileContext';

const menuTabs = [
    {
        label: "Account",
        key: "account",
        icon: <i className="bx bx-user me-1"></i>,
        component: <Account />
    },
    {
        label: "Billing",
        key: "billing",
        icon: <i className="fa-solid fa-dollar-sign me-1"></i>,
        component: <Billing />
    }
]

function Profile() {
    useTitle("Profile");
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(menuTabs[1])

    useEffect(() => {
        if (location.state?.activeTab) {
            const selectedTab = menuTabs.find(tab => tab.key === location.state.activeTab);
            if (selectedTab) {
                setActiveTab(selectedTab);
            }
        }
    }, [location.state?.activeTab]);

    return (
        <Layout>
            <ProfileProvider>
                <div className="content-wrapper">
                    {/* Content */}
                    <div className="mx-md-2 flex-grow-1  ">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="nav nav-pills flex-column flex-md-row mb-3">
                                    {
                                        menuTabs.map((tab, index) => {

                                            return (
                                                <li className={`nav-item me-2 ${activeTab.key == tab.key ? "" : "d-none"} `} onClick={() => {
                                                    setActiveTab({
                                                        key: tab.key,
                                                        component: tab.component
                                                    })
                                                }}
                                                    key={index}>
                                                    <a className={`nav-link ${activeTab.key == tab.key ? "active" : "bg-secona"}`} href="#">{tab.icon} {tab.label}</a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div div className="col-md-12">
                                {activeTab.component}
                            </div>
                        </div>
                    </div>

                    <div className="content-backdrop fade" />
                </div>
            </ProfileProvider>
        </Layout>
    );
}

export default Profile