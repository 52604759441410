import { isEmpty } from 'lodash'
import { Button } from 'primereact/button'
import React from 'react'

function SearchInput({ setSearchTerm, searchTerm, getData, isLoading, resetSearch, isDisabled }) {

    const handleInput = () => {
        if (!isEmpty(searchTerm))
            resetSearch()
    }

    return (
        <div className="d-flex flex-nowrap w-full justify-content-start my-3 px-3">
            <input
                type="text"
                className="form-control w-8/12 md:w-10/12"
                placeholder="Search ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={isDisabled}
            />
            {
                !isEmpty(searchTerm) ?
                    <Button className="ms-1 p-button-secondary p-button-sm w-1/12 md:w-1/12" icon="pi pi-times" onClick={handleInput} />
                    :
                    <></>
            }
            <Button className="ms-1 p-button-sm w-4/12 md:w-2/12" label="Search" disabled={isDisabled} loading={isLoading} onClick={getData} />
        </div>

    )
}

export default SearchInput
