import React, { useEffect } from 'react'

import { ProtectedRouter, ProtectedRouterTwo } from "./ProtectedRouter";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import VerifyEmailProcess from "../pages/Auth/VerifyEmail/VerifyEmailProcess";
import ForgetPassword from "../pages/Auth/ResetPassword/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import LogoutProcess from "../pages/Auth/Logout/LogoutProcess";
import VerifyEmail from "../pages/Auth/VerifyEmail/VerifyEmail";
import Dashboard from "../pages/Board/Dashboard";
import Signup from "../pages/Auth/Signup";
import Login from "../pages/Auth/Login";


import CampaignDatatable from "../pages/Campaign/DataTable";
import PrayerCampaigns from "../pages/Apps/Prayer/PrayerCampaigns/PrayerCampaigns";
import CreateCampaign from "../pages/Campaign/Components/Create";
import UpdateCampaign from "../pages/Campaign/Components/Update";
import CampaignSetup from "../pages/Campaign/Components/setup/CampaignSetup";

import DeviceShow from "../pages/Devices/Component/Show";
import Index from "../pages/Devices/Index";

import PlaylistDatatable from "../pages/Playlist/Datatable";
import PlaylistSetup from "../pages/Playlist/Components/setup/PlaylistSetup";

import FolderDragDrop from "../pages/Media/components/FolderDragDrop/FolderDragDrop";
import MediaDataTable from "../pages/Media";
import LinksDataTable from "../pages/Links/DataTable";

import Apps from "../pages/Apps/Apps";

import InteractiveCampaignDatatable from "../pages/Campaign/InteractiveCampaign/DataTable";
import InteractiveCampaignSetup from "../pages/Campaign/InteractiveCampaign/Components/setup/InteractiveCampaignSetup";

import NotFound from "../pages/Layout/NotFound";
import Profile from "../pages/Profile/Profile";
import Reports from "../pages/Reports/Reports";
import Landing from "../pages/Landing/Landing";

import axiosInstance from '../config/Axios';

import { CompanyDetailsRouter } from './CompanyDetailsRouter';
import CustomRssIndex from '../pages/Apps/CustomRss/CustomRssIndex';
import EmployeeSignUp from '../pages/Auth/EmployeeSignup';
import BroadcastCard from '../pages/Apps/Broadcast/BroadcastCard';
import UsersIndex from '../pages/Users/UsersIndex';
import PrayerCard from '../pages/Apps/Prayer/PrayerCard';
import RssIndex from '../pages/Apps/Rss/RssIndex';
import Multi3DViewerIndex from '../pages/Apps/Multi3dViewer/Multi3DViewerIndex';
import LayoutsIndex from '../pages/Apps/Layouts/LayoutsIndex';
import LayoutSetup from '../pages/Apps/Layouts/components/LayoutSetup';

import { useSelector, useDispatch } from 'react-redux';
import { setPackages } from '../redux/Slices/Global/globalSlice';
 
function RoutesContainer() {
    const companyDetails = useSelector((state) => state.global.companyDetails);
    const dispatch = useDispatch();

    const features = companyDetails?.package?.features;

    useEffect(() => {
        axiosInstance.get(`/landing/packages`).then((result) => {
            dispatch(setPackages(result?.data?.data));
        });
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                 <Route path="/" element={<Landing />} />
                <Route element={<ProtectedRouter />}>
                    <Route element={<CompanyDetailsRouter />}>
                        <Route path="/dashboard" element={<Dashboard />} />

                        <Route group="media">
                            <Route path="/media/gallery/:id?" element={<MediaDataTable />} />
                            <Route path="/media/move" element={<FolderDragDrop />} />
                            <Route path="/links" element={<LinksDataTable />} />
                        </Route>

                        <Route path="/profile" element={<Profile />} />
                        <Route path="/reports" element={<Reports />} />

                        <Route group="users">
                            <Route path="/users" element={<UsersIndex />} />
                        </Route>

                        {
                            features?.interactive_action &&
                            <Route group="/interactive/campaigns">
                                <Route path="/interactive/campaigns" element={<InteractiveCampaignDatatable />} />
                                <Route path="/interactive/campaign/:id/setup" element={<InteractiveCampaignSetup />} />
                            </Route>
                        }

                        <Route group="campaigns">
                            <Route path="/campaigns/list" element={<CampaignDatatable />} />
                            <Route path="/campaigns/:type?/:id/setup" element={<CampaignSetup />} />

                            <Route path="/campaigns/create" element={<CreateCampaign />} />
                            <Route path="/campaigns/:id/update" element={<UpdateCampaign />} />
                        </Route>

                        <Route group="devices">
                            <Route path="/devices/list" element={<Index />} />
                            <Route path="/devices/:id/show" element={<DeviceShow />} />
                        </Route>

                        <Route group="playlist">
                            <Route path="/playlist/list" element={<PlaylistDatatable />} />
                            <Route path="/playlist/:id/show" element={<PlaylistSetup />} />
                        </Route>

                        <Route group="apps">
                            <Route path="/apps" element={<Apps />} />
                            <Route path="/apps/prayer" element={<PrayerCard />} />
                            <Route path="/apps/prayer/campaigns" element={<PrayerCampaigns />} />

                            <Route path="/apps/broadcast" element={<BroadcastCard />} />

                            <Route path="/apps/rss" element={<RssIndex />} />
                            <Route path="/apps/rss/custom" element={<CustomRssIndex />} />


                            <Route path="/apps/3d/viewer" element={<Multi3DViewerIndex />} />

                            <Route path="/apps/layouts" element={<LayoutsIndex />} />
                            <Route path="/apps/layouts/:id/show" element={<LayoutSetup />} />

                            <Route path="/apps/rss" element={ <RssIndex /> } />
                            <Route path="/apps/rss/custom" element={ <CustomRssIndex /> } />


                            <Route path="/apps/3d/viewer" element={ <Multi3DViewerIndex /> } />

                            <Route path="/apps/layouts" element={ <LayoutsIndex /> } />
                            <Route path="/apps/layouts/:id/show" element={<LayoutSetup />} />

                         </Route>
                    </Route>
                </Route>

                <Route element={<ProtectedRouterTwo />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/signup/:invitation_id" element={<EmployeeSignUp />} />

                    <Route path="/forget/password" element={<ForgetPassword />} />
                    <Route path="/reset/password/:token" element={<ResetPassword />} />
                    <Route path="/verify/email/:payload" element={<VerifyEmailProcess />} />
                </Route>
                <Route path="/verify/email" element={<VerifyEmail />} />
                <Route path="/logout/process" element={<LogoutProcess />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter >)
}

export default RoutesContainer