import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react'

import { Dialog } from 'primereact/dialog';
import { useForm } from "react-hook-form";

import AssignScheduleCampaign from './AssignScheduleForm';

import { setSelectedDevice, setSelectedGroup } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

const AssignCampaignToGroup = () => {
  const openDialog = useSelector((state) => state.global.openDialog);

  const dispatch = useDispatch();
  const { reset } = useForm();

  useEffect(() => {
    reset();
  }, [reset]);

  const onClose = () => {
    dispatch(setSelectedGroup({}));
    dispatch(setSelectedDevice({}));
    dispatch(toggleDialog("assignDialog"))
  }

  return (
    <Dialog visible={openDialog.assignDialog}
      breakpoints={{ '960px': "75vh", }}
      style={{ width: '30vw' }}
      header="Assign campaign to Group"
      modal className="p-fluid"
      onHide={onClose}>

      <AssignScheduleCampaign />

    </Dialog>
  )
}

export default AssignCampaignToGroup