import { useState, useEffect, useMemo, useRef } from "react";

import { SelectButton } from "primereact/selectbutton";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

import "../Media/css/mediaTable.css";

import { JUSTIFY_OPTIONS, LINK_DEFAULT_FILTERS } from "../../config/media";
import { useScrollLoadingContext } from "../Contexts/ScrollLoadingContext";
import { useGalleryContext } from "../Contexts/GalleryContext";
import { ProgressSpinner } from "primereact/progressspinner";
import FirebaseListener from "../Services/FirebaseMediaListener";
import { useTitle } from "../../config/global";
import UploadPopup from "../Media/components/UploadPopup/UploadPopup";
import MediaFilter from "../SharedComponents/Gallery/MediaFilter";
import SearchBar from "../SharedComponents/Gallery/SearchBar";
import MediaCard from "../Media/components/MediaLayout/MediaCard";
import Layout from "../Layout/Layout";
import ScrollContainer from "../SharedComponents/ScrollContainer";
import { galleryRefetch, setData, setLastElement, setParams } from "../../redux/Slices/scrollSlice";
import { useDispatch, useSelector } from "react-redux";

function LinksDataTable() {
  useTitle("Links");

  const lastElementRef = useRef(null);
  const dispatch = useDispatch();

  const { layout, setLayout } = useGalleryContext();
  const { isFetching, data: media } = useSelector((state) => state.scroll);

  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState(LINK_DEFAULT_FILTERS);

  useEffect(() => {
    if (lastElementRef.current) {
      dispatch(setLastElement(lastElementRef.current));
    }
  }, [lastElementRef.current]);

  const handleApplyFilters = () => {
    dispatch(setData([]))
    dispatch(setParams(filters))
    setVisible(false)
  };

  const justifyTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  const getClasses = (index) => {
    return `w-98 ${layout == "list" ? `media_list ${index + 1 != media.length ? "border-bottom border-gray" : ""} `
      : "media_grid"
      } mx-2 py-2 media position-relative`
  }

  const subNavbarProps = useMemo(() => {
    return {
      title: "Links",
    };
  }, []);

  const subNavbarContent = useMemo(() => {
    return (
      <div className={`flex justify-end  w-100`}>
        <div className="flex-grow-1">
          <SearchBar handleApplyFilters={handleApplyFilters} filters={filters} setFilters={setFilters} />
        </div>
        <Button
          icon="pi pi-filter-fill fs-5"
          className=" p-button-text p-button-rounded   font-bold text-secondary  "
          onClick={() => setVisible(true)}
          tooltip="Toggle Filters"
        />
        <UploadPopup content="links" />
      </div>
    );
  }, [filters]);

  return (
    <Layout subNavbarContent={subNavbarContent} subNavbarProps={subNavbarProps} padding={false}>
      <div className="dataview-demo  px-4 pt-4">
        <div className="d-flex flex-column justify-content-start bg-white rounded-3 media_container px-auto mx-1 ">
          <div className="col-12 px-3 d-flex justify-content-between align-items-center btn_container border-bottom">
            <div className=" py-3 d-flex  ">
              <SelectButton
                value={layout}
                options={JUSTIFY_OPTIONS}
                className="mx-1 layout_btn"
                onChange={(e) => setLayout(prev => e.value == null ? prev : e.value)}
                itemTemplate={justifyTemplate}
                optionLabel="value" />

            </div>
            <div className=" d-flex justify-content-end align-items-center  my-3">

              <Sidebar icon="pi pi-filter-fill fs-5" visible={visible} position="right" className="p-sidebar-sm w-[25px]" onHide={() => setVisible(false)}>
                <MediaFilter
                  filters={filters}
                  setFilters={setFilters}
                  onApplyFilters={handleApplyFilters}
                />
              </Sidebar>
            </div>
          </div>
          <ScrollContainer path={`/folder/media?section=LINKS`}>

            {/* <DragDrop /> */}
            <div className={` row justify-content-center py-3   ${layout === "list" ? "h-[89%]" : "h-[91%]"}`}>
              {
                !isFetching && media?.length == 0 ?
                  <h5 className="py-5 text-center w-100">No media assigned yet!</h5>
                  :
                  <>
                    {
                      layout == "list" &&
                      <div className="media_list mb-2">
                        <div className="col-1"></div>
                        <div className="col-md-3 col-sm-3 col-4 text-center text-truncate pe-4"></div>
                        <div className="col-md-1 col-2 fs-6 px-0 text-center fst-italic d-none d-sm-block"> Duration </div>
                        <div className="col-2 fs-6 px-0 text-center fst-italic  d-none d-sm-block">Kind </div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic">Size</div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic d-none d-xl-block">Created-at</div>
                        <div className="col-3"> </div>
                      </div>
                    }
                    <div className="cards_container object-fit row align-content-start justify-content-start align-items-start mx-auto scroll_div px-2">
                      {
                        media?.map((item, index) => {
                          return (
                            <div key={item.id}
                              className={getClasses(index)}
                              ref={index === media.length - 1 ? lastElementRef : null}
                            >
                              <MediaCard item={item} layout={layout} />
                            </div>
                          );
                        })
                      }
                    </div>
                  </>
              }

              {
                isFetching && media.length ?
                  <div className="w-full p-4">
                    <ProgressSpinner
                      className='h-[50px] w-[50px] flex justify-center items-center'
                      strokeWidth="3"
                      fill="var(--surface-ground)"
                      animationDuration="30s" />
                  </div>
                  :
                  <></>
              }
            </div>
          </ScrollContainer>
        </div>
      </div>
      <FirebaseListener path={"/Companies/" + localStorage.getItem("company_id") + "/actions"} getData={() => dispatch(galleryRefetch())} />
    </Layout>
  );
}

export default LinksDataTable;
