import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

import { getFormErrorMessage } from "../../../config/global";

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useUpdateInteractiveMedia } from "../../../queries";
import { Button } from "primereact/button";

function EditInteractiveMediaDialog() {
    const { openDialog, selectedMediaInfo } = useSelector((state) => state.global);
    const { formState: { errors }, control, handleSubmit, reset, } = useForm();
    const updateInteractiveMedia = useUpdateInteractiveMedia();
    const dispatch = useDispatch();

    useEffect(() => {
        reset({
            title: selectedMediaInfo.title,
            thumb: selectedMediaInfo.thumb,
        })
    }, [reset])

    const onSubmit = async (data) => {
        await updateInteractiveMedia.mutateAsync({
            data: data,
            id: selectedMediaInfo.media_id
        }, {
            onSuccess: () => selectedMediaInfo({})
        })
    };

    const closeDialog = () => dispatch(toggleDialog("editInteractiveMedia"))

    return (
        <>
            <Dialog visible={openDialog.editInteractiveMedia}
                onHide={() => { closeDialog() }}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '50vw', minHeight: '300px' }}
                header="Edit Interactive Media"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className=" px-5 d-flex flex-column align-items-center justify-content-center my-5">
                        <div className="field mb-4 w-100">
                            <label className="form-label"> title </label>
                            <span className="p-float-label">
                                <Controller name="title" control={control}
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                            <InputText
                                                placeholder="title"
                                                id={field.name}
                                                {...field}
                                                className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`} />
                                        </div>
                                    )}
                                />
                            </span>
                            {getFormErrorMessage("title", errors)}
                        </div>

                        <div className="field w-100">
                            <label className="form-label"> Thumb </label>
                            <span className="p-float-label">
                                <Controller name="thumb" control={control}
                                    rules={{
                                        required: false,
                                        pattern: {
                                            value:
                                                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i,
                                            message: "Invalid Url!",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"> <i className="fas fa-link"></i> </span>
                                            <InputText
                                                placeholder="thumb"
                                                id={field.name}
                                                {...field}
                                                className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`}
                                            />
                                        </div>
                                    )}
                                />
                            </span>
                            {getFormErrorMessage("thumb", errors)}
                        </div>
                    </div>

                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                            disabled={updateInteractiveMedia.isLoading}
                            onClick={closeDialog} />
                        <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Update" loading={updateInteractiveMedia.isLoading} />
                    </div>
                </form>
            </Dialog>
        </>
    );
}

export default EditInteractiveMediaDialog