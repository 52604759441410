import { useRef, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

import "../../../css/layouts.css"
import AreaControls from "./Controls/AreaControls";
import DynamicContainer from './Controls/DynamicContainer';

import LayoutNameController from './Controls/LayoutNameController';
import { unitOptions } from '../../../../../config/layoutConstans';

import { useCustomToast } from '../../../../Contexts/ToastContext';
import { useSelector } from 'react-redux';

const DrawingArea = ({
    areas,
    setAreas,
    currentAspectRatio,
    setCurrentAspectRatio,
    layoutName,
    setLayoutName,
    titleError,
    handleUnitChange,
    isSaveDisabled,
    setIsSaveDisabled
}) => {
    const unit = useSelector((state) => state.layout.unit);
    const [overlappingAreas, setOverlappingAreas] = useState([]);
    const scrollableDivRef = useRef(null);

    return (
        <>
            {/* Preview Area */}
            <DynamicContainer
                areas={areas}
                setAreas={setAreas}
                currentAspectRatio={currentAspectRatio}
                setCurrentAspectRatio={setCurrentAspectRatio}
                scrollableDivRef={scrollableDivRef}

            />
            {/* Controls */}

            <div className="flex flex-col w-full lg:w-[40%]  mr-3">
                <div className="mt-1 flex  w-100 lg:w-1/2">
                    <div className="w-75">
                        <label className="text-sm font-medium mb-1">Title</label>
                        <div className="d-flex mb-3 ">

                            <LayoutNameController layoutName={layoutName} setLayoutName={setLayoutName} titleError={titleError} />

                        </div>
                    </div>

                    <div className="w-25">
                        <label className="text-sm font-medium mb-1">Unit</label>
                        <Dropdown
                            value={unit?.value || unitOptions[0]}
                            options={unitOptions}
                            optionLabel="label"
                            optionValue="value"
                            className="mb-3 mt-1 w-100"
                            onChange={handleUnitChange}
                            placeholder="Select a Unit"
                        />
                    </div>

                </div>
                <div className="flex flex-col w-100 lg:w-1/2">
                    <AreaControls
                        areas={areas}
                        setAreas={setAreas}
                        currentAspectRatio={currentAspectRatio}
                        isSaveDisabled={isSaveDisabled}
                        overlappingAreas={overlappingAreas}
                        setOverlappingAreas={setOverlappingAreas}
                        setIsSaveDisabled={setIsSaveDisabled}
                        scrollableDivRef={scrollableDivRef}

                    />
                    {overlappingAreas.length > 0 && (
                        <div className=" flex mt-3 text-white font-medium  alert bg-orange-300" role="alert">
                            <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                            <div className="ml-3">
                                These zones are being overlapped by some other zone:{" "}
                                {overlappingAreas.join(", ")}.
                                <br />
                                Please adjust the zones to avoid overlapping.
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </>
    );
};

export default DrawingArea;
