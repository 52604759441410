import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMoveMediaOrFolderMutation } from "../../../../queries";
import { useGalleryContext } from "../../../Contexts/GalleryContext";
import { setLastElement } from "../../../../redux/Slices/scrollSlice";
import FolderCard from "../MediaLayout/FolderCard";
import MediaCard from "../MediaLayout/MediaCard";

function FolderDragDrop({ setCurrentFolder, currentFolder, setBreadCrumb, breadCrumb }) {
    const { data } = useSelector(state => state.scroll);
    const { mutate: moveItem } = useMoveMediaOrFolderMutation();
    const { layout } = useGalleryContext()
    const [draggedItem, setDraggedItem] = useState({
        id: "",
        type: ""
    });
    const dispatch = useDispatch();
    // Type option => media / folder
    const onDrag = (event, ID, type) => {
        event.preventDefault();
        setDraggedItem({ id: ID, type: type });
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    const onDrop = (event, folderID) => {
        if (draggedItem.id == folderID) return false
        moveItem({ type: draggedItem.type, id: draggedItem.id, destinationId: folderID });
        // setDraggedItem({ id: "", type: "" });
    }

    const getClasses = (index) => {
        return ` ${layout === "list" ? "media_list  " : "media_grid "}
         ${index + 1 !== data.length && layout === "list" ? "border-bottom border-gray" : ""} 
         mx-auto py-3 px-2 media position-relative`
    }

    const lastElementRef = useRef(null);
    useEffect(() => {
        if (lastElementRef.current) {
            dispatch(setLastElement(lastElementRef.current));
        }
    }, [lastElementRef.current]);

    return data?.map((item, index) => {
        const isLastElement = index === data.length - 1;
        return !item.is_folder ?
            <div key={item.id} className={getClasses(index)} draggable onDrag={(event) => onDrag(event, item.id, "media")}
                ref={isLastElement ? lastElementRef : null}
            >
                <MediaCard item={item} />
            </div >
            :
            <div key={item.id}
                ref={isLastElement ? lastElementRef : null}
                className={getClasses(index)}
                draggable
                onDrag={(event) => onDrag(event, item.id, "folder")}
                onDrop={event => onDrop(event, item.id)}
                onDragOver={(event => onDragOver(event, item.id))}
            >
                <FolderCard
                    setCurrentFolder={setCurrentFolder}
                    currentFolder={currentFolder}
                    item={item}
                    setBreadCrumb={setBreadCrumb}
                    breadCrumb={breadCrumb}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                />
            </div >

    })

}

export default FolderDragDrop