import React, { useState, useEffect, useRef, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import { useOrderCampaignContent, useOrderPlaylistContent } from "../../../queries";
import { onDragEnd } from "../../../config/global";
import _ from 'lodash';

import CampaignCard from "./CampaignCard";
import PlaylistCard from "./PlaylistCard";

const taskStatus = {
    layer: {
        name: "Main layer",
        items: [],
    }
};

function DragDrop({ data }) {
    const orderCampaign = useOrderCampaignContent();
    const orderPlaylist = useOrderPlaylistContent();

    useEffect(() => {
        taskStatus.layer.items = data;
        setColumns(taskStatus)
    }, [data])

    taskStatus.layer.items = data;
    const [columns, setColumns] = useState(taskStatus);

    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else if (columns && Object.keys(columns).length > 0) {
            if (!_.isEqual(columns.layer.items, data)) {
                order();
            }
        }
    }, [columns]);

    const order = async () => {
        let pathName = window.location.pathname
        if (columns.layer.items.length > 0)
            if ((pathName.includes("campaign") || pathName.includes("layout"))) {
                let campaignPlaylistMediaIds = Object.entries(columns.layer.items).map(item => item[1].campaign_playlist_media_id);
                await orderCampaign.mutateAsync({ "campaign_playlist_ids": campaignPlaylistMediaIds ?? [] })
            } else {
                let PlaylistMediaIds = Object.entries(columns.layer.items).map(item => item[1].playlist_media_id);
                await orderPlaylist.mutateAsync({ "playlistmedia_ids": PlaylistMediaIds ?? [] })
            }
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center dragdrop_scroll   "   >
                <DragDropContext onDragEnd={(result) => { onDragEnd(result, columns, setColumns) }} className="w-100" >
                    {Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div className="d-flex flex-column align-items-center" key={columnId}>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {(provided, snapshot) => {
                                        return (
                                            <div {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="  droppable_container dragdrop_scroll scroll_div"
                                            >
                                                {column?.items?.map((item, index) => {
                                                    return (
                                                        <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index} >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            ...provided.draggableProps.style,
                                                                        }}
                                                                        className="draggable_container px-0 px-sm-2 ">

                                                                        {!window.location.pathname.includes("playlist") ?
                                                                            <CampaignCard item={item} />
                                                                            :
                                                                            <PlaylistCard item={item} />
                                                                        }
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        );
                    })}
                </DragDropContext>
            </div>
        </>
    )
}

export default DragDrop