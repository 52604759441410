import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

import { useTitle, formatDuration, TOOLTIP_OPTIONS } from '../../config/global';
import { toggleDialog, toggleDeleteDialog } from '../../redux/Slices/Global/globalSlice';
import { PLAYLISTS_TABLE_CONFIG } from '../../Constants/datatables';
import { useDataTableContext } from '../Contexts/DataTableContext';
import { useDeletePlaylist } from '../../queries';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';

import Create from './Components/Create';
import Update from './Components/Update';
import Layout from "../Layout/Layout";

const PlaylistDatatable = () => {
	useTitle('PlayLists');

	const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();
	const { openDialog, deleteDialog } = useSelector((state) => state.global);

	const [PlayList, setPlayList] = useState({ name: "" });
	const deleteRow = useDeletePlaylist();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setLazyParams(prev => ({
			...prev, ...PLAYLISTS_TABLE_CONFIG
		}))
	}, [])

	// Dialog controls
	const openCreateDialog = () => {
		setPlayList({});
		dispatch(toggleDialog("createPlaylist"));
	}

	const openEditDialog = (data) => {
		setPlayList(data);
		dispatch(toggleDialog("updatePlaylist"));
	}

	// Delete playlist handler
	const confirmDeletePlayList = (data) => {
		setPlayList(data);
		dispatch(toggleDeleteDialog("playlist"));
	}

	const deletePlayList = async () => {
		await deleteRow.mutateAsync({ id: PlayList.id })
	}

	// Data table body columns template
	const actionBodyTemplate = (rowData) => {
		return (
			<div className="d-inline-block text-nowrap">
				{/* Setup page */}
				<Button icon="bx bx-slideshow" className={`p-button-rounded p-button-secondary p-button-text`}
					tooltipOptions={TOOLTIP_OPTIONS}
					tooltip="Setup"
					onClick={() => navigate('/playlist/' + rowData.id + '/show')} />

				{/* Update playlist */}
				<Button icon="pi pi-pencil" className={`p-button-rounded p-button-secondary p-button-text`}
					tooltipOptions={TOOLTIP_OPTIONS}
					tooltip="Update"
					onClick={() => openEditDialog(rowData)} />

				{/* Delete playlist */}
				<Button icon="bx bx-trash" className={`p-button-rounded p-button-secondary p-button-text`}
					tooltipOptions={TOOLTIP_OPTIONS}
					tooltip="Delete"
					loading={deleteRow.isLoading && rowData.id === PlayList?.id}
					onClick={() => confirmDeletePlayList(rowData)} />
			</div>
		);
	}

	const durationBodyTemplate = (rowData) => {
		return formatDuration(rowData.playlist_duration)
	}

	const statusBodyTemplate = (rowData) => {
		return <span className={`text-center badge ${rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success"}`} >{rowData.status.toLowerCase()}</span>
	}

	const subNavbarContent = useMemo(() => {
		return (
			<div className="w-100 text-end"><button type="button" className="btn btn-primary" onClick={openCreateDialog} disabled={loading}>
				<span className="tf-icons bx bx-PlayList"></span> New PlayList
			</button></div>
		);
	}, [loading]);

	return (
		<Layout subNavbarContent={subNavbarContent}>
			<div className="card p-3 h-100">
				<div className='table-responsive text-nowrap rounded h-100'>
					<DataTable value={data?.playlist} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
						paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
						totalRecords={totalRecords} onPage={onPage}
						onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
						onFilter={onFilter} filters={lazyParams.filters} loading={loading} className="table"
						scrollable scrollHeight="76vh"

					>
						<Column header="Name" field="name" sortable filter className='text-center' style={{ width: "20%" }} />
						<Column header="Total Duration" field="playlist_duration" className='text-center' body={durationBodyTemplate} style={{ width: "15%" }} />
						<Column header="status" filterField="status" body={statusBodyTemplate} className='text-center' style={{ width: "15%" }} />
						<Column header="Created AT" field="created_at" filterField="created_at" className='text-center' style={{ width: "15%" }} />
						<Column header="Updated AT" field="updated_at" filterField="updated_at" className='text-center' style={{ width: "15%" }} />
						<Column body={actionBodyTemplate} exportable={false} className='text-center' style={{ width: '20%' }} ></Column>
					</DataTable>
				</div>

				{openDialog.createPlaylist && <Create />}
				{openDialog.updatePlaylist && <Update PlayList={PlayList} setReload={setReload} />}
				{deleteDialog.playlist &&
					<DeleteConfirmation
						deleteHandler={deletePlayList}
						itemName={PlayList.name ?? "playlist"}
						dialogKey="playlist"
					/>
				}
			</div>
		</Layout>
	);
}
export default PlaylistDatatable;