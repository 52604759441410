import Menu from "./Menu";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./css/mainLayout.css"

function Layout({ children, padding = true, subNavbarProps = {}, subNavbarContent = null }) {
    const paddingClass = padding ? "mx-3 mx-md-3 px-2 container-p-y" : "";

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Navbar {...subNavbarProps} content={subNavbarContent} />
                    <div className="content-wrapper">
                        <div className={`scroll_div overflow-y-auto overflow-x-hidden flex-grow-1 ${paddingClass}`} >
                            {children}
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
            {/* <div className="layout-overlay layout-menu-toggle"></div> */}
            {/* <div className="drag-target"></div> */}
        </div>
    );
}
export default Layout;