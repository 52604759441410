import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useState } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useStretchScreensMedia, useUnStretchScreensMedia, useUpdateLayout } from '../../../../../queries/layouts';
import { useUpdateCampaignMutation } from '../../../../../queries';
import InteractiveLayoutPreview from './InteractiveLayoutPreview';
import { formatDuration, } from "../../../../../config/global";
import { useCustomToast } from '../../../../Contexts/ToastContext';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';

const LayoutInfo = ({ refetch }) => {

    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { layoutAspectRatio, selectedLayout, screenDetails, screenMedia, screen_duration, } = useSelector((state) => state.layout);
    const { control, handleSubmit } = useForm({ defaultValues: { layoutName: selectedLayout.title } });

    const { mutate: updateCampaign, isLoading: updating } = useUpdateCampaignMutation();
    const { mutate: stretchScreenMedia, isLoading: isStretching } = useStretchScreensMedia();
    const { mutate: unstretchScreenMedia, isLoading: isUnStretching } = useUnStretchScreensMedia();
    const { mutate: updateLayout, isLoading } = useUpdateLayout();

    const [isEditing, setIsEditing] = useState(false);

    // const [allStretched, setAllStretched] = useState(false);
    // const [enabledStretch, setEnabledStretch] = useState(false);
    // const { data, isLoading: checkingState } = useFetchMuteStatus(screenDetails.id, setAllStretched, setEnabledStretch);
    // const { refetch } = useGetScreen(screenDetails?.id);

    const onTitleSubmit = (data) => {
        updateLayout(
            {
                layoutId: id,
                data: {
                    "title": data.layoutName,
                    "status": "ACTIVE",
                },
            },
            {
                onSuccess: () => {
                    setIsEditing(false)
                    showToast('success', 'Update Layout', "Layout Title Updated Successfully!");
                },
            }
        );
    };

    const muteHandler = () => {
        updateCampaign({
            id: screenDetails?.id,
            data: {
                "status": "DRAFT",
                "name": screenDetails?.name,
                "settings": {
                    is_muted: 1,
                    maintain_aspect_ratio: screenDetails?.settings.maintain_aspect_ratio
                },
            },
        }, {
            onSuccess: () => {
                showToast('success', 'Screen Updating', "The Screen Audio updated successfully!");
                queryClient.invalidateQueries(['getCampaignLayerContent', screenDetails?.id, "MAIN"])
                refetch()
            }
        })
    }

    const unmuteHandler = () => {
        updateCampaign({
            id: screenDetails?.id,
            data: {
                "status": "DRAFT",
                "name": screenDetails?.name,
                "settings": {
                    is_muted: 0,
                    maintain_aspect_ratio: screenDetails?.settings.maintain_aspect_ratio
                },
            },
        }, {
            onSuccess: () => {
                showToast('success', 'Screen Updating', "The Screen Audio updated successfully!");
                queryClient.invalidateQueries(['getCampaignLayerContent', screenDetails?.id, "MAIN"])
                refetch()
            }
        })
    }

    const maintainAspectRatioHandler = () => {
        updateCampaign({
            id: screenDetails?.id,
            data: {
                "status": "DRAFT",
                "name": screenDetails?.name,
                "settings": {
                    is_muted: screenDetails?.settings?.is_muted,
                    maintain_aspect_ratio: screenDetails?.settings?.maintain_aspect_ratio ? 0 : 1
                },
            },
        }, {
            onSuccess: () => {
                showToast('success', 'Screen Updating', "The Screen updated successfully!");
                queryClient.invalidateQueries(['getCampaignLayerContent', screenDetails?.id, "MAIN"])
                refetch()
            }
        })
    }

    return (
        <div className="card-body h-[85vh] scroll_div overflow-auto">
            <div className="col-md-12 col-lg-12 order-md-1 order-lg-1 ">
                <div className="user-avatar-section">
                    <div className="d-flex justify-center align-items-center flex-column">
                        <div className="text-center w-[100%]   ">
                            <h4 className="mb-1 ">Layout Info </h4>
                            <InteractiveLayoutPreview />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 text-center">
                        <p className="  mb-2">{layoutAspectRatio?.width}X{layoutAspectRatio?.height} </p>

                        {isEditing ? (
                            <>
                                <form className="flex mt-1 w-100" onSubmit={handleSubmit(onTitleSubmit)}  >
                                    <div className="flex flex-col w-full">
                                        <Controller name="layoutName" control={control} rules={{
                                            required: "Title is required",
                                            minLength: { value: 3, message: "Title must be at least 3 characters" },
                                        }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <InputText
                                                        {...field}
                                                        placeholder="My custom layout"
                                                        className={`p-2 border rounded-lg  ${fieldState.error ? "border-red-500" : "border-gray-300"
                                                            }`}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                    {fieldState.error && (
                                                        <span className="text-red-500 text-start text-sm pt-1">{fieldState.error.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div><Button label="" icon="fa-solid fa-check" className="p-0 me-2 bg-transparent text-black border-transparent " type="submit" /></div>
                                </form>
                            </>
                        ) : (
                            <>
                                <div className="flex justify-center">
                                    <h5 className="mb-0">
                                        {
                                            selectedLayout.title.length > 15
                                                ? selectedLayout.title.substring(0, 15) + "..."
                                                : selectedLayout.title}
                                    </h5>
                                    <Button
                                        label=""
                                        icon="fa-solid fa-pen"
                                        className="p-0 me-2 bg-white text-black border-white"
                                        onClick={() => setIsEditing(true)}
                                    />
                                </div>
                            </>)}
                    </div>
                </div>
            </div>

            <div className="col-md-12 col-lg-12 order-md-1 order-lg-1 ">
                <div className="">
                    <div className="flex">
                        <div className=" w-50 ">
                            <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-windows bx-sm"></i></span>
                                <div>
                                    <span className='text-xs'>No. Of Screens</span>
                                    <h5 className="mb-0">{selectedLayout.screens_setting?.length ?? "--"}</h5>
                                </div>
                            </div>
                        </div>
                        <div className=" w-50  ">
                            <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-timer  bx-sm"></i></span>
                                <div>
                                    <span className='text-xs'>Layout Duration</span>
                                    <h5 className="mb-0">{formatDuration(selectedLayout.layout_duration ?? "0")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button label="Edit Layout" className='mt-4  mx-2 py-2 w-[99%]  p-button-outlined  ' onClick={() => dispatch(toggleDialog("EditLayoutPopUp"))} />

                </div>
                <hr></hr>
                <div className="row">
                    <div className="user-avatar-section">
                        <div className="d-flex align-items-center flex-column">
                            <div className="  text-center w-[65%] pl-5 ml-5 pe-5">
                                <h5 className="mb-2">Screen Details </h5>

                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between  mt-3">
                        <div className="w-50 d-flex align-items-start me-4 gap-3">
                            <span className="fs-5   bg-label-primary px-[12px] py-2 rounded "><i class="fa-regular fa-window-maximize"></i></span>
                            <div>
                                <h4 className="my-2">{screenDetails?.name?.length > 35
                                    ? screenDetails?.name?.substring(0, 35) + "..."
                                    : screenDetails?.name} </h4>
                            </div>
                        </div>

                    </div>
                    <div className='flex justify-between'>
                        <div className="w-50 ">
                            <div className="d-flex align-items-start me-4 mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-check bx-sm"></i></span>
                                <div>
                                    <span className='text-xs'>No. Of Media</span>
                                    <h5 className="mb-0">{screenMedia ?? "--"}</h5>
                                </div>
                            </div>
                        </div>
                        <div className=" w-50">
                            <div className="d-flex align-items-start   mt-3 gap-3">
                                <span className="badge bg-label-primary p-2 rounded"><i className="bx bx-time bx-sm"></i></span>
                                <div>
                                    <span className='text-xs'>Total Duration</span>
                                    <h5 className="mb-0">{formatDuration(screenDetails?.campaign_duration ?? "0")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mt-3 row justify-center">
                    <hr ></hr>
                    <div className="user-avatar-section">
                        <div className="d-flex align-items-center flex-column">
                            <h5 className="mb-2"> Actions  </h5>
                            <div className="d-flex justify-end w-100">
                                {updating ? <ProgressSpinner className='w-[25px] h-[25px] mx-0' strokeWidth="3" /> : <></>}
                            </div>
                        </div>
                        <div className="overflow-y-auto overflow-x-hidden  ">
                            {/* mute section  */}
                            <div className="w-100 d-flex justify-content-between align-items-center me-4 mt-3 gap-3">
                                <div className='d-flex align-items-center   gap-3'>
                                    <span className="fs-4 bg-label-primary px-2 py-1 w-[40.8px] h-[40.8px] rounded text-center">
                                        {!screenDetails?.settings?.is_muted ? <i className="fa-solid fa-volume-high fa-sm"></i> : <i className="fa-solid fa-volume-xmark fa-sm"></i>}
                                    </span>
                                    <div>
                                        <span className="text-xs">Screen's Audio: {screenDetails?.settings?.is_muted ? "Muted" : "Not Muted"}</span>
                                        <h5 className=" ">Mute All Screen Media</h5>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center   gap-3'>
                                    <InputSwitch disabled={updating} checked={!!screenDetails?.settings?.is_muted}
                                        onChange={(e) => screenDetails?.settings?.is_muted ? unmuteHandler() : muteHandler()} />
                                </div>
                            </div>
                            {/* stretch section  */}
                            <div className="w-100 d-flex justify-content-between align-items-center me-4 mt-3 gap-3">
                                <div className='d-flex align-items-center   gap-3'>
                                    <span className="fs-4 bg-label-primary w-[40.8px] h-[40.8px] px-2 py-1 rounded text-center">
                                        {screenDetails?.settings?.maintain_aspect_ratio ? <i class="fa-solid fa-panorama fa-sm"></i> : <i class="fa-solid fa-image fa-sm"></i>}
                                    </span>
                                    <div>
                                        <span className="text-xs">Screen's Media: {screenDetails?.settings?.maintain_aspect_ratio ? "Fit Screen" : "Disable fit to screen"}</span>
                                        <h5 className=" ">Maintain aspect ratio</h5>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center   gap-3' id="stretchSwitchWrapper" style={{ display: "inline-block", position: "relative" }}>
                                    {isStretching || isUnStretching ? <ProgressSpinner className='w-[25px] h-[25px]' strokeWidth="3" /> : <> </>}
                                    <InputSwitch disabled={isStretching || isUnStretching} checked={!!(screenDetails?.settings?.maintain_aspect_ratio)}
                                        onChange={maintainAspectRatioHandler} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        </div >

    );
};

export default LayoutInfo;