import React, { useEffect, useState } from 'react'

import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import "../../../css/layouts.css"
import AddArea from './Controls/AddArea';
import DrawingArea from './DrawingArea';

import AreaControls from "./Controls/AreaControls";
import DynamicContainer from './Controls/DynamicContainer';

import LayoutNameController from './Controls/LayoutNameController';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setUnit } from '../../../../../redux/Slices/layoutSlice';


import { layoutColor,  defaultArea } from '../../../../../config/layoutConstans';
import { convertToPx, handleSwitchUnit } from '../../../../../config/layoutHelper';
import { useCreateTemplate } from '../../../../../queries/layouts';


function CustomLayoutPopUp() {
    const dispatch = useDispatch()

    const openDialog = useSelector((state) => state.global.openDialog);
    const unit = useSelector((state) => state.layout.unit);


    const [layoutName, setLayoutName] = useState({ isEditing: true, name: "" });
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [titleError, setTitleError] = useState("");
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [areas, setAreas] = useState([defaultArea,]);
    const [isMaximized, setIsMaximized] = useState(true);

    useEffect(() => {
        if (unit.value == "px") {
            const areasConverted = convertToPx(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
        if (unit.value == "%") {
            const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
    }, [unit])

    const { mutate: createTemplateMutation, isLoading } = useCreateTemplate();
    const handleCreateTemplate = () => {
        if (layoutName.isEditing || !layoutName.name.trim()) {
            setTitleError("Title is required and cannot be empty");
            return;
        }
        setTitleError("");
        createTemplateMutation({ layoutName, areas, currentAspectRatio });
    };

    const handleUnitChange = (e) => { dispatch(setUnit(e.target)) }

    const customHeader = (
        <div className="flex justify-between items-center w-full">
            <span className="text-lg font-semibold">Choose Layout</span>
        </div>
    );
    return (
        <Dialog visible={openDialog.CustomLayoutPopUp}
            breakpoints={{ '960px': '95vw' }}
            header={customHeader}
            style={{ overflow: 'hidden' }}
            modal
            blockScroll={true}
            className={`p-fluid w-full ${isMaximized ? 'p-dialog-maximized' : 'p-dialog-default md:w-[100%] lg:w-[75%] h-[85%]'}`}
            onHide={() => dispatch(toggleDialog("CustomLayoutPopUp"))}
        >
            <div className=" scroll_div overflow-auto h-full">

                <div className="flex flex-col items-center h-full  w-[100%] ">
                    <div className="scroll_div w-100 h-100     pl-5 pr-5 flex flex-col lg:flex-row gap-10 ">
                        {/* Preview Area */}

                        <DrawingArea
                            areas={areas}
                            setAreas={setAreas}
                            layoutColor={layoutColor}
                            currentAspectRatio={currentAspectRatio}
                            setCurrentAspectRatio={setCurrentAspectRatio}
                            layoutName={layoutName}
                            setLayoutName={setLayoutName}
                            titleError={titleError}
                            handleUnitChange={handleUnitChange}
                            isSaveDisabled={isSaveDisabled}
                            setIsSaveDisabled={setIsSaveDisabled}
                        />
                    </div>
                    <div className="items-end w-100">

                        <div className="flex border-top">
                            <div className="w-[80%]">
                            </div>

                            <div className="  w-[40%] mr-3 mt-3 flex justify-end gap-3 mb-3">
                                <Button label="Cancel" className={"   pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => dispatch(toggleDialog("CustomLayoutPopUp"))} />
                                <Button loading={isLoading} label="Save" className={"  pt-1 pb-1 "} disabled={isSaveDisabled} onClick={() => handleCreateTemplate()} />
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </Dialog >
    )
}

export default CustomLayoutPopUp