import { useState } from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import LayoutPreview from '../LayoutPreview';
import TemplateCustomizationPopUp from '../Custom/TemplateCustomizationPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setSelectedTemplate } from '../../../../../redux/Slices/layoutSlice';
import { useSelectTemplate } from '../../../../../queries/layouts';

const TemplatesLayouts = ({templatesLoading}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();

    const { defaultTemplate } = useSelector((state) => state.layout);
    const { openDialog } = useSelector((state) => state.global);
    const [templateIdWithAction, setTemplateIdWithAction] = useState(null);


    const { mutate: selectTemplate, isLoading: isSelecting } = useSelectTemplate(navigate, showToast);
    const handleTemplateSelect = (template) => {
        setTemplateIdWithAction(template.id);
        selectTemplate(template.id, {
            onSettled: () => {
                setTemplateIdWithAction(null);
            },
        });
    };


    const handleTemplateCustomize = (template) => {
        dispatch(setSelectedTemplate(template));
        dispatch(toggleDialog("TemplateCustomizationPopUp"))
    }

    return (
        <>
            {
                <Row className="g-3 pe-5 pl-5 ">
                    {defaultTemplate.map((template, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title><div className="text-[13px] text-center">{template.title}</div></Card.Title>
                                    <Card.Text><div className="ml-[10px] mr-[10px]">
                                        <LayoutPreview template={template} screens={template.screens_setting} onClick={() => {
                                            setSelectedTemplate(template)
                                            handleTemplateSelect(template)
                                        }} />
                                    </div></Card.Text>
                                </Card.Body>

                                <div className="w-100 pl-3 prl-3 pb-3 flex justify-center ">
                                    <Button
                                        loading={isSelecting && templateIdWithAction === template.id}
                                        className="px-1 py-1 w-[40%] p-button-raised  p-button-secondary p-button-text p-button-sm hover:bg-purple-500"
                                        label="Select" disabled={isSelecting && templateIdWithAction === template.id || templatesLoading} onClick={() => handleTemplateSelect(template)} />
                                    <Button
                                        className="px-1 py-1 w-[40%] p-button-raised p-button-secondary p-button-text p-button-sm  "
                                        label="Customize" onClick={() => handleTemplateCustomize(template)} />
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }
            {openDialog.TemplateCustomizationPopUp && <TemplateCustomizationPopUp />}

        </>
    );
};

export default TemplatesLayouts;