import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../config/Axios";

import { setIsPrayerCampaign, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";

// -------------------------Get Groups List-------------------------//
const getGroupsList = async ({ queryKey }) => {
    const [, pageNum,] = queryKey;
    let url = `/groups${!_.isNull(pageNum) ? `?page=${pageNum}&per_page=7` : ""}`

    const { data } = await axiosInstance.get(url);

    return !_.isNull(pageNum) ? data : data.data;
}

export const useGetGroupsList = (pageNum = null, enabled = true) => {
    const { showToast } = useCustomToast();

    let { isLoading: groupsLoading, data, error, isError, refetch: refetchGroup } = useQuery(
        ['getGroupsList', pageNum],
        getGroupsList,
        {
            enabled,
        }
    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { groupsLoading, data, refetchGroup };
}

//------------------------------Create Group------------------------------
const createGroup = async (payload) => {
    const { data } = await axiosInstance.post("/groups/create", payload);
    return data.data;
}

export const useCreateGroupMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()
    const queryClient = useQueryClient();

    return useMutation(createGroup, {
        onSuccess: async (data) => {
            dispatch(setIsPrayerCampaign(false))
            dispatch(toggleDialog("createGroupDialog"));
            showToast('success', 'Group Creation', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Update Group------------------------------
const updateGroup = async (payload) => {
    const { data } = await axiosInstance.put(`/groups/${payload.id}/update`, payload.data);

    return data.data;
}

export const useUpdateGroupMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(updateGroup, {
        onSuccess: async (result) => {
            showToast('success', 'Group Updating', result?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Order Group-----------------------------
const orderGroup = async (payload) => {
    const { data } = await axiosInstance.put("/groups/order", payload);
    return data.data;
}

export const useOrderGroupMutation = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch()

    return useMutation(orderGroup, {
        onSuccess: async (data) => {
            dispatch(toggleDialog("orderDialog"))
            showToast('success', 'Group Order', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Delete Group-----------------------------
const deleteGroup = async (payload) => {
    const { data } = await axiosInstance.delete(`/groups/${payload?.id}/delete`);
    return data.data;
}

export const useDeleteGroupMutation = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(deleteGroup, {
        onSuccess: async (data) => {
            showToast('success', 'Delete Group', data?.msg);
            queryClient.invalidateQueries('getGroupedDevices');
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//------------------------------Flush Group-----------------------------
const flushGroup = async (payload) => {
    const { data } = await axiosInstance.get(`/groups/${payload?.id}/screen`);
    return data.data;
}

export const useFlushGroupMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(flushGroup, {
        onSuccess: async (data) => {
            showToast('success', "Flush Group's Devices", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//------------------------------Restart Group-----------------------------
const restartGroup = async (payload) => {
    const { data } = await axiosInstance.get(`/groups/${payload?.id}/restart`);
    return data.data;
}

export const useRestartGroupMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(restartGroup, {
        onSuccess: async (data) => {
            showToast('success', "Restart Group's Devices", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//------------------------------Mute-Unmute Group-----------------------------
const muteUnmuteGroup = async (payload) => {
    const { data } = await axiosInstance.post(`/groups/${payload?.id}/mute`, payload?.data);
    return data.data;
}

export const useMuteUnmuteGroupMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(muteUnmuteGroup, {
        onSuccess: async (data) => {
            showToast('success', "Mute-Unmute Action", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//------------------------------Restart Group-----------------------------
const playPauseGroup = async (payload) => {
    const { data } = await axiosInstance.post(`/groups/play/pause`, payload);
    return data.data;
}

export const usePlayPauseGroupMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(playPauseGroup, {
        onSuccess: async (data) => {
            showToast('success', "Play-Pause Action", data?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}