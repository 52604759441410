import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Image } from 'react-bootstrap';

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useDeleteRssFeed } from '../../../queries/rss';
import { setIsCreation, setSelectedFeed } from '../../../redux/Slices/Apps/appsSlice';

function FeedsList() {
    const { data, pageNum, isLoading, isFetching, lastPage, setLastElement, setData, galleryRefetch, setPath } = useScrollLoadingContext();
    const { selectedRss } = useSelector(state => state.apps);
    const dispatch = useDispatch();

    const deleteRow = useDeleteRssFeed();
    const [isBar, setIsBar] = useState(false);

    useEffect(() => {
        setPath(`/rss/feeds/${selectedRss?.id}/contents`)
        return () => {
            setData([])
            dispatch(setSelectedFeed({}))
        }
    }, [])

    useEffect(() => {
        ["BOTTOM", "TOP"].includes(selectedRss?.rss_settings?.position) ? setIsBar(true) : setIsBar(false)
    }, [selectedRss])

    const editFeed = (rowData) => {
        dispatch(setIsCreation(false))
        dispatch(setSelectedFeed(rowData))
    }

    const deleteFeed = async (id) => {
        await deleteRow.mutateAsync({ id }, {
            onSuccess: () => galleryRefetch()
        })
    }

    const CardContent = ({ item }) => {
        return <>
            <div className={`${isBar ? "d-none" : "col-3 p-2"}`} >
                <Image src={item?.image ?? "https://popup-push-settings.s3.eu-central-1.amazonaws.com/no-image.png"} className="w-100 border" />
            </div>
            <div className={`${isBar ? "col-10 ps-4" : "col-7 "} text-dark `} >
                <div>{item?.title?.split('.')[0]}</div>
                <div className={` fs-8 fw-light  ${isBar ? "text-break" : "text-truncate"}`} >{item?.description ?? "---"} </div>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-end text-primary">
                {/* Update RSS */}
                <button className={`fs-8 mt-1 mx-1 update-button-${item?.id}`} onClick={() => editFeed(item)} data-pr-position="bottom"
                    data-pr-tooltip="Delete">
                    <i className="pi pi-pencil"></i>
                </button>

                {/* Delete RSS */}
                <button className={`fs-8 mx-1 delete-button-${item?.id}`} disabled={deleteRow.isLoading} onClick={() => deleteFeed(item?.id)} data-pr-position="bottom"
                    data-pr-tooltip="Delete" >
                    <i className="bx bx-trash"></i>
                </button>
            </div>
        </>
    }

    return <>
        {
            data?.length > 0 ?
                <div className="popular-movies-api d-flex flex-wrap ">
                    {
                        data?.map((item, index) => {
                            return index === data?.length - 1 && pageNum <= lastPage ?
                                <div className="col-12 d-flex align-items-center  p-2 mb-1 rounded bg-label-secondary border"
                                    ref={setLastElement}
                                    key={`${item?.type}-${index}`}>
                                    <CardContent item={item} />
                                </div>
                                :
                                <div className="col-12 d-flex align-items-center  p-2 mb-1 rounded bg-label-secondary border"
                                    key={`${item?.type}-${index}`}>
                                    <CardContent item={item} />
                                </div>
                        })
                    }
                </div>
                :
                !isLoading && !isFetching ?
                    <div className="d-flex justify-content-center h-full bg-[#eeeeee] font-bold p-3 my-[35px] rounded">
                        There are no content added yet!
                    </div>
                    :
                    <></>
        }

        {
            isLoading || isFetching ?
                <ProgressSpinner style={{ width: "60px", height: "60px" }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                :
                <></>
        }


    </>
}

export default FeedsList