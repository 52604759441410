import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";

import { useAddCampaignContent, useGetGalleryByLayer } from "../../../queries";
import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useAddPlaylistContent } from "../../../queries/playlistContent";
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';
import SearchInput from './SearchInput';
import BreadCrumb from "../../Media/components/MediaLayout/BreadCrumb";
import FolderCard from "../../Media/components/MediaLayout/FolderCard";
import MediaCard from "../../Media/components/MediaLayout/MediaCard";

function MediaList() {
  const dispatch = useDispatch();
  const { assign, layer, campaignDetails } = useSelector((state) => state.global);
  const screenDetails = useSelector((state) => state.layout.screenDetails);
  const { ids, setIds } = useScrollLoadingContext();
  const addCampaignContent = useAddCampaignContent()
  const addPlaylistContent = useAddPlaylistContent()
  const getGalleryByLayer = useGetGalleryByLayer();

  const [currentFolder, setCurrentFolder] = useState({});
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const { id } = useParams();

  const getData = async (folderId = "", search = searchTerm) => {
    await getGalleryByLayer.mutateAsync({
      isCampaignInteractive: Number(campaignDetails.is_interactive),
      section: "MEDIA",
      layer,
      folderId: localStorage.getItem("folder_id"),
      searchTerm: search,
    }, {
      onSuccess: (response) => setData(response)
    })
  };

  useEffect(() => {
    getData();
    return () => setIds([])
  }, []);

  useEffect(() => {
    if (assign) {
      dispatch(setDisableBtn(true))
      assignHandler();
    }
  }, [assign]);

  const assignHandler = async () => {
    if (window.location.pathname.includes("campaign")) {
      await addCampaignContent.mutateAsync({
        "ids_list": ids,
        "campaign_id": id,
        "layer": layer,
        "type": "media",
      })
    } else if (window.location.pathname.includes("layout")) {
      await addCampaignContent.mutateAsync({
        "ids_list": ids,
        "campaign_id": screenDetails.id,
        "layer": layer,
        "type": "media",
      })
    } else {
      await addPlaylistContent.mutateAsync({
        "list_media_ids": ids,
        "playlist_id": id,
        "layer": layer,
      })
    }
  };

  const onMediaChange = (e) => {
    let selectedMedia = [...ids];

    if (e.checked) selectedMedia.push(e.value);
    else selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

    setIds(selectedMedia);
  };

  const resetSearch = () => {
    setSearchTerm("");
    getData("", "");
  }

  return (
    <>
      <SearchInput
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        getData={getData}
        isLoading={getGalleryByLayer.isLoading}
        resetSearch={resetSearch}
        isDisabled={data?.length === 0}
      />

      <div className="d-flex flex-column  scroll_container scroll_div px-3 w-100">
        {
          breadCrumb.length != 0 &&
          <div className=" my-3 d-flex justify-content-start w-75">
            <BreadCrumb
              setMedia={setData}
              getData={getData}
              breadCrumb={breadCrumb}
              setBreadCrumb={setBreadCrumb}
              setCurrentFolder={setCurrentFolder}
            />
          </div>
        }
        <div className="d-flex flex-wrap py-3 w-100">
          {
            getGalleryByLayer.isLoading ?
              <ProgressSpinner
                style={{ width: "60px", height: "60px" }}
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration="1s" />
              :
              data?.length == 0 ?
                <h5 className="py-5 text-center w-100">There has been no media added yet!</h5>
                :
                <>
                  {
                    data.map((item, index) => {
                      return (
                        <div key={item.id} className={`col-12 media_list ${index + 1 != data.length ? "border-bottom border-gray" : ""} mx-2 py-2 media position-relative`} >
                          {
                            item.hasOwnProperty("path") ?
                              <div className="col-12 d-flex align-items-center justify-content-between my-1" key={`${item.type}-${index}`}>
                                <label htmlFor={item.id} className="col-11 d-flex align-items-center" >
                                  <MediaCard item={item} displayAction={false} />
                                </label>
                                <Checkbox inputId={item.id} name="media" className="me-3" value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                              </div>
                              :
                              <>
                                <div className="col-12 d-flex align-items-center my-1" key={`${item.type}-${index}`} >
                                  <FolderCard
                                    setCurrentFolder={setCurrentFolder}
                                    displayAction={false}
                                    getData={getData}
                                    item={item}
                                    setBreadCrumb={setBreadCrumb}
                                    breadCrumb={breadCrumb}
                                  />
                                </div>
                              </>
                          }
                        </div>
                      )
                    })
                  }
                </>
          }
        </div>
      </div>
    </>
  );
}

export default MediaList;
