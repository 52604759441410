const BASE_URL = process.env.REACT_APP_NOT_SECRET_CODE;
const GEONAMES_USERNAME = "popup_push";

//Get timezone
const date = new Date();
const englishTimeZoneFormatter = new Intl.DateTimeFormat("en", {
  timeZoneName: "long",
});
const englishTimeZone = englishTimeZoneFormatter
  .formatToParts(date)
  .find((part) => part.type === "timeZoneName").value;

function getProfile() {
  return {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    image: localStorage.getItem("image"),
    phone: localStorage.getItem("phone"),
    access_token: localStorage.getItem("access_token"),
  };
}

function setProfile(data) {
  localStorage.setItem("name", data?.name);
  localStorage.setItem("email", data?.email);
  localStorage.setItem("image", data?.image);
  localStorage.setItem("phone", data?.phone);
  localStorage.setItem("company_id", data?.company_id);
  localStorage.setItem("access_token", data?.access_token);
  localStorage.setItem("email_verified_at", data?.email_verified_at);
  localStorage.setItem("verification_attempts", data?.verification_attempts);
}

const emptyLocalStorage = () => {
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("image");
  localStorage.removeItem("phone");
  localStorage.removeItem("company_id");
  localStorage.removeItem("access_token");
  localStorage.removeItem("email_verified_at");
  localStorage.removeItem("verification_attempts");
};

function dateFormate(val) {
  let objectDate = new Date(val);

  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();

  return `${year}-${month}-${day}`;
}

function useTitle(title) {
  const prevTitle = document.title;
  document.title = "POPUP PUSH - " + title;

  return () => {
    document.title = "POPUP PUSH - " + prevTitle;
  };
}

function setSubTitle(data) {
  localStorage.setItem("SubTile", data?.name);
  localStorage.setItem("SubTile_created", data?.created_at);
}

function getSubTitle() {
  return {
    SubTile: localStorage.getItem("SubTile"),
    SubTile_created: localStorage.getItem("SubTile_created"),
  };
}

function getTitle() {
  const prefix = "POPUP PUSH - ";
  let title = document.title;

  return title.startsWith(prefix) ? title.slice(prefix.length) : title;
}

//To format media size+
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
function formatSize(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

//To Format Total duration HH:MM:SS
function formatDuration(totalDuration) {
  // Calculate hours, minutes, seconds, and milliseconds
  var hours = Math.floor(totalDuration / 3600);
  var minutes = Math.floor((totalDuration % 3600) / 60);
  var seconds = Math.floor(totalDuration % 60);

  // Format hours, minutes, seconds, and milliseconds
  if (hours < 10) {
    hours = "0" + hours;
  }
  
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

// Used in drag and drop components
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: { ...sourceColumn, items: sourceItems },
      [destination.droppableId]: { ...destColumn, items: destItems },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: { ...column, items: copiedItems },
    });
  }
};

//get forms validation errors
const getFormErrorMessage = (name, errors) => {
  return (
    errors[name] && <small className="p-error">{errors[name].message}</small>
  );
};

const prayersNames = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];

function explodeArray(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  if (arr.length === 0) {
    return "";
  }

  const explodedString = arr.slice(0, -1).join(", ");
  return explodedString + (arr.length > 1 ? `, ${arr[arr.length - 1]}` : "");
}

const TOOLTIP_OPTIONS = { showDelay: 100, position: "bottom" }

const validateNotEqual = (value) => {
  return value.toLowerCase() !== 'default' || `"default" is a reserved name!`
};

function trimFileNameExc(name) {
  return name?.split(".")[0];
}

function handleRssSetting(setting, title = "") {
 return   {
      ...setting,
      name: title?.split('.')[0],
      title: title?.split('.')[0],
      text_color: setting?.text_color ?? setting?.textColor,
      direction: setting?.direction === "ltr" ? "left" : "right",
      font_size: Number(setting?.font_size),
      bg_color: setting?.bg_color ?? setting?.bgColor,
    }
}

export {
  TOOLTIP_OPTIONS,
  GEONAMES_USERNAME,
  prayersNames,
  BASE_URL,
  trimFileNameExc,
  getProfile,
  setProfile,
  useTitle,
  getTitle,
  setSubTitle,
  getSubTitle,
  dateFormate,
  formatSize,
  formatDuration,
  onDragEnd,
  getFormErrorMessage,
  emptyLocalStorage,
  explodeArray,
  validateNotEqual,
  handleRssSetting
};
