import React, { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { layoutColor } from '../../../../../config/layoutConstans';
import { convertToPx, handleSwitchUnit } from '../../../../../config/layoutHelper';

import DrawingArea from './DrawingArea';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setSelectedTemplate, setUnit } from '../../../../../redux/Slices/layoutSlice';

import { useCustomizeTemplate } from '../../../../../queries/layouts';


function TemplateCustomizationPopUp() {
    const dispatch = useDispatch()
    const unit = useSelector((state) => state.layout.unit);

    const openDialog = useSelector((state) => state.global.openDialog);
    const selectedTemplate = useSelector((state) => state.layout.selectedTemplate);
    const newAreas = selectedTemplate.screens_setting.map(area => ({
        ...area,
        unit: "%"
    }));
    const [layoutName, setLayoutName] = useState({ isEditing: false, name: "" });
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [areas, setAreas] = useState(newAreas);

    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [titleError, setTitleError] = useState("");
    const [isMaximized, setIsMaximized] = useState(true);

    useEffect(() => {
        setLayoutName({ isEditing: true, name: "" });
    }, [])

    useEffect(() => {
        if (unit.value == "px") {
            const areasConverted = convertToPx(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
        if (unit.value == "%") {
            const areasConverted = handleSwitchUnit(areas, currentAspectRatio);
            setAreas(areasConverted)
        }
    }, [unit])
    const { mutate: createTemplateMutation, isLoading } = useCustomizeTemplate();

    const handleCreateTemplate = () => {
        if (layoutName.isEditing || !layoutName.name.trim()) {
            setTitleError("Title is required and cannot be empty");
            return;
        }
        setTitleError("");
        createTemplateMutation({ layoutName, areas, currentAspectRatio });
    };

    const handleUnitChange = (e) => {
        dispatch(setUnit(e.target))
    }

    const handleOnHide = () => {
        dispatch(toggleDialog("TemplateCustomizationPopUp"))
        dispatch(setSelectedTemplate({
            id: "",
            title: "",
            screens_setting: [],
        }))
    }
    const customHeader = (
        <div className="flex justify-between items-center w-full">
            <span className="text-lg font-semibold">Customize Your Layout</span>
        </div>
    );
    return (
        <Dialog visible={openDialog.TemplateCustomizationPopUp}
            breakpoints={{ '960px': '95vw' }}
            header={customHeader}
            modal
            className={`p-fluid w-full ${isMaximized ? 'p-dialog-maximized' : 'p-dialog-default md:w-[100%] lg:w-[75%] h-[85%]'}`}
            onHide={() => handleOnHide()}
        >
            <div className="scroll_div overflow-auto h-full">

                <div className="flex flex-col items-center  mt-1 h-full w-[100%] ">
                    <div className="scroll_div w-100 h-100     pl-5 pr-5 flex flex-col lg:flex-row gap-10 ">

                        <DrawingArea
                            areas={areas}
                            setAreas={setAreas}
                            layoutColor={layoutColor}
                            currentAspectRatio={currentAspectRatio}
                            setCurrentAspectRatio={setCurrentAspectRatio}
                            layoutName={layoutName}
                            setLayoutName={setLayoutName}
                            titleError={titleError}
                            handleUnitChange={handleUnitChange}
                            isSaveDisabled={isSaveDisabled}
                            setIsSaveDisabled={setIsSaveDisabled}
                        />
                    </div>
                    <div className="items-end w-100">

                        <div className="flex border-top">
                            <div className="w-[80%]">
                            </div>

                            <div className="  w-[40%] mr-3 mt-3 flex justify-end gap-3 mb-3">
                                <Button label="Cancel" className={"  me-2 pt-1 pb-1 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => handleOnHide()} />
                                <Button loading={isLoading} label="Save" className={"  pt-1 pb-1  "} disabled={isSaveDisabled} onClick={() => handleCreateTemplate()} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Dialog>
    )
}

export default TemplateCustomizationPopUp