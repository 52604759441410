import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import { useTitle } from '../../config/global';
import landing from '../../assets/img/boy-with-rocket-light.png';
import Layout from "./Layout";

import '../../assets/vendor/css/pages/page-auth.css';
import logo from '../../assets/img/logo.png';

const NotFound = () => {
    useTitle('File Not Found');
    
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("access_token") !== null)
            setIsAuthenticated(true)
    }, [])

    return (
        <>
            {
                isAuthenticated ?
                    <Layout>
                        <div className="text-center mt-5" >
                            <h2 className="mb-2 mx-2">Page Not Found </h2>
                            <p className="mb-4 mx-2">Oops! 😖 The requested URL was not found on this server.</p>
                        </div>
                    </Layout >
                    :
                    <div className="authentication-wrapper authentication-cover">
                        <div className="authentication-inner row m-0">
                            <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                                <div className="w-100 d-flex justify-content-center">
                                    <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                                </div>
                            </div>
                            <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                                <div className="w-px-400 mx-auto">
                                    {/* Logo */ }
                                    <div className="app-brand mb-5">
                                        <Link to="/home" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                            <img src={ logo } className="img-fluid" width={ 150 } alt="logo" />
                                        </Link>
                                    </div>

                                    <div className="text-center mt-5" >
                                        <h2 className="mb-4 mx-2">Page Not Found </h2>
                                        <p className="mb-4 mx-2">Oops! The requested URL was not found on this server.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
};
export default NotFound;
