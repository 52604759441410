import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import DevicesList from './assignPopup/devices/DevicesList';
import GroupsList from './assignPopup/groups/GroupsList';

import { useAssignCampaignDevices, useGetDevicesList, useGetGroupsList } from '../../../queries';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import SearchInput from '../../SharedComponents/AddContentPopup/SearchInput';

function AssignPopup({ campaignId }) {
    const dispatch = useDispatch();
    const { openDialog } = useSelector((state) => state.global);

    const [selectedCategory, setSelectedCategory] = useState("devices");
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [data, setData] = useState([]);
    const [ids, setIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { data: devicesList, devicesLoading, refetchDevices, isFetching } = useGetDevicesList(pageNum, searchTerm, false);
    const { data: groupList, groupsLoading, refetchGroup } = useGetGroupsList(pageNum, false);
    const assignCampaignDevices = useAssignCampaignDevices();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );


    const handleSearch = async () => {
        setPageNum(1);
        if (selectedCategory === "devices") {
            try {
                const { data } = await refetchDevices();
                setData(data?.data);
                setLastPage(data?.pagination?.total_pages || 1);
            } catch (error) {
                console.error("Error fetching devices:", error);
            }
        }
    };

    // Update data on category change or new fetch results
    useEffect(() => {
        if (selectedCategory === "devices" && devicesList?.data && devicesList?.pagination.total > data?.length) {
            const updatedData = pageNum === 1 ? devicesList.data : [...data, ...devicesList.data];
            setData(updatedData);
            setLastPage(devicesList.pagination?.total_pages || 1);
        } else if (selectedCategory === "groups" && groupList?.data && groupList?.pagination.total > data?.length) {
            const updatedData = pageNum === 1 ? groupList.data : [...data, ...groupList.data];
            setData(updatedData);
            setLastPage(groupList.pagination?.total_pages || 1);

        }
    }, [devicesList, groupList, selectedCategory]);

    // Fetch data when pageNum changes
    useEffect(() => {
        if (pageNum <= lastPage || lastPage === null) {
            if (selectedCategory === "devices") {
                refetchDevices()
            }
            else {
                refetchGroup()
            };
        }
    }, [pageNum, selectedCategory]);

    // Scroll observer logic
    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    const renderFooter = () => {
        return (
            <div>
                <Button
                    className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm"
                    label="Cancel"
                    disabled={assignCampaignDevices.isLoading}
                    onClick={onClose}
                />
                <Button
                    label={"Assign"}
                    loading={assignCampaignDevices.isLoading}
                    className="p-button-sm py-2 px-4"
                    disabled={assignCampaignDevices.isLoading || ids.length === 0}
                    onClick={assignToDevice}
                />
            </div>
        );
    };

    const assignToDevice = async () => {
        const data = {
            campaign_id: campaignId,
            ...(selectedCategory === "devices" ? { devices_ids: ids, type: "devices" } : { groups_ids: ids, type: "groups" }),
        };

        await assignCampaignDevices.mutateAsync(data, {
            onSuccess: (data) => {
                const rejected = data?.rejected;
                if (rejected?.satellite?.length === 0 && rejected?.hdmi?.length === 0) {
                    dispatch(toggleDialog("assignDeviceGroup"));
                }
            },
        });
    };

    const onClose = () => {
        dispatch(toggleDialog("assignDeviceGroup"));
    };

    const onCategoryChangeHandler = (category) => {
        setIds([]);
        setData([]);
        setPageNum(1);
        setSelectedCategory(category);
    };
    const resetSearch = () => {
        setSearchTerm("")
    }
    return (
        <Dialog
            header="Assign To Device"
            visible={openDialog.assignDeviceGroup}
            onHide={onClose}
            breakpoints={{ "960px": "95vw" }}
            style={{ width: "40vw" }}
            footer={renderFooter}
        >
            <div className="d-flex device_assign_container border-top border-bottom min-h-[40vh]">
                <div className="col-2 d-flex flex-col text-capitalize border-end ">
                    <span
                        className={`px-3 py-2 cursor-pointer ${selectedCategory === "groups" ? "active_category" : ""}`}
                        onClick={() => onCategoryChangeHandler("groups")}
                    >
                        groups
                    </span>
                    <span
                        className={`px-3 py-2 cursor-pointer ${selectedCategory === "devices" ? "active_category" : ""}`}
                        onClick={() => onCategoryChangeHandler("devices")}
                    >
                        devices
                    </span>
                </div>
                <div className="col-10 d-flex flex-column my-3 ">
                    {selectedCategory === "devices" ? <SearchInput
                        setSearchTerm={setSearchTerm}
                        searchTerm={searchTerm}
                        getData={handleSearch}
                        isLoading={isFetching}
                        resetSearch={resetSearch}
                        isDisabled={data?.length === 0}
                    /> : ""}
                    <div className="d-flex flex-wrap justify-content-center scroll_container scroll_div px-2">
                        {selectedCategory === "devices" ? (
                            <DevicesList
                                data={data}
                                ids={ids}
                                setIds={setIds}
                                setLastElement={setLastElement}
                                pageNum={pageNum}
                                lastPage={lastPage}
                                loading={isFetching || devicesLoading}
                            />
                        ) : (
                            <GroupsList
                                data={data}
                                ids={ids}
                                setIds={setIds}
                                setLastElement={setLastElement}
                                pageNum={pageNum}
                                lastPage={lastPage}
                                groupsLoading={groupsLoading}
                            />
                        )}
                        {
                            (isFetching || devicesLoading || groupsLoading) ?
                                <Spinner
                                    variant="primary"
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    className="m-3"
                                    aria-hidden="true"
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default AssignPopup;
