import avatar from "../../../assets/img/unknown_person.jpg";

const initialState = {
  disableBtn: false,
  isLoading: false,
  rerender: false,
  packages: [],
  stepsBack: -1,
  countries: [],
  rerenderList: false,
  rerenderSubscription: false,
  companyDetails: {},
  subscriptionInfo: {
    isProPackage: false,
    isFinished: false,
    isYearly: false,
    isFree: false,
  },
  userInfo: {},
  isAuthenticated: true,
  layerOptions: [],
  selectedApp: {},
  assign: false,
  layer: "MAIN",
  type: "media",
  isPrayerCampaign: false,
  hideTabs: false,
  campaignDetails: { total_duration: 0 },
  isPublished: false,
  selectedMediaInfo: {},
  deletedItemId: "",
  profileImage:
    localStorage.getItem("image") === "null"
      ? avatar
      : localStorage.getItem("image"),
  showAssignForm: false,
  openDialog: {
    // Shared component (Add content dialog)
    assignScheduleCampaigns: false,
    assignCampaignsDropDown: false,
    GroupAssignCampaignsDropDown:false,
    assignDeviceGroup: false,
    addContent: false,
    // Media-Folder
    moveMediaFolder: false,
    // Folder
    createFolder: false,
    updateFolder: false,
    // Media
    previewMedia: false,
    uploadMedia: false,
    updateMedia: false,
    // Campaign
    editInteractiveMedia: false,
    campaignMediaSetting: false,
    createCampaign: false,
    updateCampaign: false,
    resetCampaign:false,
    
    // Device
    createGroupDialog: false,
    confirmDialog: false,
    assignDialog: false,
    updateDevice: false,
    orderDialog: false,
    //Playlist
    updatePlaylist: false,
    createPlaylist: false,
    // Broadcast
    createBroadcastDialog: false,
    // Billing
    paymentDialog: false,
    addScreens: false,
    upgrade: false,
    // Users
    addUser: false,
    // Apps
    rssFeedAddition: false,
    rssCreation: false,
    customRssCreation: false,
    streamPreview: false,
    addCustomFeed: false,
    createEditCollection: false,
    addCollectionContent: false,
  },
  subLoaders: {
    // Interactive campaign
    durationLoader: false,
    titleLoader: false,
    thumbLoading: false,
    // Broadcast App
    createLoading: false,
    streamsLoading: true,
    reload: false,
    // Campaign Media
    deleteCampaignMedia: false,
    // Billing
    packageDetails: false,
    invoicePreview: false,
    subscriptions: false,
    calculatePrice: true,
    packages: false,
    addScreens: false,
    // Company
    isFree: false,
  },
  deleteDialog: {
    interactiveCampaign: false,
    playlist: false,
    campaign: false,
    device: false,
    folder: false,
    media: false,
    user: false,
  },
};

export default initialState;
