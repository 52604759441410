import React from 'react';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';

import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useCreateFolderMutation } from '../../../../queries/folderMedia';

function CreateFolderPopup({ setCurrentFolder, setBreadCrumb, breadCrumb }) {
    const { formState: { errors }, handleSubmit, register, reset } = useForm({ name: "" });
    const { mutate: createFolder, isLoading } = useCreateFolderMutation(setCurrentFolder, setBreadCrumb, breadCrumb);
    
    const { openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const { id } = useParams();

    const onSubmit = (data) => {
        data.parent_folder_id = id;
        createFolder({ data, parentFolderId: id });
        reset();
    };

    return (
        <>
            <Button icon={<i className="fas fa-folder-plus text-secondary fs-3 p-1"></i>} tooltip="New folder"
                tooltipOptions={{ position: 'bottom', autoHide: false }} onClick={() => dispatch(toggleDialog("createFolder"))}
                className=" bg-transparent border-0 p-1 mx-1 text-primary" />

            <Dialog header="Folder Creation"
                visible={openDialog.createFolder}
                onHide={() => { dispatch(toggleDialog("createFolder")) }}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '30vw' }}>

                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="my-5 px-5">
                        <input
                            {...register("name", {
                                required: "Folder name is required!",
                                minLength: {
                                    value: 3, message: 'Length must be 3 or more',
                                },
                                pattern: {
                                    value: /^[^.].*$/,
                                    message: "Please Enter a valid name",
                                },
                            })}
                            aria-invalid={errors.name ? "true" : "false"}
                            className={errors.name ? 'form-control is-invalid' : 'form-control'}
                            placeholder="Folder Name" id='folder_name'
                        />
                        {errors.name && <p className='fv-plugins-message-container invalid-feedback'>{errors.name?.message}</p>}
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={isLoading} onClick={() => dispatch(toggleDialog("createFolder"))}>Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={isLoading} >
                            {
                                isLoading ?
                                    <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                    :
                                    "Create"
                            }
                        </button>
                    </div>
                </form>

            </Dialog>
        </>
    )
}

export default CreateFolderPopup