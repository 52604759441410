import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setDisableBtn, toggleDeleteDialog, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { handleResErrors } from "./utils";
import { PLAYLISTS_TABLE_CONFIG } from "../Constants/datatables";

//--------------------------Create Playlist--------------------------
const createPlaylist = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/create`, payload);
    return data.data;
}
export const useCreatePlaylistMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return useMutation(createPlaylist, {
        onSuccess: async (data) => {
            dispatch(toggleDialog("createPlaylist"));
            showToast('success', 'PlayList Creation', 'PlayList Created Successfully');
            navigate('/playlist/' + data?.id + '/show')
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update Playlist--------------------------
const updatePlaylist = async (payload) => {
    const { data } = await axiosInstance.put(`/playlist/${payload.id}`, payload.data);
    return data.data;
}

export const useUpdatePlaylistMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(updatePlaylist,
        {
            onError: (error) => handleResErrors(error, showToast)
        })
}

// -------------------------Delete Playlist-------------------------//
const deletePlaylist = async (payload) => {
    const { data } = await axiosInstance.delete(`/playlist/${payload.id}/delete`);
    return data.data;
}

export const useDeletePlaylist = () => {
    const { setLazyParams } = useDataTableContext();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    return useMutation(deletePlaylist, {
        onSuccess: async () => {
            dispatch(toggleDeleteDialog("playlist"));
            showToast('success', 'Delete Playlist', 'PlayList Deleted Successfully');
            setLazyParams(prev => ({ ...prev, ...PLAYLISTS_TABLE_CONFIG }))
        },
        onError: (error) => {
            handleResErrors(error, showToast)
            dispatch(setDisableBtn(false))
        }
    })
}

//--------------------------Get Playlist By ID--------------------------
const getPlaylist = async (id) => {
    const { data } = await axiosInstance.get(`/playlist/${id}`);
    return data.data;
}

export const useGetPlaylist = (id) => {
    const { showToast } = useCustomToast();

    const { isFetching: playlistFetching, isLoading: playlistLoading, data: playlistInfo, refetch: refetchPlaylist } = useQuery(
        ['getPlaylist', id],
        () => getPlaylist(id),
        {
            enabled: !!id,
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { playlistLoading, playlistInfo, refetchPlaylist, playlistFetching };
}

//--------------------------Get Playlist Content by layer--------------------------
const getPlaylistLayerContent = async ({ queryKey }) => {
    const [, id, layer] = queryKey
    const { data } = await axiosInstance.get(`/playlist/${id}/${layer}/media`);
    return data.data;
}

export const useGetPlaylistLayerContent = (id, layer) => {
    const { showToast } = useCustomToast();

    const { isLoading, data, refetch } = useQuery(
        ['getPlaylistLayerContent', id, layer],
        getPlaylistLayerContent,
        {
            enabled: !!id,
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, refetch };
}

//--------------------------Playlist Campaign--------------------------
const publishPlaylist = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/${payload.id}/publish`, {});
    return data.data;
}

export const usePublishPlaylistMutation = () => {
    const { showToast } = useCustomToast();
    const updatePlaylist = useUpdatePlaylistMutation();
    const queryClient = useQueryClient();

    return useMutation(publishPlaylist, {
        onSuccess: async (res, variables) => {
            await updatePlaylist.mutateAsync({
                id: variables.id,
                data: {
                    status: "ACTIVE",
                    name: variables?.name,
                },
            }).then(() => queryClient.invalidateQueries(['getPlaylist', variables.id])
            )
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Playlist rese--------------------------
const resetPlaylist = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/${payload.id}/reset`, {});
    return data.data;
}

export const useResetPlaylistMutation = () => {
    const { showToast } = useCustomToast();
    const updatePlaylist = useUpdatePlaylistMutation();
    const queryClient = useQueryClient();

    return useMutation(resetPlaylist, {
        onSuccess: async (res, variables) => {
            await updatePlaylist.mutateAsync({
                id: variables.id,
                data: {
                    status: "ACTIVE",
                    name: variables?.name,
                },
            }).then(() => 
                {queryClient.invalidateQueries(['getPlaylist', variables.id])
                queryClient.invalidateQueries(['getPlaylistLayerContent', variables.id, "MAIN"])}

            )
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
