import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import "./css/mediaTable.css";

import FirebaseMediaListener from "../Services/FirebaseMediaListener";
import DeleteConfirmation from "../SharedComponents/DeleteConfirmation";
import CreateFolderPopup from "./components/Folder/CreateFolder";
import UploadPopup from "./components/UploadPopup/UploadPopup";
import BreadCrumb from "./components/MediaLayout/BreadCrumb";
import Layout from "../Layout/Layout";

import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

import { useGalleryContext } from "../Contexts/GalleryContext";
import FolderDragDrop from "./components/FolderDragDrop/FolderDragDrop";
import MediaFilter from "../SharedComponents/Gallery/MediaFilter";
import SearchBar from "../SharedComponents/Gallery/SearchBar";

import { deleteItemLocally, galleryRefetch, setData, setParams } from "../../redux/Slices/scrollSlice";
import { EMPTY_FOLDER, JUSTIFY_OPTIONS, MEDIA_DEFAULT_FILTERS } from "../../config/media";
import { useDeleteMultiFolderMedia } from "../../queries";
import { useDispatch, useSelector } from 'react-redux';
import { toggleDeleteDialog } from '../../redux/Slices/Global/globalSlice';
import ScrollContainer from "../SharedComponents/ScrollContainer";
import { useTitle } from "../../config/global";
function MediaDataTable() {
  useTitle("Media");
  const { multiSelectCheck, setMultiSelectCheck, selectedItem, setSelectedItem, idsList, setIdsList, layout, setLayout, } = useGalleryContext();

  const deleteDialog = useSelector((state) => state.global.deleteDialog);
  const { isFetching, data } = useSelector((state) => state.scroll);
  const deleteMultiItems = useDeleteMultiFolderMedia();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentFolder, setCurrentFolder] = useState(EMPTY_FOLDER);
  const [breadCrumb, setBreadCrumb] = useState(JSON.parse(localStorage.getItem("bread_crumb")) ?? []);
  const [filters, setFilters] = useState(MEDIA_DEFAULT_FILTERS);
  const [visible, setVisible] = useState(false);

  const handleApplyFilters = () => {
    dispatch(setData([]))
    dispatch(setParams(filters))
    setVisible(false)
  };

  const layoutOptionsTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  // Multi media/folder delete handler
  useEffect(() => {
    if (multiSelectCheck) {
      var ids = data.map((item) => item.id);
      setIdsList(ids);
    } else {
      setIdsList([]);
    }
  }, [multiSelectCheck]);

  const deleteHandler = async () => {
    await deleteMultiItems.mutateAsync(idsList, {
      onSuccess: () => {
        idsList.forEach((id) => dispatch(deleteItemLocally(id)));
        dispatch(toggleDeleteDialog("media"));
        setIdsList([]);
        setMultiSelectCheck(false);
      }
    });
  };

  const subNavbarProps = useMemo(() => {
    return {
      title: "Media",
    };
  }, []);

  const subNavbarContent = useMemo(() => {
    return (
      <div className={`flex justify-end  w-100`}>
        <div className="flex-grow-1">
          <SearchBar handleApplyFilters={handleApplyFilters} filters={filters} setFilters={setFilters} />
        </div>
        <Button
          icon="pi pi-filter-fill fs-5"
          className=" p-button-text p-button-rounded   font-bold text-secondary  "
          onClick={() => setVisible(true)}
          tooltip="Toggle Filters"
        />
        <UploadPopup currentFolder={currentFolder} content="media" />
      </div>
    );
  }, [filters]);

  return (
    <Layout subNavbarContent={subNavbarContent} subNavbarProps={subNavbarProps} padding={false}>
      <div className="px-4 pt-4 dataview-demo ">
        <div className="d-flex flex-column justify-content-start bg-white rounded-3 media_container px-auto mx-1 ">
          <div className="col-12 px-3 py-2 py-sm-0 d-flex justify-content-between align-items-sm-center align-items-start btn_container border-bottom">
            <div className="d-flex flex-sm-row flex-column">
              <SelectButton
                value={layout}
                options={JUSTIFY_OPTIONS}
                className="m-1  mb-3 mb-sm-0"
                onChange={(e) => setLayout(prev => e.value == null ? prev : e.value)}
                itemTemplate={layoutOptionsTemplate}
                optionLabel="value"
              />
              {
                breadCrumb?.length != 0 &&
                <BreadCrumb
                  getData={() => dispatch(galleryRefetch())}
                  breadCrumb={breadCrumb} setBreadCrumb={setBreadCrumb} setCurrentFolder={setCurrentFolder} />

                  
                }
            </div>
            <div className=" d-flex justify-content-end align-items-center align-items-start my-sm-3 w-[40%]">
              {!multiSelectCheck ?
                <>
                  <CreateFolderPopup
                    currentFolder={currentFolder}
                    setCurrentFolder={setCurrentFolder}
                    setBreadCrumb={setBreadCrumb}
                    breadCrumb={breadCrumb}
                  />
                  <Sidebar icon="pi pi-filter-fill fs-5" visible={visible} position="right" className="p-sidebar-sm w-[25px]" onHide={() => setVisible(false)}>
                    <MediaFilter
                      filters={filters}
                      setFilters={setFilters}
                      onApplyFilters={handleApplyFilters}
                    />
                  </Sidebar>
                </>
                :
                <>
                  <Tooltip target=".delete-button" className="fs-8" />
                  <button
                    className="btn  btn-icon delete-button  px-2 "
                    onClick={() => { setSelectedItem(""); dispatch(toggleDeleteDialog("media")); }}
                    data-pr-position="bottom"
                    data-pr-tooltip="delete"
                  >
                    <i className="bx bx-trash fs-4 fw-light text-danger me-4"></i>
                  </button>
                  <div className="border-end border-start px-3 d-flex align-items-center">
                    <i className="fas fa-folder fw-light fs-4 pe-2"></i>
                    <span className="fs-5 ">{idsList.length}</span>
                  </div>
                </>
              }
              <div className="d-flex  px-2">
                <Checkbox inputId="binary" checked={multiSelectCheck} onChange={(e) => setMultiSelectCheck(e.checked)} className="me-2 main_checkbox media_checkbox" />
              </div>
            </div>
          </div>

          <ScrollContainer path={`/folder/media?section=MEDIA`}>
            <div className={`row justify-content-center align-items-start align-content-start ${layout === "list" ? "h-[85%]" : "h-[85%]"} `}>
              {
                !isFetching && data?.length === 0 ?
                  <h5 className="py-5 text-center w-100">
                    No media assigned yet!
                  </h5>
                  :
                  <>
                    {
                      layout === "list" && (
                        <div className="media_list  mx-2 py-2 media position-relative  media_banner">
                          <div className="col-4"></div>
                          <div className="col-md-1 col-2 fs-6 px-0 text-center fst-italic d-none d-sm-block">Duration </div>
                          <div className="col-2 fs-6 px-0 text-center fst-italic  d-none d-sm-block">Kind</div>
                          <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic">Size</div>
                          <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic d-none d-xl-block">Created Date</div>
                          <div className="col-3"> </div>
                        </div>
                      )
                    }

                    <div className="cards_container object-fit row  align-content-start justify-content-start align-items-start mx-auto scroll_div px-2">
                      <FolderDragDrop
                        setCurrentFolder={setCurrentFolder}
                        currentFolder={currentFolder}
                        setBreadCrumb={setBreadCrumb}
                        breadCrumb={breadCrumb} />
                      {/* {
                        isFetching && data.length ?
                          <div className="w-full p-4">
                            <ProgressSpinner
                              className='h-[50px] w-[50px] flex justify-center items-center'
                              strokeWidth="3"
                              fill="var(--surface-ground)"
                              animationDuration="30s" />
                          </div>
                          :
                          <></>
                      } */}
                    </div>
                  </>
              }
            </div>
          </ScrollContainer>

        </div>
      </div>

      {
        (deleteDialog?.media && selectedItem == "") &&
        <DeleteConfirmation
          isLoading={deleteMultiItems.isLoading}
          deleteHandler={deleteHandler}
          itemName={`${idsList.length} ${idsList.length == 1 ? "file" : "files"}  `}
          dialogKey="media"
        />
      }

      <FirebaseMediaListener
        path={"/Companies/" + localStorage.getItem("company_id") + "/actions"}
        getData={() => dispatch(galleryRefetch())}
        currentFolder={currentFolder} />

    </Layout>
  );
}

export default MediaDataTable;
