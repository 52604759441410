import '../../../../assets/vendor/css/pages/page-auth.css';
import 'react-phone-input-2/lib/style.css'

import { useProfileContext } from '../../../Contexts/ProfileContext';
import ProfileForm from './ProfileForm';

import { useSelector } from 'react-redux';
import { useUpdateUserProfile } from '../../../../queries/profile';


function UserProfile() {
    const { newImage } = useProfileContext();
    const companyDetails = useSelector((state) => state.global.companyDetails);
    const updateUserProfile = useUpdateUserProfile();

    const userInfo = {
        "name": companyDetails?.user?.name,
        "phone": companyDetails?.user?.phone,
        "email": companyDetails?.user?.email
    };

    const submitHandler = async (data) => {
        await updateUserProfile.mutateAsync({
            ...data,
            profile_img: newImage.user,
        });
    };
    return (
        <ProfileForm profileType={"user"} submitHandler={submitHandler} data={userInfo} />
    )
}

export default UserProfile