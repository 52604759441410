import  { useEffect } from 'react'
import { useSelector } from 'react-redux';

import { db } from "../../config/firebase";
import { onValue, ref } from "firebase/database";

function FirebaseChannelsListener({ setChannelsList, path }) {
    const selectedDevice = useSelector((state) => state.device.selectedDevice);

    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        const unsubscribe = onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                setChannelsList(data.Channels);
            }
        });
        return () => unsubscribe()

    }, [selectedDevice.id]);

    return null
}

export default FirebaseChannelsListener