import axiosInstance from "../config/Axios";

import { useCustomToast } from "../pages/Contexts/ToastContext";

import { useMutation } from "react-query";

import { useDispatch } from "react-redux";
import { toggleDialog } from "../redux/Slices/Global/globalSlice";

const addUser = async (data) => {
  const response = await axiosInstance.post("/users/create", data);
  return response.data;
};

export const useAddUser = () => {
  const dispatch = useDispatch();
  const { showToast } = useCustomToast();

  return useMutation(addUser, {
    onSuccess: () => {
      showToast("success", "Create User", "User Created Successfully");
      dispatch(toggleDialog("addUser"));
    },
    onError: (error) => {
      showToast("error", "Create User", error.message);
    },
  });
};
