import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';

import SubscriptionsDatatable from './Billing/SubscriptionsDatatable'
import ScreenUsageCard from './Billing/ScreenUsageCard'
import PlanDetailsCard from './Billing/PlanDetailsCard'
import MediaUsageCard from './Billing/MediaUsageCard'

import PaymentDialog from './Payment/PaymentDialog';
import { Toast } from 'primereact/toast';

function Billing() {
  const { subscriptionInfo, openDialog } = useSelector((state) => state.global);

  const toast = useRef(null);

  useEffect(() => {
    if (subscriptionInfo.isFinished)
      toast.current.show({
        severity: 'warn',
        summary: 'Expired Subscription',
        detail: 'Oops! Your subscription has expired!',
        sticky: true,
        closable: false
      })
  }, [subscriptionInfo.isFinished])



  return (
    <div className="card mb-4">

      <h4 className="card-header">Billing Details</h4>

      <div className="card-body d-flex">
        <div className="col-3 px-2">
          <div className='fs-8 mb-2'>Current Plan Details</div>
          <PlanDetailsCard />
        </div>

        <div className="col-3 px-2">
          <div className='fs-8 mb-2' > Plan Usage </div>
          <div className="d-flex flex-column justify-content-between plan_usage_wrapper">
            <ScreenUsageCard />
            <MediaUsageCard />
          </div>
        </div>

        <div className="col-6 px-2">
          <div className='fs-8 mb-2'>Subscription History</div>
          <SubscriptionsDatatable />
        </div>
      </div>
      {
        subscriptionInfo.isFinished &&
        <Toast ref={toast} position="bottom-right"></Toast>
      }

      {
        openDialog.paymentDialog && <PaymentDialog />
      }
    </div>
  )
}

export default Billing