import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { useEnableDevicesPrayer, useGetDevicesList } from '../../../../queries';
import { setPrayerEnableDialog } from '../../../../redux/Slices/Apps/appsSlice';
import DevicesList from '../../../Campaign/Components/assignPopup/devices/DevicesList';

const options = [
    { label: 'Show the prayers campaign', value: 1 },
    { label: 'Show a black screen', value: 0 },
];

function PrayerEnableDialog() {
    const dispatch = useDispatch()

    const { prayerEnableDialog } = useSelector(state => state.apps);
    const [selectedOption, setSelectedOption] = useState(options[1].value);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [data, setData] = useState([]);
    const [ids, setIds] = useState([]);

    const { data: devicesList, devicesLoading, refetchDevices, isFetching } = useGetDevicesList(pageNum, false);
    const enablePrayer = useEnableDevicesPrayer();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    // Update data on category change or new fetch results
    useEffect(() => {
        if (devicesList?.data && devicesList?.pagination.total > data.length) {
            let idsArr = devicesList?.data?.filter(item => item.is_prayer_enable === true).map(item => item.id)
            setIds(prev => pageNum === 1 ? idsArr : [...prev, ...idsArr]);
            const updatedData = pageNum === 1 ? devicesList.data : [...data, ...devicesList.data];
            setData(updatedData);
            setLastPage(devicesList.pagination?.total_pages || 1);
        }
        if (devicesList?.data[0].show_prayer_media) {
            setSelectedOption(options[0].value)
        } else {
            setSelectedOption(options[1].value)
        }
    }, [devicesList]);

    // Fetch data when pageNum changes
    useEffect(() => {
        if (pageNum <= lastPage || lastPage === null) {
            refetchDevices();
        }
    }, [pageNum]);

    //Scroll observer
    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    // Prayer Enable handler
    const updateDevices = async () => {
        await enablePrayer.mutateAsync({
            "ids": ids,
            "show_prayer_media": selectedOption
        })
    }

    // Render Dialog Footer
    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Update"
                    loading={devicesLoading || isFetching || enablePrayer.isLoading}
                    className='btn fs-6 py-2 px-3'
                    disabled={ids?.length === 0}
                    onClick={updateDevices} />
            </div>
        );
    }

    return (
        <>
            <Dialog header="Devices List" visible={prayerEnableDialog}
                onHide={() => dispatch(setPrayerEnableDialog(false))}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '40vw' }} footer={renderFooter}>

                <div className="d-flex device_assign_container border-top border-bottom ">
                    <div className="col-12 d-flex flex-column mt-3 ">
                        {
                            data?.length ?
                                <div className="w-100 col-12 mb-3 mt-2 px-4">
                                    <div className="w-100 field">
                                        <label htmlFor="displayOption" className="form-label">
                                            Choose the display option
                                        </label>
                                        <span className="p-float-label">
                                            <Dropdown
                                                value={selectedOption}
                                                options={options}
                                                placeholder="Select an option"
                                                className='w-100'
                                                onChange={(e) => { setSelectedOption(e.value) }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className="d-flex flex-wrap justify-content-center scroll_container scroll_div px-4">
                            <DevicesList
                                data={data}
                                ids={ids}
                                setIds={setIds}
                                setLastElement={setLastElement}
                                pageNum={pageNum}
                                lastPage={lastPage}
                                loading={devicesLoading || isFetching || enablePrayer.isLoading}
                            />
                            {
                                devicesLoading || isFetching ?
                                    <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='m-3' aria-hidden="true" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PrayerEnableDialog