import React, { useEffect, useState } from 'react'
import InvitationsDataTable from './InvitationsDataTable'

import UserDatatable from './UsersDatatable'

const menuTabs = [
    {
        label: "Users",
        key: "users",
        component: <UserDatatable />
    },
    {
        label: "Invitations",
        key: "Invitations",
        component: <InvitationsDataTable />
    }
]

function SubMenu({ setShowBtns }) {
    const [activeTab, setActiveTab] = useState(menuTabs[0])
    useEffect(() => {
        if (activeTab.key === menuTabs[0].key) {
            setShowBtns(true)
        } else {
            setShowBtns(false)
        }
    }, [activeTab])

    return (
        <div className="content-wrapper">
            {/* Content */}
            <div className="mx-md-2 flex-grow-1    pt-0 ">
                <div className="row">
                    <div div className="col-md-12 ">
                        <div className="card">
                            <div className="col-md-10 px-3">
                                <h5 className="card-title m-0 mt-3 me-auto">
                                    {activeTab.key === "users" ? "Team Members" : "Invitation List "}
                                </h5>
                                <ul className="nav nav-pills flex-column flex-md-row my-3">
                                    {
                                        menuTabs.map((tab, index) => {
                                            return (
                                                <li className="nav-item me-2" onClick={() => {
                                                    setActiveTab({
                                                        key: tab.key,
                                                        component: tab.component
                                                    })
                                                }}
                                                    key={index}>
                                                    <a className={`nav-link ${activeTab.key == tab.key && "active"}`} href="#">{tab.label}</a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            {activeTab.component}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-backdrop fade" />
        </div>
    )
}

export default SubMenu
