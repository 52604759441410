const unitOptions = [
  { label: "%", value: "%" },
  { label: "px", value: "px" },
];

const layoutColor = [
  "#339ecccc",
  "#2f99c6cc",
  "#2a93c0cc",
  "#268ebacc",
  "#2288b4cc",
  "#1f83adcc",
  "#1b7da7cc",
  "#1877a0cc",
  "#157199cc",
  "#126b91cc",
  "#0f658acc",
  "#0d5f82cc",
  "#0b597acc",
  "#095272cc",
  "#074c69cc",
  "#054561cc",
  "#043f58cc",
  "#02384fcc",
  "#013146cc",
  "#012b3dcc",
];

const COMMON_ASPECT_RATIOS = {
  "16:9": { width: 1920, height: 1080 }, // Full HD
  "4:3": { width: 1024, height: 768 }, // XGA
  "21:9": { width: 2560, height: 1080 }, // WFHD
  "1:1": { width: 1080, height: 1080 }, // Square (Instagram)
  "9:16": { width: 1080, height: 1920 }, // Vertical Video
  "3:2": { width: 1920, height: 1280 }, // Photography
  "5:4": { width: 1280, height: 1024 }, // Old monitors
};

const defaultArea = {
  unit: "%",
  id: 1,
  start_margin: 0,
  top_margin: 0,
  width: 100,
  height: 100,
  name: "Screen 1",
};

const defaultAreaPX = {
  unit: "px",
  id: 1,
  start_margin: 0,
  top_margin: 0,
  width: 1920,
  height: 1080,
  name: "Screen 1",
};

export {
  unitOptions,
  layoutColor,
  COMMON_ASPECT_RATIOS,
  defaultArea,
  defaultAreaPX,
};
