import React, { useMemo, useState } from 'react'

import Layout from '../Layout/Layout'
import SubMenu from './Components/SubMenu'
import { toggleDialog } from '../../redux/Slices/Global/globalSlice';
import { useDispatch } from 'react-redux';

function UsersIndex() {
  const [showBtns, setShowBtns] = useState(true)
  const [user, setUser] = useState(null);
  const dispatch = useDispatch()

  const openNew = () => {
    setUser({});
    dispatch(toggleDialog("addUser"))
  }
  const subNavbarProps = useMemo(() => {
    return {
      title: "Users",
    };
  }, []);

  const subNavbarContent = useMemo(() => {
    return showBtns ?
      <div className='flex justify-end w-100'>
        < button type="button" className="d-flex align-items-center btn btn-primary me-sm-3 me-1" onClick={openNew} >
          <i className="tf-icons bx bx-user me-2"></i> New Member
        </button >

      </div >
      : ""
  }, [showBtns]);

  return (
    <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
      <SubMenu setShowBtns={setShowBtns} />
    </Layout>
  )
}

export default UsersIndex