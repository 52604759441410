import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useForm, Controller } from "react-hook-form";
import { useCustomToast } from '../../../../../Contexts/ToastContext';
import { useCreateAspectRatio } from "../../../../../../queries/layouts";
import { convertToPx, handleSwitchUnit } from "../../../../../../config/layoutHelper";
import { useSelector } from "react-redux";

const AspectRatioController = ({
    setCurrentAspectRatio,
    setAspectRatios,
    aspectRatios,
    currentAspectRatio,
    areas,
    setAreas
}) => {
    const unit = useSelector((state) => state.layout.unit);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            aspectName: "",
            width: "",
            height: ""
        }
    });

    const handleAspectChangeChange = (e) => {
        if (unit?.value == "px") {

            const areasConvertedToPercentage = handleSwitchUnit(areas, currentAspectRatio);
            const selectedValue = e.target.value;
            const selectedAspect = aspectRatios.find(item => item.id === selectedValue);
            setCurrentAspectRatio(selectedAspect);
            const areasConvertedToPX = convertToPx(areasConvertedToPercentage, selectedAspect);
            setAreas(areasConvertedToPX)

        } else {
            const selectedValue = e.target.value;
            const selectedAspect = aspectRatios.find(item => item.id === selectedValue);
            setCurrentAspectRatio(selectedAspect);
        }
    }
    const { mutate: createAspectRatio, isLoading } = useCreateAspectRatio();
    const handleCreateNewAspect = (data) => {
        const newAspect = {
            name: data.aspectName,
            width: parseInt(data.width, 10),
            height: parseInt(data.height, 10),
        };
        createAspectRatio(newAspect, {
            onSuccess: (newAspectRatio) => {
                setAspectRatios((prevAspectRatios) => [...prevAspectRatios, newAspectRatio]);
                setCurrentAspectRatio(newAspectRatio);
                setShowCreateModal(false);
                reset();
            },
        });

    }


    return (
        <>

            <div className="flex w-100">
                <Dropdown
                    value={currentAspectRatio?.id || ""}
                    options={aspectRatios}
                    optionLabel="name"
                    optionValue="id"
                    filter
                    className="mb-3 w-75"
                    onChange={handleAspectChangeChange}
                    placeholder="Select a screen"
                />
                <Button label="New Aspect Ratio" className={"px-1 py-0 w-25 ml-2 h-[43px]   text-[15px] bg-blue text-white"} onClick={() => setShowCreateModal(true)} />

                {/* <button
                    className="px-1 py-0 w-25 ml-2 h-[43px] text-[15px] bg-[#6366F1] text-white rounded-lg hover:bg-blue-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                    onClick={() => setShowCreateModal(true)}
                >
                    New Aspect Ratio
                </button> */}
            </div>
            {showCreateModal && (
                <div className="z-10 fixed top-0 left-0 w-full h-full   flex justify-center items-center"
                    style={{ backgroundColor: 'rgb(107 114 128 / 29%)' }}>
                    <div className="bg-white p-6 rounded-md w-[300px]">
                        <h2 className="text-lg font-bold mb-4">Create New Aspect Ratio</h2>
                        <form onSubmit={handleSubmit(handleCreateNewAspect)}>
                            <div className="mb-3">
                                <label className="block text-sm mb-2">Aspect Name:</label>
                                <Controller
                                    name="aspectName"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Aspect name is required.",
                                        minLength: { value: 3, message: "Minimum 3 characters required." },
                                    }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className="border p-2 w-full"
                                            placeholder="Enter aspect name"
                                        />
                                    )}
                                />
                                {errors.aspectName && (
                                    <p className="text-red-500 text-sm">{errors.aspectName.message}</p>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="block text-sm mb-2">Width:</label>
                                <Controller
                                    name="width"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Width is required.",
                                        min: { value: 1, message: "Width must be at least 1." },
                                        max: { value: 10000, message: "Width cannot exceed 10000." },
                                    }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="number"
                                            className="border p-2 w-full"
                                            placeholder="Enter width"
                                        />
                                    )}
                                />
                                {errors.width && (
                                    <p className="text-red-500 text-sm">{errors.width.message}</p>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="block text-sm mb-2">Height:</label>
                                <Controller
                                    name="height"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Height is required.",
                                        min: { value: 1, message: "Height must be at least 1." },
                                        max: { value: 10000, message: "Height cannot exceed 10000." },
                                    }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="number"
                                            className="border p-2 w-full"
                                            placeholder="Enter height"
                                        />
                                    )}
                                />
                                {errors.height && (
                                    <p className="text-red-500 text-sm">{errors.height.message}</p>
                                )}
                            </div>
                            <div className="flex justify-end space-x-2">
                                <Button label="Create" className={"pt-1 pb-1 me-2"} type="submit" />
                                <Button label="Cancel" className={"pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => setShowCreateModal(false)} />
                            </div>
                        </form>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default AspectRatioController;
