import React, { useState, useEffect, useRef } from "react";

import { Spinner } from 'react-bootstrap';
import "../../../../css/layouts.css"

import AspectRatioController from "./AspectRatioController";
import { COMMON_ASPECT_RATIOS, layoutColor } from '../../../../../../config/layoutConstans';

import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../../../redux/Slices/Global/globalSlice';
import { useFetchAllAspectRatio } from "../../../../../../queries/layouts";
import { isEmpty } from "lodash";
import AddArea from "./AddArea";
import { setSelectedArea } from "../../../../../../redux/Slices/layoutSlice";

const DynamicContainer = ({ areas, setAreas, currentAspectRatio, setCurrentAspectRatio, scrollableDivRef }) => {
    const dispatch = useDispatch();
    const drawingAreaRef = useRef(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const { selectedTemplate, selectedLayout, layoutAspectRatio, unit, selectedArea } = useSelector((state) => state.layout);

    const [aspectWidth, setAspectWidth] = useState();
    const [aspectHeight, setAspectHeight] = useState();
    const [aspectRatios, setAspectRatios] = useState([]);
    const { data: allAspectRatio, isLoading: isFetching } = useFetchAllAspectRatio();

    useEffect(() => {
        const updateDimensions = () => {
            if (drawingAreaRef.current) {
                const { offsetWidth, offsetHeight } = drawingAreaRef.current;
                setDimensions({ width: offsetWidth, height: offsetHeight });
            }
        };

        if (!isEmpty(allAspectRatio)) {
            dispatch(toggleLoader("createLoading"))
            setCurrentAspectRatio(layoutAspectRatio);
            updateDimensions();
            window.addEventListener("resize", updateDimensions);
            const data = allAspectRatio?.data?.data;
            setAspectRatios(data)
            if (selectedTemplate?.aspect_ratio_id) {
                const selectedAspect = data?.find(item => item.id === selectedTemplate.aspect_ratio_id);
                setCurrentAspectRatio(selectedAspect);
            } else if (selectedLayout?.aspect_ratio_id) {
                const selectedAspect = data?.find(item => item.id === selectedLayout.aspect_ratio_id);
                setCurrentAspectRatio(layoutAspectRatio);
            } else {
                setCurrentAspectRatio(data[1])
            }
            dispatch(toggleLoader("createLoading"))
            return () => {
                window.removeEventListener("resize", updateDimensions);
            };
        }
    }, [allAspectRatio]);

    useEffect(() => {
        if (currentAspectRatio) {
            const aspectKey = `${currentAspectRatio.width}:${currentAspectRatio.height}`;

            if (COMMON_ASPECT_RATIOS[aspectKey]) {
                setAspectWidth(COMMON_ASPECT_RATIOS[aspectKey].width);
                setAspectHeight(COMMON_ASPECT_RATIOS[aspectKey].height);
            } else {
                setAspectWidth(currentAspectRatio.width);
                setAspectHeight(currentAspectRatio.height);
            }
        }
    }, [currentAspectRatio]);


    const calculateScaledDimensions = () => {
        if (aspectWidth === 0 || aspectHeight === 0) return { width: 0, height: 0 };
        let widthRatio, heightRatio;

        widthRatio = dimensions?.width / aspectWidth;
        heightRatio = dimensions?.height / aspectHeight;

        const scaleFactor = Math.min(widthRatio, heightRatio);

        const scaledWidth = aspectWidth * scaleFactor;
        const scaledHeight = aspectHeight * scaleFactor;

        return { width: scaledWidth, height: scaledHeight };
    };

    const { width: scaledWidth, height: scaledHeight } = calculateScaledDimensions()
    const SNAP_THRESHOLD = unit.value === "px" ? 10 : 1; //the too close limit

    const checkNearbyAreas = (currentArea, areas, newX, newY, newWidth, newHeight, action) => {
        areas.forEach(area => {
            if (area.id === currentArea.id) return;

            //   (R2L)    
            if (Math.abs(newX + newWidth - area.start_margin) < SNAP_THRESHOLD) {
                newWidth = area.start_margin - newX;
            }
            // (L2R) 
            if (Math.abs(newX - (area.start_margin + area.width)) < SNAP_THRESHOLD) {
                let delta = (area.start_margin + area.width) - newX;
                newX = area.start_margin + area.width;
                newWidth -= delta;
            }
            // (B2T)  
            if (Math.abs(newY + newHeight - area.top_margin) < SNAP_THRESHOLD) {
                newHeight = area.top_margin - newY;
            }
            //   (T2B) 
            if (Math.abs(newY - (area.top_margin + area.height)) < SNAP_THRESHOLD) {
                let delta = (area.top_margin + area.height) - newY;
                newY = area.top_margin + area.height;
                newHeight -= delta;
            }
        });

        return { newX, newY, newWidth, newHeight };
    };

    const handleMouseDown = (event, areaId) => {
        event.preventDefault();
        const startX = event.clientX;
        const startY = event.clientY;

        setAreas(prevAreas =>
            prevAreas.map(area => {
                if (area.id !== areaId) return area;

                const div = document.getElementById(`resizable-${area.id}`);
                if (!div) return area;

                const rect = div.getBoundingClientRect();
                const edgeThreshold = 10;

                const nearLeft = startX - rect.left < edgeThreshold;
                const nearRight = rect.right - startX < edgeThreshold;
                const nearTop = startY - rect.top < edgeThreshold;
                const nearBottom = rect.bottom - startY < edgeThreshold;

                // Determine action
                let action = 'move';
                if (nearLeft) action = 'resizeLeft';
                if (nearRight) action = 'resizeRight';
                if (nearTop) action = 'resizeTop';
                if (nearBottom) action = 'resizeBottom';

                const handleMouseMove = (moveEvent) => {
                    let dx = moveEvent.clientX - startX;
                    let dy = moveEvent.clientY - startY;

                    let newX = area.start_margin;
                    let newY = area.top_margin;
                    let newWidth = area.width;
                    let newHeight = area.height;

                    const speedFactor = 2;
                    if (unit.value === "px") {
                        dx *= speedFactor;
                        dy *= speedFactor;

                        if (action === 'resizeRight') {
                            newWidth = Math.min(aspectWidth - area.start_margin, area.width + dx);
                        } else if (action === 'resizeLeft') {
                            let tempX = area.start_margin + dx;
                            let tempWidth = area.width - dx;
                            if (tempX >= 0 && tempWidth >= 5) {
                                newX = tempX;
                                newWidth = Math.min(aspectWidth - tempX, tempWidth);
                            }
                        } else if (action === 'resizeBottom') {
                            newHeight = Math.min(aspectHeight - area.top_margin, area.height + dy);
                        } else if (action === 'resizeTop') {
                            let tempY = area.top_margin + dy;
                            let tempHeight = area.height - dy;
                            if (tempY >= 0 && tempHeight >= 5) {
                                newY = tempY;
                                newHeight = Math.min(aspectHeight - tempY, tempHeight);
                            }
                        } else if (action === 'move') {
                            newX = Math.max(0, Math.min(aspectWidth - area.width, area.start_margin + dx));
                            newY = Math.max(0, Math.min(aspectHeight - area.height, area.top_margin + dy));
                        }
                    } else {
                        if (action === 'resizeRight') {
                            newWidth = Math.min(100 - area.start_margin, area.width + (dx / scaledWidth) * 100);
                        } else if (action === 'resizeLeft') {
                            let tempX = area.start_margin + (dx / scaledWidth) * 100;
                            let tempWidth = area.width - (dx / scaledWidth) * 100;
                            if (tempX >= 0 && tempWidth >= 5) {
                                newX = tempX;
                                newWidth = Math.min(100 - tempX, tempWidth);
                            }
                        } else if (action === 'resizeBottom') {
                            newHeight = Math.min(100 - area.top_margin, area.height + (dy / scaledHeight) * 100);
                        } else if (action === 'resizeTop') {
                            let tempY = area.top_margin + (dy / scaledHeight) * 100;
                            let tempHeight = area.height - (dy / scaledHeight) * 100;
                            if (tempY >= 0 && tempHeight >= 5) {
                                newY = tempY;
                                newHeight = Math.min(100 - tempY, tempHeight);
                            }
                        } else if (action === 'move') {
                            newX = Math.max(0, Math.min(100 - area.width, area.start_margin + (dx / scaledWidth) * 100));
                            newY = Math.max(0, Math.min(100 - area.height, area.top_margin + (dy / scaledHeight) * 100));
                        }
                    }

                    //  snapping logic
                    const snappedValues = checkNearbyAreas(area, prevAreas, newX, newY, newWidth, newHeight);
                    newX = snappedValues.newX;
                    newY = snappedValues.newY;
                    newWidth = snappedValues.newWidth;
                    newHeight = snappedValues.newHeight;

                    setAreas(prev =>
                        prev.map(a =>
                            a.id === areaId
                                ? { ...a, start_margin: newX, top_margin: newY, width: newWidth, height: newHeight }
                                : a
                        )
                    );
                };




                const handleMouseUp = () => {
                    document.removeEventListener("mousemove", handleMouseMove);
                    document.removeEventListener("mouseup", handleMouseUp);
                };

                document.addEventListener("mousemove", handleMouseMove);
                document.addEventListener("mouseup", handleMouseUp);

                return area;
            })
        );
    };

    const handleOnClick = (areaId, area) => {
        dispatch(setSelectedArea(area))

        if (scrollableDivRef.current) {
            // Find the element with the selected id inside the scrollable div
            const relatedElement = scrollableDivRef.current.querySelector(`#${areaId}`);
            if (relatedElement) {
                // Scroll to the related element
                relatedElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }

    }
    return (
        <> {
            isFetching ?
                <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5' aria-hidden="true" />
                </div>
                :
                <div className={`overflow-hidden   w-full lg:w-[80%] mt-1`}>
                    <div className="text-sm font-medium mb-2">Aspect Ratio: {currentAspectRatio.width} x {currentAspectRatio.height} </div>

                    <div className="flex w-100  ">
                        <div className="w-full lg:w-[85%]">
                            <AspectRatioController areas={areas} setAreas={setAreas} aspectRatios={aspectRatios} currentAspectRatio={currentAspectRatio} setCurrentAspectRatio={setCurrentAspectRatio} setAspectRatios={setAspectRatios} />
                        </div>
                        <div className=" ml-3 w-full lg:w-[15%]">
                            <AddArea currentAspectRatio={currentAspectRatio} setAreas={setAreas} areas={areas} />
                        </div>
                    </div>

                    <div id="theDrawingArea" ref={drawingAreaRef} className={`h-[83%]   overflow-hidden overflow-x-hidden relative  w-full  max-h-[75vh]`}>
                        <div className="bg-gray-100  justify-center flex border rounded-md" style={{ width: dimensions.width, height: dimensions.height > scaledHeight ? scaledHeight : dimensions.height }}>
                            <div id={"outLine"} className={`bg-white overflow-hidden p-4 relative border border-gray-300 rounded-md`} style={{ width: scaledWidth, height: scaledHeight }}>
                                {areas.map((area, index) => (
                                    <div
                                        id={`resizable-${area.id}`}
                                        key={area.id}
                                        className="absolute cursor-pointer resizable"
                                        onClick={(e) => handleOnClick(`area_${index + 1}`, area)}
                                        onMouseDown={(e) => handleMouseDown(e, area.id)}
                                        style={{
                                            left: unit.value === "%"
                                                ? `${((area.start_margin ?? 0) / 100) * (scaledWidth || 1)}px`
                                                : `${((area.start_margin ?? 0) / aspectWidth) * (scaledWidth || 1)}px`,
                                            top: unit.value === "%"
                                                ? `${((area.top_margin ?? 0) / 100) * (scaledHeight || 1)}px`
                                                : `${((area.top_margin ?? 0) / aspectHeight) * (scaledHeight || 1)}px`,
                                            width: unit.value === "%"
                                                ? `${area.width}%`
                                                : `${(area.width * 100) / aspectWidth}%`,
                                            height: unit.value === "%"
                                                ? `${area.height}%`
                                                : `${(area.height * 100) / aspectHeight}%`,
                                            backgroundColor: selectedArea?.id === area.id ? "#8a8aed" : layoutColor[index],
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "black",
                                            position: "absolute",
                                        }}
                                    >
                                        {index + 1}
                                        {selectedArea?.id === area.id ?
                                            <> <div className="resize-handle" style={{ top: "50%", left: -5, cursor: "ew-resize" }} onMouseDown={(e) => handleMouseDown(e, area.id)}></div>
                                                <div className="resize-handle" style={{ top: -5, right: "50%", cursor: "ns-resize" }} onMouseDown={(e) => handleMouseDown(e, area.id)}></div>
                                                <div className="resize-handle" style={{ bottom: -5, right: "50%", cursor: "ns-resize" }} onMouseDown={(e) => handleMouseDown(e, area.id)}></div>
                                                <div className="resize-handle" style={{ top: "50%", right: -5, cursor: "ew-resize" }} onMouseDown={(e) => handleMouseDown(e, area.id)}></div>
                                            </>
                                            : ""}
                                    </div>


                                ))}
                            </div>
                        </div>
                    </div>
                </div >
        }</>
    );
};

export default DynamicContainer;