import React, { useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useTitle } from '../../../config/global';
import { INVITATIONS_TABLE_CONFIG } from '../../../Constants/datatables';

const InvitationsDataTable = () => {
    useTitle('Users');
    const { totalRecords, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev, ...INVITATIONS_TABLE_CONFIG
        }))
    }, [])

    const statusBodyTemplate = (rowData) => {

        return (
            <div className=''>
                <span className={` badge ${rowData.status == "ACCEPTED" ?
                    "bg-label-success"
                    : rowData.status == "PENDING" ? "bg-label-warning" :
                        "bg-label-danger"} `}>
                    {rowData.status.toLowerCase()}
                </span>
            </div>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (rowData.created_at);
    }

    return (
        < >
            <div className='table-responsive text-nowrap'>
                <DataTable value={data?.invitations} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                    paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                    totalRecords={totalRecords} onPage={onPage} scrollable scrollHeight="69vh"
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading} className="table"
                >
                    <Column
                        field="email"
                        header="Email"
                        sortable
                        filter
                        className='text-center' />
                    <Column header="status" filterField="status"
                        body={statusBodyTemplate}
                        showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '14rem' }}
                        filter sortable
                        className='text-center' />
                    <Column
                        header="Created Date"
                        filterField="created_at"
                        dataType="date"
                        style={{ minWidth: '10rem' }}
                        body={dateBodyTemplate}
                        sortable />
                </DataTable>
            </div>
        </ >
    );
}
export default InvitationsDataTable;
