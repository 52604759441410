import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import ChannelsList from './ChannelsList';
import PlayLists from './PlayLists';
import LayoutList from './LayoutList';
import MediaList from './MediaList';
import LinksList from './LinksList';
import AppsList from './AppsList';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setAssign, setSelectedApp, setType, toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';

function AddContentPopup() {
    const { layer, hideTabs, openDialog, assign } = useSelector((state) => state.global);
    const { ids } = useScrollLoadingContext();
    const dispatch = useDispatch();

    const menuItems = [
        {
            key: "playlist",
            title: "PlayList",
            child: <PlayLists />,
            visible: layer === "MAIN" && window.location.pathname.includes("campaign") && !hideTabs,
            icon: <i className="bx bxs-playlist menu-icon"></i>,
        },

        {
            key: "media",
            title: "Media",
            child: <MediaList />,
            visible: true,
            icon: <i className="menu-icon tf-icons bx bx-images"></i>,
        },

        {
            key: "links",
            title: "Links",
            child: <LinksList />,
            visible: true,
            icon: <i className="menu-icon tf-icons bx bx-link"></i>,
        },
        {
            key: "layout",
            title: "Layout",
            child: <LayoutList />,
            visible: (layer === "MAIN") && window.location.pathname.includes("campaign") && !hideTabs,
            icon: <i className="bx bxs-layout menu-icon"></i>,
        },
        {
            key: "channels",
            title: "Channels",
            child: <ChannelsList />,
            visible: !hideTabs && (["MAIN", "PLAYGROUND"].includes(layer)),
            icon: <i className='menu-icon bx bx-tv me-2'></i>,
        },
        {
            key: "apps",
            title: "Apps",
            child: <AppsList />,
            visible: true,
            icon: <AiOutlineAppstoreAdd className='menu-icon me-2' />,
        }

    ]
    const [activeTab, setActiveTap] = useState(menuItems[0]?.visible ? menuItems[0] : menuItems[1]);

    const assignMedia = () => {
        dispatch(setAssign(true))
    }

    const renderFooter = () => {
        return (
            <Button icon={"pi pi-plus"}
                label={"ADD"}
                loading={assign}
                className='btn btn-primary text-center fs-6 p-2'
                onClick={assignMedia}
                disabled={activeTab?.key === "channels" ? false : ids?.length === 0} />
        );
    }

    const onHideHandler = () => {
        dispatch(setSelectedApp({}))
        dispatch(toggleDialog("addContent"))
    }

    return (
        <>
            <Dialog header="Add Content" visible={openDialog.addContent}
                onHide={onHideHandler}
                footer={renderFooter}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '60vw', minHeight: "50vh" }}
            >
                <div className="d-flex flex-nowrap overflow-hidden h-full">
                    <div className="col-3 col-sm-2 col-lg-2 px-0 bg-white media_options_container border-end min-h-[50vh]" >
                        {
                            menuItems.map((item, index) => {
                                return item?.visible ?
                                    <button key={index} className={`py-2 px-3 border-0 w-100 text-start d-flex align-items-center ${item?.key == activeTab?.key ? "active_upload_button" : "bg-transparent"}`}
                                        onClick={() => { dispatch(setType(item?.key)); setActiveTap(item) }}>
                                        {item?.icon}
                                        <span>{item?.title}</span>
                                    </button>
                                    :
                                    <></>
                            })
                        }
                    </div>
                    <div className="col-10 justify-content-center align-items-start">
                        {activeTab?.child}
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddContentPopup