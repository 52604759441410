import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { getFormErrorMessage, validateNotEqual } from '../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useCreateCampaignMutation } from '../../../queries';

import { useSelector, useDispatch } from 'react-redux';
import {  toggleDialog } from '../../../redux/Slices/Global/globalSlice';
 
const Create = () => {
    const dispatch = useDispatch()
    const { openDialog, disableBtn, layer } = useSelector((state) => state.global);

    const { control, formState: { errors }, handleSubmit } = useForm();
    const { isInteractiveCampaign } = useDataTableContext();

    const createCampaign = useCreateCampaignMutation();

    const hideDialog = () => {
        dispatch(toggleDialog("createCampaign"));
    }

    const onSubmit = async (data) => {
        await createCampaign.mutateAsync({
            "name": data.name,
            "is_interactive": isInteractiveCampaign
        })
    };
   
    return (
        <Dialog visible={openDialog.createCampaign} breakpoints={{ '960px': '95vw' }} style={{ width: '600px' }} header="Create Campaign" modal className="p-fluid" onHide={hideDialog}>
            <form onSubmit={handleSubmit(onSubmit)} className=" d-flex flex-column align-items-center">
                <div className={`col-12 px-3 my-5  w-75`}>
                    <div className="field">
                        <label className="form-label"> Name </label>
                        <span className="p-float-label">
                            <Controller name="name" control={control}
                                rules={{ required: 'Name is required.', validate: validateNotEqual }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus
                                        className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                        </span>
                        {getFormErrorMessage('name', errors)}
                    </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-center py-3 border-top ">
                    <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={disableBtn} onClick={hideDialog}>Cancel</button>
                    <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={disableBtn}>
                        {disableBtn ?
                            <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            "Create"
                        }
                    </button>
                </div>
            </form>
        </Dialog>
    );
};
export default Create;
