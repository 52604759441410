import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';

import { useRetrieveMediaDuration, useUpdateMediaDuration } from '../../../../queries';
import { formatDuration } from '../../../../config/global';

function MediaDuration({ item }) {
    const retrieveMediaDuration = useRetrieveMediaDuration()
    const updateMediaDuration = useUpdateMediaDuration()
    const loading = retrieveMediaDuration.isLoading || updateMediaDuration.isLoading

    const [editDuration, setEditDuration] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState("");
    const [duration, setDuration] = useState(item.total_duration);
    const previousDuration = useRef(item.total_duration);
    const [send, setSend] = useState(false);

    const sendOnkeypress = (e) => {
        setEditDuration(false)
        setSend(true)
    }

    const retrieveDefaultValue = async () => {
        await retrieveMediaDuration.mutateAsync({
            id: item.campaign_playlist_media_id
        }, {
            onSuccess: () => setEditDuration(false)
        })
    }

    const editDurationHandle = () => {
        if (!JSON.parse(sessionStorage.getItem('is_default_prayer'))) {
            setSelectedMedia(item.id)
            setEditDuration(true)
        }
    }

    // Duration Handler
    useEffect(() => {
        if (send && previousDuration.current != duration) {
            updateHandler()
        }
        setSend(false)
    }, [send])

    const updateHandler = async () => {
        if (previousDuration.current != duration) {
            await updateMediaDuration.mutateAsync({
                id: item.campaign_playlist_media_id,
                data: {
                    "total_duration": duration,
                    "layer": item.layer
                }
            }, {
                onSuccess: () => {
                    setEditDuration(false);
                    previousDuration.current = duration
                }
            })
        } else {
            setEditDuration(false)
        }
    }

    return (
        <>
            {
                editDuration ?
                    <div className=' d-flex align-items-center justify-content-center'>
                        <InputNumber inputId="minmax" mode="decimal"
                            value={item.total_duration}
                            onValueChange={(e) => setDuration(e.value)}
                            onChange={(e) => setDuration(e.value)}
                            onKeyDown={(e) => e.key === 'Enter' ? sendOnkeypress(e) : ""}
                            min={1}
                            max={86399} //   (23:59:59)
                            className="duration_input" />

                        {
                            loading && selectedMedia === item.id ?
                                <div className="cursor-pointer  text-center" >
                                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
                                </div>
                                :

                                <div className="d-flex flex-column">
                                    <Tooltip target={`.save_duration-${item.id}`} content="Save changes" position='bottom' className='fs-8' />
                                    <i className={`col-1 mx-2 fas fa-check-circle text-lighter mb-2 cursor-pointer save_duration-${item.id}`}
                                        onClick={updateHandler}></i>
                                    <Tooltip target={`.retrieve_duration-${item.id}`} content="Retrieve duration" position='bottom' className='fs-8' />
                                    <i className={`col-1 mx-2 fas fa-redo text-lighter cursor-pointer retrieve_duration-${item.id}`} onClick={retrieveDefaultValue}> </i>
                                </div>
                        }
                    </div>
                    :
                    (_.isNull(item.media_id)) ?
                        <div className='text-light text-center'>
                            {!_.isNull(item.playlist_id) ? formatDuration(item.playlist_duration) : formatDuration(item.layout_duration)}
                        </div>
                        :
                        loading && selectedMedia === item.id ?
                            <div className="cursor-pointer  text-center" >
                                <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
                            </div>
                            :
                            <>
                                <Tooltip target={`.edit_duration-${item.id}`} content="Edit duration" position='bottom' className="fs-8" hideDelay={300} />
                                <div className={`edit_duration-${item.id} cursor-pointer  text-center`}
                                    onClick={editDurationHandle}>
                                    {formatDuration(item.total_duration)}
                                </div>
                            </>
            }
        </>
    )
}

export default MediaDuration