import _, { isEmpty } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { handleResErrors, useInvalidateCampaignLayer } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { useSelector } from "react-redux";

// -------------------------Get Gallery -------------------------//
const getGallery = async ({ queryKey }) => {
    const [, pageNum,] = queryKey;
    let url = `/groups${!_.isNull(pageNum) ? `?page=${pageNum}&per_page=7` : ""}`

    const { data } = await axiosInstance.get(url);

    return !_.isNull(pageNum) ? data : data.data;
}

export const useGetGallery = (pageNum = null, enabled = true) => {
    const { showToast } = useCustomToast();

    let { isLoading: groupsLoading, data, error, isError, refetch: refetchGroup } = useQuery(
        ['getGallery', pageNum],
        getGallery,
        {
            enabled,
        }
    );

    if (isError) {
        showToast("error", "Fetching Date", error.response?.data?.message)
    }

    return { groupsLoading, data, refetchGroup };
}

//-------------------------- Delete Media--------------------------
const updateMedia = async (payload) => {
    const { data } = await axiosInstance.post(`/media/${payload?.id}/update`, payload.data);
    return data?.data;
}

export const useUpdateMedia = () => {
     const { showToast } = useCustomToast();
    const invalidateCampaignLayer = useInvalidateCampaignLayer();  

    return useMutation(updateMedia, {
        onSuccess: async () => {
            invalidateCampaignLayer();
            showToast('success', 'Update Media Name', "Media name updated successfully!");
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//-------------------------- Delete Media--------------------------
const deleteMedia = async (payload) => {
    const { data } = await axiosInstance.delete(`/media/${payload?.id}`);
    return data?.data;
}

export const useDeleteMedia = () => {
    const { showToast } = useCustomToast();

    return useMutation(deleteMedia, {
        onSuccess: async (responseData) => {
            showToast('success', 'Delete Media', responseData?.msg);
        },
        onError: (error) => {
            handleResErrors(error, showToast)
        }
    })
}

//-------------------------- Get Gallery by Layer--------------------------
const getGalleryByLayer = async (payload) => {
    const { layer, isCampaignInteractive, searchTerm, section, folderId } = payload
    let url = `/media?section=${section}&layer=${layer}`;

    if (isCampaignInteractive) url += `&is_interactive=${isCampaignInteractive}`
    if (!isEmpty(folderId)) url += `&folder_id=${folderId}`
    if (!isEmpty(searchTerm)) url += `&search=${encodeURIComponent(searchTerm.trim())}`

    const { data } = await axiosInstance.get(url);
    return data.data;
}

export const useGetGalleryByLayer = () => {
    const { showToast } = useCustomToast();
    return useMutation(getGalleryByLayer, {
        onError: (error) => {
            handleResErrors(error, showToast)
        },
        cacheTime: 0
    })
}
