import { createSlice } from "@reduxjs/toolkit";
import { last } from "lodash";

const initialState = {
  lastElement: null,
  isFetching: false,
  searchTerm: "",
  lastPage: null,
  rerender: false,
  pageNum: 1,
  params: {},
  data: [],
  path: "",
  ids: [],
};

const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    setLastElement(state, action) {
      state.lastElement = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setLastPage(state, action) {
      state.lastPage = action.payload;
    },
    setPageNum(state, action) {
       if (state.pageNum < state.lastPage  || state.lastPage === null) {
        let num = action.payload === 1 ? action.payload : state.pageNum + 1
        state.pageNum = num;
      }
    },
    setData(state, action) {
      state.data = state.pageNum === 1 ? action.payload : [...state.data, ...action.payload];
    },
    setPath(state, action) {
      state.path = action.payload;
    },
    setIds(state, action) {
      state.ids = action.payload;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setParams(state, action) {
      state.params = action.payload;
    },
    setRerender(state, action) {
      state.rerender = action.payload;
    },
    updateItemLocally(state, action) {
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteItemLocally(state, action) {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
    resetScrollStates(state) {
      state.ids = [];
      state.path = "";
      state.pageNum = 1;
      state.data = [];
    },
    galleryRefetch(state) {
      if (state.pageNum === 1) {
        state.rerender = true
      } else {
        state.pageNum = 1;
      }
    },
  },
});

export const {
  resetScrollStates,
  galleryRefetch,
  setLastElement,
  setIsFetching,
  setSearchTerm,
  setRerender,
  setLastPage,
  updateItemLocally,
  deleteItemLocally,
  setPageNum,
  setParams,
  setData,
  setPath,
  setIds,
} = scrollSlice.actions;

export default scrollSlice.reducer;
