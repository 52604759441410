import { useMutation } from "react-query";
import axiosInstance from "../config/Axios";
import { useDispatch } from "react-redux";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import { setDisableBtn } from "../redux/Slices/Global/globalSlice";

const joinedDataTable = ["campaign", "subscriptions"]

const dataTable = async (payload) => {
    let ordered_col = payload?.sortField ? payload?.sortField : 'created_at';
    let ordered_type = payload?.sortOrder == 1 ? "desc" : 'asc';
    let url = ""

    const filteredFilters = Object.fromEntries(
        Object.entries(payload?.filters).filter(([key, filter]) => filter.value !== '')
    );

    const pushed = {
        "order_by": {
            [ordered_col]: ordered_type
        },
        "filters": filteredFilters,
        "filters_date": payload?.filters_date
    };

    if (joinedDataTable.indexOf(payload?.modelName) !== -1) {
        url = `/datatable/${payload?.modelName}?page=${parseInt(payload?.page, 10) + 1}&per_page=${payload?.rows}`;
    } else {
        url = `/datatable/${payload?.modelName}/view?page=${payload?.page + 1}&per_page=${payload?.rows}`;
    }

    const { data } = await axiosInstance.post(url, pushed)

    return data
}

export const useDataTableMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(dataTable, {
        onError: (error) => {
            showToast("error", "DataTable", error.response?.data?.message)
        }
    })
}

const deleteRow = async (payload) => {
    const { data } = await axiosInstance.delete(`/datatable/${payload?.table}/${payload?.id}/delete`)

    return data
}

export const useDeleteRowMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(deleteRow, {
        onSuccess: (data) => {
            showToast("success", "DataTable", data?.msg)
            dispatch(setDisableBtn(false));
        },
        onError: (error) => {
            dispatch(setDisableBtn(false));
            showToast("error", "DataTable", error.response?.data?.message)
        }
    })
}

//------------------
const fetchDataByPathMutation = async ({ path, pageNum, searchTerm, params , signal}) => {
    let hasPrams = path.includes("?")
    const { data } = await axiosInstance.get(`${path}${hasPrams ? "&" : "?"}page=${pageNum}&search=${searchTerm}`, {params, signal})
    return data
}

export const useFetchDataByPathMutation = () => {
    const { showToast } = useCustomToast();
    return useMutation(fetchDataByPathMutation, {
        cacheTime:0,
        onError: (error) => showToast("error", "Fetching Data", error.response?.data?.message)
    })
}