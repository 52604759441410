import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useCreateGroupMutation } from '../../../../queries';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

const CreateGroup = () => {
    const dispatch = useDispatch();
    const { openDialog } = useSelector((state) => state.global);
    const { control, formState: { errors }, handleSubmit } = useForm();
    const createGroup = useCreateGroupMutation()

    const onSubmit = async (data) => {
        await createGroup.mutateAsync({ "name": data.name })
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const closePopup = () => {
        dispatch(toggleDialog("createGroupDialog"))
    }
    return (

        <Dialog visible={openDialog.createGroupDialog}
            breakpoints={{ '960px': '95vw' }}
            style={{ width: '600px' }}
            header="Create Group"
            modal className="p-fluid" onHide={() => dispatch(toggleDialog("createGroupDialog"))}>

            <form onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                    }
                }} className=" d-flex flex-column align-items-center">
                <div className={`col-12 px-3 my-5  w-75`}>
                    <div className="field">
                        <label className="form-label">
                            Name
                        </label>
                        <span className="p-float-label">
                            <Controller name="name" control={control}
                                rules={{ required: 'Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus
                                        className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                        </span>
                        {getFormErrorMessage('name')}
                    </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-center py-3 border-top ">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createGroup.isLoading} onClick={closePopup} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Create" loading={createGroup.isLoading} />
                </div>
            </form>
        </Dialog>
    );
};
export default CreateGroup;
