import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playlistDetails: {
    total_duration: 0,
  },
  updateLoading:false
};

const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    setPlaylistDetails(state, action) {
      state.playlistDetails = action.payload;
    },
    setUpdateLoading(state, action) {
      state.playlistDetails = action.payload;
    },
  },
});

export const { setPlaylistDetails, setUpdateLoading } = playlistSlice.actions;

export default playlistSlice.reducer;
