import { useEffect } from "react";

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";
import { useParams } from "react-router-dom";

function FirebaseMediaListener({ path, getData }) {

  let fullPath = process.env.REACT_APP_APP_ENV + "/" + path;
  const { id } = useParams();

  useEffect(() => {
    const query = ref(db, fullPath);

    const unsubscribe = onValue(query, (snapshot) => {
      if (snapshot.exists()) {  
         getData();
      }

      // Cleanup the listener and timer on component unmount or dependency change
      return () => unsubscribe()
    })
  }, [id, path]);

  return null
}

export default FirebaseMediaListener