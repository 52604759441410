import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setDeleteDialog, setOpenDialog, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { useGalleryContext } from "../pages/Contexts/GalleryContext";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { useScrollLoadingContext } from "../pages/Contexts/ScrollLoadingContext";
import { getSubTitle, getTitle } from "../config/global";
import { deleteItemLocally, galleryRefetch, updateItemLocally } from "../redux/Slices/scrollSlice";


const fetchMedia = async ({ queryKey }) => {
    const [, filters, id] = queryKey
    const activeFilters = prepareFilters(filters, id);
    const { data } = await axiosInstance.get("/folder/media", { params: activeFilters });
    return data.data;
};

export const useMediaQuery = (filters, id) => {
    const { data: media, isLoading, refetch } = useQuery({
        queryKey: ["media", filters, id],
        queryFn: fetchMedia,
        enabled: false,
    });

    return { media, isLoading, refetch };
};

const prepareFilters = (filters, id) => {
    const activeFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
        if (value !== "" && value !== null && value !== undefined) {
            activeFilters[key] = value;
        }
    });
    if (filters.section === "MEDIA") {
        activeFilters.folder_id = id || "";
    }
    return activeFilters;
};

//------------------------------Delete folder Media-----------------------------
const deleteFolderMedia = async (payload) => {
    const { data } = await axiosInstance.post(`/folder/media/delete`, { ids: payload });
    return data.data;
}

export const useDeleteMultiFolderMedia = () => {
    const { showToast } = useCustomToast();

    return useMutation(deleteFolderMedia, {
        onSuccess: (result) => {
            showToast("success", "Deleted", result?.msg);
        },
        onError: (error) => handleResErrors(error, showToast),
    })
}

// -----------------------------Check Capacity----------------------------------------------//
export const checkMediaCapacity = (size = 0) => {
    return axiosInstance.post(`/company/check/media/capacity`, { size });
};

export const useCheckMediaCapacity = () => {
    const { setCapacityAvailability } = useGalleryContext();

    const fetchCapacity = async (size = 0) => {
        try {
            await checkMediaCapacity(size);
            setCapacityAvailability(true);
        } catch (error) {
            setCapacityAvailability(false);
        }
    };

    return fetchCapacity;
};


// --------------------------------Create Media -------------------------------------------//
const createMedia = async ({ file, fileUrl, folderId, onUploadProgress }) => {
    const { data } = await axiosInstance.post(
        "/media/create",
        {
            folder_id: folderId,
            type: "media",
            name: file.name,
            size: file.size,
            path: fileUrl,
        },
        {
            onUploadProgress,
        }
    );
    return data;
};

export const useCreateMediaMutation = (setProgress, setCounter) => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const { galleryRefetch } = useScrollLoadingContext();

    return useMutation(
        ({ file, fileUrl, folderId }) =>
            createMedia({
                file,
                fileUrl,
                folderId,
                onUploadProgress: (evt) => {
                    const progressPercent = Math.round((evt.loaded / evt.total) * 50 + 50);

                    if (file) {
                        setProgress((prevProgress) => ({
                            ...prevProgress,
                            [file.name]: progressPercent,
                        }));
                    }
                },
            }),
        {
            onSuccess: (_, { file }) => {
                // galleryRefetch()
                if (file) {
                    setCounter((prev) => prev - 1);
                    setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 100 }));
                }
            },
            onError: (_, { file }) => {
                if (file) {
                    setProgress((prevProgress) => ({ ...prevProgress, [file.name]: "failed" }));
                }
                showToast("error", "Media Upload", "File upload failed!");
            },
        }
    );
};

// -----------------------------Create Link----------------------------------------------//
export const createLink = async (data) => {
    const { name, path, type } = data;

    const response = await axiosInstance.post("/media/create", {
        type: "links",
        name,
        size: 0,
        path,
        content_type: type,
    });

    return response.data;
};

export const useCreateLinkMutation = () => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    return useMutation(createLink, {
        onSuccess: () => {
            dispatch(galleryRefetch())
            dispatch(toggleDialog("uploadMedia"));
            showToast("success", "Link Upload", "Link Uploaded!");
        },
        onError: (error) => {
            handleResErrors(error, showToast);
        },
    });
};

// -----------------------------Create folder----------------------------------------------//
const createFolder = async ({ data, parentFolderId }) => {
    data.parent_folder_id = parentFolderId;
    const response = await axiosInstance.post("/folder/create", data);
    return response.data;
};

export const useCreateFolderMutation = (setCurrentFolder, setBreadCrumb, breadCrumb) => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    return useMutation(
        ({ data, parentFolderId }) => createFolder({ data, parentFolderId }),
        {

            onSuccess: (result) => {
                const folderData = result.data;
                dispatch(toggleDialog("createFolder"));
                showToast("success", "Folder Creation", "Folder Created Successfully");
                navigate(`/media/gallery/${folderData.id}`);

                setCurrentFolder({
                    id: folderData.id,
                    name: folderData.folder_name,
                });

                setBreadCrumb((current) => [
                    ...current,
                    { label: folderData.folder_name, id: folderData.id },
                ]);

                localStorage.setItem(
                    "bread_crumb",
                    JSON.stringify([...breadCrumb, { label: folderData.folder_name, id: folderData.id }])
                );
                localStorage.setItem("folder_id", folderData.id);
            },
            onError: (error) => {
                showToast("error", "Folder Creation", error?.response?.data?.message || "Failed to create folder");
            },

        }
    );
};


// --------------------------------Update folder   -------------------------------------------//
export const updateFolder = async ({ folderId, data }) => {
    const response = await axiosInstance.put(`/folder/${folderId}`, data);
    return response.data;
};

export const useUpdateFolderMutation = () => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    return useMutation(
        ({ folderId, data }) => updateFolder({ folderId, data }),
        {
            onSuccess: (result) => {
                dispatch(updateItemLocally(result?.data))
                dispatch(toggleDialog("updateFolder"));
                showToast("success", "Folder Updating", "Folder updated successfully!");
            },
            onError: (error) => {
                handleResErrors(error, showToast);
            },
        }
    );
};

// --------------------------------move folder   -------------------------------------------//
const moveMediaOrFolder = async ({ type, id, destinationId }) => {
    const response = await axiosInstance.post("/folder/media/move", {
        type,
        id,
        destination_id: destinationId,
    });
    return response.data;
};

export const useMoveMediaOrFolderMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    return useMutation(moveMediaOrFolder, {
        onSuccess: (result) => {
            dispatch(deleteItemLocally(result.data.id));
            showToast("success", "Move Operation", "Item moved successfully!");
        },
        onError: (error) => {
            handleResErrors(error, showToast);
        },
    });
};

const moveMediaFolder = async ({ type, id, destinationId }) => {
    const response = await axiosInstance.post("/folder/media/move", {
        type,
        id,
        destination_id: destinationId,
    });
    return response.data;
};

export const useMoveMediaFolderMutation = () => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    return useMutation(moveMediaFolder, {
        onSuccess: (result) => {
            dispatch(deleteItemLocally(result.data.id));
            showToast("success", "Move", result?.msg);
            dispatch(toggleDialog("moveMediaFolder"));
        },
        onError: (error) => {
            handleResErrors(error, showToast);
        },

    });
};

// --------------------------------get  folders-------------------------------------------//
export const fetchFolders = async () => {
    const { data } = await axiosInstance.get("/folder/all");
    return data.data.folders;
};

export const useFoldersQuery = () => {
    return useQuery("folders", fetchFolders, {
        staleTime: 60000,
    });
};

// --------------------------------get sub  folders-------------------------------------------//
export const fetchSubFolders = async (parentFolderId) => {
    const { data } = await axiosInstance.get(`/folder/all?parent_folder_id=${parentFolderId}`);
    return data.data.folders;
};

export const useSubFoldersQuery = (parentFolderId, enabled = false) => {
    return useQuery(["subfolders", parentFolderId], () => fetchSubFolders(parentFolderId), {
        enabled: !!parentFolderId && enabled,
        staleTime: 60000,
    });
};

// --------------------------------edit (delete)   folders   -------------------------------------------//

export const deleteFolder = async (folderId) => {
    const response = await axiosInstance.delete(`/folder/${folderId}`);
    return response.data;
};

export const useDeleteFolderMutation = () => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    return useMutation(deleteFolder, {
        onSuccess: (result) => {
            showToast("success", "Delete Folder", result?.msg);
            dispatch(setDeleteDialog("folder"));
        },
        onError: (error) => {
            handleResErrors(error, showToast);
        },
    });
};

// --------------------------------update media-------------------------------------------//
export const updateMedia = async ({ mediaId, formData }) => {
    const response = await axiosInstance.post(`/media/${mediaId}/update`, formData);
    return response.data;
};

export const useUpdateMediaMutation = () => {
    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    const page = getTitle()
    return useMutation(
        ({ mediaId, formData }) => updateMedia({ mediaId, formData }),
        {
            onSuccess: (updatedItem) => {
                if (['media', 'links'].includes(page?.toLowerCase()))
                    dispatch(updateItemLocally(updatedItem?.data));
                dispatch(toggleDialog("updateMedia"));
                showToast("success", "Media Updating", "Media Updated Successfully!");
            },
            onError: (error) => {
                handleResErrors(error, showToast);
            },
        }
    );
};
