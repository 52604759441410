import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import {
    DEFAULT_LINKS_FILTERS,
    DEFAULT_MEDIA_FILTERS,
    LINK_DEFAULT_FILTERS,
    MEDIA_DEFAULT_FILTERS
} from "../../../config/media";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { setData, setParams } from "../../../redux/Slices/scrollSlice";

const SearchBar = ({ filters, handleApplyFilters, setFilters }) => {
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: window.location.pathname.includes("media") ? MEDIA_DEFAULT_FILTERS : LINK_DEFAULT_FILTERS
    });
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(setData([]));
        dispatch(setParams({}));
        handleApplyFilters()
    };

    const handleReset = () => {
        let option = filters.section === "MEDIA" ? DEFAULT_MEDIA_FILTERS : DEFAULT_LINKS_FILTERS
        setFilters(option)
        dispatch(setData([]))
        dispatch(setParams({}));
        setValue("search", "");
    };

    return (
        <form className="h-100" onSubmit={handleSubmit(onSubmit)}>
            <span className="p-input-icon-right w-100 h-100">
                {/* Search Icon */}
                <i className="pi pi-search cursor-pointer"
                    style={{ right: filters.search ? "35px" : "10px" }}
                    onClick={handleSubmit(onSubmit)}
                />

                {!isEmpty(filters.search) ? <i className="pi pi-times cursor-pointer " style={{ color: "red" }} onClick={handleReset} /> : <></>}

                <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                        <InputText
                            id="search"
                            {...field}
                            
                            value={filters.search} // Sync input value
                            placeholder="Search by Name"
                            className="p-inputtext-sm w-100 pe-5 h-100"
                            onChange={(e) => {
                                field.onChange(e);
                                setFilters({ ...filters, search: e.target.value });
                            }}
                        />
                    )}
                />
            </span>
        </form>
    );
};

export default SearchBar