import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import '../../../assets/vendor/css/pages/page-auth.css';
import logo from '../../../assets/img/logo.png';
import landing from '../../../assets/img/boy-with-rocket-light.png';
import { useTitle } from '../../../config/global';

import { useResendEmailMutation } from '../../../queries/auth';

function VerifyEmail () {
    useTitle('Verify Email');

    const [verificationAttempts, setVerificationAttempts] = useState(localStorage.getItem("verification_attempts"));

    const resendEmail = useResendEmailMutation();

    const resendEmailHandler = async () => {
        await resendEmail.mutateAsync({
            email: localStorage.getItem("email")
        }, {
            onSuccess: async (data) => {
                setVerificationAttempts(data?.verification_attempts)
            }
        });
    }

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */ }
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                    <img src={ logo } className="img-fluid" width={ 200 } alt="logo" />
                                </Link>
                            </div>
                            <div className='pt-5'>
                                <h4 className='text-center'>Verify Your Account </h4>
                                <p className="my-5 text-center fs-5">
                                    We have sent Verification Email
                                    <br />
                                    <b>{ localStorage.getItem("email") != "" ? "to " + localStorage.getItem("email") : "" }</b>
                                </p>

                            </div>
                            <div className='pt-2 d-flex justify-content-center'>
                                {
                                    verificationAttempts < 3 ?
                                        <button className="btn btn-primary text-center " onClick={ () => resendEmailHandler() }>
                                            Resend Email
                                        </button>
                                        :
                                        <p className='text-center'>
                                            If you don't receive a verification email, please contact support!
                                        </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyEmail