import axiosInstance from "../config/Axios";
import axios from "axios";

export const createStripeToken = async (cardData) => {
  const formData = new URLSearchParams();
  formData.append("card[number]", cardData.card_number.replace(/\s/g, ""));
  formData.append("card[exp_month]", cardData.expiry_date.slice(0, 2));
  formData.append("card[exp_year]", cardData.expiry_date.slice(3, 6));
  formData.append("card[cvc]", cardData.cvv);

  const response = await axios.post(
    `https://api.stripe.com/v1/tokens`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data.id;
};

export const processPayment = async ({ token, packageData }) => {
  const url =
    packageData.action === "addScreens"
      ? `/payment/add/devices`
      : `/payment/buy/package`;

  const response = await axiosInstance.post(url, {
    strip_token: token,
    package_id: packageData.selectedPackage?.id,
    period: packageData.selectedPricing?.period,
    number_of_devices: packageData.numberOfScreens,
    devices_list: packageData.ids,
  });

  return response.data;
};
