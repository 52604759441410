import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDeleteDialog } from '../../redux/Slices/Global/globalSlice';

function DeleteConfirmation({ isLoading = false, itemName, deleteHandler, dialogKey }) {
    const dispatch = useDispatch();
    const { deleteDialog, disableBtn } = useSelector((state) => state.global);

    const confirmDelete = () => {
        deleteHandler();
    }

    const onClose = () => {
        dispatch(toggleDeleteDialog(dialogKey))
    }

    const footer = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" disabled={isLoading} onClick={onClose} />
            <Button label="Yes"
                icon="pi pi-checks" className="p-button-text p-component p-confirm-dialog-accept p-button-danger"
                loading={isLoading} disabled={dialogKey === "device" ? disableBtn : false}
                onClick={confirmDelete}
            />
        </>
    );

    return (
        <>
            <Dialog visible={deleteDialog[dialogKey]}
                style={{ width: '450px' }}
                breakpoints={{ '960px': '95vw' }}
                header="Confirm" modal
                footer={footer}
                onHide={onClose}>
                <div className="confirmation-content my-5">
                    <div className='text-center'>
                        <i className="pi pi-exclamation-triangle mr-3 d-block" style={{ fontSize: '2rem' }} />
                        <br />
                        <span>Are you sure you want to delete </span> <br /> <b>{itemName} </b>?
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteConfirmation