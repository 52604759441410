import Layout from '../../Layout/Layout';
import CreateBroadcast from './components/CreateBroadcast';
import StreamsDataTable from './components/StreamsDataTable';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useTitle } from '../../../config/global';
import { useMemo } from 'react';

function BroadcastCard() {
    const { openDialog, isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch()
    useTitle("Broadcast");

    const subNavbarContent = useMemo(() => {
        return (
            <div className='flex justify-end w-100'>
                <button type="reset" className="btn btn-primary "
                    disabled={isLoading}
                    onClick={() => dispatch(toggleDialog("createBroadcastDialog"))} >
                    New Broadcast
                </button>
            </div>
        );
    }, [isLoading]);

    const subNavbarProps = useMemo(() => {
        return {
            icon: <i className="fas fa-broadcast-tower fs-3 text-secondary "></i> || " ",
        };
    }, []);

    return (
        <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex flex-column m-2">
                        <StreamsDataTable />
                    </div>
                </div>
            </div>
            {openDialog.createBroadcastDialog && <CreateBroadcast />}
        </Layout>
    )
}

export default BroadcastCard