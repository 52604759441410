import React, { useEffect, useState } from 'react'


import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';

import ChangeTemplatesLayouts from './Edit/ChangeTemplatesLayouts';
import ChangeCustomLayouts from './Edit/ChangeCustomLayouts';
import EditLayout from './Edit/EditLayout';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, toggleLoader } from '../../../../../redux/Slices/Global/globalSlice';
import { Button } from 'primereact/button';
import { useFetchTemplates } from '../../../../../queries/layouts';
import { Spinner } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';

function EditLayoutPopUp() {

  const { openDialog } = useSelector((state) => state.global);

  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { data, isLoading, refetch } = useFetchTemplates(currentPage);
  useEffect(() => {
    if (data?.totalRecords !== undefined) {
      setTotalRecords(data.totalRecords);
    }
  }, [data]);
  const [isMaximized, setIsMaximized] = useState(true);


  const items = [
    {
      label: 'Customizing Current Layout',
      value: 0,
      command: () => setActiveIndex(0),
    },
    {
      label: 'Custom Templates',
      value: 1,
      command: () => setActiveIndex(1),
    },
    {
      label: 'Default Templates',
      value: 2,
      command: () => setActiveIndex(2),
    },
  ];

  useEffect(() => {
    refetch();
  }, [currentPage]);


  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <EditLayout isMaximized={isMaximized} />;
      case 1:
        return (<ChangeCustomLayouts
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={totalRecords}
        />);

      case 2:
        return <ChangeTemplatesLayouts />;

      default:
        return null;
    }
  };


  const customHeader = (
    <div className="flex justify-between items-center w-full">
      <span className="text-lg font-semibold">Edit Your Layout</span>
      <div className="items-center flex w-[25%]">
 
        <Dropdown
          value={activeIndex}   
          options={items}
          optionLabel="label"
          optionValue="value"  
          className="mx-2 w-100"
          onChange={(e) => {
            setActiveIndex(e.value);  
          }}
          placeholder="Select a Unit"
        />

      </div>
    </div>
  );

  return (
    <Dialog visible={openDialog.EditLayoutPopUp}
      breakpoints={{ '960px': '95vw' }}
      header={customHeader}
      style={{ overflow: 'hidden' }}
      modal
      blockScroll={true}
      className={`p-fluid w-full ${isMaximized ? 'p-dialog-maximized' : 'p-dialog-default md:w-[100%] lg:w-[75%] h-[85%]'}`}
      onHide={() => dispatch(toggleDialog("EditLayoutPopUp"))}
    >
      <div className="scroll_div overflow-x-hidden h-full">

        {/* <TabMenu className='pe-5 pl-5' model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} /> */}
        {
          isLoading ?
            <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
              <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
            </div> :
            <div className="scroll_div flex flex-col   overflow-y-auto h-full" >
              {renderContent()}
            </div>
        }

      </div>

    </Dialog>
  )
}

export default EditLayoutPopUp