import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react'
import moment from 'moment/moment';

import { db } from "../../config/firebase";
import { onValue, ref } from "firebase/database";

import { setOnlineDevices } from '../../redux/Slices/deviceSlice';

function DeviceLastLoginListener({ path, serialName }) {
    const dispatch = useDispatch();

    // Timer reference to clear and reset
    const timerRef = useRef(null);

    useEffect(() => {
        const query = ref(db, `${process.env.REACT_APP_APP_ENV}/${path}`);

        const checkOfflineStatus = () => {
            dispatch(setOnlineDevices({ [serialName]: 0 }));
        };

        const unsubscribe = onValue(query, (snapshot) => {

            if (snapshot.exists()) {
                const data = snapshot.val();
                const currentTime = moment();
                const endTime = moment(data);
                const diffSeconds = currentTime.diff(endTime) / 1000;
                const isOnline = diffSeconds > 12 ? 0 : 1;
                dispatch(setOnlineDevices({ [serialName]: isOnline }));

                // Clear the previous timer and set a new one
                if (timerRef.current) clearTimeout(timerRef.current);
                timerRef.current = setTimeout(checkOfflineStatus, 12000); // Wait 11 seconds to mark offline
            }
        });

        // Cleanup the listener and timer on component unmount or dependency change
        return () => {
            unsubscribe();
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [path, serialName]);

    return null;
}

export default DeviceLastLoginListener