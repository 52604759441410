import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { RxDropdownMenu } from "react-icons/rx";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Spinner } from "react-bootstrap";

import { getFormErrorMessage } from "../../../../config/global";
import { useGalleryContext } from "../../../Contexts/GalleryContext";

import { useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useCreateLinkMutation } from "../../../../queries";

const commonTypes = [
  ".m3u8",
  "youtube.com",
  ".mp3",
  ".mp4",
  "drive",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".glb",
  ".gltf",
  ".webp"
]

const typesOptions = [
  { label: "Image", value: "image" },
  { label: "Video", value: "video" },
  { label: "Audio", value: "audio" },
  { label: "3D", value: "3d" },
  { label: "Youtube", value: "youtube" },
  { label: "Web view", value: "webview" },
  { label: "External link", value: "external_link" },
]

function Link() {

  const { formState: { errors }, control, handleSubmit, } = useForm();
  const dispatch = useDispatch();

  const { mutate: uploadLink, isLoading } = useCreateLinkMutation();
  const [showDropdown, setShowDropdown] = useState(false);

  function containsAny(str, arr) {
    return arr.some(item => str.includes(item));
  }

  const onLinkChange = (e) => {
    if (!containsAny(e.target.value, commonTypes)) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }

  const onSubmit = async (data) => {
    uploadLink(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" px-5 d-flex flex-column align-items-center justify-content-center my-5">
          <div className="field mb-4 w-100">
            <label className="form-label">
              Link Name
            </label>
            <span className="p-float-label">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required.",
                }}
                render={({ field, fieldState }) => (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="fas fa-align-justify"></i>
                    </span>
                    <InputText
                      placeholder="Name"
                      id={field.name}
                      {...field}
                      className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`} />
                  </div>
                )}
              />
            </span>
            {getFormErrorMessage("name", errors)}
          </div>

          <div className="field w-100 mb-4">
            <label className="form-label"> Link  </label>
            <span className="p-float-label">
              <Controller name="path" control={control}
                rules={{
                  required: "Link is required.",
                  pattern: {
                    value:
                      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i,
                    message: "Invalid Url!",
                  },
                }}
                render={({ field, fieldState }) => (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="fas fa-link"></i>
                    </span>
                    <InputText
                      placeholder="Link"
                      id={field.name}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        onLinkChange(e);
                      }}
                      className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`}
                    />
                  </div>
                )}
              />
            </span>
            {getFormErrorMessage("link", errors)}
          </div>

          {showDropdown ?
            <div className="field w-100 ">
              <label className="form-label"> link content type  </label>
              <span className="p-float-label">
                <Controller name="type" control={control}
                  rules={{ required: showDropdown ? "Type is required." : false }}
                  render={({ field, fieldState }) => (
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <RxDropdownMenu />
                      </span>
                      <Dropdown id={field.name} {...field}
                        onChange={(e) => field.onChange(e.value)}
                        options={typesOptions}
                        optionLabel="label"
                        optionValue="value"
                        inputRef={field.ref}
                        className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                        placeholder="select type..."
                      />
                    </div>
                  )}
                />
              </span>
              {getFormErrorMessage("type", errors)}
            </div>
            :
            <></>
          }
        </div>
        <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
          <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={isLoading}
            onClick={() => dispatch(toggleDialog("uploadMedia"))}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary me-3"
            disabled={isLoading}
          >
            {isLoading ?
              <Spinner as="span" animation="border" size="sm" role="status" className="mx-3" aria-hidden="true" />
              :
              "Save"
            }
          </button>
        </div>
      </form>
      {/* </div> */}
    </>
  );
}

export default Link;
