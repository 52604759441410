import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import MenuList from "./MenuList";
import logo from "../../assets/img/logo.png";
import "./css/NavTitle.css";

import { getTitle } from "../../config/global";

import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../queries";
import { setCampaignDetails, setStepsBack, toggleDialog } from "../../redux/Slices/Global/globalSlice";
import ResetConfirmation from "../SharedComponents/ResetConfirmation";

const Navbar = ({ content, title = null, date = null, icon = null }) => {
    const [visibleLeft, setVisibleLeft] = useState(false);
    const titleData = title == null ? getTitle() : title;
    const dateData = date == null ? "" : date;
    const { profileImage, campaignDetails, openDialog, stepsBack } = useSelector((state) => state.global);
    const { playlistDetails } = useSelector((state) => state.playlist);
    const { screenDetails } = useSelector((state) => state.layout);

    const navigate = useNavigate()
    const location = useLocation();
    const menuRef = useRef(null);
    const dispatch = useDispatch()
    const subMenuItems = [
        {
            label: "Billing Settings",
            icon: "pi pi-dollar",
            command: () => navigate("/profile", { state: { activeTab: "billing" } })
        },
        {
            label: "Account Settings",
            icon: "pi pi-user",
            command: () => navigate("/profile", { state: { activeTab: "account" } })
        },
        { separator: true },
        {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => logoutHandler(),
            className: "text-red-500"
        },
    ]
    const currentPath = window.location.pathname;

    const pagesWithWarning = ["/setup"];
    const warningPaths = [
        "setup",
        /^\/playlist\/[a-f0-9-]+\/show$/,
        /^\/apps\/layouts\/[a-f0-9-]+\/show$/

    ];
    const backCustomizedAction = warningPaths.some((path) =>
        typeof path === "string" ? currentPath.includes(path) : path.test(currentPath)
    );

    const handleBackClick = () => {

        if (backCustomizedAction) {
            if (campaignDetails.status === "DRAFT" || playlistDetails?.status === "INACTIVE" || screenDetails?.status === "DRAFT") {
                dispatch(toggleDialog("resetCampaign"))
            } else {
                window.history.go(stepsBack);
                dispatch(setStepsBack(-1))
            }
        } else {
            window.history.go(stepsBack);
            dispatch(setStepsBack(-1))
        }
    };

    const logout = useLogoutMutation();

    const logoutHandler = () => {
        logout.mutateAsync()
    }

    const shouldAddClass = backCustomizedAction || window.location.pathname.includes("apps/");
    return (
        <>
            {/* Navbar Container */}
            <nav className="border-bottom py-0 layout-navbar navbar bg-navbar-theme flex-wrap profile-navbar" id="layout-navbar ">
                <div className="container-fluid d-flex justify-content-between align-items-center py-2 profile-navbar">
                    {/* Logo */}
                    <a href="/" className="d-flex align-items-center">
                        <img src={logo} className="logo_navTitle img-fluid" alt="logo" width={20} />
                    </a>

                    {/* Sidebar Menu Button */}
                    <div className="flex">
                        <img src={profileImage} className="w-px-40 object-fit-cover rounded-circle cursor-pointer" onClick={(e) => menuRef.current.toggle(e)} />

                        <Button
                            icon="fas fa-bars"
                            onClick={() => setVisibleLeft(true)}
                            className="p-2 bg-transparent border-0 text-light"
                        />
                    </div>
                </div>
            </nav >

            <div className={` shadow-sm w-100 ${!shouldAddClass ? "py-2" : ""} d-flex align-items-center justify-content-between bg-white pe-[1.625rem] `}>
                {backCustomizedAction || window.location.pathname.includes("apps/") ?
                    <>
                        {/* Back Button */}
                        <button className="border-right-only mx-1 px-2 py-2" onClick={handleBackClick}>
                            <i className="fa-solid fa-chevron-left mr-1"></i>
                        </button></>
                    :
                    <button className=" mx-1 px-2 py-2"  >
                    </button>

                }

                {/* Title */}
                <div className="ml-3 my-2 mr-5 ">
                    <div className="flex">
                        {icon ? <div className=" mr-3">
                            {icon}
                        </div> : ""}
                        <span className={` fs-4 fw-semibold d-block`}>{titleData}</span>
                    </div>
                    {date && <small className="text-muted">{dateData}</small>}
                </div >

                <div className="navbar-nav flex-row align-items-center d-lg-flex flex-grow-1 ">
                    {content}
                </div>
            </div >

            <Sidebar visible={visibleLeft} position="right" onHide={() => setVisibleLeft(false)}>
                <aside id="layout-menu" className="menu-vertical menu bg-menu-theme mx-auto h-[97vh]">
                    <MenuList />
                </aside>
            </Sidebar>
            <Menu className="z-[9999]" model={subMenuItems} popup ref={menuRef} />
            {openDialog.resetCampaign ? <ResetConfirmation /> : <></>}

        </>
    );
};

export default Navbar;
