export const FONTS = {
    ar: [
        { label: 'Alexandria', value: 'Alexandria' },
        { label: 'Zain', value: 'Zain' },
        { label: 'Beiruti', value: 'Beiruti' },
        { label: 'Cairo Play', value: 'Cairo Play' },
        { label: 'Noto Sans Arabic', value: 'Noto Sans Arabic' },
        { label: 'Rubik', value: 'Rubik' },
        { label: 'Tajawal', value: 'Tajawal' },
        { label: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic' },
        { label: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic' },
        { label: 'Noto Kufi Arabic', value: 'Noto Kufi Arabic' },
        { label: 'Noto Naskh Arabic', value: 'Noto Naskh Arabic' },
    ],
    en: [
        { label: 'Roboto', value: 'Roboto' },
        { label: 'Open Sans', value: 'Open Sans' },
        { label: 'Montserrat', value: 'Montserrat' },
        { label: 'Poppins', value: 'Poppins' },
        { label: 'Lato', value: 'Lato' },
        { label: 'Oswald', value: 'Oswald' },
        { label: 'Merriweather', value: 'Merriweather' },
        { label: 'Playfair Display', value: 'Playfair Display' },
        { label: 'Kanit', value: 'Kanit' },
        { label: 'Outfit', value: 'Outfit' },
    ]
};


export const FONT_SIZES = [
    { label: 'Small', value: 20 },
    { label: 'Medium', value: 25 },
    { label: 'Large', value: 30 }
];

export const LANGUAGES = [
    { label: "Arabic", value: "ar" },
    { label: "English", value: "en" }
];

// RSS position dropdown options
export const POSITION_OPTIONS = [
    { label: "Full Screen", value: "FULL_SCREEN" },
    { label: "Bottom", value: "BOTTOM" },
    { label: "Top", value: "TOP" },
];

export const BROADCAST_TYPES = [
    { label: "One Way", value: "ONE_WAY" },
    { label: "Two way", value: "TWO_WAY" },
    { label: "Multiple", value: "MULTIPLE" },
];

export const BROADCAST_SETTINGS = {
    ONE_WAY: {
        is_camera_enable: 0,
        is_mic_enable: 0
    },
    TWO_WAY: {
        is_camera_enable: 1,
        is_mic_enable: 1
    },
    MULTIPLE: {
        is_camera_enable: 0,
        is_mic_enable: 1
    }
}


export const TEMPLATE_FEED = {
    en: [
        {
            "title": "Here will be the title of the first news article",
            "link": "https://yourwebsite.com/news-article-1",
            "description": "This is where the description of the news article will go.",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        },
        {
            "title": "Here will be the title of the second news article",
            "link": "https://yourwebsite.com/news-article-2",
            "description": "This is where the description of the news article will go.",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        },
        {
            "title": "Here will be the title of the third news article",
            "link": "https://yourwebsite.com/news-article-3",
            "description": "This is where the description of the news article will go.",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        }
    ],
    ar: [
        {
            "title": "  عنوان المقالة الإخبارية الأولى",
            "link": "https://yourwebsite.com/news-article-1",
            "description": " وصف المقالة الإخبارية الأولى.",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        },
        {
            "title": "  عنوان المقالة الإخبارية الثانية",
            "link": "https://yourwebsite.com/news-article-2",
            "description": " وصف المقالة الإخبارية الثانية. ",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        },
        {
            "title": "  عنوان المقالة الإخبارية الثالثة",
            "link": "https://yourwebsite.com/news-article-3",
            "description": " وصف المقالة الإخبارية الثالثة.",
            "date": "YYYY-MM-DD",
            "image": "https://play-lh.googleusercontent.com/eq6Vvfw6SSH-cWOXwhfW6GzLL316J3VzHH8lSIJMct2_3vYyw73kE8ARfKoNaQ5pRA"
        }
    ]
}
