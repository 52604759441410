import { useQuery } from "react-query";
import axiosInstance from "../config/Axios";
import { useSelector } from "react-redux";

const fetchDashboardData = async () => {
  const response = await axiosInstance.get(`/dashboard`);
  return response.data.data;
};

export const useFetchDashboardData = () => {
  const { isAuthenticated } = useSelector((state) => state.global);

  return useQuery({
    queryKey: ['dashboardData'],
    queryFn: fetchDashboardData,
    enabled: isAuthenticated,
  });
};
