import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiXSquare } from "react-icons/fi";
import axiosRetry from 'axios-retry';
import axios from "axios";

import { CircularProgressbarWithChildren, buildStyles, } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useCustomToast } from "../../../Contexts/ToastContext";
import axiosInstance from "../../../../config/Axios";

import upload_icon from "../../../../assets/img/upload-image-icon.png";
import { formatSize } from "../../../../config/global";

import { useDispatch, useSelector } from 'react-redux';
import { useCheckMediaCapacity, useCreateMediaMutation } from "../../../../queries";
import { getFileIcon } from "../../../../config/media";
import { useScrollLoadingContext } from "../../../Contexts/ScrollLoadingContext";

const bucketName = 'storage-popuppushnewaccount';
const apiBaseUrl = 'https://7m3wpxae29.execute-api.eu-west-1.amazonaws.com/Pre-signedUrlgenerator';


const S3_COMPANY_ID = localStorage.getItem("company_id");
const allowedFileTypes = [
  "mp3",
  "mp4",
  "png",
  "jpeg",
  "webp",
  "jpg",
  "gif",
  "mpeg",
  "glb",
  "gltf",
  "mov",
  "avi",
  "webm",
];

const UploadMedia = () => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});
  const [counter, setCounter] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [files, setFiles] = useState([]);
  const fetchCapacity = useCheckMediaCapacity();

  const { galleryRefetch } = useScrollLoadingContext();
  const companyDetails = useSelector((state) => state.global.companyDetails);
  const { showToast } = useCustomToast();
  const uploadMedia = useCreateMediaMutation(setProgress, setCounter);

  // folder ID 
  const { id } = useParams();
  const MAX_FILE_LIMIT = 20;

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (files.length + droppedFiles.length > MAX_FILE_LIMIT) {
      showToast('error', 'File Limit Exceeded', `You can only upload up to ${MAX_FILE_LIMIT} files.`);
      const filesToAdd = droppedFiles.slice(0, MAX_FILE_LIMIT - files.length);
      setFiles([...files, ...filesToAdd]);
      return;
    }
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleFileInput = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (files.length + selectedFiles.length > MAX_FILE_LIMIT) {
      showToast('error', 'File Limit Exceeded', `You can only upload up to ${MAX_FILE_LIMIT} files.`);
      const filesToAdd = selectedFiles.slice(0, MAX_FILE_LIMIT - files.length);
      setFiles([...files, ...filesToAdd]);
      return;
    }
    selectedFiles.forEach(file => {
      if (file.size <= companyDetails.upload_once_content_capacity) {
        setFiles(current => [...current, file]);
      } else {
        showToast('error', 'Not Allowed', file.name + " size more than 1GB!");
      }
    });
  };

  function generateS3Url(fileType) {
    if (allowedFileTypes.includes(fileType.toLowerCase())) {
      return `${generateString(30)}.${fileType == "mpeg" ? "mp3" : fileType}`;
    } else {
      return;
    }
  }

  const createMedia = async (file, fileUrl) => {
    await uploadMedia.mutateAsync({ file, fileUrl, folderId: id },
        {
        onSuccess: () => {
          galleryRefetch()}
      }
    )
  }

  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: () => 2000,
    retryCondition: () => true,
  });

  const uploadHandler = async (file, fileDataResponse, fileObjectKey) => {
    try {
      const formData = new FormData();

      Object.keys(fileDataResponse.fields).forEach((key) => {
        formData.append(key, fileDataResponse.fields[key]);
      });

      formData.append('file', file);

      const uploadResponse = await axios.post(fileDataResponse.url, formData, {
        onUploadProgress: (evt) => {
          const progressPercent = Math.round((evt.loaded / evt.total) * 50 + 50);
          setProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progressPercent,
          }));
        },
      });

      await createMedia(file, `https://storage.popuppush.com/${fileObjectKey.replace(/\s/g, "")}`);
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      setProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: "failed",
      }));
      showToast('error', 'Files Uploading', "Error uploading files, try again!");
    }
  };

  const upload3sURL = async (files) => {
    const fileNames = [];
    const objectKeys = [];

    files.forEach((file) => {
      let fileType = file.type == ""
        ? file.name.slice(file.name.lastIndexOf('.') + 1)
        : file.type.includes("quicktime")
          ? file.name?.split(".")[1]
          : file.type?.split("/")[1];

      if (fileType.includes("json")) {
        fileType = fileType?.split('+')[0];
      } else if (fileType.includes("avi")) {
        fileType = fileType?.split('.')[1];
      }

      const hashedFile = generateS3Url(fileType, file.name);
      const objectKey = `${S3_COMPANY_ID}/${localStorage.getItem("current_folder") ? localStorage.getItem("current_folder") : "root"}/${hashedFile}`;

      fileNames.push(objectKey.replace(/\s/g, ""));
      objectKeys.push(objectKey);
    });

    const apiUrl = "https://7m3wpxae29.execute-api.eu-west-1.amazonaws.com/Pre-signedUrlgenerator";

    const requestBody = {
      bucket_name: 'storage-popuppushnewaccount',
      file_names: fileNames,
    };

    try {
      const response = await axios.post(apiUrl, requestBody);
      return {
        dataResponse: response.data.presigned_urls,
        objectKeys: objectKeys,
      };
    } catch (error) {
      console.error("Error generating pre-signed URL:", error);
      throw error;
    }
  };

  const uploadFile = async () => {
    setCounter(files?.length);
    try {
      const { dataResponse, objectKeys } = await upload3sURL(files);

      const keys = Object.keys(dataResponse);
      await Promise.all(
        files.map(async (file, index) => {
          try {
            await fetchCapacity(file.size);
            setUploading(true);
            const theKey = keys[index];
            const fileDataResponse = dataResponse[theKey];
            const fileObjectKey = objectKeys[index];
            // Upload the file
            await uploadHandler(file, fileDataResponse, fileObjectKey);
          } catch (error) {
            setUploading(false);
            showToast('error', 'Files Uploading', "You've reached the maximum allowed media storage!");
            throw error;
          }
        })
      );
      setUploading(false);
    } catch (error) {
      setUploading(false);
      showToast('error', 'Error', 'Failed to upload some files.');
    }
  };
  useEffect(() => {
    if (counter === 0) {
      showToast('success', 'Files Uploading', "File uploaded successfully!");
      setUploading(false);
      setFiles([]);
      setProgress({});
    }
  }, [counter]);


  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  // Generate random string for file name
  function generateString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  return (
    <div>
      <div className="bg-[#f8f8f8] relative d-flex flex-wrap">
        {
          // Upload input
          !uploading && (
            <div className={`${files.length > 0 ? 'col-4' : 'col-12'} grid justify-center items-center  drag-drop-container ${isDraggingOver ? "drag-over" : ""}`}
              onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handleDragLeave}>
              <div className={`drag-drop-container ${isDraggingOver ? "drag-over" : ""}`} onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handleDragLeave}>
                <label htmlFor="file" className="grid justify-center ">
                  <div className=" grid items-center justify-center my-7 ">
                    <img src={upload_icon} className={`mx-auto cursor-pointer ${files.length > 0 ? 'col-4' : "w-[10rem] h-[10rem]"} `} alt="Upload Media" />
                  </div>
                  <h1 className="leading-[1em] text-[1.5em] m-auto">Upload Media </h1>
                  <span className="text-[13px] leading-[20px] font-[400] text-[#787777]  my-2 mx-auto text-center"> Drop your first video or photo here</span>
                </label>
                <input className="hidden" type="file" id="file" multiple accept=".mp3, .mp4, .png, .jpeg, .wepb, .jpg, .gif, .mpeg, .glb, .gltf ,.mov ,.avi  , .webm" onChange={handleFileInput} />
              </div>
            </div>
          )
        }
        {
          // Display chosen files to upload 
          files.length > 0 && (
            <div className={`${uploading ? "col-12" : "col-8"} my-4 justify-start overflow-y-scroll max-h-[350px] px-3 scroll_div `}>
              <p className=" uppercase text-[12px] font-[500] text-[#555]"> {`${files?.length} ${files?.length == 1 ? "File" : ""}Files`}</p>
              {files.map((file, index) => (
                <div key={file.name} className=" shadow-md bg-white p-4 my-3 w-full flex  items-center justify-between ">
                  <div className="flex align-items-center  gap-4  w-[70%]">
                    {
                      file.type.includes("image") ?
                        <img src={URL.createObjectURL(file)} alt={file.name} className="h-[40px] w-[70px]" />
                        :
                        getFileIcon(file.type)
                    }
                    <div className="d-flex flex-column justify-content-start w-100">
                      <span className="text-truncate w-90">{file.name}</span>
                      {/* <div>
                        <span className="bg-secondary text-white px-2 py-1 fs-10  me-2 rounded-[5px] text-center"> { formatSize(file.size) } </span>
                      </div> */}
                    </div>

                  </div>
                  <div className=" ">
                    <button disabled={uploading} className="  disabled:opacity-30" onClick={() => handleRemoveFile(index)}>
                      <i className="bx bx-trash text-light"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )
        }

        {
          // Uploading process dialog
          uploading && (
            <div className={`fixed bottom-0 right-0 m-4 z-[50] ${isOpen ? "" : "hidden"}`}  >
              <div className="bg-white p-4 rounded-md shadow-md">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-lg font-bold">Upload Progress</div>
                  <div className="flex">
                    <button onClick={() => setIsOpen(false)}>
                      <FiXSquare className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="overflow-y-scroll w-[400px] max-h-[300px] scroll_div" >
                  {files.map((file) => (
                    <div key={file.name} className="flex items-center justify-between align-items-center py-1 me-1 px-4 bg-white shadow-md rounded-lg my-3" >
                      <div className=" col-1 flex-shrink-0">
                        <div className="text-2xl text-gray-400">
                          {getFileIcon(file.type)}
                        </div>
                      </div>

                      <div className=" col-8 flex-grow ml-4 d-flex flex-column ">
                        <p className="text-gray-900 fs-6 text-truncate ">{file.name}</p>
                        <div className="d-flex  align-items-center my-1">
                          {
                            progress[file.name] < 100 ? (
                              <span className={`bg-[#3295ff] text-white px-2 py-1 fs-10  me-2 rounded-[5px] text-center`} > Processing </span>
                            ) : progress[file.name] == 100 ? (
                              <span className=" bg-[#28a745] text-white px-2 py-1 fs-10  me-2 rounded-[5px] text-center"> Success  </span>
                            ) : progress[file.name] == "failed" ? (
                              <span className=" bg-[#dc3545] text-white px-2 py-1 fs-10  me-2 rounded-[5px] text-center">  Failed </span>
                            ) :
                              ""
                          }
                          <div className="flex items-center">
                            <span className=" bg-secondary text-white px-2 py-1 fs-10  me-2 rounded-[5px] text-center">
                              {formatSize(file.size)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* progress spinner */}
                      <div className="col-2 d-flex justify-content-center">
                        <div className="w-12   ">
                          <CircularProgressbarWithChildren
                            value={progress[file.name] || 1}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathTransitionDuration: 0.5,
                              pathColor: `rgba(62, 152, 199, ${progress[file.name] / 100})`,
                              textColor: "#f88",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                          >
                            <div className="text-sm text-gray-600">
                              {`${progress[file.name] <= 100 ? progress[file.name] + '%' : ""}`}
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        }

        {
          // Upload Button
          uploading || files.length === 0 ?
            ""
            :
            <div className="col-12 py-4 border-top bg-white flex justify-center items-center text-white font-semibold border-red-900 ">
              <button type="reset" className="btn btn-label-secondary me-3"
                onClick={() => {
                  setFiles([]);
                  setProgress({});
                  setUploading(false);
                }}
              >
                Reset
              </button>
              <button type="submit" className="btn btn-primary" onClick={uploadFile} >Upload</button>
            </div>
        }
      </div>
    </div>
  );
};

export default UploadMedia;
