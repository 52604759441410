import _ from "lodash";
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setIsAuthenticated, setUserInfo } from "../redux/Slices/Global/globalSlice";
import { emptyLocalStorage, setProfile } from "../config/global";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import { handleResErrors } from "./utils";
import axiosInstance from "../config/Axios"

// Login
const login = async (payload) => {
    const { data } = await axiosInstance.post("/users/login", payload);

    return data.data;
}

export const useLogInMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return useMutation(login, {
        onSuccess: async (data) => {
            if (data?.type === "USER" || data?.type === "OWNER") {
                if (!data?.email_verified_at) {
                    localStorage.setItem("email", data?.email);
                    localStorage.setItem("email_verified_at", data?.email_verified_at);
                    localStorage.setItem("verification_attempts", data?.verification_attempts);
                    navigate("/verify/email");
                }
                else {
                    dispatch(setIsAuthenticated(true))
                    setProfile(data);
                    dispatch(setUserInfo(data))
                    navigate("/dashboard");
                }
            }
        },
        onError: (error) => showToast("error", 'Login Credential', error.response?.data?.message)
    })
}

// Registration
const register = async (payload) => {
    const { data } = await axiosInstance.post("/users/signup", payload);

    return data.data;
}

export const useRegisterMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    return useMutation(register, {
        onSuccess: async (data) => {
            localStorage.setItem("email", data?.email);
            navigate("/verify/email")
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// Employee Registration
const employeeRegister = async (payload) => {
    const { data } = await axiosInstance.post(`/users/accept/${payload?.invitationId}/invitation`, payload?.data);

    return data.data;
}

export const useEmployeeRegisterMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    return useMutation(employeeRegister, {
        onSuccess: async (data) => {
            setProfile(data);
            dispatch(setUserInfo(data))
            navigate("/dashboard");
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

// Verify Email
const verifyEmail = async (payload) => {
    const { data } = await axiosInstance.post("/users/verify/email", payload);

    return data.data;
}

export const useVerifyEmailMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    return useMutation(verifyEmail, {
        onSuccess: async (data) => {
            setProfile(data)
            showToast('success', 'Email Verification', "Email has been verified successfully!");
            navigate("/dashboard")
        },
        onError: (error) => {
            navigate('/login')
            showToast("error", "Email Verification", error?.response?.data?.message)
        }
    })
}

// Resend Verification Email
const resendEmail = async (payload) => {
    const { data } = await axiosInstance.post("users/resend/email/verification", payload);

    return data.data;
}

export const useResendEmailMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(resendEmail, {
        onSuccess: async (data) => {
            localStorage.setItem("verification_attempts", data?.verification_attempts);
            showToast("success", "Email Verification", data?.msg);
        },
        onError: (error) => {
            let _data = error?.response?.data;
            localStorage.setItem("verification_attempts", _data?.verification_attempts);
            showToast("error", "Email Verification", _data?.msg)
        }
    })
}

// Forget Password
const forgetPassword = async (payload) => {
    const { data } = await axiosInstance.post("/users/forget-password", payload);

    return data.data;
}

export const useForgetPasswordMutation = () => {
    const { showToast } = useCustomToast();

    return useMutation(forgetPassword, {
        onSuccess: async (data) => {
            showToast('success', 'Forget Password', data?.msg);

        },
        onError: (error) => {
            showToast('error', 'Forget Password', error?.response?.data?.message);
        }
    })
}

// Reset Password
const resetPassword = async (payload) => {
    const { data } = await axiosInstance.post(`/users/reset-password/${payload?.token}`, payload.data)

    return data.data;
}

export const useResetPasswordMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    return useMutation(resetPassword, {
        onSuccess: async (data) => {
            showToast('success', 'Reset Password', data?.msg);
            navigate("/login");
        },
        onError: (error) => {
            showToast('error', 'Reset Password', error.response?.data?.message);
        }
    })
}

// Logout
const logout = async () => {
    const { data } = await axiosInstance.get(`/users/logout`)

    return data.data;
}

export const useLogoutMutation = () => {
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    return useMutation(logout, {
        onSuccess: async (data) => {
            showToast('success', 'Logout', data?.msg);
            emptyLocalStorage()
            navigate("/login");
        },
        onError: (error) => {
            showToast('error', 'Logout', error.response?.data?.message);
        }
    })
}