import React, { useMemo } from 'react'

import Layout from '../../Layout/Layout';
import LayoutsDataTable from './components/LayoutsDataTable';
import { useTitle } from '../../../config/global';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import CreateLayoutPopUp from './components/CreateLayoutPopUp';

function LayoutsIndex() {
    const { openDialog, isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch()
    useTitle("Layouts")

    const subNavbarContent = useMemo(() => {
        return (
            <div className='flex justify-end w-100'>
                <button type="reset" className="btn btn-primary "
                    disabled={isLoading}
                    onClick={() => dispatch(toggleDialog("CreateLayoutPopUp"))} >
                    New Layout
                </button>
            </div>

        );
    }, [isLoading]);

    const subNavbarProps = useMemo(() => {
        return {
            icon: <i className="bx bxs-layout text-secondary  fs-2 "></i> || " ",
        };
    }, []);

    return (
        <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
            <div className="card h-100">
                <div className="card-body object-contain h-100">
                    <div className="d-flex flex-column object-contain h-100">
                        <LayoutsDataTable />
                    </div>
                </div>
            </div>
            {openDialog.CreateLayoutPopUp && <CreateLayoutPopUp />}
        </Layout>
    )
}

export default LayoutsIndex