import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

import { setSelectedDevice, setOnlineDevices } from '../../../redux/Slices/deviceSlice';
import { setAssign } from '../../../redux/Slices/Global/globalSlice';
import FirebaseChannelsListener from '../../Services/FirebaseChannelsListener';
import DeviceLastLoginListener from '../../Services/DeviceLastLoginListener';
import { getFormErrorMessage } from '../../../config/global';
import {
    useAddCampaignContent,
    useAddPlaylistChannel,
    useCheckSatelliteSupporting,
    useSendGetChannelNotification,
    useUpdateDeviceChannels
} from '../../../queries';

const ChannelsList = () => {
    // to assign channel , its mandatory to have the device online and support satellite
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { selectedDevice, onlineDevices } = useSelector((state) => state.device);
    const { layer, assign } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    const [channelsList, setChannelsList] = useState([]);
    const { id } = useParams();

    const campaign_id = window.location.pathname.includes("layout") ? screenDetails.id : id;
    const { isLoading, devicesList, totalDeviceWithConflict } = useCheckSatelliteSupporting(campaign_id)
    const updateChannelsMutation = useUpdateDeviceChannels();
    const sedNotification = useSendGetChannelNotification()
    const addCampaignContent = useAddCampaignContent()
    const addPlaylistChannel = useAddPlaylistChannel()
    const dispatch = useDispatch();

    // Send notification to device to update Channel on firebase
    const fetchChannels = async () => {
        await sedNotification.mutateAsync(selectedDevice?.id)
    }

    const updateChannels = async () => {
        await updateChannelsMutation.mutateAsync({
            id: selectedDevice?.id,
            data: { "channels": channelsList }
        })
    }

    const onSubmit = async (data) => {
        let formData = {
            "layer": window.location.pathname.includes("layout") ? "MAIN" : layer,
            "type": "channel",
        }

        // Find selected option
        if ((data.channels)) {
            const selectedOption = channelsList.find(channel => channel.chanNub === data.channels);
            const pathName = window.location.pathname;

            if (pathName.includes("campaign") || pathName.includes("layout"))
                await addCampaignContent.mutateAsync({
                    ...formData,
                    campaign_id: pathName.includes("campaign") ? id : screenDetails.id,
                    channel_info: selectedOption,
                })
            else {
                await addPlaylistChannel.mutateAsync({
                    ...formData,
                    playlist_id: id,
                    channel_name: selectedOption?.serviceName,
                    channel_id: selectedOption?.chanNub
                })
            }
        }
    }

    //o on device change find the device info to get channels 
    const deviceHandler = (id) => {
        setSelectedDevice({ id: id, })
        devicesList.map(item => {
            if (item.id == id) {
                dispatch(setSelectedDevice({
                    id: id,
                    name: item.name,
                    online: 0,
                    serial_name: item.serial_name
                }))
            }
        })
    }

    useEffect(() => {
        reset({ device: selectedDevice?.id })
        if (!_.isEmpty(selectedDevice?.id))
            fetchChannels();

        if (selectedDevice.id !== "" && !onlineDevices[selectedDevice.serial_name]) {
            const updatedOnlineDevices = {
                ...onlineDevices,
                [selectedDevice.serial_name]: 0,
            };

            dispatch(setOnlineDevices(updatedOnlineDevices));
        }
    }, [selectedDevice.id]);

    useEffect(() => {
        if (assign) {
            handleSubmit(onSubmit)()
            dispatch(setAssign(false))
        }
    }, [assign]);

    useEffect(() => {
        if (channelsList.length != 0) {
            updateChannels()
        }
    }, [channelsList]);

    return (
        <div className='w-100 flex justify-center items-center h-full'>
            {
                isLoading ?
                    <ProgressSpinner
                        style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    totalDeviceWithConflict === 0 ?
                        devicesList.length == 0 ?
                            <Message
                                className="fw-bold fs-3 p-4 w-75"
                                severity="warn"
                                text="There are no devices that support satellites!"
                            />
                            :
                            <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                                <form className="d-flex flex-column align-items-center">
                                    {/* Devices input */}
                                    <div className={`col-10 my-5 px-4`}>
                                        <div className="field">
                                            <label className=" form-label" htmlFor="basic-default-company"> Devices Support satellite </label>
                                            <span className="p-float-label">
                                                <Controller name="device" control={control}
                                                    rules={{ required: "This field is required" }}
                                                    render={({ field, fieldState }) => (
                                                        <Dropdown id={field.name} {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e.value);
                                                                deviceHandler(e.value)
                                                            }}
                                                            options={devicesList}
                                                            value={selectedDevice.id}
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            ref={(el) => {
                                                                if (el?.focus) field.ref(el);
                                                            }} 
                                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                            placeholder="Select Device"
                                                            filter
                                                            filterBy="name"
                                                        />
                                                    )
                                                    }
                                                />
                                                {getFormErrorMessage('device', errors)}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Channels input */}
                                    <div className={`col-10 mb-5 px-4 flex justify-center`}>
                                        {
                                            onlineDevices[selectedDevice.serial_name]
                                                ?
                                                (channelsList?.length ?
                                                    <div className="field w-100">
                                                        <label className="form-label" htmlFor="basic-default-company">Channels </label>
                                                        <span className="p-float-label">
                                                            <Controller name="channels" control={control}
                                                                rules={{ required: 'Channel is required.' }}
                                                                render={({ field, fieldState }) => {
                                                                    return (
                                                                        <Dropdown id={field.name} {...field}
                                                                            options={channelsList}
                                                                            optionLabel="serviceName"
                                                                            optionValue="chanNub"
                                                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                                            placeholder="Select Device"
                                                                            ref={(el) => {
                                                                                if (el?.focus) field.ref(el);
                                                                            }}
                                                                            filter
                                                                            filterBy="serviceName"
                                                                        />
                                                                    )
                                                                }} />
                                                            {getFormErrorMessage('channels', errors)}
                                                        </span>
                                                    </div>
                                                    :
                                                    <Message
                                                        severity="warn"
                                                        className='text-center'
                                                        text={`Waiting for device to update channels!`}
                                                    />
                                                )
                                                :
                                                <Message
                                                    severity="error"
                                                    className='text-center'
                                                    text={`The selected device "${selectedDevice.name}" is offline, please turn the device on! `}
                                                />
                                        }
                                    </div>
                                </form>

                                {
                                    isLoading ?
                                        <ProgressSpinner
                                            style={{ width: '60px', height: '60px', opacity: .1 }}
                                            fill="var(--surface-ground)"
                                            animationDuration="1s"
                                            strokeWidth="3"
                                        />
                                        : <></>
                                }
                            </div>
                        :
                        <Message
                            className="fw-bold fs-3 p-4 w-75"
                            severity="warn"
                            text="This campaign has been assigned to devices that do not support satellite. And assigning a channel to it will cause issues!"
                        />


            }

            <FirebaseChannelsListener
                setChannelsList={setChannelsList}
                path={"Devices/" + selectedDevice.serial_name + "/DeviceSource"} />


            <DeviceLastLoginListener
                path={"Devices/" + selectedDevice.serial_name + "/lastLogin"}
                serialName={selectedDevice.serial_name} />

        </div>
    );
}

export default ChannelsList