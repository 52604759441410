import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { formatDuration, formatSize } from "../../../config/global";
import { toggleDeleteDialog, toggleDialog } from "../../../redux/Slices/Global/globalSlice";
import { useDeleteMedia } from "../../../queries";
import DeleteConfirmation from "../DeleteConfirmation";
import UpdateMedia from "../../Media/components/MediaLayout/UpdateMedia";
import MoveMediaFolder from "../../Media/components/FolderDragDrop/MoveMediaFolder";
import { Tooltip } from "primereact/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useGalleryContext } from "../../Contexts/GalleryContext";

const FileDetails = ({ data }) => {
  const { setRerender, selectedItem, setSelectedItem } = useGalleryContext();
  const { openDialog, deleteDialog } = useSelector((state) => state.global);
  const deleteRss = useDeleteMedia();
  const dispatch = useDispatch()
  const [type, setType] = useState();
  
  useEffect(() => {
    if (data.section == "LINKS") {
      if (data.type == "YOUTUBE") {
        setType("Youtube / Video")
      } else {
        setType("Links / WebView")
      }
    } else {
      setType(`${data.type}/ ${data.file_name?.split('.').pop()}`);
    }

  }, [])


  const popupHandler = (dialogKey) => {
    setSelectedItem(data.id);
    dispatch(toggleDialog(dialogKey))
  }

  const deletePopupHandler = (dialogKey) => {
    setSelectedItem(data.id)
    dispatch(toggleDeleteDialog(dialogKey));
  }

  const deleteHandler = async () => {
    await deleteRss.mutateAsync(
      { id: data.id },
      {
        onSuccess: () => {
          dispatch(toggleDialog("previewMedia"));
          dispatch(toggleDeleteDialog("media"));
        }
      });
  }

  return (
    <div className="h-100 flex flex-col justify-between  ">
      {/* Tabs */}
      <TabView>
        <TabPanel header="DETAILS">
          <div className=" ">
            <div className="space-y-4">
              <p className="text-sm text-gray-600">
                <span className="font-medium mr-2">Format:</span> {type}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium mr-2">File size:</span> {formatSize(data.size)}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium mr-2" >Duration:</span>{data.section === "LINKS" ? data.type === "YOUTUBE" ? formatDuration(data.duration) : "" : formatDuration(data.duration)}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium mr-2">Uploaded date:</span> {data.created_at}
              </p>
            </div>
          </div>
        </TabPanel>
      </TabView>

      {/* Footer Actions */}
      <div className="flex justify-between items-center mt-4 border-t pt-4">
        <Tooltip target={`.delete-button`} className="fs-8" />
        <Button
          icon="pi pi-trash"
          data-pr-position="bottom"
          data-pr-tooltip="delete"
          className="p-button-text text-red-600 hover:text-red-800 delete-button"
          onClick={() => { deletePopupHandler("media") }}
        />
        <div className="flex space-x-2">
          <Tooltip target={`.update-button `} className="fs-8" />
          <Button icon="fa-solid fa-pen" className="p-button-text update-button"
            data-pr-position="bottom"
            data-pr-tooltip="update"
            onClick={() => popupHandler("updateMedia")}
          />

          {
            data.section !== "LINKS" &&
            <>
              <Tooltip target={`.move-button `} className="fs-8" />
              <Button icon="fa-solid fa-arrow-right" className="p-button-text move-button" onClick={() => { popupHandler("moveMediaFolder") }} data-pr-position="bottom"
                data-pr-tooltip="move" />
            </>}
        </div>
      </div>

      {(deleteDialog.media && selectedItem == data.id) && <DeleteConfirmation isLoading={deleteRss.isLoading} itemName={data.file_name} deleteHandler={deleteHandler} dialogKey="media" />}
      {(openDialog.updateMedia && selectedItem == data.id) && <UpdateMedia item={data} setRerender={setRerender} />}
      {(openDialog.moveMediaFolder && selectedItem == data.id) && <MoveMediaFolder data={data} />}

    </div>
  );
};

export default FileDetails;
