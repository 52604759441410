
import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from "react-router-dom";
import { Column } from 'primereact/column';

import { formatDuration } from '../../../../config/global';
import { LAYOUTS_TABLE_CONFIG } from '../../../../Constants/datatables';

import { useDataTableContext } from '../../../Contexts/DataTableContext';
import { useCustomToast } from '../../../Contexts/ToastContext';

import '../../../../assets/vendor/css/datatable.css'
import Update from './Setup/Update';
import { useDeleteLayout } from '../../../../queries/layouts';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, toggleDeleteDialog, setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';
import { setSelectedLayout, } from '../../../../redux/Slices/layoutSlice';
import DeleteConfirmation from '../../../SharedComponents/DeleteConfirmation';

function LayoutsDataTable() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();

    const { totalRecords, loading, setReload, lazyParams, setLazyParams, data, onPage, onSort, dataHandler, onFilter, } = useDataTableContext();
    const { openDialog, deleteDialog } = useSelector((state) => state.global);

    const [layout, setLayout] = useState({ name: "" });

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev, ...LAYOUTS_TABLE_CONFIG
        }))
    }, [])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Setup page */}
                <Tooltip target={`.setup-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon setup-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="Setup" onClick={() => navigate('/apps/layouts/' + rowData.id + '/show')}><i className='bx bx-slideshow me-1'></i></button>
                {/* Update layout */}
                <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon update-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={() => openEditDialog(rowData)}><i className="pi pi-pencil"></i></button>

                {/* Delete layout */}
                <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon delete-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="Delete" onClick={() => confirmDeleteLayout(rowData)} ><i className="bx bx-trash"></i></button>
            </div>
        )
    }
    const openEditDialog = (data) => {
        setLayout(data);
        dispatch(toggleDialog("updateLayout"));
    }
    // Delete playlist handler
    const confirmDeleteLayout = (data) => {
        setLayout(data)
        dispatch(setSelectedLayout(data))
        dispatch(toggleDeleteDialog("layout"));
    }

    const { mutate: deleteOneLayout } = useDeleteLayout()
    const deleteLayout = () => {
        deleteOneLayout(layout.id,{
            onSuccess: () => {
                setReload(true);

            }
        })
    }

    const totalScreens = (data) => {
        return (
            data.screens_setting.length
        )
    }
    const totalDurationTemplate = (data) => {
        return (
            <div className="text-center">
                {formatDuration(data.layout_duration)}
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <div className=''>
                <span className={`text-center badge ${rowData.status == "DRAFT" ? "bg-label-danger" : "bg-label-success"}`} >{rowData.status.toLowerCase()}</span>
            </div>
        );
    }
    return (
        <div className='table-responsive text-nowrap '>
            <DataTable value={data?.layouts} filterDisplay="row" lazy responsiveLayout="scroll" dataKey="id"
                paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                totalRecords={totalRecords} onPage={onPage} scrollable={true} scrollHeight="74vh"
                onSort={dataHandler} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={dataHandler} filters={lazyParams.filters} loading={loading} className="table " 
            >
                <Column field="title" header="Title" className='text-center ' sortable filter filterPlaceholder="Search by Title" />
                <Column field="screens_setting" className='text-center' header="No. of Screen" body={totalScreens} />
                <Column field="status" header="Status" className='text-center' sortable body={statusBodyTemplate} />
                <Column field="layout_duration" header="Layout Duration" className='text-center' body={totalDurationTemplate} sortable />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column field="" header="" className='text-center ' body={actionBodyTemplate} />
            </DataTable>
            {
                openDialog.updateLayout && <Update layout={layout} setReload={setReload} />
            }
            {
                deleteDialog.layout &&
                <DeleteConfirmation
                    deleteHandler={deleteLayout}
                    itemName={layout.title}
                    dialogKey="layout"
                />
            }
        </div>

    )
}

export default LayoutsDataTable