import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react'

import { IoNewspaperOutline } from "react-icons/io5";
import "../css/apps.css"

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration, handleRssSetting } from '../../../config/global';
import { useDataTableContext } from '../../Contexts/DataTableContext';

import CreateCustomRssDialog from './CreateCustomRssDialog';
import AddFeedDialog from './AddFeedDialog';
import Layout from '../../Layout/Layout';

import { setIsCreation, setRssSettings, setSelectedRss } from '../../../redux/Slices/Apps/appsSlice';
import { CUSTOM_RSS_TABLE_CONFIG } from '../../../Constants/datatables';
import { useDeleteMedia } from '../../../queries/media';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function CustomRssIndex() {
  useTitle("Custom RSS")

  const openDialog = useSelector((state) => state.global.openDialog);
  const dispatch = useDispatch()
  const deleteRss = useDeleteMedia();

  const { totalRecords, lazyParams, setLazyParams, onPage, onSort, onFilter, getDataTable, data, } = useDataTableContext();

  const fetchData = () => {
    setLazyParams(prev => ({
      ...prev, ...CUSTOM_RSS_TABLE_CONFIG
    }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const durationBodyTemplate = (rowData) => {
    return formatDuration(rowData.duration)
  }

  const nameBodyTemplate = (rowData) => {
    return rowData.file_name?.split('.')[0]
  }

  const editRss = (data) => {
    let _settings = handleRssSetting(data?.rss_settings, data?.title);
    dispatch(setRssSettings(_settings))
    dispatch(setSelectedRss(data))
    dispatch(setIsCreation(false))
    dispatch(toggleDialog("customRssCreation"))
  }

  const deleteHandler = async (data) => {
    await deleteRss.mutateAsync(
      { id: data.id },
      {
        onSuccess: () => fetchData()
      });
  }

  const addFeedHandler = (rowData) => {
    dispatch(setSelectedRss(rowData))
    dispatch(toggleDialog("addCustomFeed"))
  }

  // Data Table Body Template
  const actionBodyTemplate = (rowData) => {
    return (
      <div className=" d-flex justify-content-center">
        {/* Add Feeds RSS */}
        <Tooltip target={`.add-feed-button-${rowData.id}`} showDelay={100} className="fs-8" content="Add Feed Content" position='bottom' />
        <button className={`btn btn-sm btn-icon add-feed-button-${rowData.id} mx-2`} onClick={() => addFeedHandler(rowData)} >
          <i class='bx bx-add-to-queue'></i>
        </button>

        {/* Update RSS */}
        <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" content="Setting" position='bottom' />
        <button className={`btn btn-sm fs-6 mr-1 btn-icon update-button-${rowData.id}`} onClick={() => editRss(rowData)}>
          <i className="fas fa-cog"></i>
        </button>

        {/* Delete RSS */}
        <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} content="Delete" position='bottom' className="fs-8" />
        <button disabled={deleteRss.isLoading} className={`btn btn-sm btn-icon delete-button-${rowData.id}`} onClick={() => deleteHandler(rowData)} >
          <i className="bx bx-trash"></i>
        </button>

      </div>
    );
  }

  const subNavbarContent = useMemo(() => {
    return (
      <div className='flex justify-end w-100'>
        <button className="btn btn-primary mx-1" disabled={getDataTable?.isLoading}
          onClick={() => dispatch(toggleDialog("customRssCreation"))} >
          Create
        </button>
      </div>
    );
  }, [getDataTable?.isLoading]);



  const subNavbarProps = useMemo(() => {
    return {
      icon: <IoNewspaperOutline className='text-secondary  fs-2' /> || " ",
    };
  }, []);
  return (
    <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
      <div className="card">


        <div className=" d-flex flex-column m-4 ">
          <div className='table-responsive text-nowrap'>
            <DataTable value={data?.media} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
              paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
              totalRecords={totalRecords} onPage={onPage}
              onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
              onFilter={onFilter} filters={lazyParams.filters} loading={getDataTable?.isLoading} className="table"
            >
              <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" body={nameBodyTemplate} />
              <Column field="duration" className='text-center' header="Total Duration" body={durationBodyTemplate} />
              <Column field="created_at" header="Created Date" className='text-center' sortable />
              <Column body={actionBodyTemplate} exportable={false} ></Column>
            </DataTable>
          </div>
        </div>
      </div>

      {openDialog.customRssCreation ? <CreateCustomRssDialog /> : <></>}
      {openDialog.addCustomFeed ? <AddFeedDialog /> : <></>}

    </Layout >
  )

}

export default CustomRssIndex