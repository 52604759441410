import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLayout: {
    id: "",
    title: "",
    screens_setting: [],
  },
  defaultTemplate: [],
  userTemplate: [],
  selectedTemplate: {
    id: "",
    title: "",
    screens_setting: [],
  },
  screenDetails: {},
  screens_setting: [],
  reloadTrigger: false,
  screenMedia: "",
  isPublished: "",
  layoutAspectRatio: {},
  unit: { label: "Percentage %", value: "%" },
  screen_duration: 0,
  screen_is_muted: 1,

  selectedArea: {},
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSelectedLayout(state, action) {
      state.selectedLayout = action.payload;
    },
    setUnit(state, action) {
      state.unit = action.payload;
    },
    setLayoutAspectRatio(state, action) {
      state.layoutAspectRatio = action.payload;
    },
    updateSelectedLayoutTitle(state, action) {
      state.selectedLayout.title = action.payload;
    },
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
    setDefaultTemplate(state, action) {
      state.defaultTemplate = action.payload;
    },
    setUserTemplate(state, action) {
      state.userTemplate = action.payload;
    },
    addToUserTemplate(state, action) {
      const newTemplate = {
        id: action.payload.id,
        title: action.payload.title,
        screens_setting: action.payload.screens_setting,
      };
      state.userTemplate.push(newTemplate);
    },

    setScreenDetails(state, action) {
      state.screenDetails = action.payload;
    },
    setScreenMedia(state, action) {
      state.screenMedia = action.payload;
    },
    setScreens_setting(state, action) {
      state.screens_setting = action.payload;
    },
    setReloadTrigger(state, action) {
      state.reloadTrigger = action.payload;
    },
    setIsPublished(state, action) {
      state.isPublished = action.payload;
    },
    updateCampaignDuration(state, action) {
      if (state.screenDetails) {
        state.screen_duration = action.payload;
      }
    },
    updateCampaignMuted(state, action) {
      if (state.screenDetails) {
        state.screen_is_muted = action.payload;
      }
    },
    setSelectedArea(state, action) {
      state.selectedArea = action.payload;
    },
    resetState: (state) => {
      state.selectedLayout = {
        id: "",
        title: "",
        screens_setting: [],
      };
      state.screenDetails = {};
      state.screens_setting = [];
      state.layoutAspectRatio = {};
    },
  },
});

export const {
  setSelectedLayout,
  setUnit,
  setLayoutAspectRatio,
  updateSelectedLayoutTitle,
  setScreenMedia,
  setSelectedTemplate,
  setDefaultTemplate,
  setUserTemplate,
  addToUserTemplate,
  setScreenDetails,
  setScreens_setting,
  setReloadTrigger,
  setIsPublished,
  updateCampaignDuration,
  updateCampaignMuted,

  setSelectedArea,
  resetState,
} = layoutSlice.actions;

export default layoutSlice.reducer;
