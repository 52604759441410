import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { useAddCustomRssContent, useUpdateCustomRssContent } from '../../../queries/rss';
import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { getFormErrorMessage } from '../../../config/global';
import { chooseOptions } from '../../../config/constants';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { setIsCreation } from '../../../redux/Slices/Apps/appsSlice';
import { useSelector } from 'react-redux';
import { Message } from 'primereact/message';

function FeedForm() {
    const updateContent = useUpdateCustomRssContent()
    const addContent = useAddCustomRssContent()

    const { formState: { errors }, handleSubmit, control, reset } = useForm();
    const { selectedRss, isCreation, selectedFeed } = useSelector(state => state.apps);
    const { galleryRefetch } = useScrollLoadingContext();
    const dispatch = useDispatch()

    const [image, setImage] = useState({});
    const fileUploadRef = useRef(null);

    useEffect(() => {
        reset({ title: selectedFeed?.title, description: selectedFeed?.description })
    }, [selectedFeed])

    const feedHandler = async (data) => {
        const formData = new FormData();
        formData.append("title", data?.title);

        if (data?.description)
            formData.append("description", data?.description);

        if (!isEmpty(image))
            formData.append("image", image);

        if (isCreation)
            await addContent.mutateAsync({
                id: selectedRss?.id,
                data: formData
            }, {
                onSuccess: () => handleSuccess()
            })
        else
            await updateContent.mutateAsync({
                id: selectedFeed?.id,
                data: formData
            }, {
                onSuccess: () => handleSuccess()
            })

    }

    const handleSuccess = () => {
        reset({ title: "", description: "" })
        galleryRefetch()
        onCancelUpload()
    }

    const onCancelUpload = () => {
        setImage({})
        fileUploadRef.current.clear();
    };

    const closeDialogHandler = () => {
        if (isCreation)
            dispatch(toggleDialog("addCustomFeed"))
        else {
            reset({ title: "", description: "" })
            dispatch(setIsCreation(true))
        }
    }

    return (
        <form onSubmit={handleSubmit(feedHandler)} className=" d-flex flex-column justify-content-center">
            <div className="col-12 d-flex flex-column justify-content-center border-top border-gray p-3">
                <h5 className='col-12 mb-1 '>
                    News Content
                </h5>
                <h6 className='font-normal text-[#CC8925] mb-3' >Any changes you make will be instantly reflected in the RSS link</h6>
                {/* Title Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="title" className={`mb-2 form-label text-capitalize`} >Title</label>
                    <Controller name="title" control={control}
                        rules={{ required: "Title field is required!" }}
                        render={({ field, fieldState }) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                inputRef={field.ref}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            />
                        )} />
                    {getFormErrorMessage("title", errors)}
                </div>

                {/* Description Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="description" className={`mb-2 form-label text-capitalize`} >Description</label>
                    <Controller name="description" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) => (
                            <InputTextarea
                                id={field.name}
                                {...field}
                                inputRef={field.ref}
                                rows={7}
                                cols={30}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                        )} />
                </div>

                {/* Image Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="image" className={`mb-1 form-label text-capitalize`} >Image</label>
                    <Controller name="image" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) => (
                            <FileUpload
                                mode="basic"
                                name="image"
                                accept="image/*"
                                maxFileSize={1000000}
                                customUpload
                                onSelect={(e) => setImage(e.files[0])}
                                ref={fileUploadRef}
                                onClick={onCancelUpload}
                                chooseOptions={chooseOptions}
                            />
                        )} />

                </div>
            </div>
            <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" type='button'
                    disabled={updateContent.isLoading || addContent.isLoading}
                    onClick={closeDialogHandler} />
                <Button className="py-2 px-4 me-3 w-auto p-button-sm" label={isCreation ? "Create" : "Update"}
                    loading={updateContent.isLoading || addContent.isLoading}
                />
            </div>
        </form>
    )
}

export default FeedForm