import { useEffect } from "react";

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";

function FirebaseDashboardListener({ path, getData }) {

    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        const unsubscribe = onValue(query, (snapshot) => {
            if (snapshot.exists()) {
                getData();
            }
        });
        return () => unsubscribe()

    }, []);

    return null
}
export default FirebaseDashboardListener