import React, { useState, useEffect } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';

import { formatDuration, } from "../../../../../config/global";

import AddContentPopup from '../../../../SharedComponents/AddContentPopup/AddContentPopup';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setScreenDetails, setScreenMedia, updateCampaignDuration, updateCampaignMuted } from '../../../../../redux/Slices/layoutSlice';
import { usePublishScreensMutation, useGetCampaignLayerContent, useResetCampaignMutation } from '../../../../../queries';
import DragDrop from '../../../../SharedComponents/DragDrop/DragDrop';

const ScreenInfo = () => {
    const dispatch = useDispatch();
    const { screens_setting, screenDetails, screen_duration } = useSelector((state) => state.layout);
    const { openDialog } = useSelector((state) => state.global);
    const { mutate: publishScreen } = usePublishScreensMutation();
    const resetCampaign = useResetCampaignMutation()

    const { data, isLoading: campaignLoading } = useGetCampaignLayerContent(screenDetails?.id, "MAIN");
    const options = [
        {
            label: 'Add Content',
            icon: 'pi pi-plus',
            command: () => {
                dispatch(toggleDialog("addContent"))
            }
        },
        {
            label: 'Publish Layout',
            icon: '',
            command: () => {
                publishScreen()
            }
        },
        {
            label: 'Reset',
            visible: screenDetails?.status === "DRAFT",
            icon: '',
            command: () => {
                reset()
            }
        }


    ];

    const reset = async () => {
        await resetCampaign.mutateAsync({ id: screenDetails?.id, name: screenDetails?.name })
    }


    // Fetch Screen data 
    useEffect(() => {
        dispatch(setScreenMedia(data?.length ?? 0));
    }, [data]);

    const handleScreenChange = (e) => {
        const selectedScreen = screens_setting?.find(
            (item) => item.id === e.target.value
        );
        dispatch(setScreenDetails(selectedScreen));
        dispatch(updateCampaignDuration(selectedScreen.campaign_duration));
        dispatch(updateCampaignMuted(selectedScreen.is_muted));


    };

    return (
        <>
            <div className="p-0 d-flex justify-content-between mb-3">
                <div className="col-8 col-md-9 d-flex">

                    <Dropdown
                        value={screenDetails?.id || ""}
                        options={screens_setting}
                        optionLabel="name"
                        optionValue="id"
                        className="w-50"
                        onChange={handleScreenChange}
                        placeholder="Select a screen"
                    />
                    <div className='mx-3 d-none d-sm-block'>
                        <p className="mb-0 ">Total Duration</p>
                        <div className='fw-bold'> {formatDuration(screenDetails?.campaign_duration ?? "0")}</div>
                    </div>
                    <Button label="Discard" className='py-1 mr-1 p-button-outlined p-button-danger'
                        onClick={reset}
                        visible={screenDetails?.status === "DRAFT"}
                        loading={resetCampaign.isLoading}
                    />
                </div>
                <div className='d-none d-md-flex'>

                    <Button label="Add Content" icon="bx bx-plus-circle " className='py-1  p-button-outlined p-button-secondary '
                        onClick={() => dispatch(toggleDialog("addContent"))}
                        disabled={campaignLoading} />
                </div>
                <SplitButton label="Option" model={options} className="  mr-2 mb-2 d-flex d-md-none" />

            </div>
            {
                data?.length === 0 && !campaignLoading ?
                    <h5 className='w-100 card d-flex align-items-center text-center p-5 mt-5'>
                        There is no media assigned yet !
                    </h5>
                    :
                    ""
            }
            {
                campaignLoading ?
                    <div className='w-100 h-100 d-flex align-items-center' >
                        <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                    </div>
                    :
                    data?.length != 0 ? <DragDrop data={data} /> : ""
            }

            {openDialog.addContent && <AddContentPopup />}
        </>
    );
};

export default ScreenInfo;