import React, { useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom';

import { useGalleryContext } from '../../../Contexts/GalleryContext';

import DeleteConfirmation from '../../../SharedComponents/DeleteConfirmation';
import MoveMediaFolder from '../FolderDragDrop/MoveMediaFolder';
import { formatSize } from '../../../../config/global';
import UpdateFolder from '../Folder/UpdateFolder';
import folderIcon from "../../../../assets/img/folder.png"

import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDeleteDialog, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useDeleteFolderMutation } from '../../../../queries';
import { deleteItemLocally } from '../../../../redux/Slices/scrollSlice';

function FolderCard({ item, breadCrumb, setBreadCrumb, setCurrentFolder, currentFolder, displayAction = true, onDrop, onDragOver, getData }) {
    const { layout, idsList, setIdsList, multiSelectCheck, selectedItem, setSelectedItem } = useGalleryContext();
    const { openDialog, deleteDialog } = useSelector((state) => state.global);
    const deleteFolder = useDeleteFolderMutation();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const menu = useRef(null);

    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Update',
                    icon: 'pi pi-refresh',
                    command: () => {
                        updateHandler()
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => {
                        deleteHandler();

                    }
                },
                {
                    label: "Move",
                    icon: "pi pi-arrow-right",
                    command: () => {
                        popupHandler("moveMediaFolder")
                    },
                },
            ]
        },
    ];

    function getSubFolderMedia() {
        setCurrentFolder({
            id: item.id,
            name: item.folder_name
        });
        setBreadCrumb(current => [...current, { label: item.folder_name, id: item.id }]);
        localStorage.setItem("bread_crumb", JSON.stringify([...breadCrumb, { label: item.folder_name, id: item.id }]));
        localStorage.setItem("folder_id", item.id);
        if (window.location.pathname.includes("/media/gallery"))
            navigate(`/media/gallery/${item.id}`)
        else {
            getData()
        }
    }

    const onMediaChange = (e) => {
        let selectedMedia = [...idsList];

        if (e.checked)
            selectedMedia.push(e.value);
        else
            selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

        setIdsList(selectedMedia);
    }

    const updateHandler = () => {
        setSelectedItem(item.id)
        dispatch(toggleDialog("updateFolder"))
        setCurrentFolder({
            id: item.id,
            name: item.folder_name
        });
    }

    const popupHandler = (dialogKey) => {
        setSelectedItem(item.id);
        dispatch(toggleDialog(dialogKey))
    }

    const deleteHandler = () => {
        setSelectedItem(item.id)
        dispatch(toggleDeleteDialog("folder"))
    }

    const deleteFolderMutation = async () => {
        await deleteFolder.mutateAsync(item.id,
            { onSuccess: () => dispatch(deleteItemLocally(item.id)) }
        )
    }

    return (
        <>
            {
                layout == "grid" ?
                    <>
                        <LazyLoadImage src={folderIcon} className=" layout_media my-1 cursor-pointer" onClick={getSubFolderMedia}
                            onDrop={event => onDrop(event, item.id)}
                            onDragOver={(event => onDragOver(event, item.id))}
                        />
                        <div className='text-truncate w-100 text-center mt-3  cursor-pointer'
                            onClick={getSubFolderMedia}
                            onDrop={event => onDrop(event, item.id)}
                            onDragOver={(event => onDragOver(event, item.id))}
                        >
                            {item.folder_name}
                        </div>
                        <div className='text-truncate w-100 text-center mt-2 fw-light fs-8'>{item.created_at}</div>
                        <span className={`position-absolute text-center ${multiSelectCheck ? "folder_action_left" : "folder_action_right"}`} onClick={(event) => menu.current.toggle(event)} >
                            <i className="fas fa-ellipsis-h text-light fs-4" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup></i>
                            <Menu model={items} popup ref={menu} id="popup_menu" />
                        </span>
                        {
                            multiSelectCheck &&
                            <Checkbox inputId={item.id} name="media" className='me-2 position-absolute grid_media_checkbox media_checkbox' value={item.id} onChange={onMediaChange} checked={idsList.indexOf(`${item.id}`) !== -1} />
                        }
                    </>
                    :
                    <>
                        {
                            !displayAction ?
                                <>
                                    <div className="col-2 ">
                                        <LazyLoadImage src={folderIcon} className="layout_media pe-2 cursor-pointer" onClick={getSubFolderMedia}
                                            onDrop={event => onDrop(event)}
                                            onDragOver={(event => onDragOver(event))}
                                        />
                                    </div>
                                    <div className="col-8 col-md-4 col-lg-6 text-truncate pe-md-2 pe-lg-4 cursor-pointer" onClick={getSubFolderMedia}>{item.folder_name} </div>
                                    <div className="col-3 col-md-4 col-sm-2 fs-6 text-center d-none d-md-block ">
                                        {formatSize(item.total_size)}
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-2 col-sm-1">
                                        <LazyLoadImage src={folderIcon} className="layout_media pe-2 cursor-pointer" onClick={getSubFolderMedia} />
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-4 text-truncate pe-md-4 text-truncate">{item.folder_name} </div>
                                    <div className="col-2 d-none d-sm-block"></div>
                                    <div className="col-3  col-sm-2 col-md-2 fs-6 text-truncate text-center">
                                        {formatSize(item.total_size)}
                                    </div>
                                </>
                        }

                        {
                            displayAction && (
                                <div className="col-4 col-sm-3 col-md-3 text-end d-flex align-items-center justify-content-end">
                                    <div className='actions list_folder_action  '  >
                                        <Tooltip target={`.update-button-${item.id}`} className="fs-8" />
                                        <button className={`btn btn-sm btn-icon update-button-${item.id}`}
                                            data-pr-position="bottom"
                                            data-pr-tooltip="update"
                                            onClick={() => updateHandler()}>
                                            <i className="pi pi-pencil text-light"></i>
                                        </button>

                                        <Tooltip target={`.delete-button-${item.id}`} className="fs-8" />
                                        <button className={`btn btn-sm btn-icon delete-button-${item.id}`}
                                            data-pr-position="bottom"
                                            data-pr-tooltip="delete"
                                            onClick={() => deleteHandler(item.id)} >
                                            <i className="bx bx-trash text-light"></i>
                                        </button>

                                        <Tooltip target={`.move-button-${item.id}`} className="fs-8" />
                                        <button
                                            className={`btn btn-sm btn-icon move-button-${item.id}`}
                                            data-pr-position="bottom"
                                            data-pr-tooltip="move"
                                            onClick={() => popupHandler("moveMediaFolder")}
                                        >
                                            <i className="fas fa-arrow-right text-light "></i>
                                        </button>
                                    </div>
                                    {multiSelectCheck &&
                                        <Checkbox inputId={item.id} name="media" className='mx-1 mx-sm-3 p-1 media_checkbox' value={item.id} onChange={onMediaChange} checked={idsList.indexOf(`${item.id}`) !== -1} />
                                    }
                                </div>
                            )
                        }
                    </>
            }

            {
                (openDialog.updateFolder && selectedItem == item.id) && <UpdateFolder item={item} currentFolder={currentFolder} />
            }

            {
                (deleteDialog.folder && selectedItem == item.id) &&
                <DeleteConfirmation
                    isLoading={deleteFolder.isLoading}
                    deleteHandler={deleteFolderMutation}
                    itemName={item.folder_name}
                    dialogKey="folder" />
            }

            {(openDialog.moveMediaFolder && selectedItem == item.id) ? <MoveMediaFolder data={item} /> : <></>}
        </>
    )
}

export default FolderCard