import _ from "lodash";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

export const handleGroupsData = (data) => {
    let _groups = {};

    Object.values(data.data).forEach(group => {
        _groups = { ..._groups, [group.id]: { group_id: group.id, name: group.name, items: group.devices, } }
    })

    return _groups;
}

export const handleResErrors = async (error, showToast) => {
    let _errors = error?.response?.data?.errors;

    if (_.isObject(_errors)) {
        _.forIn(_errors, (value) => {
            showToast("error", "Error", value)
        });
    } else {
        showToast("error", "Error", error.response?.data?.msg ?? error.response?.data?.message)
    }
}

// Generate error message
export const generateMessage = (arr, type) => {
    let msg = `${arr.map((name) => '"' + name + '" ')} ${arr.length > 1 ? "don't" : "doesn't"}  support ${type}!`;
    return { severity: 'error', summary: 'Assign Campaign', detail: msg, life: 3000 }
}


export const useInvalidateCampaignLayer = () => {
    const queryClient = useQueryClient();
    const { campaignDetails, layer } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    return (variables = {}) => {
        let campaignId = window.location.pathname.includes("layout") ? screenDetails?.id : campaignDetails.id;
        let campaignLayer = window.location.pathname.includes("layout") ? "MAIN" : layer;

        campaignId = variables.campaign_id ?? campaignId;

        queryClient.invalidateQueries(["getCampaignLayerContent", campaignId, campaignLayer]);

        if (window.location.pathname.includes("layout")) {
            queryClient.invalidateQueries(["screensData"], screenDetails?.pivot.layout_id);
            queryClient.invalidateQueries(["layoutData"], screenDetails?.pivot.layout_id);
        } else {
            queryClient.invalidateQueries(["getCampaign", variables.campaign_id ?? campaignId]);
        }
    };
};


