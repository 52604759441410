import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import AssignCampaignsDropDown from "../CampaignAssignment/AssignCampaignsDropDown";
import ScheduleCampaignDialog from "../CampaignAssignment/ScheduleCampaignDialog";
import DeleteConfirmation from "../../../SharedComponents/DeleteConfirmation";
import GroupHeader from "./GroupHeader";
import DeviceCard from "./DeviceCard";
import Update from "../Update";

import { setSelectedGroup } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog, setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';
import { useDeleteDevice, useUpdateDeviceGroup } from "../../../../queries";


function Group({ devicesGroup }) {
    const dispatch = useDispatch();
    const updateDeviceGroup = useUpdateDeviceGroup();
    const deleteDevice = useDeleteDevice();

    const { openDialog, deleteDialog } = useSelector((state) => state.global);
    const { selectedDevice } = useSelector((state) => state.device);

    // Assign to device dropdown option "campaigns"
    const [columns, setColumns] = useState({});

    useEffect(() => {
        setColumns(devicesGroup);
    }, [devicesGroup]);

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: { ...sourceColumn, items: sourceItems, },
                [destination.droppableId]: { ...destColumn, items: destItems, },
            });

            updateDeviceGroupHandler(result.destination.droppableId, result.draggableId)

        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns, [source.droppableId]: { ...column, items: copiedItems, },
            });
        }
    };

    const updateDeviceGroupHandler = async (groupID, deviceID) => {
        await updateDeviceGroup.mutateAsync({
            data: {
                "group_id": groupID == "undefined" ? "" : groupID,
                "device_id": deviceID
            }
        })
    }

    const assignSchedule = (groupID) => {
        dispatch(setSelectedGroup(groupID));
        dispatch(toggleDialog("assignDialog"))
    }

    const deleteDeviceHandler = async () => {
        dispatch(setDisableBtn(true))
        await deleteDevice.mutateAsync({ id: selectedDevice?.id })
    }

   

    return (
        <>
            <div className="d-flex flex-column justify-content-center w-100"  >
                <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)} className="w-100" >
                    {
                        Object.entries(columns).map(([columnId, column], index) => {
                            return (
                                <div className="d-flex flex-column align-items-start " key={columnId}>
                                    <GroupHeader
                                        hasDevices={column.items.length > 0}
                                        assignSchedule={assignSchedule}
                                        groupName={column.name}
                                        columnId={columnId}
                                        groupID={column.group_id}
                                    />

                                    <Droppable droppableId={columnId} key={columnId} group_id={column.group_id} >
                                        {
                                            (provided, snapshot) => {
                                                return (
                                                    <Card {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="p-3 bg-white w-100 mb-3 group_card"
                                                    >
                                                        {
                                                            column.items.length != 0 ?
                                                                column.items.map((device, index) => {
                                                                    return (
                                                                        <Draggable key={`${device.id}`} draggableId={`${device.id}`} index={index} >
                                                                            {(provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            ...provided.draggableProps.style,
                                                                                        }}
                                                                                        className=" px-0 px-sm-2  ">

                                                                                        <DeviceCard data={device} />
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })

                                                                :
                                                                <div>No Devices add to this group</div>
                                                        }
                                                        {provided.placeholder}
                                                    </Card>
                                                );
                                            }}
                                    </Droppable>
                                </div>
                            );
                        })}
                </DragDropContext>
            </div>

            {
                deleteDialog.device &&
                <DeleteConfirmation
                    deleteHandler={deleteDeviceHandler}
                    itemName={selectedDevice.name ?? selectedDevice.serial_name}
                    dialogKey="device"
                />
            }

            {
                openDialog.updateDevice
                && <Update
                    device={selectedDevice} />
            }

            {openDialog.assignCampaignsDropDown ? <AssignCampaignsDropDown deviceID={selectedDevice.id} /> : <></>}

            {
                openDialog.assignScheduleCampaigns ? <ScheduleCampaignDialog /> : <></>
            }
        </>
    )
}

export default Group