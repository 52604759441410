import { defaultRssSetting } from "../../../config/constants";

const initialState = {
    // Dialog Controls
    createDialog: false,
    prayerEnableDialog: false,

    // Prayer Data
    isLoading: false,
    data: { next: 0, location: "" },

    // RSS Data
    selectedFeed: {},
    newSeparator: null,
    rssSettings: defaultRssSetting,
    selectedRss: {},
    isCreation: true,
    rssName: "Instance Name",
};

export default initialState;
