import DeviceCard from './DeviceCard'
import { Message } from 'primereact/message'

function DevicesList({ data, ids, setIds, pageNum, lastPage, setLastElement, loading }) {
    
    return (
        <>
            {
                data?.length === 0 && !loading  ?
                    <div className="d-flex p-5 justify-content-center ">
                        <Message className="fw-bold fs-3 p-4 w-100" severity="warn" text="  There are no devices added yet!" />
                    </div>
                    :
                    <>
                        {
                            data?.map((item, index) => {
                                return (index === data.length - 1 && pageNum < lastPage ?
                                    <div className="col-12 d-flex align-items-center justify-content-between " ref={setLastElement} key={`${item.id}-${index}`}>
                                        <DeviceCard ids={ids} setIds={setIds} item={item} />
                                    </div>
                                    :
                                    <div className={`col-12 d-flex align-items-center justify-content-between my-1 
                                        ${data.length == index + 1 ? "" : "border-bottom"} pb-2`} key={`${item.id}-${index}`} >
                                        <DeviceCard ids={ids} setIds={setIds} item={item} />
                                    </div>
                                )
                            })
                        }
                    </>
            }
        </>
    )
}

export default DevicesList