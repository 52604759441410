import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useAddCampaignContent } from '../../../queries/campaignContent';
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';
import SearchInput from './SearchInput';
import MediaStack from './MediaStack';

function PlayLists() {
    const [localSearchTerm, setLocalSearchTerm] = useState("")
    const addCampaignContent = useAddCampaignContent();
    const screenDetails = useSelector((state) => state.layout.screenDetails);
    const dispatch = useDispatch();

    const { layer, assign } = useSelector((state) => state.global);
    const { id } = useParams();

    const {
        ids, setIds, data,
        resetScrollStates, pageNum, isFetching,
        lastPage, setLastElement,
        setPath, setSearchTerm, searchTerm
    } = useScrollLoadingContext();

    useEffect(() => {
        setPath(`/playlist/company`)
        return () => resetScrollStates()
    }, [])

    const onPlaylistChange = (e) => {
        let selectedPlaylists = [...ids];

        if (e.checked)
            selectedPlaylists.push(e.value);
        else
            selectedPlaylists.splice(selectedPlaylists.indexOf(e.value), 1);

        setIds(selectedPlaylists);
    }

    const assignHandler = async () => {
        await addCampaignContent.mutateAsync({
            "ids_list": ids,
            "campaign_id": window.location.pathname.includes("layout") ? screenDetails.id : id,
            "layer": layer,
            "type": "playlist",
        })
    }

    useEffect(() => {
        if (assign) {
            dispatch(setDisableBtn(true));
            assignHandler();
        }
    }, [assign])

    const resetSearch = () => {
        setLocalSearchTerm("")
        setSearchTerm("")
    }
    const getData = () => {
        setSearchTerm(localSearchTerm)
    }

    return (
        <>
            <SearchInput
                setSearchTerm={setLocalSearchTerm}
                searchTerm={localSearchTerm}
                getData={getData}
                isLoading={isFetching}
                resetSearch={resetSearch}
                isDisabled={data?.length === 0}
            />

            {
                data?.length === 0 && !isFetching ?
                    isEmpty(searchTerm) ?
                        <h5 className="py-5 text-center w-100">There aren't any playlist has been created yet!</h5>
                        :
                        <h5 className="py-5 text-center w-100">No results found!</h5>
                    :
                    <>
                        <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                            <div className="d-flex my-3">
                                <div className="col-1"></div>
                                <div className="col-3 mx-2 text-light fs-6  ps-5">Name</div>
                            </div>
                            <div className="popular-movies-api d-flex flex-wrap ">
                                {
                                    data.map((item, index) => {
                                        return index === data?.length - 1 && pageNum <= lastPage ?
                                            (
                                                <div className="col-12 d-flex align-items-center justify-content-between " ref={setLastElement} key={`${item.type}-${index}`}>
                                                    <label htmlFor={item.id} className="col-10 col-sm-11 d-flex ">
                                                        <div className="col-3 col-sm-2 mx-2 ">
                                                            <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center"><i className="fas fa-list-ul fs-4  text-white"></i></div>
                                                        </div>
                                                        <div className="col-2  mx-1 fs-6 d-flex align-items-center text-truncate">
                                                            {item.name}
                                                        </div>
                                                        <div className="col-3 text-center text-light" >
                                                            {(item.media)?.length != 0 ?
                                                                <MediaStack mediaArray={item.media} /> :
                                                                "Empty Playlist!"
                                                            }
                                                        </div>
                                                    </label>
                                                    <div className="col-2 col-sm-1 pe-2">
                                                        <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onPlaylistChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="col-12 d-flex align-items-center justify-content-between my-1 border-bottom pb-2" key={`${item.type}-${index}`} >
                                                    <label htmlFor={item.id} className="col-10 col-sm-11 d-flex ">
                                                        <div className="col-3 col-sm-2 mx-2 ">
                                                            <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center playlist_icon_container">
                                                                <i className="fas fa-list-ul fs-4 text-white"></i>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 mx-1 fs-6 d-flex align-items-center text-truncate">{item.name}</div>
                                                        <div className="col-5 fs-6 text-center text-light d-flex justify-content-center align-items-center d-none d-md-flex" >
                                                            {(item.media)?.length != 0 ?
                                                                <MediaStack mediaArray={item.media} /> :
                                                                <div >------------</div>
                                                            }
                                                        </div>
                                                    </label>
                                                    <div className="col-2 col-sm-1 pe-2">
                                                        <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onPlaylistChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                    </div>
                                                </div>
                                            )
                                    })
                                }
                            </div>
                        </div>
                    </>
            }
            {
                isFetching ?
                    <ProgressSpinner
                        className='h-[50px] w-[50px] flex justify-center items-center'
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="30s" />
                    :
                    <></>
            }
        </>)

}

export default PlayLists