import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTitle } from '../../../config/global';

const PrayerCountdown = ({ prayerTimes, setNextPrayerIndex, nextPrayerIndex, refetch }) => {
    const [timeLeft, setTimeLeft] = useState("");
    const dispatch = useDispatch();
    useTitle("Prayer");
    
    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = moment();
            var nextPrayerTime = null;

            if (nextPrayerIndex == 0) {
                const tomorrow = now.clone().add(1, 'days');
                nextPrayerTime = moment(`${tomorrow.format('YYYY-MM-DD')} ${prayerTimes[nextPrayerIndex]}`);
            } else {
                nextPrayerTime = moment(`${now.format('YYYY-MM-DD')} ${prayerTimes[nextPrayerIndex]}`);
            }

            var duration = moment.duration(nextPrayerTime.diff(now));

            if (nextPrayerTime.isBefore(now)) {
                if (nextPrayerIndex == 4) {
                    clearInterval(interval);
                    refetch()
                } else {
                    dispatch(setNextPrayerIndex((prevIndex) => (prevIndex + 1)));
                    return;
                }
            }

            setTimeLeft(`${duration.hours()} : ${duration.minutes()} : ${duration.seconds()} `);
        };

        const interval = setInterval(calculateTimeLeft, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [nextPrayerIndex, prayerTimes]);

    return timeLeft
};

export default PrayerCountdown;
