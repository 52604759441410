

import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useState } from 'react'

import { getFormErrorMessage } from '../../../config/global';
import { POSITION_OPTIONS } from '../../../Constants/apps';
import { setRssSettings } from '../../../redux/Slices/Apps/appsSlice';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Sketch from '@uiw/react-color-sketch';

const ColorsInputs = () => {
    const dispatch = useDispatch()
    const { rssSettings } = useSelector(state => state.apps);
    const [displayColorPicker, setDisplayColorPicker] = useState({
        text: false,
        bg: false
    });

    const colorPickerHandler = (key) => {
        setDisplayColorPicker(prev => ({ ...prev, [key]: !prev[key] }))
    }
 
    return (
        <>
            <div className={`col-6 pe-1 mb-2`}>
                <label htmlFor="bg_color" className={`mb-0 form-label text-capitalize`}  >Background color</label>
                <div className="border-1 p-1 rounded d-flex position-relative">
                    <Sketch
                        color={rssSettings?.bg_color }
                        disableAlpha={false}
                        onChange={(color) => {
                            dispatch(setRssSettings({ bg_color: color.hexa }))
                        }}
                        onMouseLeave={() => colorPickerHandler("bg")}
                        className={`position-absolute  color_picker ${displayColorPicker.bg ? "d-block" : "d-none"}`}
                    />
                    <div className='me-3 cursor-pointer border-1'
                        onClick={() => colorPickerHandler("bg")}
                        style={{ backgroundColor: `${rssSettings?.bg_color }`, width: "25px", height: "25px" }} >
                    </div>
                    <div className='border-bottom' >{rssSettings?.bg_color }</div>
                </div>
            </div>

            <div className={`col-6 ps-1 mb-2`}>
                <label htmlFor="bg_color" className={`mb-0 form-label text-capitalize`}>Text color</label>
                <div className="border-1 p-1 rounded d-flex position-relative">
                    <Sketch
                        color={rssSettings?.text_color }
                        disableAlpha={false}
                        onChange={(color) => {
                            dispatch(setRssSettings({ text_color: color.hexa }))
                        }}
                        onMouseLeave={() => colorPickerHandler("text")}
                        className={`right-[0] position-absolute color_picker ${displayColorPicker.text ? "d-block" : "d-none"}`}
                    />
                    <div className='me-3 cursor-pointer border-1'
                        onClick={() => colorPickerHandler("text")}
                        style={{ backgroundColor: `${rssSettings?.text_color }`, width: "25px", height: "25px" }} >
                    </div>
                    <div className='border-bottom' >{rssSettings?.text_color}</div>
                </div>
            </div>
        </>
    )
}

const PositionInput = ({ control, errors }) => {
    const dispatch = useDispatch()

    const rssSettings = useSelector(state => state.apps.rssSettings);

    return (
        <>
            {/* Position Option */}
            <div className={`mb-2 col-12`}>
                <label htmlFor="" className={`form-label text-capitalize mb-0`}>position</label>
                <Controller name="position" control={control}
                    rules={{ required: "position is required!" }}
                    render={({ field, fieldState }) => (
                        <Dropdown
                            id={field.name} {...field}
                            value={rssSettings?.position}
                            options={POSITION_OPTIONS}
                            onChange={(e) => {
                                field.onChange(e.value);
                                dispatch(setRssSettings({ position: e.value }))
                            }}
                            optionLabel="label"
                            optionValue="value"
                            ref={(el) => {
                                if (el?.focus) field.ref(el);
                            }}
                            placeholder="select..."
                            className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                        />
                    )}
                />
                {getFormErrorMessage('position', errors)}
            </div>
        </>
    )
}

const TitleInput = ({ control, errors }) => {
    const dispatch = useDispatch()

    return (
        <div className={`mb-2 col-12`}>
            <label htmlFor="title" className={`mb-0 form-label text-capitalize`} >RSS Title</label>
            <Controller name="title" control={control}
                rules={{ required: "Title field is required!" }}
                render={({ field, fieldState }) => (
                    <InputText
                        id={field.name}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                            dispatch(setRssSettings({ title: e.target.value }))
                        }}
                        inputRef={field.ref}
                        className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                    />
                )} />
            {getFormErrorMessage("title", errors)}
        </div>
    )
}

const DurationInput = ({ control, errors }) => {
    const dispatch = useDispatch()

    return (
        <>
            <div className={`mb-2 col-12`}>
                <label className="mb-0 form-label text-capitalize"> Item Duration </label>
                <span className="p-float-label w-100">
                    <Controller name="duration" control={control}
                        rules={{
                            required: 'Item Duration is required.',
                            min: {
                                value: 3,
                                message: "The minimum allowed duration is 3 seconds!"
                            }
                        }}
                        render={({ field, fieldState }) => (
                            <InputNumber
                                id={field.name}
                                {...field}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    dispatch(setRssSettings({ duration: e.value }))
                                }}
                                inputRef={field.ref}
                                className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                mode="decimal"
                                min={1}
                                ref={(el) => {
                                    if (el?.focus) field.ref(el);
                                }}
                                showButtons
                                decrementButtonClassName="btn-primary"
                                incrementButtonClassName="btn-primary"
                            />
                        )} />
                </span>
                {getFormErrorMessage('duration', errors)}
            </div>
        </>
    )
}

export { ColorsInputs, PositionInput, DurationInput, TitleInput }