import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';
import AddContentPopup from '../../../SharedComponents/AddContentPopup/AddContentPopup';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import DragDrop from '../../../SharedComponents/DragDrop/DragDrop';
import Layout from '../../../Layout/Layout'

import { useGetPlaylist, useGetPlaylistLayerContent, usePublishPlaylistMutation, useResetPlaylistMutation, } from '../../../../queries';
import { useTitle, formatDuration, } from "../../../../config/global";
import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog, setLayer } from '../../../../redux/Slices/Global/globalSlice';
import { setPlaylistDetails } from '../../../../redux/Slices/playlistSlice';

function PlaylistSetup() {
  useTitle("Playlist Setup")

  const { openDialog, layerOptions, layer } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { isLoading, data, refetch: refetchLayerData } = useGetPlaylistLayerContent(id, layer)
  const { playlistLoading, playlistInfo, playlistFetching } = useGetPlaylist(id)
  const publishPlaylist = usePublishPlaylistMutation();
  const resetPlaylist = useResetPlaylistMutation()

  const { playlistDetails } = useSelector((state) => state.playlist);
  const isPublishing = publishPlaylist.isLoading;

  //Actions options (mobile view)
  const options = [
    {
      label: 'Add Content',
      icon: 'pi pi-plus',
      command: () => dispatch(toggleDialog("addContent"))
    },
    {
      label: 'Publish',
      icon: '',
      command: () => publish()
    }, {
      label: 'Reset',
      visible: playlistDetails?.status === "INACTIVE",
      icon: '',
      command: () => {
        reset()
      }
    }

  ];

  useEffect(() => {
    dispatch(setPlaylistDetails(playlistInfo));
  }, [playlistInfo])


  const publish = async () => {
    await publishPlaylist.mutateAsync({
      id: id, name: playlistInfo.name
    })
  }


  const reset = async () => {
    await resetPlaylist.mutateAsync({ id, name: playlistInfo?.name })
  }


  const subNavbarProps = useMemo(() => {
    return {
      title: playlistLoading ? "Loading..." : playlistDetails?.name || " ",
      date: playlistLoading ? "" : playlistDetails?.created_at || ""
    };
  }, [playlistDetails, playlistLoading, isPublishing]);

  const subNavbarContent = useMemo(() => {
    return (
      <div className={`flex ${playlistDetails?.status === "INACTIVE" ? "justify-between" : "justify-end"} w-100`}>
        <Button label={"Discard"} loading={resetPlaylist.isLoading} className={"p-button-sm  me-2 p-button-outlined p-button-danger "}
          visible={playlistDetails?.status === "INACTIVE"}
          onClick={reset} />
        <div className="">
          <Button
            label="Publish"
            className="py-2 me-2 px-5 "
            disabled={playlistDetails?.status === "ACTIVE"}
            loading={isPublishing || playlistFetching}
            onClick={publish}
          />
        </div>
      </div>

    );
  }, [playlistDetails, isPublishing, playlistFetching, resetPlaylist.isLoading]);

  return (
    <>
      <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
        <div className=" mr-1 d-flex justify-content-between mb-3">
          <div className="col-8 col-md-6 d-flex  ">
            {/* dropdown */}
            <Dropdown value={layer} options={layerOptions} optionLabel="name" optionValue="code" className='w-50 '
              onChange={(e) => dispatch(setLayer(e.value))} />
            {
              //Duration 
              !playlistLoading && layer === "MAIN" &&
              <div className='mx-3 d-none d-sm-block'>
                <p className="mb-0">Total Duration</p>
                <div className='fw-bold'> {formatDuration(playlistDetails?.playlist_duration)}</div>
              </div>
            }
          </div>
          <div className='d-none d-md-flex'>
            {/* Add content */}
            <Button label="Add Content" icon="pi pi-plus" className='py-2 mr-1 p-button-outlined p-button-secondary' onClick={() => dispatch(toggleDialog("addContent"))
            } disabled={playlistLoading || isLoading} />
          </div>

          {/* Action btn on mobile */}
          <SplitButton label="Option" model={options} className="p-button-sm mr-2 mb-2 d-flex d-md-none" />
        </div>

        {
          !isLoading && data?.length == 0 ?
            <h5 className='w-100 card d-flex align-items-center p-5  text-center mt-5'>
              There is no media assigned yet !
            </h5>
            :
            ""
        }

        {
          isLoading ?
            <div className='w-100 h-100 d-flex align-items-center' >
              <ProgressSpinner style={{ width: '60px', height: '60px', }} strokeWidth="3" fill="var(--surface-ground)" />
            </div>
            :
            data?.length != 0 ? <DragDrop data={data} /> : ""
        }
        {openDialog.addContent ? <AddContentPopup /> : <></>}
      </Layout>
    </>
  )
}

export default PlaylistSetup