import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration, handleRssSetting } from '../../../config/global';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import { RSS_TABLE_CONFIG } from '../../../Constants/datatables';
import { useDeleteMedia } from '../../../queries/media';
import { setIsCreation, setRssSettings, setSelectedRss } from '../../../redux/Slices/Apps/appsSlice';

function RssDataTable() {
    useTitle('RSS');

    const { totalRecords, lazyParams, setLazyParams, data, onPage, onSort, onFilter, getDataTable } = useDataTableContext();
    const deleteRss = useDeleteMedia();
    const dispatch = useDispatch()

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev, ...RSS_TABLE_CONFIG
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex items-center text-nowrap">
                {/* Update RSS */}
                <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" content="Setting" position='bottom' />
                <button className={`btn btn-sm fs-6 mr-1 btn-icon update-button-${rowData.id}`} onClick={() => editRss(rowData)}>
                    <i className="fas fa-cog"></i>
                </button>

                {/* Delete RSS */}
                <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} content="Delete" position='bottom' className="fs-8" />
                <button disabled={deleteRss.isLoading} className={`btn btn-sm btn-icon delete-button-${rowData.id}`} onClick={() => deleteHandler(rowData)} >
                    <i className="bx bx-trash"></i>
                </button>
            </div>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return formatDuration(rowData.duration)
    }

    const nameBodyTemplate = (rowData) => {
        return rowData.file_name?.split('.')[0]
    }

    const editRss = (data) => {
        let _settings = handleRssSetting(data?.rss_settings, data?.title);
        dispatch(setRssSettings(_settings))
        dispatch(setSelectedRss(data))
        dispatch(setIsCreation(false))
        dispatch(toggleDialog("rssFeedAddition"))
    }

    const deleteHandler = async (data) => {
        await deleteRss.mutateAsync(
            { id: data.id },
            {
                onSuccess: () => {
                    setLazyParams(prev => ({
                        ...prev, ...RSS_TABLE_CONFIG
                    }))
                }
            });
    }

    return (
        <div className='table-responsive text-nowrap'>
            <DataTable value={data?.media} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={onFilter} filters={lazyParams.filters} loading={getDataTable.isLoading} className="table"
            >
                <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" body={nameBodyTemplate} />
                <Column field="duration" className='text-center' header="Total Duration" body={durationBodyTemplate} />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column body={actionBodyTemplate} exportable={false} ></Column>
            </DataTable>
        </div>
    )
}

export default RssDataTable