import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { isEmpty } from 'lodash';
import tzLookup from 'tz-lookup';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Select from 'react-select';

import { useGetCites, useGetCountries, useUpdateDeviceMutation } from '../../../queries';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { getFormErrorMessage } from '../../../config/global';
import { setSelectedCountry } from '../../../redux/Slices/deviceSlice';

const status = [
    { name: 'Active', code: 'ACTIVE' },
    { name: 'Inactive', code: 'INACTIVE' },
];

function Update({ device }) {
    const { openDialog } = useSelector((state) => state.global);
    const { selectedCountry } = useSelector((state) => state.device);
    const { formState: { errors }, control, handleSubmit, reset, setError } = useForm();

    const dispatch = useDispatch()
    const queryClient = useQueryClient();
    const updateDevice = useUpdateDeviceMutation();

    const { data: countries } = useGetCountries();
    const { data: cities, isLoading } = useGetCites();

    const [selectLocManually, setSelectLocManually] = useState(false);
    const [selectedCity, setSelectedCity] = useState();

    useEffect(() => {
        setSelectedCity(null)
        if (!isEmpty(selectedCountry))
            queryClient.invalidateQueries('getCities');
    }, [selectedCountry])

    useEffect(() => {
        reset({
            name: device.name,
            status: device.status
        })
    }, [device])

    const onSubmit = async (formData) => {
        if (!selectedCity && selectLocManually)
            setError('city', { type: 'manual', message: 'City  is required!' });
        else {
            await updateDevice.mutateAsync({
                id: device.id,
                data: {
                    name: formData.name,
                    status: formData.status,
                    location: {
                        "country_name": selectLocManually ? selectedCountry.label : "",
                        "timezone": selectLocManually ? tzLookup(selectedCity.lat, selectedCity.lng) : "",
                        "city": selectLocManually ? selectedCity.value : ""
                    }
                }
            })
        }
    };

    return (
        <>
            <Dialog visible={openDialog.updateDevice}
                style={{ width: '500px' }}
                header="Update Device Status"
                modal className="p-fluid"
                breakpoints={{ '960px': '95vw' }}
                onHide={() => dispatch(toggleDialog("updateDevice"))}>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={`col-12 mb-3 mt-5 px-4`}>
                        <div className="field ">
                            <label className="form-label"> Name  </label>
                            <span className="p-float-label">
                                <Controller name="name" control={control}
                                    rules={{ required: 'Name is required.' }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} autoFocus inputRef={field.ref}
                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        />
                                    )} />
                            </span>
                            {getFormErrorMessage('name', errors)}
                        </div>
                    </div>

                    {/* Status input */}
                    <div className={`col-12 my-3 px-4`}>
                        <div className="field">
                            <label className="form-label" htmlFor="basic-default-company"> Status </label>
                            <span className="p-float-label">
                                <Controller name="status" control={control}
                                    rules={{ required: 'Status is required.' }}
                                    render={({ field, fieldState }) =>
                                    (<Dropdown id={field.name} {...field}
                                        onChange={(e) => field.onChange(e.value)}
                                        options={status}
                                        optionLabel="name"
                                        optionValue="code"
                                        inputRef={field.ref}
                                        className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                    />)
                                    } />
                                {getFormErrorMessage('status', errors)}
                            </span>
                        </div>
                    </div>

                    {
                        selectLocManually ?
                            <>
                                {/* Country Select */}
                                <div className={`col-12 my-3 px-4`}>
                                    <div className="field">
                                        <label htmlFor="" className='mb-2 form-label'>Country</label>
                                        <Controller name="country_name" control={control}
                                            rules={{ required: selectLocManually && "Country  is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    options={countries}
                                                    value={selectedCountry}

                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions);
                                                        dispatch(setSelectedCountry(selectedOptions))
                                                    }}
                                                    placeholder="Select..."
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage('country_name', errors)}
                                    </div>
                                </div>

                                {/* City Select */}
                                <div className={`col-12 my-3 px-4`}>
                                    <div className="field">
                                        <label htmlFor="" className='mb-2 form-label'>City</label>
                                        <Controller name="city" control={control}
                                            rules={{ required: selectLocManually && "City is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    value={selectedCity}
                                                    options={cities}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions);
                                                        setSelectedCity(selectedOptions)
                                                    }}
                                                    placeholder="Select..."
                                                    isDisabled={isLoading}
                                                    isLoading={isLoading}

                                                />
                                            )}
                                        />
                                        {getFormErrorMessage('city', errors)}
                                    </div>
                                </div>
                            </>
                            : <></>
                    }

                    {/* Select Location Manually Checkbox */}
                    <div className={`col-12 mt-4 mb-3 px-4`}>
                        <div className="field">
                            <div className="field-checkbox d-flex align-items-center">
                                <Checkbox inputId="select_location" name="select_location" value="select_location" onChange={(e) => setSelectLocManually(prev => !prev)} checked={selectLocManually} />
                                <label htmlFor="select_location" className='ms-2'>Select the device location manually.</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 text-center mt-5 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={updateDevice.isLoading} onClick={() => dispatch(toggleDialog("updateDevice"))} />
                        <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Update" loading={updateDevice.isLoading} />
                    </div >
                </form >
            </Dialog >
        </>
    )
}

export default Update