import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useUpdatePlaylistMutation } from '../../../queries';
import { getFormErrorMessage } from '../../../config/global';
import { PLAYLIST_OPTIONS } from '../../../Constants/playlist';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useCustomToast } from '../../Contexts/ToastContext';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { PLAYLISTS_TABLE_CONFIG } from '../../../Constants/datatables';

function Update({ PlayList }) {
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { setLazyParams } = useDataTableContext();
    const { openDialog } = useSelector((state) => state.global);
    const updatePlaylist = useUpdatePlaylistMutation();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    useEffect(() => {
        reset(PlayList)
    }, [PlayList])

    const hideDialog = () => {
        dispatch(toggleDialog("updatePlaylist"));
    }

    const onSubmit = async (data) => {
        await updatePlaylist.mutateAsync({
            id: PlayList.id,
            data: {
                "name": data.name,
                "status": data.status,
            }
        }, {
            onSuccess: () => {
                dispatch(toggleDialog("updatePlaylist"));
                showToast('success', 'Update PlayList', "Playlist Updated Successfully!");
                setLazyParams(prev => ({ ...prev, ...PLAYLISTS_TABLE_CONFIG }))

            }
        })
    };

    return (
        <>
            <Dialog visible={openDialog.updatePlaylist}
                style={{ width: '500px' }}
                header="Update Playlist" modal
                className="p-fluid" onHide={hideDialog}
                breakpoints={{ '960px': '95vw' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='px-5 my-5'>
                        <div className={` col-12 mb-5`}>
                            <div className="field">
                                <label className="form-label"> Name  </label>
                                <span className="p-float-label">
                                    <Controller name="name" control={control}
                                        rules={{ required: 'Name is required.' }}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field} autoFocus
                                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage('name', errors)}
                            </div>
                        </div>

                        {/* Status input */}
                        <div className={` col-12`}>
                            <div className="field">
                                <label className="form-label" htmlFor="basic-default-company">  Status </label>
                                <span className="p-float-label">
                                    <Controller name="status" control={control}
                                        rules={{ required: 'Status is required.' }}
                                        render={({ field, fieldState }) =>
                                        (<Dropdown id={field.name} {...field}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={PLAYLIST_OPTIONS}
                                            optionLabel="name"
                                            optionValue="code"
                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        />)
                                        } />
                                    {getFormErrorMessage('status', errors)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={updatePlaylist.isLoading} onClick={hideDialog} />
                        <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Update" loading={updatePlaylist.isLoading} />
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default Update