import React, { useState, useEffect, useRef, useContext, createContext } from 'react';
import { useFetchDataByPathMutation } from '../../queries/dataTable';
import { isEmpty } from 'lodash';
import { useQueryClient } from "react-query";

const ScrollLoading = createContext({});

export const ScrollLoadingProvider = (props) => {
    const [lastElement, setLastElement] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [data, setData] = useState([]);
    const [path, setPath] = useState("");
    const [ids, setIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [params, setParams] = useState({});

    const fetchDataByPathMutation = useFetchDataByPathMutation()
    const isLoading = fetchDataByPathMutation.isLoading
    const isFetching = fetchDataByPathMutation.isLoading

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    const refetch = async () => {
        if (path) {
            await fetchDataByPathMutation.mutateAsync({
                path, pageNum, searchTerm, params
            }, {
                onSuccess: (response) => {
                    // if (!isEmpty(response?.data)) {
                    setLastPage(response?.pagination?.total_pages);
                    setData(prevData => {
                        const newData = pageNum === 1 ? response.data : [...prevData, ...response.data];
                        return newData;
                    });
                    // } else if (!isEmpty(searchTerm)) {
                    // setData([])
                    // }
                }
            })
        }
    }

    useEffect(() => {
        if (!isEmpty(path) && pageNum === 1) {
            refetch();
        }
    }, [path]);


    useEffect(() => {
        if ((pageNum <= lastPage || lastPage == null)) {
            console.log(pageNum,lastPage);
            
            refetch();
        }
    }, [pageNum]);

    useEffect(() => {
        galleryRefetch()
    }, [searchTerm, params]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);


    const resetScrollStates = () => {
        setIds([])
        setPath("")
        setPageNum(1)
    }

    const galleryRefetch = () => (pageNum === 1) ? refetch() : setPageNum(1)


    const updateItemLocally = (updatedItem) => {
        setData((prevData) =>
            prevData.map((item) => item.id === updatedItem.id ? updatedItem : item
            ));
    };

    const deleteItemLocally = (itemId) => {
        setData((prevData) =>
            prevData.filter((item) => item.id !== itemId)
        );
    };

    return (
        <ScrollLoading.Provider value={{
            data, setData,
            ids, setIds,
            resetScrollStates,
            lastElement, setLastElement,
            isUploaded, setIsUploaded,
            lastPage, setLastPage,
            loading, setLoading,
            pageNum, setPageNum,
            rerender, setRerender,
            searchTerm, setSearchTerm,
            setPath, isLoading, isFetching,
            params, setParams,
            refetch, galleryRefetch,
            updateItemLocally,
            deleteItemLocally
        }}>
            {props.children}
        </ScrollLoading.Provider>
    )

}

export const useScrollLoadingContext = () => {
    return useContext(ScrollLoading);
}
