import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { galleryRefetch, resetScrollStates, setData, setIsFetching, setLastPage, setPageNum, setRerender } from '../../redux/Slices/scrollSlice'
import { useFetchDataByPathMutation } from '../../queries/dataTable'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useParams } from 'react-router-dom'

export default function ScrollContainer({ path, children }) {
    const fetchDataByPathMutation = useFetchDataByPathMutation()

    const { pageNum, searchTerm, params, lastPage, lastElement, rerender, isFetching, data } = useSelector(state => state.scroll)
    const dispatch = useDispatch();
    const { id } = useParams();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                dispatch(setPageNum());
            }
        })
    );

    const refetch = async () => {
        if (path) {
            await fetchDataByPathMutation.mutateAsync({
                path, pageNum, searchTerm, params: { ...params, folder_id: id ?? "" }
            }, {
                onSuccess: (response) => {
                    dispatch(setLastPage(response?.pagination?.total_pages));
                    dispatch(setData(response.data))
                    dispatch(setRerender(false))
                }
            })
        }
    }

    useEffect(() => {
        if (!isEmpty(path)) {
            dispatch(setData([]))
            dispatch(galleryRefetch());
        }
        return () => dispatch(resetScrollStates())
    }, [path]);

    useEffect(() => {
        if ((pageNum <= lastPage || lastPage == null)) {
            refetch();
        }
    }, [pageNum]);

    useEffect(() => {
        if (rerender) {
            refetch();
        }
    }, [rerender]);

    useEffect(() => {
        dispatch(galleryRefetch())
    }, [searchTerm, params]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    useEffect(() => {
        dispatch(setIsFetching(fetchDataByPathMutation.isLoading))
    }, [fetchDataByPathMutation.isLoading]);

    return (
        <>
            {
                isFetching && data.length === 0 ?
                    <ProgressSpinner
                        className='h-[50px] w-[50px] flex justify-center items-center'
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="30s" />
                    :
                    <></>
            }
            {children}
        </>
    )
}
