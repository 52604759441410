import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from "react-bootstrap";
import { Dialog } from "primereact/dialog";

import { useOrderGroupMutation } from "../../../../queries";
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { onDragEnd } from "../../../../config/global";

const droppableColumns = {
    Columns: {
        name: "All Groups",
        items: [],
    }
};

function GroupsOrderingDialog({ groups }) {
    const dispatch = useDispatch();
    const { openDialog } = useSelector((state) => state.global);
    const orderGroup = useOrderGroupMutation();

    droppableColumns.Columns.items = Object.values(groups);
    const [columns, setColumns] = useState(droppableColumns);

    const order = async () => {
        let groupIds = Object.entries(columns.Columns.items).map(item => item[1].group_id);

        await orderGroup.mutateAsync({ "ids": groupIds })
    }

    const closeDialog = () => {
        dispatch(toggleDialog("orderDialog"))
    }

    return (
        <Dialog visible={openDialog.orderDialog}
            style={{ width: '35vw' }}
            header="Update Group Order"
            modal className="p-fluid"
            breakpoints={{ '960px': '95vw' }}
            onHide={closeDialog}>

            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)} className="w-100" >
                {
                    Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div className="d-flex flex-column align-items-center" key={columnId}>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {
                                        (provided, snapshot) => {
                                            return (
                                                <div {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="p-3 droppable_container "
                                                >
                                                    {
                                                        column.items.map((item, index) => {
                                                            return (
                                                                <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index} >
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={{
                                                                                    backgroundColor: '#f5f5f9',
                                                                                    userSelect: "none",
                                                                                    ...provided.draggableProps.style,
                                                                                }}
                                                                                className='d-flex flex-row flex-nowrap align-items-center p-3 pe-3  px-0 px-sm-2 my-2 rounded-2 border-1'>
                                                                                <i className="fas fa-network-wired mx-3"></i>
                                                                                <span>{item.name}</span>

                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                </Droppable>
                            </div>
                        );
                    })}
            </DragDropContext>

            <div className="col-12 text-center mt-5 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <button type="reset"
                    className="btn btn-label-secondary me-3 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={orderGroup?.isLoading}
                    onClick={closeDialog}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary me-2" disabled={orderGroup?.isLoading} onClick={order}>
                    {orderGroup?.isLoading ?
                        <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        :
                        "Order"
                    }
                </button>
            </div>
        </Dialog>
    )
}

export default GroupsOrderingDialog