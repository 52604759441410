import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { Card } from 'primereact/card';

import MediaSettingPopup from './MediaSettingPopup';
import MediaDuration from './components/MediaDuration';
import MediaTitle from './components/MediaTitle';
import MediaThumb from './components/MediaThumb';

import { useDeleteCampaignContent, useUpdateInteractiveMedia } from '../../../queries';
import { setDeletedItemId, toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { setRssSettings, setSelectedRss } from '../../../redux/Slices/Apps/appsSlice';
import AddFeedDialog from '../../Apps/CustomRss/AddFeedDialog';
import { handleRssSetting } from '../../../config/global';

function CampaignCard({ item }) {
    const { layer, campaignDetails, deletedItemId, openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const updateInteractiveMedia = useUpdateInteractiveMedia()
    const deleteCampaignContent = useDeleteCampaignContent()
    //Pop up controllers  
    const [mediaInfoPopup, setMediaInfoPopup] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({});

    const changeThumb = async (e) => {
        setSelectedMedia(item)
        var formData = new FormData();
        formData.append("thumb", e.target.files[0]);
        formData.append("file_name", item.file_name.slice(0, 20));

        await updateInteractiveMedia.mutateAsync({
            data: formData,
            id: item.id
        }, {
            onSuccess: () => setSelectedMedia({})
        })
    }

    // Delete  Handler
    const removeFromCampaign = async () => {
        dispatch(setDeletedItemId(item.id))
        await deleteCampaignContent.mutateAsync({
            id: item.campaign_playlist_media_id,
            campaign_id: item.campaign_id
        })
    }

    const settingPopupHandler = (data) => {
        let _settings = handleRssSetting(data?.rss_settings, data?.title);
        dispatch(setRssSettings(_settings))
        setSelectedMedia(data)
        dispatch(toggleDialog("mediaSettingPopup"))
    }

    const addFeedHandler = (item) => {
        dispatch(setSelectedRss(item))
        dispatch(toggleDialog("addCustomFeed"))
    }

    return (
        <>
            <div className='w-100 d-flex align-items-center'>
                <Card className='w-100 d-flex row playlist_card'>
                    <div className="d-flex align-items-center  flex-nowrap justify-content-between">
                        {/* ------------Media Thumb and Name-------- */}
                        {
                            !(_.isNull(item.playlist_id)) ?
                                <div onClick={() => navigate(`/playlist/${item.id}/show`)} className=' cursor-pointer col-1 me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                                    <i className="fas fa-list-ul fs-4  text-white"></i>
                                </div>
                                :
                                !(_.isNull(item.layout_id)) ?
                                    <div onClick={() => navigate(`/apps/layouts/${item.id}/show`)} className=' cursor-pointer col-1 me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                                        <i className="bx bx-layout fs-2  text-white "></i>
                                    </div>
                                    :
                                    <>
                                        {
                                            ["HDMI", "CHANNEL", "RSS", "RSS_CUSTOM", "MULTI_3D"].includes(item.type) ?
                                                <div className=' cursor-pointer col-1 me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                                                    <span className="text-nowrap fs-6 text-white text-center">{(item.type).replace(/_/g, " ")}</span>
                                                </div>
                                                :
                                                <div className='col-2 col-md-1 me-2 d-flex align-items-center justify-content-center position-relative'>
                                                    {
                                                        updateInteractiveMedia.isLoading && item.id === selectedMedia?.id ?
                                                            <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
                                                            :
                                                            <MediaThumb item={item} />
                                                    }
                                                </div>
                                        }
                                    </>
                        }

                        {
                            campaignDetails.is_interactive ?
                                <MediaTitle item={item} />
                                :
                                <div className='col-5 col-md-3 text-truncate pe-3 ps-2'>
                                    {
                                        !(_.isNull(item.playlist_id)) ? item.playlist_title : !(_.isNull(item.layout_id)) ? item.layout_title : item.type == "RSS" || item.type == "RSS_CUSTOM" ? item.file_name?.split(".")[0] : item.file_name
                                    }
                                </div>

                        }

                        {/* -------Duration------- */}
                        <div className='col-2 d-none d-md-block'>
                            <MediaDuration item={item} />
                        </div>

                        <div className='cpl-3 col-sm-3 col-md-5 d-flex align-items-center justify-content-end  pe-sm-3'>
                            {
                                campaignDetails.is_interactive && layer == "MAIN" &&
                                <>
                                    <Tooltip target={`.edit-thumb-${item.id}`} content="Upload Thumb" position='bottom' className="fs-8" />
                                    <div className="button-wrapper" onClick={() => setSelectedMedia(item)}>
                                        <label htmlFor={`${item.id}-upload`} className={`edit-thumb-${item.id} btn p-0 me-2 text-light`} tabIndex={0}>
                                            <i className="bx bx-image-add"></i>
                                            <input onChange={changeThumb} type="file" id={`${item.id}-upload`} className="account-file-input" hidden accept="image/png, image/jpeg" />
                                        </label>
                                    </div>
                                </>
                            }
                            {
                                item.hasOwnProperty("path") && !JSON.parse(sessionStorage.getItem('is_default_prayer'))
                                    ?
                                    <>
                                        <Tooltip target={`.edit-button-${item.id}`} content="Edit" position='bottom' className="fs-8" />
                                        <button className={`btn bg-transparent p-0 mx-1 edit-button-${item.id}  pt-1 d-block d-md-none`} >
                                            <i onClick={() => setMediaInfoPopup(true)} className="fas fa-pencil text-light"></i>
                                        </button>
                                        <Tooltip target={`.setting-button-${item.id}`} content="Setting" position='bottom' className="fs-8" />
                                        <button className={`btn bg-transparent p-0 mx-1 setting-button-${item.id} pt-1`}  >
                                            <i onClick={() => settingPopupHandler(item)} className="fas fa-cog text-light"></i>
                                        </button>
                                        {item.type == "RSS_CUSTOM" ?
                                            <>
                                                {/* Add Feeds RSS */}
                                                <Tooltip target={`.setting-button-${item.id}`} showDelay={100} className="fs-8" content="Add Feed Content" position='bottom' />
                                                <button className={`btn bg-transparent p-0 mx-1 setting-button-${item.id} pt-1`} onClick={() => addFeedHandler(item)} >
                                                    <i class='bx bx-add-to-queue text-light'></i>
                                                </button>
                                            </>
                                            : ""}
                                    </>
                                    :
                                    ""
                            }
                            {
                                !JSON.parse(sessionStorage.getItem('is_default_prayer')) &&
                                <>
                                    <Tooltip target={`.delete-button-${item.id}`} content="Delete" position='bottom' className="fs-8" />
                                    {
                                        deletedItemId === item.id && deleteCampaignContent.isLoading ?
                                            <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="3" fill="var(--surface-ground)" className='mx-1' animationDuration="2s" />
                                            :
                                            <button className={`btn bg-transparent p-0 mx-1 delete-button-${item.id}`} onClick={removeFromCampaign} >
                                                <i className="bx bx-trash  text-light"></i>
                                            </button>
                                    }

                                    <i className="fas fa-bars text-light mx-2"></i>
                                </>
                            }
                        </div>
                    </div>
                </Card>
            </div >
            {
                openDialog.mediaSettingPopup && selectedMedia?.campaign_playlist_media_id == item?.campaign_playlist_media_id ?
                    <MediaSettingPopup removeMedia={removeFromCampaign} item={selectedMedia} setSelectedRow={setSelectedMedia} /> : <></>
            }
            {openDialog.addCustomFeed ? <AddFeedDialog /> : <></>}

        </>
    )
}

export default CampaignCard