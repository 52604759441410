import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

import { useAddCampaignContent, useAddPlaylistContent } from '../../../queries';
import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';
import SearchInput from './SearchInput';
import LazyImage from '../ShowMedia/LazyImage'

function LinksList() {
    const { assign, layer, campaignDetails } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);
    const {
        ids, setIds, data,setData,
        resetScrollStates, pageNum,
        isFetching, lastPage,
        setLastElement, setPath,
        setSearchTerm, searchTerm
    } = useScrollLoadingContext();

    const [localSearchTerm, setLocalSearchTerm] = useState("")
    const addCampaignContent = useAddCampaignContent()
    const addPlaylistContent = useAddPlaylistContent()
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        setData([])
        const isInterActive = campaignDetails.is_interactive
        setPath(`/media?section=links&layer=${layer}${!isEmpty(isInterActive) ? `&is_interactive=${Number(isInterActive)}` : ""}`)
        return () => resetScrollStates()
    }, [])

    useEffect(() => {
        if (ids.length == 0) {
            dispatch(setDisableBtn(true))
        } else {
            dispatch(setDisableBtn(false))
        }
    }, [ids])

    useEffect(() => {
        if (assign) {
            dispatch(setDisableBtn(true))
            assignHandler()
        }
    }, [assign])

    const assignHandler = async () => {
        if (window.location.pathname.includes("campaign")) {
            await addCampaignContent.mutateAsync({
                "ids_list": ids,
                "campaign_id": id,
                "layer": layer,
                "type": "link",
            })
        }
        else if (window.location.pathname.includes("layout")) {
            await addCampaignContent.mutateAsync({
                "ids_list": ids,
                "campaign_id": screenDetails.id,
                "layer": layer,
                "type": "link",
            })
        }
        else {
            await addPlaylistContent.mutateAsync({
                "list_media_ids": ids,
                "playlist_id": id,
                "layer": layer,
            })
        }
    }

    const onMediaChange = (e) => {
        let selectedMedia = [...ids];

        if (e.checked)
            selectedMedia.push(e.value);
        else
            selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

        setIds(selectedMedia);
    }

    const resetSearch = () => {
        setLocalSearchTerm("")
        setSearchTerm("")
    }

    const getData = () => {
        setSearchTerm(localSearchTerm)
    }

    return (
        <>
            <SearchInput
                setSearchTerm={setLocalSearchTerm}
                searchTerm={localSearchTerm}
                getData={getData}
                isLoading={isFetching}
                resetSearch={resetSearch}
                isDisabled={data?.length === 0}
            />
            {
                data.length === 0 && !isFetching ?
                    isEmpty(searchTerm) ?
                        <h5 className="py-5 text-center w-100">There are no compatible links with this layer that have been added yet.</h5>
                        :
                        <h5 className="py-5 text-center w-100">No results found!</h5>
                    :
                    <>

                        <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                            {/* <div className="row my-3">
                                <div className="col-2"></div>
                                <div className="col-6 col-md-4 px-1 text-light fs-6"> name</div>
                                <div className="col-3 col-md-4 px-1 text-light fs-6 d-none d-sm-block" >type</div>
                            </div> */}
                            <div className="d-flex flex-wrap mb-4">
                                {
                                    [...data].reverse().map((item, index) => {
                                        let imgEl = null;
                                        if (item.type != "HDMI") {
                                            imgEl = <LazyImage media={item} popupType={"media_preview"} index={index} styleClass={"me-2 image_container  w-75"} />;
                                        } else {
                                            imgEl = <div className={`p-4 d-flex bg-secondary align-items-center justify-content-center playlist_icon_container w-75`}>
                                                <span className=" fs-6 text-white"> HDMI</span>
                                            </div>
                                        }
                                        return index === data?.length - 1 && pageNum <= lastPage ?
                                            <div className=" w-100 d-flex align-items-center justify-content-between my-1 pb-2" ref={setLastElement} key={`${item.type} - ${index}`} >
                                                <label htmlFor={item.id} className="col-11 d-flex ">
                                                    <div className="col-2 mx-2 "> {imgEl}  </div>
                                                    <div className="col-9 col-md-3 col-sm-6 mx-1 fs-6 d-flex align-items-center "> {item.file_name} </div>
                                                    <div className="col-4 d-flex align-items-center justify-content-center text-capitalize d-none d-sm-block fs-6 text-light fs-6">
                                                        {item.type}
                                                    </div>
                                                </label>
                                                <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                            </div>
                                            :
                                            <div className={`w-100 flex items-center justify-content-between my-1 me-3 pb-2 ${index + 1 === data.length ? "" : "border-bottom"} `} key={`${item.type} - ${index}`} >
                                                <label htmlFor={item.id} className="col-11 d-flex ">
                                                    <div className={`col-2 mx-2 `}> {imgEl}</div>
                                                    <div className="col-9 col-sm-6 col-md-3   mx-1 fs-6 d-flex align-items-center text-truncate"> {item.file_name} </div>
                                                    <div className="col-3 d-flex align-items-center justify-content-center d-none d-sm-flex text-capitalize fs-6 text-light fs-6 fst-italic  ">
                                                        <span>{item.type == "HDMI" ? item.type : item?.type?.toLowerCase()}</span>
                                                    </div>
                                                </label>
                                                <div className="col-2 col-sm-1 me-3">
                                                    <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                </div>
                                            </div>
                                    })
                                }
                                {
                                    isFetching &&
                                    <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    );
}

export default LinksList