import React from 'react';

import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';

function MediaStack({ mediaArray }) {
    return (
        <AvatarGroup className="mb-3">
            {
                mediaArray?.length > 5 ?
                    <>
                        <Avatar image={mediaArray[0].thumb} size="large" className='rounded-2 ' />
                        <Avatar image={mediaArray[1].thumb} size="large" className='rounded-2 ' />
                        <Avatar image={mediaArray[2].thumb} size="large" className='rounded-2 ' />
                        <Avatar image={mediaArray[3].thumb} size="large" className='rounded-2 ' />
                        <Avatar image={mediaArray[4].thumb} size="large" className='rounded-2 ' />
                        <Avatar label={`+${mediaArray.length - 5}`} size="large" className=' bg-primary fs-6 p-1 text-white ' />
                    </>
                    :
                    mediaArray?.map((item) => <Avatar key={item.id + `${Math.random()}`} image={item.thumb} size="large" className=' rounded-2' />)
            }
        </AvatarGroup>
    )
}

export default MediaStack