import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { STREAMS_TABLE_CONFIG } from "../Constants/datatables";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import { toggleDialog } from "../redux/Slices/Global/globalSlice";
import axiosInstance from "../config/Axios";

//--------------------------Create  Broadcast--------------------------
const createBroadcast = async (payload) => {
    const { data } = await axiosInstance.post(`/streams/create`, payload);

    return data.data;
}

export const useCreateBroadcastMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(createBroadcast, {
        onSuccess: async (data) => {
            setLazyParams(prev => ({ ...prev, ...STREAMS_TABLE_CONFIG }))
            dispatch(toggleDialog("createBroadcastDialog"))
            showToast('success', "Broadcast Creation", "Broadcast created successfully!");
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//--------------------------Get All devices--------------------------
const getDevices = async () => {
    const { data } = await axiosInstance.get(`/company/devices/all`);
    return data.data;
}

export const useGetDevices = () => {
    const { showToast } = useCustomToast();

    let { isLoading: devicesLoading, data, isFetching, refetch: refetchDevices } = useQuery(
        'getDevices', getDevices,
        {
            onError: (error) => showToast("error", "Fetching Date", error.response?.data?.message)
        }
    );

    return { devicesLoading, data, refetchDevices, isFetching };
}

//--------------------------Stop Processing  Broadcast--------------------------
const stopBroadcast = async (payload) => {
    const { data } = await axiosInstance.get(`/streams/${payload.id}/stop`);
    return data.data;
}

export const useStopBroadcastMutation = () => {
    const { showToast } = useCustomToast();
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()

    return useMutation(stopBroadcast, {
        onSuccess: async (data) => {
            setLazyParams(prev => ({ ...prev, ...STREAMS_TABLE_CONFIG }))
            showToast("success", "Stream", data?.msg)
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//--------------------------Get Broadcast Info--------------------------
const getBroadcastInfo = async ({ queryKey }) => {
    const [, id] = queryKey;
    const { data } = await axiosInstance.get(`/streams/${id}/devices`);
    return data.data;
}

export const useGetBroadcastInfo = (id) => {
    const { showToast } = useCustomToast();

    let { isLoading , data, refetch  } = useQuery(
        ['getBroadcastInfo', id], getBroadcastInfo,
        {
            onError: (error) => showToast("error", "Fetching Date", error.response?.data?.message)
        }
    );

    return { isLoading, data, refetch };
}
