import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { setAssign, setDisableBtn, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";

//--------------------------Get Playlist By ID--------------------------
const addPlaylistContent = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/media/assign`, payload);
    return data.data;
}

export const useAddPlaylistContent = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { layer } = useSelector((state) => state.global);

    return useMutation(addPlaylistContent, {
        onSuccess: async (result, variables) => {
            dispatch(setDisableBtn(false));
            dispatch(setAssign(false))
            let rejectedSatellite = result?.rejected?.satellite;
            let rejectedHDMI = result?.rejected?.hdmi;
            var errorsArr = [];

            if (!isEmpty(rejectedHDMI)) {
                let msg = `${rejectedHDMI.map((name) => name + ", ")}don't support HDMI!`;
                errorsArr.push({ severity: 'error', summary: 'Assign PlayList', detail: msg, life: 3000 });
            }

            if (!isEmpty(rejectedSatellite)) {
                let msg = `${rejectedSatellite.map((name) => '"' + name + '" ')} don't support  satellite!`;
                errorsArr.push({ severity: 'error', summary: 'Assign PlayList', detail: msg, life: 3000 });
            }

            if (!isEmpty(errorsArr)) {
                errorsArr?.map((error) => { showToast(error.severity, error.summary, error.detail) })
            } else {
                showToast('success', `Assign Media to playlist`, result?.msg);

                dispatch(toggleDialog("addContent"))

                queryClient.invalidateQueries(['getPlaylist', variables.playlist_id])
                queryClient.invalidateQueries(['getPlaylistLayerContent', variables.playlist_id, layer])
            }
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Get Playlist By ID--------------------------
const addPlaylistChannel = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/channel/assign`, payload);
    return data.data;
}

export const useAddPlaylistChannel = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { layer } = useSelector((state) => state.global);

    return useMutation(addPlaylistChannel, {
        onSuccess: async (result, variables) => {
            dispatch(setDisableBtn(false));
            dispatch(setAssign(false))
            showToast('success', `Assign Media to playlist`, result?.msg);

            dispatch(toggleDialog("addContent"))
            queryClient.invalidateQueries(['getPlaylist', variables.playlist_id])
            queryClient.invalidateQueries(['getPlaylistLayerContent', variables.playlist_id, layer])

        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
//--------------------------Order Playlist Content--------------------------
const orderPlaylistContent = async (payload) => {
    const { data } = await axiosInstance.put(`playlist/media/orders`, payload);
    return data.data;
}

export const useOrderPlaylistContent = () => {
    const playlistDetails = useSelector((state) => state.playlist.playlistDetails);
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(orderPlaylistContent, {
        onSuccess: async (variables) => {
            queryClient.invalidateQueries(['getPlaylist', playlistDetails.id])
            showToast('success', 'Campaign', "The campaign has been reordered successfully!");

        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update Playlist Content Setting--------------------------
const updatePlaylistContentSettings = async (payload) => {
    const { data } = await axiosInstance.post(`/playlist/media/setting`, payload);
    return data.data;
}

export const useUpdatePlaylistContentSettings = () => {
    const playlistDetails = useSelector((state) => state.playlist.playlistDetails);
    const { layer, openDialog } = useSelector((state) => state.global);
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(updatePlaylistContentSettings, {
        onSuccess: async (result) => {
            if (openDialog.mediaSettingPopup)
                dispatch(toggleDialog("mediaSettingPopup"))
            if (playlistDetails.id) {
                queryClient.invalidateQueries(['getPlaylist', playlistDetails.id])
                queryClient.invalidateQueries(['getPlaylistLayerContent', playlistDetails.id, layer])
            }
            showToast('success', "PlayList Setting", result?.msg);

        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Retrieve Playlist Content Duration--------------------------
const retrievePlaylistContentDuration = async (id) => {
    const { data } = await axiosInstance.put(`/playlist/media/${id}/duration`, {});
    return data.data;
}

export const useRetrievePlaylistContentDuration = () => {
    const playlistDetails = useSelector((state) => state.playlist.playlistDetails);
    const { layer } = useSelector((state) => state.global);
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(retrievePlaylistContentDuration, {
        onSuccess: async (result) => {
            if (playlistDetails.id) {
                queryClient.invalidateQueries(['getPlaylist', playlistDetails.id])
                queryClient.invalidateQueries(['getPlaylistLayerContent', playlistDetails.id, layer])
            }
            showToast('success', "Retrieve Media duration ", result?.msg);

        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
//--------------------------Delete Playlist Content--------------------------
const deletePlaylistContent = async (payload) => {
    const { data } = await axiosInstance.delete(`/playlist/${payload.playlist_id}/media/${payload.playlist_media_id}/delete`);
    return data.data;
}

export const useDeletePlaylistContent = () => {
    const playlistDetails = useSelector((state) => state.playlist.playlistDetails);
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();
    const layer = useSelector((state) => state.global.layer);

    return useMutation(deletePlaylistContent
        , {
            onSuccess: async (result) => {
                if (playlistDetails.id) {
                    queryClient.invalidateQueries(['getPlaylist', playlistDetails.id])
                    queryClient.invalidateQueries(['getPlaylistLayerContent', playlistDetails.id, layer])
                }
                showToast('success', 'Playlist Media', result?.msg);

            },
            onError: (error) => handleResErrors(error, showToast)
        })
}

