import { useEffect } from 'react'

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";

function FirebaseDeviceListener({ path, setRealtimeData }) {
    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        const unsubscribe = onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                setRealtimeData({
                    "id": data.current_campaign_id,
                    "name": data.current_campaign,
                    "is_playing": data.isPlaying,
                    "screen_angle": data.screenAngle,
                    "is_muted": data.isMuted,

                });
            }
        });
        return () => unsubscribe()
    }, []);

    return null
}

export default FirebaseDeviceListener


