import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'

import { useGetGroupedDevices } from '../../queries/devices';
import { toggleDialog } from '../../redux/Slices/Global/globalSlice';
import { useTitle } from '../../config/global';
import "./css/device.css";

import AssignCampaignToGroup from './Component/CampaignAssignment/AssignCampaignToGroup';
import GroupsOrderingDialog from './Component/Grouping/GroupsOrderingDialog';
import CreateGroup from './Component/Grouping/CreateGroup';
import Confirm from './Component/Confirm';
import Filter from './Component/Filter';
import Layout from '../Layout/Layout'
import Group from './Component/Grouping/Group'

import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { useGetCampaigns } from '../../queries';
import GroupAssignCampaignsDropDown from './Component/CampaignAssignment/GroupAssignCampaignsDropDown';

function Index() {
    useTitle("Devices")

    const { openDialog, disableBtn } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const { data: groups, isLoading } = useGetGroupedDevices();
    useGetCampaigns();

    const subNavbarProps = useMemo(() => {
        return {
            title: "Devices",
        };
    }, []);

    const subNavbarContent = useMemo(() => {
        return (
            <div className={`flex justify-end  w-100`}>
                <div className="flex-grow-1">
                    <Filter />
                </div>

                <Button label={"New Device"} icon={"bx bx-plus"} className={"p-button-sm px-4 ml-2 "}
                    disabled={disableBtn}
                    onClick={() => dispatch(toggleDialog("confirmDialog"))} />
            </div>
        );
    }, [disableBtn]);


    return (
        <Layout subNavbarContent={subNavbarContent} subNavbarProps={subNavbarProps}>
            <div className="d-flex flex-column align-items-start ">
                <div className="col-12 d-flex mb-2 align-items-start justify-content-between">
                    < Button label={"New Group"} icon={"bx bx-plus-circle"} className={"p-button-m   p-button-text  me-2 "}
                        disabled={disableBtn}
                        onClick={() => dispatch(toggleDialog("createGroupDialog"))} />
                    <div className="col-5 d-flex justify-content-end">
                        <Button label={"Reorder Groups"} icon={"bx bx-sort-alt-2"} className={"p-button-m    p-button-text  me-2 "}
                            disabled={disableBtn}
                            onClick={() => dispatch(toggleDialog("orderDialog"))} />
                        <Button label={"Assign Schedule"} icon={"bx bx-plus"} className={"p-button-m   p-button-text  me-2 "}
                            disabled={disableBtn}
                            onClick={() => dispatch(toggleDialog("assignDialog"))} />
                    </div>
                </div>
                <div className='scroll_div h-[81.5vh] w-100 overflow-y-auto overflow-x-hidden'>
                    {
                        isLoading ?
                            <div className='my-5 h-100 w-100 d-flex align-items-center justify-content-center'>
                                <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
                            </div>
                            :
                            _.isEmpty(groups) ?
                                <div className='card d-flex justify-content-center text-center w-100 my-5 p-5'>
                                    No results found!
                                </div>
                                :
                                <div className='col-12 '>
                                    <Group devicesGroup={groups} />
                                </div>
                    }
                </div>

            </div>
            {openDialog.confirmDialog ? <Confirm /> : <></>}
            {openDialog.createGroupDialog ? <CreateGroup /> : <></>}
            {openDialog.assignDialog ? <AssignCampaignToGroup /> : <></>}
            {openDialog.GroupAssignCampaignsDropDown ? <GroupAssignCampaignsDropDown /> : <></>}
            {openDialog.orderDialog ? <GroupsOrderingDialog groups={groups} /> : <></>}
        </Layout>
    )
}

export default Index