import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import _, { isEmpty } from "lodash";

import { setSelectedDevice } from "../redux/Slices/deviceSlice";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";

//--------------------------Check if campaign assigned to devices don't support satellite--------------------------
const checkSatelliteSupporting = async ({ queryKey }) => {
    const [, campaignID] = queryKey;
    const { data } = await axiosInstance.get(`/campaign/device/${campaignID}/check/satellite`);
    return data.data;
}

export const useCheckSatelliteSupporting = (campaignID) => {
    const { selectedDevice } = useSelector((state) => state.device);
    const sedNotification = sendGetChannelNotification()
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    const { isLoading, data, refetch } = useQuery(
        ['checkSatelliteSupporting', campaignID],
        checkSatelliteSupporting,
        {
            onSuccess: async (data) => {
                // total_devices is the total devices does not support satellite
                if (data?.total_devices === 0) {
                    let devices = data?.devices_support_satellite;
                    if (isEmpty(selectedDevice.id) && devices?.length) {

                        dispatch(setSelectedDevice({
                            serial_name: devices[0].serial_name,
                            name: devices[0].name,
                            id: devices?.[0].id,
                            online: 1,
                        }))
                    }
                }
            },
            onError: (error) => handleResErrors(error, showToast),
        }
    );

    let devicesList = data?.devices_support_satellite
    let totalDeviceWithConflict = data?.total_devices
    return { isLoading, devicesList, totalDeviceWithConflict, refetch };
}

//--------------------------Send notification to device to update Channel on firebase--------------------------
const sendGetChannelNotification = async (id) => {
    if (id) {
        const { data } = await axiosInstance.get(`/device/${id}/channels`);
        return data.data;
    }
}

export const useSendGetChannelNotification = () => {
    const { showToast } = useCustomToast();

    return useMutation(sendGetChannelNotification, {
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Send notification to device to update Channel on firebase--------------------------
const updateDeviceChannels = async (payload) => {
    if (payload) {
        const { data } = await axiosInstance.post(`/device/channels/${payload.id}`, { channels: payload?.data?.channels });
        return data.data;
    }
}

export const useUpdateDeviceChannels = () => {
    const { showToast } = useCustomToast();

    return useMutation(updateDeviceChannels, {
        onError: (error) => handleResErrors(error, showToast)
    })
}