import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration } from '../../config/global';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import UpdateCampaign from './Components/Update';
import AssignPopup from './Components/AssignToDevicePopup';
import Layout from "../Layout/Layout";
import Create from './Components/Create';

import { useDeleteRowMutation } from '../../queries/dataTable';
import { useDataTableContext } from '../Contexts/DataTableContext';
import { emptyCampaign } from '../../config/constants';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, setIsPrayerCampaign, toggleDeleteDialog, setStepsBack } from '../../redux/Slices/Global/globalSlice';
import { CAMPAIGNS_TABLE_CONFIG } from '../../Constants/datatables';
import { Button } from 'primereact/button';

const CampaignDatatable = () => {
    useTitle('Campaigns');

    const { openDialog, deleteDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { totalRecords, setReload, lazyParams, setLazyParams, data, onPage, dataHandler, setIsInteractiveCampaign, getDataTable } = useDataTableContext();

    const [campaignId, setCampaignId] = useState();
    const [campaign, setCampaign] = useState({ name: "" });

    const deleteCampaign = useDeleteRowMutation();

    useEffect(() => {
        setStepsBack(-1)
        setLazyParams(prev => ({
            ...prev, ...CAMPAIGNS_TABLE_CONFIG
        }))
    }, [])

    //Delete Campaign Handler
    const confirmDeleteUser = (data) => {
        setCampaign(data);
        dispatch(toggleDeleteDialog("campaign"))
    }

    const deleteCampaignHandler = async () => {
        await deleteCampaign.mutateAsync({
            id: campaign?.id,
            table: 'campaign'
        }, {
            onSuccess: () => {
                setReload(true)
                dispatch(toggleDeleteDialog("campaign"))
            }
        })
    }

    const assignHandler = (id) => {
        setCampaignId(id)
        dispatch(toggleDialog("assignDeviceGroup"))
    }

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Assign to device */}
                <Tooltip target={`.assign-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon assign-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip={`Assign ${rowData.name}  to  device`} onClick={() => { assignHandler(rowData.id) }} >
                    <i className='bx bx-up-arrow-alt'></i>
                </button>

                {/* Link to setup page */}
                <Tooltip target={`.setup-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon setup-button-${rowData.id}`} onClick={() => goToSetup(rowData.id)} data-pr-position="bottom" data-pr-tooltip="Setup"><i className='bx bx-slideshow' ></i></button>

                {/* Update campaign */}
                <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button className={`btn btn-sm btn-icon update-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={() => editCampaign(rowData)}><i className="pi pi-pencil"></i></button>

                {
                    rowData.name != "default" ?
                        <>
                            {/* Delete Campaign */}
                            <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} className="fs-8" />
                            <button className={`btn btn-sm btn-icon delete-record delete-button-${rowData.id}`} data-pr-position="bottom" data-pr-tooltip="Delete" onClick={() => confirmDeleteUser(rowData)} ><i className="bx bx-trash"></i></button>
                        </>
                        :
                        <>  </>
                }
            </div>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return <>
            {formatDuration(rowData.campaign_duration)}
        </>
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <span className={`text-center badge ${rowData.status == "DRAFT" ? "bg-label-warning" : "bg-label-success"} `}>{rowData.status.toLowerCase()}</span>
            </div>
        );
    }

    //Dialog Handler
    const createCampaign = () => {
        setCampaign(emptyCampaign);
        setIsInteractiveCampaign(0)
        dispatch(toggleDialog("createCampaign"));
    }

    const editCampaign = (data) => {
        setCampaign(data);
        dispatch(toggleDialog("updateCampaign"))
    }

    const goToSetup = (campaignID) => {
        sessionStorage.setItem('is_default_prayer', JSON.stringify(false));
        dispatch(setIsPrayerCampaign(false))
        navigate(`/campaigns/${campaignID}/setup`)
    }

    const subNavbarContent = useMemo(() => {
        return <div className="w-100 text-end"><Button className="p-2 p-button-sm" icon="fa-solid fa-bullhorn" label="Create Campaign" onClick={createCampaign} /></div>
    }, []);

    return (
        <Layout subNavbarContent={subNavbarContent}>
            <div className="card p-3 h-100">
                <div className='table-responsive text-nowrap rounded h-100'>
                    <DataTable
                        value={data?.campaign} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                        paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                        totalRecords={totalRecords} onPage={onPage}
                        onSort={dataHandler} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={dataHandler} filters={lazyParams.filters} loading={getDataTable?.isLoading} className="table"
                        scrollable scrollHeight="76vh"
                    >
                        <Column field="name" header="Name" style={{ width: "30%" }} className='text-center' sortable filter filterPlaceholder="Search by Name" />
                        <Column field="campaign_duration" style={{ width: "10%" }} className='text-center' header="Total Duration" body={durationBodyTemplate} />
                        <Column field="status" header="Status" style={{ width: "20%" }} className='text-center' sortable body={statusBodyTemplate} filter filterPlaceholder="Search by Status" />
                        <Column field="created_at" header="Created Date" style={{ width: "20%" }} className='text-center' sortable />
                        <Column body={actionBodyTemplate} exportable={false} style={{ width: "20%" }} className='text-center' ></Column>
                    </DataTable>
                </div >

                {openDialog.createCampaign ? <Create /> : <></>}
                {openDialog.updateCampaign ? <UpdateCampaign setReload={setReload} campaign={campaign} /> : <></>}
                {openDialog.assignDeviceGroup ? <AssignPopup campaignId={campaignId} /> : <></>}
                {
                    deleteDialog.campaign ?
                        <DeleteConfirmation
                            deleteHandler={deleteCampaignHandler}
                            itemName={campaign.name}
                            dialogKey="campaign"
                        />
                        : <></>
                }
            </div >
        </Layout >
    );
}

export default CampaignDatatable;
