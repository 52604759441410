import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Message } from 'primereact/message';

import UploadMedia from "./UploadMedia";
import Link from "./Link";

import { useGalleryContext } from "../../../Contexts/GalleryContext";
import { useScrollLoadingContext } from "../../../Contexts/ScrollLoadingContext";
import { useCheckMediaCapacity } from "../../../../queries";
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { galleryRefetch } from '../../../../redux/Slices/scrollSlice';


function UploadPopup({ content = "media", size = 0 }) {
  const { capacityAvailability } = useGalleryContext();
  const openDialog = useSelector((state) => state.global.openDialog);
  const fetchCapacity = useCheckMediaCapacity();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCapacity(size);
  }, [size])

  const onClose = () => {
    dispatch(toggleDialog("uploadMedia"))
    dispatch(galleryRefetch())
  }

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-end ">
          <Button label={`${content == "media" ? "Upload" : "New Link"}`} icon={`${content == "media" ? "fas fa-upload" : "fas fa-plus"}`} tooltip={`${content == "media" ? "Upload" : "Add Link"}`}
            tooltipOptions={{ position: "bottom" }} className={"p-button-sm px-5"}
            onClick={() => dispatch(toggleDialog("uploadMedia"))} />
        </div>
      </div>

      <Dialog
        header={content == "media" ? "Media Upload" : "Link Upload"}
        visible={openDialog.uploadMedia}
        onHide={onClose}
        breakpoints={{ "960px": "95vw" }}
        style={{ width: content == "media" ? "55vw" : "40vw" }}
      >
        {
          !capacityAvailability ?
            <div className="p-5 text-center ">
              <Message className="fw-bold fs-3 p-4 w-100" severity="warn" text="You've reached the maximum allowed media storage!" />
            </div>
            :
            content == "media" ?
              <UploadMedia />
              :
              <Link />
        }
      </Dialog>
    </>
  );
}

export default UploadPopup;
