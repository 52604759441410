import React, { useState, useEffect } from 'react'

import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { useFoldersQuery, useMoveMediaFolderMutation, useSubFoldersQuery } from '../../../../queries';
import { Button } from 'primereact/button';

function MoveMediaFolder({ data }) {
    const dispatch = useDispatch();
    const { openDialog } = useSelector((state) => state.global);

    const [subFolders, setSubFolders] = useState({});

    const [openIcon, setOpenIcon] = useState({});
    const [selectedFolder, setSelectedFolder] = useState("");

    const { mutate: moveItem, isLoading: isMoving } = useMoveMediaFolderMutation();
    const { data: folders = [], isLoading: isFetchingFolders } = useFoldersQuery();

    const renderHeader = (name) => {
        return (
            <div className='d-flex align-items-center' >
                <span className=' fs-6 me-2 text-gray d-flex align-items-center'>
                    Move  <i className="ms-1 fas fa-chevron-right"></i>
                </span>
                <h3 className='text-dark'>{name}</h3>
            </div>
        )
    }

    const FolderItem = ({ item }) => {
        const { data: fetchedSubFolders = [], refetch, isFetching } = useSubFoldersQuery(item.id, openIcon[item.id]);

        const showSubFolder = async (parentFolderId) => {
            setOpenIcon((prev) => ({ ...prev, [parentFolderId]: !openIcon[parentFolderId] }));

            if (!openIcon[parentFolderId] && !subFolders[parentFolderId]) {
                const result = await refetch();
                if (result.data) {
                    setSubFolders((prev) => ({
                        ...prev,
                        [parentFolderId]: result.data,
                    }));
                }
            }
        };

        return (
            <>
                <div
                    className={`col-12 ps-3 py-2 cursor-pointer folder_row ${selectedFolder === item.id ? "selected_folder" : ""
                        }`}
                    onClick={() => setSelectedFolder(item.id)}
                >
                    {openIcon[item.id] ? (
                        <>
                            <i
                                className="fas fa-minus-circle cursor-pointer fs-8 me-2"
                                data-id={item.id}
                                onClick={() => showSubFolder(item.id)}
                            ></i>
                            <i className="fas fa-folder-open text-primary me-1"></i>
                        </>
                    ) : (
                        <>
                            <i
                                className="fas fa-plus-circle cursor-pointer fs-8 me-2"
                                data-id={item.id}
                                onClick={() => showSubFolder(item.id)}
                            ></i>
                            <i className="fas fa-folder text-primary me-1"></i>
                        </>
                    )}
                    <span className="cursor-pointer">{item.folder_name}</span>
                </div>

                {isFetching ? (
                    <div className="ps-5 text-muted">Loading...</div>
                ) : (
                    fetchedSubFolders?.map((subItem, index) => (
                        <div className="col-12 ps-5" key={`${subItem.id}-${index}`}>
                            <FolderItem item={subItem} />
                        </div>
                    ))
                )}
            </>
        );
    };

    const moveMediaFolder = () => {
        moveItem({
            type: data.hasOwnProperty("path") ? "media" : "folder",
            id: data.id,
            destinationId: selectedFolder,
        });
    };

    const onClose = () => dispatch(toggleDialog("moveMediaFolder"))

    const Footer = () => {
        return (
            <div className="col-12 text-center d-flex  align-items-end  justify-content-center  ">
                <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={isMoving} onClick={onClose} />
                <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Move" loading={isMoving} onClick={moveMediaFolder} />
            </div>
        )
    }

    return (
        <>
            <Dialog header={renderHeader(data.file_name)}
                visible={openDialog.moveMediaFolder}
                onHide={onClose}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '50vw' }}

                footer={Footer}>
                {
                    isFetchingFolders ?
                        <div className="d-flex justify-content-center align-items-center my-5 py-5">
                            <Spinner as="span" animation="border" size="lg" role="status" className='mx-3 text-primary' aria-hidden="true" />
                        </div>
                        :
                        <div className='m-4 d-flex flex-column'>
                            <div className={`col-12 p-2 folder_row cursor-pointer ${selectedFolder == "root" && "selected_folder"}`} onClick={() => setSelectedFolder("root")}>
                                <i className="fas fa-folder text-primary me-1"></i>
                                <span className=' cursor-pointer' onClick={() => setSelectedFolder("root")}> root </span>
                            </div>
                            {
                                folders.map((item, index) => {
                                    return (
                                        <div className='d-flex flex-column align-items-center' key={`${item.id}-${index}`}>
                                            {
                                                data.id != item.id &&
                                                <FolderItem item={item} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

            </Dialog>
        </>
    )
}

export default MoveMediaFolder