import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import {
  DEFAULT_LINKS_FILTERS,
  DEFAULT_MEDIA_FILTERS,
  LINKS_TYPE_OPTIONS,
  MEDIA_TYPE_OPTIONS,
  SORT_OPTIONS,
} from "../../../config/media";
import { setData, setParams } from "../../../redux/Slices/scrollSlice";
import { useDispatch } from "react-redux";

const MediaFilter = ({ filters, setFilters, onApplyFilters }) => {
  const { control, handleSubmit, reset } = useForm({ defaultValues: filters });
  const dispatch = useDispatch();

  const onResetFilters = () => {
    dispatch(setData([]));
    dispatch(setParams({}));
    if (filters.section === "MEDIA") {
      setFilters(DEFAULT_MEDIA_FILTERS);
      reset(DEFAULT_MEDIA_FILTERS);
    } else {
      reset(DEFAULT_LINKS_FILTERS);
      setFilters(DEFAULT_LINKS_FILTERS);
    }
  };


  return (<>
    <h2 className="mt-5"><i class="fa-solid fa-filter fa-sm"></i> Filters</h2>
    <form onSubmit={handleSubmit(onApplyFilters)} className="d-flex flex-column py-4 px-2">
      {/* Search Input */}
      <div className="mb-4 ">
        <label htmlFor="search" className="">Search by Name</label>
        <span className="p-input-icon-right w-100">
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <InputText
                // {...field}
                id="search"
                value={filters.search}
                placeholder="Search by Name"
                className="p-inputtext-sm w-100"
                onChange={(e) => {
                  setFilters({ ...filters, search: e.target.value });
                }}
              />
            )}
          />
        </span>
      </div>

      {/* Sort by Time */}
      <div className="mb-4">
        <label htmlFor="sortOrder" className="">Sort by Time</label>
        <Controller
          name="sortOrder"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              id="sortOrder"
              className="p-inputtext-sm w-100"
              options={SORT_OPTIONS}
              placeholder="Sort by Created Time"
              onChange={(e) => {
                field.onChange(e);
                setFilters({ ...filters, sortOrder: e.value });
              }}
            />
          )}
        />
      </div>

      {/* Filter by Type */}
      <div className="mb-4">
        <label htmlFor="typeFilter" className="">Filter by Type</label>
        <Controller
          name="typeFilter"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              id="typeFilter"
              className="p-inputtext-sm w-100"
              options={filters.section === "MEDIA" ? MEDIA_TYPE_OPTIONS : LINKS_TYPE_OPTIONS}
              placeholder="Filter by Type"
              onChange={(e) => {
                field.onChange(e);
                setFilters({ ...filters, typeFilter: e.value });
              }}
            />
          )}
        />
      </div>

      {/* Size Filter (Only for MEDIA) */}
      {filters.section === "MEDIA" && (
        <>
          <div className="mb-4">
            <label htmlFor="minSize" className="">Min Size (KB)</label>
            <Controller
              name="minSize"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  id="minSize"
                  min={0}
                  className="p-inputtext-sm w-100"
                  type="number"
                  placeholder="in KB"
                  onChange={(e) => {
                    field.onChange(e);
                    setFilters({ ...filters, minSize: e.target.value });
                  }}
                />
              )}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="maxSize" className="">Max Size (KB)</label>
            <Controller
              name="maxSize"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  id="maxSize"
                  className="p-inputtext-sm w-100"
                  type="number"
                  min={0}
                  placeholder="in KB"
                  onChange={(e) => {
                    field.onChange(e);
                    setFilters({ ...filters, maxSize: e.target.value });
                  }}
                />
              )}
            />
          </div>
        </>
      )}
      {/* Filter by Duration */}
      <div className="mb-4">
        <label htmlFor="minDuration" className="">Min Duration (sec)</label>
        <Controller
          name="minDuration"
          control={control}
          render={({ field }) => (
            <InputText
              {...field}
              id="minDuration"
              className="p-inputtext-sm w-100"
              type="number"
              min={0}
              placeholder="in sec"
              onChange={(e) => {
                field.onChange(e);
                setFilters({ ...filters, minDuration: e.target.value });
              }}
            />
          )}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="maxDuration" className="">Max Duration (sec)</label>
        <Controller
          name="maxDuration"
          control={control}
          render={({ field }) => (
            <InputText
              {...field}
              id="maxDuration"
              className="p-inputtext-sm w-100"
              type="number"
              min={0}
              placeholder="in sec"
              onChange={(e) => {
                field.onChange(e);
                setFilters({ ...filters, maxDuration: e.target.value });
              }}
            />
          )}
        />
      </div>

      {/* Buttons */}
      <div className="d-flex justify-content-between gap-2 mt-10">
        <button type="button" className="w-50 p-button-sm btn btn-secondary fs-6 p-2" onClick={onResetFilters}>
          Reset
        </button>
        <button type="submit" className="w-50 p-button-sm btn btn-primary fs-6 p-2">
          Filter
        </button>
      </div>
    </form>

  </>
  );
};

export default MediaFilter;
