import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useAddCampaignContent } from '../../../queries';
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';
import { formatDuration } from '../../../config/global';

function LayoutList() {
    const addCampaignContent = useAddCampaignContent()
    const { layer, assign } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const { id } = useParams();

    const { ids, setIds, data, resetScrollStates, pageNum, isFetching, lastPage, setLastElement, setPath, searchTerm } = useScrollLoadingContext();

    useEffect(() => {
        setPath(`/layout/company`)
        return () => resetScrollStates()
    }, [])

    const onLayoutChange = (e) => {
        let selectedLayouts = [...ids];

        if (e.checked)
            selectedLayouts.push(e.value);
        else
            selectedLayouts.splice(selectedLayouts.indexOf(e.value), 1);

        setIds(selectedLayouts);
    }

    useEffect(() => {
        if (ids.length == 0) {
            dispatch(setDisableBtn(true))
        } else {
            dispatch(setDisableBtn(false))
        }
    }, [ids])

    const assignHandler = async () => {
        await addCampaignContent.mutateAsync({
            "ids_list": ids,
            "campaign_id": id,
            "layer": layer,
            "type": "layout",
        })
    }

    useEffect(() => {
        if (assign) {
            dispatch(setDisableBtn(true));
            assignHandler()
        }
    }, [assign])

    return (
        <>
            {
                data?.length === 0 && !isFetching ?
                    isEmpty(searchTerm) ?
                        <h5 className="py-5 text-center w-100">There aren't any layouts has been created yet!</h5>
                        :
                        <h5 className="py-5 text-center w-100">No results found!</h5>
                    :
                    <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                        <div className="d-flex my-3">
                            <div className="col-4 col-md-2"></div>
                            <div className="col-4 mx-1 text-light fs-6">Name</div>
                            <div className="col-4 mx-1 text-light fs-6 d-none d-lg-block">Total Duration</div>
                        </div>
                        <div className="popular-movies-api d-flex flex-wrap ">
                            {
                                data.map((item, index) => {
                                    return item.layout_duration > 0 ?
                                        index === data.length - 1 && pageNum <= lastPage ?
                                            (
                                                <div className="col-12 d-flex align-items-center justify-content-between " ref={setLastElement} key={`${item.type}-${index}`}>
                                                    <label htmlFor={item.id} className="col-10 col-sm-11 d-flex ">
                                                        <div className="col-4 col-sm-2 mx-2 ">
                                                            <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center"><i className="fas fa-table-layout fs-4  text-white"></i></div>
                                                        </div>
                                                        <div className="col-4 mx-1 fs-6 d-flex align-items-center text-truncate">
                                                            {item.title}
                                                        </div>
                                                        <div className="col-4  col-md-2 col-lg-2 fs-6 text-center d-none d-lg-block ">
                                                            {formatDuration(item.layout_duration)}
                                                        </div>
                                                    </label>
                                                    <div className="col-2 col-sm-1 pe-2">
                                                        <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onLayoutChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="col-12 d-flex align-items-center justify-content-between my-1 border-bottom pb-2" key={`${item.type}-${index}`} >
                                                    <label htmlFor={item.id} className="col-10 col-sm-11 d-flex align-items-center ">
                                                        <div className="col-4 col-sm-2 mx-2 ">
                                                            <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center playlist_icon_container">
                                                                <i className="fas fa-window-maximize  fs-4 text-white"></i>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mx-1 fs-6 d-flex align-items-center text-truncate">{item.title}</div>
                                                        <div className="col-4 col-md-2 col-lg-2 fs-6 text-center d-none d-lg-block ">
                                                            {formatDuration(item.layout_duration)}
                                                        </div>
                                                    </label>
                                                    <div className="col-2 col-sm-1 pe-2">
                                                        <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onLayoutChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                    </div>
                                                </div>
                                            )
                                        :
                                        <></>
                                })
                            }
                        </div>
                    </div>
            }
            {
                isFetching ?
                    <ProgressSpinner
                        className='h-[50px] w-[50px] flex justify-center items-center'
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="30s" />
                    :
                    <></>
            }
        </>
    );
}

export default LayoutList