import { useState } from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import LayoutPreview from '../LayoutPreview';
import CustomLayoutPopUp from './CustomLayoutPopUp';
import TemplateCustomizationPopUp from './TemplateCustomizationPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { setSelectedTemplate } from '../../../../../redux/Slices/layoutSlice';
import { useDeleteTemplate, useSelectTemplate } from '../../../../../queries/layouts';

const CustomLayouts = ({ templatesLoading }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { showToast } = useCustomToast();

    const { userTemplate } = useSelector((state) => state.layout);
    const { openDialog } = useSelector((state) => state.global);
    const [templateIdWithAction, setTemplateIdWithAction] = useState(null);

    const { mutate: selectTemplate, isLoading: isSelecting } = useSelectTemplate(navigate, showToast);
    const { mutate: deleteTemplate, isLoading: isDeleting } = useDeleteTemplate();

    const handleTemplateSelect = (template) => {
        setTemplateIdWithAction(template.id);
        selectTemplate(template.id, {
            onSettled: () => {
                setTemplateIdWithAction(null);
            },
        });
    };

    const handleTemplateCustomize = (template) => {
        dispatch(setSelectedTemplate(template));
        dispatch(toggleDialog("TemplateCustomizationPopUp"))
    }

    const handleTemplateDelete = (template) => {
        setTemplateIdWithAction(template.id);
        deleteTemplate(template.id, {
            onSettled: () => {
                setTemplateIdWithAction(null);
            },
        })
    }

    return (<>
        <Row className=" overflow-y-auto overflow-x-hidden g-3 pe-5 pl-5  mb-5 pb-5 ">
            <Col xs={12} sm={6} md={4} lg={3} onClick={() => {
                dispatch(toggleDialog("CustomLayoutPopUp"))
            }}>
                <Card className="h-[277px] border border-dashed border-gray-300 shadow-none hover:shadow-md transition-shadow">
                    <Card.Body className="flex flex-col items-center justify-center h-40 bg-gray-100 cursor-pointer" >
                        <i className="bx bx-plus bx-lg "></i>
                        <div className="text-sm font-medium text-gray-600">Add New Template</div>
                    </Card.Body>
                </Card>
            </Col>
            {userTemplate.map((template, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3}>
                    <Card className='hover:border-2 hover:border-primary-500 transition duration-200'>
                        <Card.Body>
                            <Card.Title><div className="text-[13px] text-center">{template.title}</div></Card.Title>
                            <Card.Text><div className="ml-[10px] mr-[10px]">
                                <LayoutPreview template={template} screens={template.screens_setting} onClick={() => {
                                    setSelectedTemplate(template)
                                    handleTemplateSelect(template)
                                }} />
                            </div></Card.Text>
                        </Card.Body>
                        <div className="w-100 pl-3 pb-3 flex gap-2">

                            <Button
                                loading={isSelecting && templateIdWithAction === template.id}
                                className="px-1 py-1 w-[40%] p-button-raised  p-button-secondary p-button-text p-button-sm hover:bg-purple-500"
                                label="Select" disabled={isSelecting && templateIdWithAction === template.id || templatesLoading} onClick={() => handleTemplateSelect(template)} />


                            <Button
                                className="px-1 py-1 w-[40%] p-button-raised p-button-secondary p-button-text p-button-sm  "
                                label="Customize" onClick={() => handleTemplateCustomize(template)} />


                            <Button
                                disabled={isDeleting && templateIdWithAction === template.id || templatesLoading}
                                loading={isDeleting && templateIdWithAction === template.id}
                                className="px-1 py-1 w-[20%] text-[15px] p-button-danger p-button-text  mr-2  "
                                icon="bx bxs-trash-alt" onClick={() => handleTemplateDelete(template)} />


                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
        {openDialog.CustomLayoutPopUp && <CustomLayoutPopUp />}
        {openDialog.TemplateCustomizationPopUp && <TemplateCustomizationPopUp />}
    </>)
};

export default CustomLayouts;