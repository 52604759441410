import AddRssDialog from './AddRssDialog';
import RssDataTable from './RssDataTable';
import Layout from '../../Layout/Layout';

import { useSelector, useDispatch } from 'react-redux';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

import "../css/apps.css";
import { useMemo } from 'react';
import { IoNewspaperOutline } from 'react-icons/io5';

function RssIndex() {
    const openDialog = useSelector((state) => state.global.openDialog);
    const dispatch = useDispatch()

    const { getDataTable } = useDataTableContext();

    const subNavbarContent = useMemo(() => {
        return (
            <div className='flex justify-end w-100'>
                <button className="btn btn-primary mx-1" disabled={getDataTable.isLoading}
                 onClick={() => dispatch(toggleDialog("rssFeedAddition"))} > Add New RSS</button>
            </div>)
    }, [getDataTable.isLoading]);

    const subNavbarProps = useMemo(() => {
        return {
            icon: <IoNewspaperOutline className='text-secondary  fs-2' /> || " ",
        };
    }, []);

    return (
        <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent}>
            <div className="card">

                <div className=" d-flex flex-column  m-4">
                    <RssDataTable />
                </div>
            </div>
            {openDialog.rssFeedAddition ? <AddRssDialog /> : <></>}
        </Layout >
    )
}

export default RssIndex