import { AiFillAudio, AiFillFile, AiFillFileImage, AiTwotoneVideoCamera } from "react-icons/ai";

export const LINK_DEFAULT_FILTERS = {
  section: "LINKS",
  sortOrder: "desc",
  resetTriggered: false,
};

export const MEDIA_DEFAULT_FILTERS = {
  section: "MEDIA",
  sortOrder: "desc",
  resetTriggered: false,
};

//Layout option
export const JUSTIFY_OPTIONS = [
  { icon: "pi pi-align-justify", value: "list" },
  { icon: "pi pi-th-large", value: "grid" },
];


export const EMPTY_FOLDER = {
  id: "",
  name: "",
}

export const SORT_OPTIONS = [
  { label: "Newest First", value: "desc" },
  { label: "Oldest First", value: "asc" },
];

export const MEDIA_TYPE_OPTIONS = [
  { label: "All", value: "" },
  { label: "Image", value: "IMAGE" },
  { label: "Audio", value: "AUDIO" },
  { label: "Video", value: "VIDEO" },
  { label: "3D File", value: "3D" },
  { label: "Other", value: "OTHER" },
];

export const LINKS_TYPE_OPTIONS = [
  { label: "All", value: "" },
  { label: "External Link", value: "EXTERNAL_LINK" },
  { label: "YouTube", value: "YOUTUBE" },
  { label: "Drive", value: "DRIVE" },
  { label: "Webview", value: "WEBVIEW" },
  { label: "Image", value: "IMAGE" },
  { label: "Audio", value: "AUDIO" },
  { label: "Video", value: "VIDEO" },
  { label: "3D File", value: "3D" },
];

export const DEFAULT_MEDIA_FILTERS = {
  section: "MEDIA",
  sortOrder: "desc",
  resetTriggered: true,
};

export const DEFAULT_LINKS_FILTERS = {
  section: "LINKS",
  sortOrder: "desc",
  resetTriggered: true,
};

export const getFileIcon = (fileType) => {
  switch (fileType) {
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/webp":
    case "image/jpg":
      return <AiFillFileImage />;
    case "video/mp4":
    case "video/webm":
    case "mov":
    case "avi":
      return <AiTwotoneVideoCamera />;
    case "audio/mp3":
    case "audio/mpeg":
      return <AiFillAudio />;
    default:
      return <AiFillFile />;
  }
};