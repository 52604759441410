import React from 'react'
import { useForm, Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../../config/global';
import { useProfileContext } from '../../../Contexts/ProfileContext';
import { useCustomToast } from '../../../Contexts/ToastContext';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { useScrollLoadingContext } from '../../../Contexts/ScrollLoadingContext';
import { Spinner } from 'react-bootstrap';
import { useDataTableContext } from '../../../Contexts/DataTableContext';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog, setRerender, setRerenderSubscription, toggleLoader } from '../../../../redux/Slices/Global/globalSlice';
import { useMutation } from "react-query";
import { createStripeToken, processPayment } from '../../../../queries';

function PaymentForm({ closeDialog }) {
  const dispatch = useDispatch();
  const disableBtn = useSelector((state) => state.global.disableBtn);
  const { paymentData, selectedPackage, selectedPricing, numberOfScreens, action } = useProfileContext();
  const { formState: { errors }, control, handleSubmit, reset } = useForm();
  const { setIds, setData, ids } = useScrollLoadingContext();
  const { setReload } = useDataTableContext();
  const { showToast } = useCustomToast();
   

  const stripeMutation = useMutation(createStripeToken, {
    onSuccess: (token) => {
      paymentMutation.mutate({ token, packageData: { selectedPackage, selectedPricing, numberOfScreens, ids, action } });
    },
    onError: (error) => {
      showToast("error", "Package Payment", error?.response?.data?.error?.message);
      dispatch(setDisableBtn(false));
    }
  });

  const paymentMutation = useMutation(processPayment, {
    onSuccess: (result) => {
      dispatch(setRerenderSubscription(true));
      dispatch(setDisableBtn(false));
      dispatch(toggleDialog("paymentDialog"));
      dispatch(toggleLoader("reload"));
      setReload(true);
      dispatch(setRerender(true));
      setIds([]);
      setData([]);
      showToast("success", "Package Payment", result?.data?.msg);
    },
    onError: (error) => {
      showToast("error", "Package Payment", "Payment failed. Please try again.");
      dispatch(setDisableBtn(false));
    }
  });

  const onSubmit = (data) => {
    dispatch(setDisableBtn(true));
    stripeMutation.mutate(data);
   };


  return (
    <>
      <div className='mt-3'>
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-items-center">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="fs-8 ">Cardholder Name</label>
              <span className="p-float-label">
                <Controller name="holder_name" control={control}
                  rules={{ required: 'Name is required!' }}
                  render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field}
                      className={`w-100 form-control ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                  )} />
              </span>
              {getFormErrorMessage('holder_name', errors)}
            </div>
          </div>

          <div className="col-12">
            <div className="form-group mb-2">
              <label className="fs-8 ">Card Number</label>
              <span className="p-float-label">
                <Controller name="card_number" control={control}
                  rules={{
                    required: 'Card number is required!',
                    minLength: {
                      value: 16,
                      message: 'Card number must be at least 16 digits!',
                    },
                    maxLength: {
                      value: 19,
                      message: 'Card number can be up to 16 digits!',
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      mask="9999 9999 9999 9999"
                      placeholder="**** **** **** ****"
                      slotChar="**** **** **** ****"
                      id={field.name}
                      {...field}
                      className={`w-100 form-control ${classNames({ 'p-invalid': fieldState.invalid })}`}></InputMask>
                  )}
                />
              </span>
              {getFormErrorMessage('card_number', errors)}
            </div>
          </div>

          <div className="col-12 d-flex">
            <div className="form-group mb-2 pe-1 col-6">
              <label className="fs-8 ">Expiry Date</label>
              <span className="p-float-label">
                <Controller name="expiry_date" control={control}
                  rules={{
                    required: 'Expiry date is required!',
                    pattern: {
                      value: /^(0[1-9]|1[0-2])\/\d{2}$/,
                      message: 'Please enter valid date!',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      mask="99/99" placeholder="99/99" slotChar="mm/yy"
                      id={field.name}
                      {...field}
                      className={`w-100 form-control ${classNames({ 'p-invalid': fieldState.invalid })}`}></InputMask>
                  )} />
              </span>
              {getFormErrorMessage('expiry_date', errors)}
            </div>

            <div className="form-group mb-2 col-6">
              <label className="fs-8">CVV</label>
              <span className="p-float-label">
                <Controller name="cvv" control={control}
                  rules={{
                    required: 'CVV number is required!',
                    minLength: {
                      value: 3,
                      message: 'CVV must be at least 3 digits!',
                    },
                    maxLength: {
                      value: 4,
                      message: 'CVV can be up to 4 digits!',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field}
                      className={`w-100 form-control ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                  )} />
              </span>
              {getFormErrorMessage('cvv', errors)}
            </div>
          </div>

          <div className="col-12 d-flex justify-content-center mt-5">
            <button disabled={disableBtn} type="reset" className="btn btn-label-secondary me-3 px-4 border " onClick={() => { closeDialog() }} data-bs-dismiss="modal" aria-label="Close">
              Cancel
            </button>
            <button type="submit" className="btn btn-primary px-4" disabled={disableBtn}>
              {
                disableBtn ?
                  <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                  :
                  <>
                    Pay  <b> $ {paymentData.total}</b>
                  </>
              }

            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default PaymentForm