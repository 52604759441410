export const TYPES_ALLOWED_STRETCH = ["CORNER", "VIDEO", "IMAGE"]


export const SORT_OPTIONS = [
    { label: "Newest First", value: "desc" },
    { label: "Oldest First", value: "asc" },
];

export const MEDIA_TYPE_OPTIONS = [
    { label: "All", value: "" },
    { label: "Image", value: "IMAGE" },
    { label: "Audio", value: "AUDIO" },
    { label: "Video", value: "VIDEO" },
    { label: "3D File", value: "3D" },
    { label: "Other", value: "OTHER" },
];

export const LINKS_TYPE_OPTIONS = [
    { label: "All", value: "" },
    { label: "External Link", value: "EXTERNAL_LINK" },
    { label: "YouTube", value: "YOUTUBE" },
    { label: "Drive", value: "DRIVE" },
    { label: "Webview", value: "WEBVIEW" },
];

export const DEFAULT_MEDIA_FILTERS = {
    section: "MEDIA",
    searchInput: "",
    typeFilter: "",
    minDuration: "",
    maxDuration: "",
    sortOrder: "desc",
    minSize: "",
    maxSize: "",
    folder_id: "",
    resetTriggered: true,
};

export const DEFAULT_LINKS_FILTERS = {
    section: "LINKS",
    searchInput: "",
    typeFilter: "",
    minDuration: "",
    maxDuration: "",
    sortOrder: "desc",
    minSize: "",
    maxSize: "",
    folder_id: "",
    resetTriggered: true,
};
