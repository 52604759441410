import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useEffect } from 'react';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useUpdateCampaignMutation } from '../../../queries';
import { campaignStatusOptions } from '../../../config/constants';
import { getFormErrorMessage } from '../../../config/global';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useCustomToast } from '../../Contexts/ToastContext';

const UpdateCampaign = ({ campaign }) => {
    const { control, formState: { errors }, handleSubmit, reset, } = useForm()
    const updateCampaign = useUpdateCampaignMutation();
    const openDialog = useSelector((state) => state.global.openDialog);
    const { setLazyParams } = useDataTableContext();
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();

    useEffect(() => {
        reset(campaign)
    }, [campaign])

    const onSubmit = async (data) => {
        await updateCampaign.mutateAsync({
            id: campaign.id,
            data: {
                "status": data.status,
                "name": data.name,
            },
        }, {
            onSuccess: () => {
                showToast('success', 'Campaign Updating', "The campaign updated successfully!");
                dispatch(toggleDialog("updateCampaign"))
                setLazyParams(prev => ({ ...prev }))
            }
        })
    };

    const validateNotEqual = (value) => {
        return value.toLowerCase() !== 'default' || `"default" is a reserved name!`
    };

    const onClose = () => dispatch(toggleDialog("updateCampaign"))

    return (
        <Dialog visible={openDialog.updateCampaign} breakpoints={{ '960px': '95vw' }} style={{ width: '600px' }} header="Update Campaign" modal
            className="p-fluid  " onHide={() => dispatch(toggleDialog("updateCampaign"))}>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column justify-content-center">
                <div className='px-5 my-5'>
                    {
                        campaign.name != "default" ?
                            <div className={`col-12 mb-3`}>
                                <div className="field">
                                    <label className="form-label"> Name  </label>
                                    <span className="p-float-label">
                                        <Controller name="name" control={control}
                                            rules={{ required: 'Name is required.', validate: validateNotEqual }}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field} autoFocus
                                                    className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                />
                                            )}
                                        />
                                    </span>
                                    {getFormErrorMessage('name', errors)}
                                </div>
                            </div>
                            : <></>
                    }

                    {/* status input */}
                    <div className={`col-12  mb-4`}>
                        <div className="field">
                            <label className="form-label" htmlFor="basic-default-company">  Status </label>
                            <span className="p-float-label">
                                <Controller name="status" control={control}
                                    rules={{ required: 'Status is required.' }}
                                    render={({ field, fieldState }) =>
                                        <Dropdown id={field.name} {...field}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={campaignStatusOptions}
                                            optionLabel="name"
                                            optionValue="code"
                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        />
                                    } />
                                {getFormErrorMessage('status', errors)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-center py-3 border-top ">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel"
                        disabled={updateCampaign.isLoading}
                        onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Update" loading={updateCampaign.isLoading} />
                </div>
            </form>
        </Dialog>
    )
}
export default UpdateCampaign;