import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { setDisableBtn, setIsPrayerCampaign, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { useDataTableContext } from "../pages/Contexts/DataTableContext";
import { setCompanyCampaigns } from "../redux/Slices/deviceSlice";
import { handleResErrors } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";
import { setIsPublished } from "../redux/Slices/layoutSlice";

//--------------------------Get Company Campaigns--------------------------
const getCampaigns = async () => {
    const { data } = await axiosInstance.get(`/company/campaigns`);
    return data.data;
}

export const useGetCampaigns = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    const { isLoading, data, error, isError, refetch } = useQuery(
        'getCampaigns',
        getCampaigns,
        {
            onSuccess: (data) => {
                dispatch(setCompanyCampaigns(data));
            },
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}

//--------------------------Create Campaign--------------------------
const createCampaign = async (payload) => {
    const { data } = await axiosInstance.post("/campaign/create", payload);

    return data.data;
}

export const useCreateCampaignMutation = () => {
    const { isInteractiveCampaign } = useDataTableContext();
    const { showToast } = useCustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return useMutation(createCampaign, {
        onSuccess: async (data) => {
            dispatch(setDisableBtn(false))
            dispatch(setIsPrayerCampaign(false))
            dispatch(toggleDialog("createCampaign"));
            showToast('success', 'Campaign Creation', "Campaign created successfully!");

            sessionStorage.setItem('is_default_prayer', JSON.stringify(false));

            if (isInteractiveCampaign)
                navigate(`/interactive/campaign/${data?.id}/setup`)
            else
                navigate(`/campaigns/${data?.id}/setup`)
        },
        onError: (error) => {
            dispatch(setDisableBtn(false));
            handleResErrors(error, showToast)
        }
    })
}

//--------------------------Update Campaign--------------------------
const updateCampaign = async (payload) => {
    const { data } = await axiosInstance.put(`/campaign/${payload.id}/update`, payload.data);
    return data.data;
}

export const useUpdateCampaignMutation = () => {
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    return useMutation(updateCampaign, {
        onSuccess: (updatedData) => {
            if (updatedData.status === "DRAFT") {
                dispatch(setIsPublished(false));

            }
        },
        onError: (error) => handleResErrors(error, showToast)

    })
}

//--------------------------Publish Campaign--------------------------
const publishCampaign = async (payload) => {
    const { data } = await axiosInstance.post(`/campaign/${payload.id}/publish`, {});
    return data.data;
}

export const usePublishCampaignMutation = () => {
    const { showToast } = useCustomToast();
    const queryClient = useQueryClient();

    return useMutation(publishCampaign, {
        onSuccess: async (res, variables) => {
             if (window.location.pathname.includes("campaign"))
                queryClient.invalidateQueries(['getCampaign', variables.id])
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Reset Campaign--------------------------
const resetCampaign = async (payload) => {
    const { data } = await axiosInstance.post(`/campaign/${payload.id}/reset`, {});

    return data.data;
}

export const useResetCampaignMutation = () => {
    const { showToast } = useCustomToast();
    const updateCampaign = useUpdateCampaignMutation();
    const queryClient = useQueryClient();
    const { campaignDetails, layer } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    let campaignId = window.location.pathname.includes("layout") ? screenDetails?.id : campaignDetails.id
    let campaignLayer = window.location.pathname.includes("layout") ? "MAIN" : layer

    return useMutation(resetCampaign, {
        onSuccess: async (res, variables) => {
            await updateCampaign.mutateAsync({
                id: variables.id,
                data: {
                    status: "PUBLISHED",
                    name: variables?.name,
                },
            }).then(() => {
                if (window.location.pathname.includes("layout")) {
                    queryClient.invalidateQueries(["screensData"], screenDetails?.pivot.layout_id)
                } else {
                    queryClient.invalidateQueries(['getCampaign', variables.id])
                }
                queryClient.invalidateQueries(['getCampaignLayerContent', campaignId, campaignLayer])
            }

            )
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Get Campaign By ID--------------------------
const getCampaign = async (id) => {
    const { data } = await axiosInstance.get(`/campaign/${id}`);
    return data.data;
}

export const useGetCampaign = (param) => {
    const { showToast } = useCustomToast();
    const { id, enabled } = param

    const { isLoading, data, error, isError, refetch } = useQuery(
        ['getCampaign', id],
        () => getCampaign(id),
        {
            enabled,    // Control whether the query runs automatically
            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}

//--------------------------Get Campaign Content by layer--------------------------
const getCampaignLayerContent = async ({ queryKey }) => {
    const [, id, layer] = queryKey
    const { data } = await axiosInstance.get(`/campaign/content/${id}/${layer}`);
    return data.data;

}

export const useGetCampaignLayerContent = (id, layer) => {
    const { showToast } = useCustomToast();
    const { isLoading, data, error, isError, refetch } = useQuery(
        ['getCampaignLayerContent', id, layer],
        getCampaignLayerContent,
        {
            enabled: !!id,
            cacheTime: 0,

            onError: (error) => {
                showToast("error", "Fetching Data", error.response?.data?.message);
            },
        }
    );

    return { isLoading, data, error, isError, refetch };
}
