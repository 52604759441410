import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react'

import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { prayersNames } from "../../../../config/global"

import { setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';
import { setCreateDialog } from '../../../../redux/Slices/Apps/appsSlice';
import { useCreatePrayerCampaign } from '../../../../queries/apps';
import { isEmpty } from 'lodash';

const options = [
    { name: prayersNames[0], code: 0 },
    { name: prayersNames[1], code: 1 },
    { name: prayersNames[2], code: 2 },
    { name: prayersNames[3], code: 3 },
    { name: prayersNames[4], code: 4 },
]

const CreatePrayerCampaign = ({ prayerCampaigns }) => {

    const createPrayerCampaign = useCreatePrayerCampaign();
    const { createDialog } = useSelector(state => state.apps);
    const dispatch = useDispatch()

    const [selectedPrayer, setSelectedPrayer] = useState("")
    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        let _campaigns = []
        if (!isEmpty(prayerCampaigns)) {
            prayerCampaigns?.map(campaign => {
                _campaigns = [..._campaigns, campaign.name]
                let filteredArray = options.filter(obj => !_campaigns.includes(obj.name));
                setFilteredOptions(filteredArray)
            })
        } else {
            setFilteredOptions(options)
        }
    }, [prayerCampaigns])

    const create = async () => {
        await createPrayerCampaign.mutateAsync({ "prayer_index": selectedPrayer })
        setDisableBtn(true)
    }

    const onClose = () => dispatch(setCreateDialog(false))

    return (
        <Dialog visible={createDialog}
            style={{ width: '500px' }}
            breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            header="Create Prayer Campaign"
            modal className="p-fluid"
            onHide={onClose}>

            <div className='my-5 px-4'>
                <label htmlFor="" className='mb-2'>Prayer Name</label>
                <Dropdown className='w-100'
                    options={filteredOptions}
                    onChange={(e) => { setSelectedPrayer(e.value) }}
                    value={selectedPrayer}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Select campaign" />
            </div>

            <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createPrayerCampaign.isLoading} onClick={onClose} />
                <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Create" loading={createPrayerCampaign.isLoading} onClick={create} />
            </div>
        </Dialog>
    )
}

export default CreatePrayerCampaign