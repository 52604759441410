import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useDispatch, useSelector } from 'react-redux';
import { setStepsBack, toggleDialog } from '../../redux/Slices/Global/globalSlice';
import { usePublishCampaignMutation, useResetCampaignMutation, useResetPlaylistMutation } from '../../queries';
import { useParams } from 'react-router-dom';

function ResetConfirmation() {
    const dispatch = useDispatch();
    const { openDialog, campaignDetails, stepsBack } = useSelector((state) => state.global);
    const { playlistDetails } = useSelector((state) => state.playlist);
    const { screenDetails } = useSelector((state) => state.layout);

    const publishCampaign = usePublishCampaignMutation()
    const resetCampaign = useResetCampaignMutation()
    const resetPlaylist = useResetPlaylistMutation()

    const { id } = useParams();

    const publish = async () => {
        if (window.location.pathname.includes("playlist")) {
            await resetPlaylist.mutateAsync({ id, name: playlistDetails?.name })
        } else {
            let isCampaign = window.location.pathname.includes("campaign");

            await publishCampaign.mutateAsync({
                id: isCampaign ? id : screenDetails?.id,
                name: isCampaign ? campaignDetails?.name : screenDetails?.name
            })
        }
        dispatch(toggleDialog("resetCampaign"))

    }
    const onClose = () => {
        dispatch(toggleDialog("resetCampaign"))
    }

    const Back = () => {
        dispatch(toggleDialog("resetCampaign"))
        window.history.go(stepsBack);
        dispatch(setStepsBack(-1))
    }

    const reset = async () => {
        if (window.location.pathname.includes("playlist")) {
            await resetPlaylist.mutateAsync({ id, name: playlistDetails?.name })

        } else if (window.location.pathname.includes("campaign")) {
            await resetCampaign.mutateAsync({ id, name: campaignDetails?.name })
        } else {
            await resetCampaign.mutateAsync({ id: screenDetails?.id, name: screenDetails?.name })
        }
        dispatch(toggleDialog("resetCampaign"))


    }

    const footer = (
        <>
            <div className="flex justify-center gap-2">
                <Button label="Leave" className="p-button-text p-button-secondary mr-0" onClick={Back} />

                <Button label="Discard"
                    loading={resetCampaign.isLoading}
                    className="p-button-text p-component p-confirm-dialog-accept p-button-danger mr-0"
                    disabled={resetCampaign.isLoading}
                    onClick={reset} />


                <Button label="Publish"
                    className="p-button-text mr-0"
                    loading={publishCampaign.isLoading}
                    onClick={publish}
                />

            </div>
        </>
    );

    return (
        <>
            <Dialog visible={openDialog.resetCampaign}
                style={{ width: '550px' }}
                breakpoints={{ '960px': '95vw' }}
                header="Confirm" modal
                footer={footer}
                onHide={onClose}>
                <div className="confirmation-content my-5">
                    <div className='text-center'>
                        <i className="pi pi-exclamation-triangle mr-3 d-block" style={{ fontSize: '2rem' }} />
                        <br />
                        <p className="mt-3 mb-1">
                            <strong>You have unpublished changes.</strong>
                        </p>
                        <p>Would you like to keep the changes, discard them, or publish now?</p>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ResetConfirmation