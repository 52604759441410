import React from "react";

import { Dialog } from "primereact/dialog";

import { formatSize, formatDuration } from "../../../config/global";
import { useGalleryContext } from "../../Contexts/GalleryContext";
import { Media } from "./Media";

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import FileDetails from "./FileDetails";

function PreviewMedia({ data }) {
  const { setSelectedItem } = useGalleryContext();
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.global.openDialog);

  const header = () => {
    return (
      <>
        <h1 className="m-0 fw-bold align-items-center">{data.file_name.replace(/_/g, ' ')}</h1>
      </>
    );
  };

  const closeDialog = () => {
    setSelectedItem("")
    dispatch(toggleDialog("previewMedia"))
  }
  return (
    <>
      <Dialog
        visible={openDialog.previewMedia}
        onHide={() => closeDialog()}
        breakpoints={{ "960px": "95vw" }}
        maximizable={true}
        style={{
          width: "70vw",
          height: "80vh",
        }}
        header={header(data.file_name)}
      >
        <div className="d-flex flex-column flex-md-row h-100 ">
          <div className="col-8 col-md-8 col-lg-8 p-3 bg-gray-100 h-100 flex justify-center items-center">
            <Media data={data} />
          </div>
          <div className="col-4 col-md-4 col-lg-4 p-3 position-relative">
            <FileDetails data={data} />
          </div>

        </div>
      </Dialog>
    </>
  );
}

export default PreviewMedia;
