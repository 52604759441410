import React, { useEffect } from 'react'
import {  useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';

import { useVerifyEmailMutation } from '../../../queries/auth';

function VerifyEmailProcess () {
    const { payload } = useParams();

    const verifyEmail = useVerifyEmailMutation();

    const VerifyEmailHandler = async () => {
        await verifyEmail.mutateAsync({ "payload": payload })
    }

    useEffect(() => {
        VerifyEmailHandler()
    }, [])

    return (
        <>
            <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
                <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
            </div>
        </>
    )
}

export default VerifyEmailProcess