import React, { useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";

import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { useTitle } from "../../../../config/global";

import Layout from '../../../Layout/Layout';
import LayoutInfo from "./Setup/LayoutInfo";
import ScreenInfo from "./Setup/ScreenInfo";
import EditLayoutPopUp from './Setup/EditLayoutPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { resetState, setIsPublished } from '../../../../redux/Slices/layoutSlice';
import { useFetchLayoutAspectRatio, useFetchLayoutData, useFetchScreensData, usePublishScreensMutation } from '../../../../queries/layouts';
import { useCustomToast } from '../../../Contexts/ToastContext';
import { setHideTabs } from '../../../../redux/Slices/Global/globalSlice';

const LayoutSetup = () => {
    useTitle("Layout Setup")
    const { id } = useParams();
    const dispatch = useDispatch();

    const { showToast } = useCustomToast();
    const { openDialog } = useSelector((state) => state.global);

    const { selectedLayout, isPublished, screen_is_muted } = useSelector((state) => state.layout);
    const { isLoading: layoutLoading } = useFetchLayoutData(id);
    const { isLoading: screenLoading, refetch } = useFetchScreensData(id);
    const { isLoading: isFetching } = useFetchLayoutAspectRatio(selectedLayout.aspect_ratio_id);
    const publishScreen = usePublishScreensMutation();

    const loading = isFetching || layoutLoading || screenLoading || publishScreen.isLoading

    useEffect(() => {
        dispatch(setHideTabs(false))
    }, [])

    const subNavbarProps = useMemo(() => {
        return {
            title: layoutLoading ? "Loading..." : selectedLayout?.title || " ",
            date: layoutLoading ? "" : selectedLayout?.created_at || ""
        };
    }, [selectedLayout, layoutLoading]);

    const publish = async () => {
        await publishScreen.mutateAsync(id,
            {
                onSuccess: () => {
                    dispatch(setIsPublished(true))
                    showToast('success', 'Publish Layout', 'All Layout\'s screens have been published successfully.');
                },
            }
        );
    }

    const subNavbarContent = useMemo(() => {
        return (
            <div className='flex justify-end w-100'>

                <Button label="Publish Layout"
                    loading={loading}
                    className={`fs_md_6 ${loading ? "pt-2 pb-1 me-2" : "py-2 me-2 "}`}
                    disabled={isPublished}
                    onClick={() => publish()}
                />
            </div>
        );
    }, [loading, isPublished]);


    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);
    return (
        <Layout subNavbarProps={subNavbarProps} subNavbarContent={subNavbarContent} padding={false}>
            {
                loading ?
                    <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    </div>
                    :
                    <div className="p-0 flex flex-col md:flex-row">
                        <div className="px-2 mt-4 w-full md:w-[70%] md:ml-5 md:mr-5  h-[85vh] ">
                            <ScreenInfo />
                        </div>
                        <div className="w-full md:w-[30%]">
                            <div className="bg-white border">
                                <LayoutInfo refetch={refetch} />
                            </div>
                        </div>
                    </div>
            }
            {openDialog.EditLayoutPopUp && <EditLayoutPopUp />}
        </Layout>
    );
};

export default LayoutSetup;
