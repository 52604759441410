import React, { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import moment from 'moment'
import _ from 'lodash';

import { getFormErrorMessage } from '../../../../config/global';
import { useCustomToast } from '../../../Contexts/ToastContext';
import ConflictWarningContent from "./ConflictWarningContent";

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

import { setSelectedCampaign, setShowAssignForm } from '../../../../redux/Slices/deviceSlice';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { days } from '../../../../Constants/scheduleCampaign';
import {
    useAssignScheduleCampaign,
    useIgnoreDevicesWithConflict,
    useReplaceScheduleCampaign,
    useScheduleCampaignDevice
} from '../../../../queries';


function AssignScheduleForm() {
    const dispatch = useDispatch();
    const assignScheduleCampaignDevice = useScheduleCampaignDevice()
    const assignScheduleCampaign = useAssignScheduleCampaign();
    const replaceScheduleCampaign = useReplaceScheduleCampaign();
    const ignoreDevicesWithConflict = useIgnoreDevicesWithConflict();

    const { register, control, formState: { errors }, handleSubmit, reset } = useForm({ "type": "event" })
    const { companyCampaigns, companyGroups, selectedCampaign, selectedGroup } = useSelector((state) => state.device);
    const { companyDetails } = useSelector((state) => state.global);

    const selectedDevice = useSelector((state) => state.device.selectedDevice);

    const { showToast } = useCustomToast();

    const [scheduleCampaign, setScheduleCampaign] = useState("once");
    const [scheduleOptions, setScheduleOptions] = useState([
        { name: 'Once', code: "once" },
        { name: 'Repeated', code: 'schedule' },
        { name: 'Publish Now', code: 'publish_now' },
    ]);
    const [selectedDays, setSelectedDays] = useState([]);


    let minDate = new Date();

    // Set form group dropdown input value  


    const onDayChange = (e) => {
        let _selectedDays = [...selectedDays];
        let exists = selectedDays.includes(e.target.value);

        if (e.target.checked) {
            if (!exists) {
                _selectedDays.push(e.target.value);
            }
        } else {
            let index = selectedDays.indexOf(e.target.value);
            if (_selectedDays.length != 1)
                _selectedDays.splice(index, 1);
        }

        setSelectedDays(_selectedDays);
    }

    const getStartEndTine = (startDateTime) => {
        // datetime input in local time
        var date = new Date(startDateTime);

        date.setSeconds(0);
        date.setMilliseconds(0);
        // Get start time in UTC  
        var startTime = date.toUTCString().slice(17, 25)
        var utcDate = formatDate(date);

        // Add campaign duration to starttime to get end time 
        date.setSeconds(date.getSeconds() + companyCampaigns[selectedCampaign]);

        // Get end time in UTC  
        var endTime = date.toUTCString().slice(17, 25);

        return { "start_time": startTime, "end_time": endTime, "date": utcDate }

    }

    function formatDate(date) {
        const utcYear = date.getUTCFullYear();
        const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(date.getUTCDate()).padStart(2, '0');

        return `${utcYear}-${utcMonth}-${utcDay}`;
    }

    const assign = (data) => {
        let formData = {
            "campaign_id": selectedCampaign,
            "is_repeated": scheduleCampaign === "schedule" ? 1 : 0,
            "device_id": selectedDevice?.id,
            "group_id": data?.group,
            "type": "SCHEDULE",
            "is_publish_now": scheduleCampaign === "publish_now" ? 1 : 0,
        }

        if (scheduleCampaign === "schedule") {
            formData = { ...formData, days: selectedDays, ...getStartEndTine(data.start_time) }
            delete formData.date
        } else {
            var date = moment(data.date).format('YYYY-MM-DD');
            var start_time = moment(data.start_time).format('HH:mm:ss');
            formData = { ...formData, ...getStartEndTine(date + " " + start_time) }

        }

        if (!_.isEmpty(formData.group_id)) {
            assignToGroup(formData)
        } else {
            assignToDevice(formData)
        }
    }

    const assignToDevice = async (formData) => {
        await assignScheduleCampaignDevice.mutateAsync(formData)
    }

    const assignToGroup = async (formData) => {
        await assignScheduleCampaign.mutateAsync(
            formData, {
            onSuccess: (responseData) => {
                if (responseData?.error === "conflict") {
                    let content = (
                        <ConflictWarningContent
                            responseData={responseData}
                            formData={formData}
                            assignToRestGroup={assignToRestGroup}
                            replaceOldScheduleCampaign={replaceOldScheduleCampaign}
                        />
                    )

                    showToast("warn", "Assign Campaign", responseData?.msg, content);
                }
            }
        })
    }

    const replaceOldScheduleCampaign = async (formData) => {
        formData.is_publish_now = 0
        await replaceScheduleCampaign.mutateAsync(formData)
    }

    const assignToRestGroup = async (formData) => {
        formData.is_publish_now = 0
        await ignoreDevicesWithConflict.mutateAsync(formData)
    }

    useEffect(() => {
        reset({
            "group": selectedGroup,
            "type": companyDetails?.package?.features?.advance_content_schedule ? "" : "event"
        })
    }, [reset])

    useEffect(() => {
        if (scheduleCampaign == "schedule") {
            setSelectedDays(["Sunday"])
        } else {
            setSelectedDays([])
        }
    }, [scheduleCampaign])

    const isGroup = useMemo(() => (_.isEmpty(selectedDevice)), [selectedDevice])
    const loading = useMemo(() => (assignScheduleCampaignDevice.isLoading || assignScheduleCampaign.isLoading),
        [
            assignScheduleCampaignDevice.isLoading,
            assignScheduleCampaign.isLoading,
        ])

    return (
        <>
            <form onSubmit={handleSubmit(assign)} className=" d-flex flex-column justify-content-center">
                {
                    // Group field displayed when assignment for group 
                    isGroup ? (
                        <div className='mt-3 px-4'>
                            <label htmlFor="" className='mb-2 form-label'>Groups</label>
                            <Controller name="group" control={control}
                                rules={{ required: "group is required!" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        id={field.name} {...field}
                                        value={field.value}
                                        options={companyGroups}
                                        onChange={(e) => { field.onChange(e.value); }}
                                        optionLabel="name"
                                        optionValue="id"
                                        inputRef={field.ref}
                                        disabled={typeof selectedGroup === "string"}
                                        placeholder="Select group"
                                    />
                                )} />
                            {getFormErrorMessage('group', errors)}
                        </div>
                    )
                        :
                        <></>
                }

                {/* Campaign field */}
                <div className='px-4 my-3'>
                    <label htmlFor="" className='mb-2 form-label'>Campaigns</label>
                    <Controller name="campaign" control={control}
                        rules={{ required: "campaign is required!" }}
                        render={({ field, fieldState }) => {
                            return (
                                <Dropdown
                                    id={field.name} {...field}
                                    value={field.value}
                                    options={companyCampaigns}
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        dispatch(setSelectedCampaign(e.value));
                                    }}
                                    optionLabel="name"
                                    optionValue="id"
                                    inputRef={field.ref}
                                    filter
                                    placeholder="Select campaign"
                                    className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                />
                            )
                        }
                        } />
                    {getFormErrorMessage('campaign', errors)}
                </div>

                {/* Type */}
                <div className='mb-3 px-4'>
                    <label htmlFor="" className='mb-2 form-label'>Schedule</label>
                    <Controller name="type" control={control}
                        rules={{ required: "type is required!" }}
                        render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name} {...field}
                                value={field.value}
                                options={scheduleOptions}
                                onChange={(e) => { field.onChange(e.value); setScheduleCampaign(e.value) }}
                                optionLabel="name"
                                optionValue="code"
                                inputRef={field.ref}
                                placeholder="Select type"
                                className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            />
                        )
                        } />
                    {getFormErrorMessage('type', errors)}
                </div>

                {
                    scheduleCampaign != "event" &&
                    <>
                        {/* Days input */}
                        {
                            scheduleCampaign === "schedule" &&
                            <div className="col-12  mb-3 px-4">
                                <div className="field days_list">
                                    <label htmlFor="" className='mb-2 form-label'>Days</label>
                                    <ul>
                                        {
                                            days.map((day) => {
                                                return (
                                                    <li key={`${day.key}`}>

                                                        <input {...register(`${day.name}`, {
                                                            required: {
                                                                value: days.length == 0
                                                            }
                                                        })} onChange={onDayChange} type="checkbox" id={`${day.name}`}
                                                            name="days" defaultValue={`${day.key}`}
                                                            checked={selectedDays.includes(day.key)} />
                                                        <label htmlFor={`${day.name}`} >{day.name}</label>

                                                        {errors.Sun && <p className='fv-plugins-message-container invalid-feedback'> Days field is required! </p>}

                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }

                        {/* Date input */}
                        {
                            scheduleCampaign === "once" &&
                            <div className="col-12  mb-3 px-4">
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Date </label>
                                    <span className="">
                                        <Controller name="date" control={control}
                                            rules={{ required: "Date is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Calendar
                                                    id={field.name} {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    minDate={minDate}
                                                    dateFormat="dd/mm/yy"
                                                    mask="99/99/9999"
                                                    inputRef={field.ref}
                                                    className={`${classNames({ ' p-invalid': fieldState.invalid })}`}
                                                />
                                            )
                                            } />
                                    </span>
                                    {getFormErrorMessage('date', errors)}
                                </div>
                            </div>
                        }
                        {
                            scheduleCampaign === "publish_now" &&
                            <div className="col-12 mb-3 px-4">
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Date </label>
                                    <input
                                        name="date"
                                        type="text"
                                        value={new Date().toLocaleDateString('en-GB')}
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                        }

                        {/* Time input */}
                        {scheduleCampaign != "publish_now" ?
                            <div className={`col-12 mb-3 px-4`}>
                                {/* start time */}
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Start time </label>
                                    <span className="p-float-label">
                                        <Controller name="start_time" control={control}
                                            rules={{ required: 'Start time is required.' }}
                                            render={({ field, fieldState }) => {
                                                return <Calendar
                                                    id={field.name} {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    timeOnly
                                                    inputRef={field.ref}
                                                    dateFormat="HH:MM"
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                />
                                            }
                                            } />

                                    </span>
                                    {getFormErrorMessage('start_time', errors)}
                                </div>
                            </div>
                            :
                            <div className={`col-12 mb-3 px-4`}>
                                {/* start time */}
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Start time </label>
                                    <input
                                        name="start_time"
                                        type="text"
                                        value={new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                        }
                    </>}

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    {
                        !isGroup ?
                            <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Back" disabled={loading}
                                onClick={() => dispatch(setShowAssignForm(false))} />
                            :
                            <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={loading}
                                onClick={() => dispatch(toggleDialog("assignDialog"))} />
                    }
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label="Assign" loading={loading} />
                </div>
            </form>
        </>
    )
}

export default AssignScheduleForm