import _, { isEmpty } from "lodash";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { setAssign, setDisableBtn, toggleDialog } from "../redux/Slices/Global/globalSlice";
import { handleResErrors, useInvalidateCampaignLayer } from "./utils";
import { useCustomToast } from "../pages/Contexts/ToastContext";
import axiosInstance from "../config/Axios";

//--------------------------Get Campaign By ID--------------------------
const addCampaignContent = async (payload) => {
    const { data } = await axiosInstance.post(`/campaign/content/assign`, payload);
    return data.data;
}

export const useAddCampaignContent = () => {
    const invalidateCampaignLayer = useInvalidateCampaignLayer();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    return useMutation(addCampaignContent, {
        onSuccess: async (result, variables) => {
            dispatch(setDisableBtn(false));
            dispatch(setAssign(false))

            let rejectedSatellite = result?.rejected?.satellite;
            let rejectedHDMI = result?.rejected?.hdmi;
            var errorsArr = [];

            if (!isEmpty(rejectedHDMI)) {
                let msg = `${rejectedHDMI.map((name) => name + ", ")}don't support HDMI!`;
                errorsArr.push({ severity: 'error', summary: 'Assign PlayList', detail: msg, life: 3000 });
            }

            if (!isEmpty(rejectedSatellite)) {
                let msg = `${rejectedSatellite.map((name) => '"' + name + '" ')} don't support  satellite!`;
                errorsArr.push({ severity: 'error', summary: 'Assign PlayList', detail: msg, life: 3000 });
            }

            if (!isEmpty(errorsArr)) {
                errorsArr.map((error) => { showToast(error.severity, error.summary, error.detail) })
            } else {
                showToast('success', 'Assign', result?.msg);
                dispatch(toggleDialog("addContent"))
                invalidateCampaignLayer(variables);
            }
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Order Campaign Content--------------------------
const orderCampaignContent = async (payload) => {
    const { data } = await axiosInstance.put(`campaign/content/order`, payload);
    return data.data;
}

export const useOrderCampaignContent = () => {
    const { showToast } = useCustomToast();
    const invalidateCampaignLayer = useInvalidateCampaignLayer();

    return useMutation(orderCampaignContent, {
        onSuccess: async (result, variables) => {
            invalidateCampaignLayer(variables);
            showToast('success', 'Campaign', "The campaign has been reordered successfully!");
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update Campaign's Media Thumb --------------------------
const updateInteractiveMedia = async (payload) => {
    const { data } = await axiosInstance.post(`/media/interactive/${payload.id}/update`, payload.data);
    return data.data;
}

export const useUpdateInteractiveMedia = () => {
    const { showToast } = useCustomToast();
    const invalidateCampaignLayer = useInvalidateCampaignLayer();

    return useMutation(updateInteractiveMedia, {
        onSuccess: async (result, variables) => {
            invalidateCampaignLayer(variables)
            showToast('success', 'Media  Editing', "Media updated successfully!");
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Delete Campaign Content --------------------------
const deleteCampaignContent = async (payload) => {
    const { data } = await axiosInstance.delete(`/campaign/content/${payload.id}/delete`);
    return data.data;
}

export const useDeleteCampaignContent = () => {
    const { showToast } = useCustomToast();
    const invalidateCampaignLayer = useInvalidateCampaignLayer();

    return useMutation(deleteCampaignContent, {
        onSuccess: async (result, variables) => {
            invalidateCampaignLayer(variables)
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update Campaign's Media Duration --------------------------
const updateMediaDuration = async (payload) => {
    const { data } = await axiosInstance.put(`/campaign/playlist-media/${payload.id}/update`, payload?.data);
    return data.data;
}

export const useUpdateMediaDuration = () => {
    const invalidateCampaignLayer = useInvalidateCampaignLayer();
    const { showToast } = useCustomToast();

    return useMutation(updateMediaDuration, {
        onSuccess: async (result, variables) => {
            invalidateCampaignLayer(variables)
            showToast('success', 'Retrieve Media duration', result?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Retrieve Campaign's Media Duration --------------------------
const retrieveMediaDuration = async (payload) => {
    const { data } = await axiosInstance.put(`/campaign/media/${payload.id}/duration`, {});
    return data.data;
}

export const useRetrieveMediaDuration = () => {
    const invalidateCampaignLayer = useInvalidateCampaignLayer();
    const { showToast } = useCustomToast();

    return useMutation(retrieveMediaDuration, {
        onSuccess: async (result, variables) => {
            invalidateCampaignLayer(variables)
            showToast('success', 'Retrieve Media duration', result?.msg);
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}

//--------------------------Update Campaign's Content Setting --------------------------
const updateCampaignContentSettings = async (payload) => {
    const { data } = await axiosInstance.post(`/campaign/media/${payload.id}/setting`, payload.data);
    return data.data;
}

export const useUpdateCampaignContentSettings = () => {
    const invalidateCampaignLayer = useInvalidateCampaignLayer();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();

    return useMutation(updateCampaignContentSettings, {
        onSuccess: async (variables) => {
            dispatch(toggleDialog("mediaSettingPopup"))
            invalidateCampaignLayer(variables)
            showToast('success', 'Media Setting', "Campaign's media updated successfully!");
        },
        onError: (error) => handleResErrors(error, showToast)
    })
}
