import React, {  useRef } from 'react'

import { Dialog } from "primereact/dialog"
import { PDFExport } from "@progress/kendo-react-pdf";

import inVoiceHeader from "../../../../assets/img/receipt_header.png"

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function InvoicePreview({ invoiceInfo, setInvoiceInfo }) {
    const dispatch = useDispatch();
    const { openDialog, companyDetails } = useSelector((state) => state.global);
    const pdfExportComponent = useRef(null);

    const closeDialog = () => {
        setInvoiceInfo({})
        dispatch(toggleDialog("invoicePreview"))
    }
    const download = () => {
        pdfExportComponent?.current?.save();
    }

    const header = () => {
        return (
            <div className="d-flex align-items-center">
                <div className="col-6 fs">
                    Invoice Preview
                </div>
                <div className="col-6 d-flex justify-content-end align-items-start">
                    <i className="fas fa-download fs-6 me-2 text-secondary cursor-pointer download_icon" onClick={download}></i>
                </div>
            </div>
        )
    }

    return (
        <Dialog visible={openDialog.invoicePreview}
            breakpoints={{ '960px': '95vw' }}
            style={{ width: '40%' }}
            modal
            header={header}
            className="p-fluid"
            onHide={closeDialog}
        >
            {/* Close button  */}

            <PDFExport
                fileName="invoice.pdf"
                title="Invoice"
                subject="Invoice"
                paperSize="A4"
                ref={pdfExportComponent}>
                <div className='d-flex flex-wrap bg-white w-100 h-100 invoice_wrapper p-2'>
                    <img className="col-12" src={inVoiceHeader} alt="" />
                    <div className="d-flex col-12  flex-wrap p-3">

                        <div className="col-12 d-flex flex-wrap mb-3 px-3">
                            <div className="col-5 fw-bold my-1 fs-56">Invoice to</div>
                            <div className="col-7 my-1">{companyDetails?.user?.name}</div>

                            <div className="col-5 fw-bold my-1 fs-6">Data Issued</div>
                            <div className="col-7 my-1">{invoiceInfo?.start_date} </div>

                            <div className="col-5 fw-bold my-1 fs-6">Payment Method</div>
                            <div className="col-7 my-1">{invoiceInfo?.note}</div>
                        </div>

                        <div className="col-12 d-flex flex-wrap mt-4 p-3" style={{ backgroundColor: "#F8F8F9" }}>
                            <h4 className='col-12 mb-3 fw-bold'>Purchase Details:</h4>
                            {/* plan  */}
                            <div className="col-10 mt-3">
                                {invoiceInfo?.title}  Subscription Plan
                            </div>
                            <div className="col-2 mt-3 text-end">$ {invoiceInfo?.price}</div>

                            {/* number_of_devices */}
                            <div className="col-10   fs-8">
                                Number of devices
                            </div>
                            <div className="col-2 text-end fs-8">  {invoiceInfo?.number_of_devices}</div>

                            {/* Period */}
                            <div className="col-12 fw-bold text-capitalize mb-3">
                                {
                                    invoiceInfo?.period == "ONE_YEAR" ? "Annual" : invoiceInfo?.period.toLowerCase().replace(/_/g, " ")
                                }
                            </div>

                            <div className="col-10">
                                <div>Tax</div>
                                <div className='fw-bold'>%{invoiceInfo?.tax} </div>
                            </div>
                            <div className="col-2 text-end">$ {(invoiceInfo?.price * (invoiceInfo?.tax / 100) * invoiceInfo?.number_of_devices).toFixed(2)}</div>

                            <div className="col-12 d-flex fs-6 fw-bold border-2 border-start-0 border-end-0 my-3 py-3">
                                <div className="col-9 ">Total</div>
                                <div className="col-3 text-end">$ {(invoiceInfo?.paid_price).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </PDFExport>

        </Dialog>
    )
}

export default InvoicePreview