import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useEffect } from 'react'
import _ from 'lodash';

import { getFormErrorMessage } from '../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useCreateRssMutation, useUpdateRssMutation } from '../../../queries/rss';
import { resetRssStates, rssDataHandler } from '../../../redux/Slices/Apps/appsSlice';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';
import { TitleInput } from '../../SharedComponents/RSS/Inputs';
import RssSettings from '../../SharedComponents/RSS/RssSettings';
import RssPreview from '../../SharedComponents/RSS/RssPreview';
import { defaultRssSetting } from '../../../config/constants';

function AddRssDialog() {
    const { formState: { errors }, handleSubmit, control, watch, reset } = useForm({ mode: 'onChange', shouldFocusError: false });
    const { selectedRss, isCreation, } = useSelector(state => state.apps);
    const { openDialog } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const createRss = useCreateRssMutation();
    const updateRss = useUpdateRssMutation();
    const urlInput = watch('url');

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0];
            const errorElement = document.getElementById(firstErrorField);
            if (errorElement) errorElement.focus();
        }
    }, [errors]);

    useEffect(() => {
        if (isCreation)
            reset(defaultRssSetting)
        else {
            let settings = selectedRss?.rss_settings;
            reset({
                ...settings,
                position: (settings?.position),
                direction: (settings?.direction === "rtl" ? "right" : "left"),
                font_size: Number(settings?.font_size),
                title: selectedRss?.title?.split('.')[0],
                name: selectedRss?.title?.split('.')[0],

                url: selectedRss?.path,
            })
        }

        return () => dispatch(resetRssStates())
    }, [])

    const formHandler = async (data) => {
        const formData = new FormData();
        formData.append("url", data.url);
        formData.append("name", data?.title);

        dispatch(rssDataHandler({ formData, data }));

        if (isCreation)
            await createRss.mutateAsync(formData)
        else
            await updateRss.mutateAsync({ id: selectedRss?.id, data: formData })
    }

    const onClose = () => {
        dispatch(toggleDialog("rssFeedAddition"))
    }

    return (
        <Dialog visible={openDialog.rssFeedAddition}
            style={{ width: '70%', overFlow: "hidden" }}
            breakpoints={{ '960px': '95vw' }}
            header={`RSS ${isCreation ? "Creation" : "Updating"}`}
            maximizable={true}
            blockScroll={true}
            modal className="p-fluid"
            onHide={onClose}
        >
            <form onSubmit={handleSubmit(formHandler)} className="d-flex flex-column justify-content-center" encType="multipart/form-data" >
                <div className="col-12 d-flex ">
                    <div className={`col-7 auto-column my-5 px-3 `} >
                        <RssPreview rssURL={urlInput} />
                    </div>

                    <div className="col-5 ">
                        <div className='d-flex flex-wrap px-4 justify-content-center py-3 border-start border-gray scroll_div rss_wrapper'>
                            <TitleInput control={control} errors={errors} />

                            {/* URL */}
                            <div className='mb-2 col-12'>
                                <div className="field ">
                                    <label className="mb-0 form-label text-capitalize"> RSS Feed URL</label>
                                    <span className="p-float-label">
                                        <Controller name="url" control={control}
                                            rules={{
                                                required: 'RSS Feed URL is required.',
                                                pattern: {
                                                    value: /^(www|http:|https:)+[^\s]+[\w]/,
                                                    message: 'Invalid URL format'
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    id={field.name}
                                                    {...field}
                                                    inputRef={field.ref}
                                                    className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                    placeholder="https://"
                                                />
                                            )} />
                                    </span>
                                    {getFormErrorMessage('url', errors)}
                                </div>
                            </div>
                            <RssSettings errors={errors} control={control} reset={reset} />
                        </div>
                    </div>


                </div>

                <div className="col-12 text-center d-flex align-items-center justify-content-center py-4 border-top border-gray">
                    <Button className="py-2 px-4 me-3 w-auto p-button-secondary p-button-sm" label="Cancel" disabled={createRss.isLoading || updateRss.isLoading} onClick={onClose} />
                    <Button className="py-2 px-4 me-3 w-auto p-button-sm" label={isCreation ? "Create" : "Publish"} loading={createRss.isLoading || updateRss.isLoading} />
                </div>
            </form>

        </Dialog >
    )
}

export default AddRssDialog