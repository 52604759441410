import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useEffect } from "react";

import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";

import { formatDuration, formatSize, getTitle } from "../../../../config/global";
import { toggleDialog, toggleDeleteDialog } from '../../../../redux/Slices/Global/globalSlice';
import { deleteItemLocally } from '../../../../redux/Slices/scrollSlice';
import { useGalleryContext } from "../../../Contexts/GalleryContext";
import DeleteConfirmation from "../../../SharedComponents/DeleteConfirmation";
import { useDeleteMedia } from "../../../../queries/media";
import MoveMediaFolder from "../FolderDragDrop/MoveMediaFolder";
import UpdateMedia from "./UpdateMedia";
import LazyImage from "../../../SharedComponents/ShowMedia/LazyImage";


function MediaCard({ item, displayAction = true }) {
  const { layout, setLayout, idsList, setIdsList, multiSelectCheck, selectedItem, setSelectedItem } = useGalleryContext();
  const { openDialog, deleteDialog } = useSelector((state) => state.global);
  const deleteMedia = useDeleteMedia();
  const dispatch = useDispatch();
  const menu = useRef(null);

  useEffect(() => {
    if (window.location.pathname.includes("media/gallery") || window.location.pathname.includes("links")) {
      setLayout('grid')
    } else {
      setLayout('list')
    }
  }, [])

  const options = [
    {
      label: "Options",
      items: [
        {
          label: "Update",
          icon: "pi pi-refresh",
          command: () => {
            popupHandler("updateMedia")
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            deletePopupHandler("media");
          },
        },
        {
          label: "Move",
          icon: "pi pi-arrow-right",
          command: () => {
            popupHandler("moveMediaFolder")
          },
          visible: getTitle() === "Media"
        },
      ],
    },
  ];

  const deleteHandler = async () => {
    await deleteMedia.mutateAsync(
      { id: item.id },
      {
        onSuccess: () => {
          dispatch(deleteItemLocally(item.id));
          dispatch(toggleDeleteDialog("media"));
        }
      });
  }

  // On Media Checkbox Change
  const onMediaChange = (e) => {
    let selectedMedia = [...idsList];

    if (e.checked) {
      selectedMedia.push(e.value);
    }
    else {
      selectedMedia.splice(selectedMedia.indexOf(e.value), 1);
    }

    setIdsList(selectedMedia);
  };

  const popupHandler = (dialogKey) => {
    setSelectedItem(item.id);
    dispatch(toggleDialog(dialogKey))
  }

  const deletePopupHandler = (dialogKey) => {
    setSelectedItem(item.id)
    dispatch(toggleDeleteDialog(dialogKey));
  }

  return (
    <>
      {
        layout == "grid" ?
          <>
            <div className="h-[85px] w-full flex items-center justify-center overflow-hidden">
              <LazyImage
                media={item}
                popupType="media_preview"
                styleClass="max-w-full max-h-full w-auto h-auto my-1 object-contain"
              />
            </div>
            <div className="fs-6 w-100 text-truncate text-center mt-3">
              {(item.file_name)?.replace(/_/g, ' ')}
            </div>
            <div className="fs-6 w-100 text-truncate text-center mt-2 fw-light fs-8">
              {item.created_at}
            </div>
            <span
              className={`position-absolute text-center ${multiSelectCheck ? "media_action_left" : "media_action_right"}`}
              onClick={(event) => menu.current.toggle(event)} >
              <i className="fas fa-ellipsis-h text-light fs-4" tooltip="option" tooltipOptions={{ position: "bottom" }}
                onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup ></i>
              <Menu model={options} popup ref={menu} id="popup_menu" />
            </span>
            {
              multiSelectCheck && (
                <Checkbox
                  inputId={item.id}
                  name="media"
                  className="me-2 position-absolute grid_media_checkbox media_checkbox"
                  value={item.id}
                  onChange={onMediaChange}
                  checked={idsList.indexOf(`${item.id}`) !== -1}
                />
              )}
          </>
          :
          <>
            {!displayAction ? (
              <>
                <div className="col-2 pe-lg-3 pe-3 list_media h-[58px] me-2 flex justify-center items-center overflow-hidden">
                  <LazyImage
                    media={item}
                    popupType="media_preview"
                    styleClass="max-w-full max-h-full w-auto h-auto object-contain"
                    className="flex justify-center items-center"
                  />
                </div>
                <div className="col-9 col-md-6 col-lg-4 text-truncate pe-md-2 pe-lg-4">
                  {(item.file_name).replace(/_/g, ' ')}
                </div>
                <div className="col-1 col-md-2 col-lg-2 fs-6 text-center d-none d-lg-block ">
                  {(item.type).toLowerCase()}
                </div>
                <div className="col-2 col-md-2 col-lg-2 fs-6 text-center d-none d-lg-block ">
                  {item.type === "IMAGE" || item.type === "CORNER"
                    ? "--"
                    : formatDuration(item.duration)}
                </div>
                <div className="col-1 col-md-2 col-lg-2 fs-6  text-truncate text-center d-none d-md-block">
                  {formatSize(item.size) == "0 bytes"
                    ? "--"
                    : formatSize(item.size)}
                </div>
              </>
            ) : (
              <>
                <div className="col-2 col-sm-1 pe-2 pe-lg-5 list_media h-[55px] flex justify-center items-center overflow-hidden">
                  <LazyImage
                    media={item}
                    popupType="media_preview"
                    styleClass="max-w-full max-h-full w-auto h-auto object-contain"
                  />
                </div>
                <div className="col-3 col-sm-3 col-md-2 text-truncate pe-md-4">
                  {(item.file_name).replace(/_/g, ' ')}
                </div>
                <div className="col-md-1"></div>
                <div className="col-2 col-md-1 fs-6 text-center  d-none d-sm-block">
                  {item.type === "IMAGE" || item.type === "CORNER"
                    ? "--"
                    : formatDuration(item.duration)}
                </div>
                <div className="media_type col-1 col-md-2 fs-6 text-truncate  text-center  d-none d-sm-block ">
                  {item.type.toLowerCase() == "corner"
                    ? "image"
                    : (item.type.toLowerCase()).replace(/_/g, ' ')}
                </div>
                <div className="col-3 col-sm-2 col-md-2  fs-6 text-truncate text-center">
                  {formatSize(item.size) == "0 bytes"
                    ? "--"
                    : formatSize(item.size)}
                </div>
                <div className="col-2 col-md-2 fs-6 text-center  d-none d-xl-block">
                  {item.created_at}
                </div>
              </>
            )}

            {
              displayAction &&
              (
                <div className="col-4 col-sm-3 col-md-3  col-xl-1 text-end d-flex align-items-center justify-content-end">
                  <div className="actions">
                    <Tooltip target={`.preview-button-${item.id}`} showDelay={100} className="fs-8" />
                    <button
                      className={`btn btn-sm btn-icon  preview-button-${item.id}`}
                      data-pr-position="bottom"
                      data-pr-tooltip="preview"
                      onClick={() => popupHandler("previewMedia")}
                    >
                      <i className="far fa-eye text-light "></i>
                    </button>

                    <Tooltip target={`.update-button-${item.id}`} className="fs-8" />
                    <button
                      className={`btn btn-sm btn-icon update-button-${item.id}`}
                      data-pr-position="bottom"
                      data-pr-tooltip="update"
                      onClick={() => popupHandler("updateMedia")}
                    >
                      <i className="pi pi-pencil text-light "></i>
                    </button>

                    <Tooltip target={`.delete-button-${item.id}`} className="fs-8" />
                    <button
                      className={`btn btn-sm btn-icon delete-button-${item.id}`}
                      data-pr-position="bottom"
                      data-pr-tooltip="delete"
                      onClick={() => { deletePopupHandler("media") }}
                    >
                      <i className="bx bx-trash text-light"></i>
                    </button>

                    {
                      item.section !== "LINKS" && <>
                        <Tooltip target={`.move-button-${item.id}`} className="fs-8" />
                        <button
                          className={`btn btn-sm btn-icon move-button-${item.id}`}
                          data-pr-position="bottom"
                          data-pr-tooltip="move"
                          onClick={() => { popupHandler("moveMediaFolder") }}
                        >
                          <i className="fas fa-arrow-right text-light fs-6"></i>
                        </button>
                      </>
                    }
                  </div>

                  {
                    multiSelectCheck && (
                      <Checkbox inputId={item.id} name="media" className="mx-1 mx-sm-3 media_checkbox p-1 " value={item.id} onChange={onMediaChange}
                        checked={idsList.indexOf(`${item.id}`) !== -1}
                      />
                    )
                  }

                </div>
              )}
          </>
      }

      {(deleteDialog.media && selectedItem == item.id) && <DeleteConfirmation isLoading={deleteMedia.isLoading} itemName={item.file_name} deleteHandler={deleteHandler} dialogKey="media" />}

      {(openDialog.updateMedia && selectedItem == item.id) && <UpdateMedia item={item} />}

      {(openDialog.moveMediaFolder && selectedItem == item.id) && <MoveMediaFolder data={item} />}

    </>
  );
}

export default MediaCard;
